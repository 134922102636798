import { textContrastColor, whitenColor } from '@/utils/color'

export default {

    methods: {
        textColor (...args) {
            return textContrastColor(...args)
        },

        whitenColor (...args) {
            return whitenColor(...args)
        },

        headerColorStyle (color) {
            color = color || '#FFFFFF'
            return {
                color: this.textColor(color),
                backgroundColor: color
            }
        }
    }

}
