import Vue from 'vue'

export default Vue.extend({
    name: 'WisolDataGroupModuleLinkRelation',

    inheritAttrs: false,

    computed: {
        middleware () {
            return {
                disabled: (ctx, next) => {
                    if (this.disabled) {
                        ctx.data = true
                    } else {
                        next()
                    }
                },

                isLoading: (ctx, next) => {
                    if (this.isLoading) {
                        ctx.data = true
                    } else {
                        next()
                    }
                },

                filter: (ctx, next) => {
                    if (this.filter) {
                        ctx.data = [
                            ...ctx.data,
                            ...this.filter
                        ]
                    }
                    next()
                },

                fixedValues: (ctx, next) => {
                    if (this.filter) {
                        this.filter.forEach(values => Object.assign(ctx.data, values))
                    }
                    next()
                }
            }
        },

        parents () {
            return this.$parent.links
                .filter(link => {
                    return link.type === 'relation' && link.targetId === this.$parent.id
                })
                .map(link => {
                    return {
                        dataGroup: link.getSource(),
                        options: link.options
                    }
                })
        },

        filter () {
            const filter = this.parents
                .map(parent => this.getFilterFromParent(parent))
                .filter(filter => !!filter)

            if (!filter.length) {
                return null
            }
            return filter
        },

        disabled () {
            return this.parents.length && (this.isLoading || !this.filter)
        },

        isLoading () {
            return !!this.parents.find(({ dataGroup }) => {
                return dataGroup.isLoading
            })
        }
    },

    methods: {
        getFilterFromParent ({ dataGroup, options }) {
            if (!dataGroup.activeRow) {
                return null
            }

            if (!options || !options.mapping) {
                const rowId = dataGroup.getRowId(dataGroup.activeRow)
                if (rowId) {
                    return JSON.parse(rowId)
                }
                return null
            }

            const mapping = options.mapping
            const keys = Object.keys(mapping)
            if (!keys.length) {
                return null
            }

            const filter = {}
            const rowValues = dataGroup.activeRow.values
            for (const key in mapping) {
                if (!(mapping[key] in rowValues)) {
                    return null
                }
                filter[key] = rowValues[mapping[key]]
            }
            return filter
        }
    },

    render: h => h()
})
