<template>
    <div
        v-observe-visibility="onVisibilityChange"
        :class="mainClasses"
    >
        <template v-if="isVisible">
            <generic-popover
                v-if="showFilterManager"
                :target="showFilterManager"
                popover-classes="component-wisol-module-grid-box-filter-manager"
                @close="closeFilterManager"
            >
                <tab-container :tabs="filterTabs">
                    <template #default="{ activeTab }">
                        <template v-if="activeTab.type === 'pre-filter' && isAdmin && hasPreFilter">
                            <generic-filter
                                :readonly="true"
                                :filter="dataGroup.preFilter"
                            />
                        </template>
                        <template v-if="activeTab.type === 'filter' && advancedFilterModule">
                            <generic-filter
                                :filter="advancedFilter"
                                @update="onAdvancedFilterUpdate"
                            />
                        </template>
                        <template v-if="activeTab.type === 'local-filter' && hasLocalFilter">
                            <generic-filter
                                :readonly="true"
                                :filter="dataGroup.localFilter"
                            />
                        </template>
                    </template>
                </tab-container>
            </generic-popover>
            <div
                v-if="showHeader"
                :style="headerStyle"
                class="header"
            >
                <div class="title">
                    {{ title }}
                </div>
                <div class="action-bar">
                    <generic-action-icons
                        :actions="headerActions"
                        class="actions"
                    />
                </div>
                <div
                    v-show="isDirty"
                    :title="$t('data.dirty')"
                    class="dirty-icon"
                >
                    <icon name="fa/solid/exclamation" />
                    <span class="count">{{ dataGroup.dirtyRowCount }}</span>
                </div>
            </div>
            <div
                v-if="showActionBar"
                class="action-bar"
            >
                <generic-action-icons
                    :actions="actions"
                    :groups="actionGroups"
                    :fill-group="fillGroup"
                    class="actions"
                />
            </div>
            <div class="container">
                <error-capturer>
                    <template #default="{ error, reset }">
                        <div
                            v-if="error"
                            class="widget-error"
                        >
                            <p><strong>Unexpected widget error</strong></p>
                            <p>{{ error.message }}</p>
                            <p>
                                <button @click="reset">
                                    reload
                                </button>
                            </p>
                        </div>
                        <template v-else>
                            <div
                                :is="widgetComponent"
                                v-if="showWidget"
                                :id="widget['@id']"
                                :layout="widgetLayout"
                                :data-group="dataGroup"
                                v-bind="widgetOptions"
                                @update:layout="onLayoutUpdate"
                            />
                        </template>
                    </template>
                </error-capturer>
            </div>
        </template>
        <div
            v-else
            class="loading-box"
        >
            loading "{{ title }}"...
        </div>
        <confirm-dialog
            ref="confirmDialog"
            :color="color"
        />
    </div>
</template>

<style lang="scss">
@import "@wisol/theme/variables";

.component-wisol-module-grid-box {
    @include wisol-box;
    padding: 0;
    display: flex;
    flex-direction: column;
    color: nth($color-palette-grey, 5);
    height: 100%;

    &:not(.isVisible) {
        background: #FFF9;
    }

    .loading-box {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &>.header {
        font-size: 1.2em;
        flex: 0 0 2.2em;
        display: flex;
        align-items: center;

        .dirty-icon {
            font-size: 1.3em;
            background: map-get($status-colors, 'exception');
            padding: 0 0.44em;
            color: text-contrast-color(map-get($status-colors, 'exception'), $text-color);
            height: 100%;
            border-width: 0 0 0 1px;
            border-style: solid;
            border-color: #FFFFFF;

            .count {
                font-size: 0.7em;
            }
        }

        .title {
            margin: 0 0.6em;
            flex: 1 0 0;
            text-transform: uppercase;
        }
    }

    &>.action-bar {
        font-size: 1em;
        flex: 0 0 2.5em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: nth($color-palette-grey, 2);

        .actions {
            width: 100%;
        }
    }

    &>.container {
        max-width: none;
        padding: 0;
        flex: 1 1 0;
        overflow: auto;
    }

    .widget-error {
        height: 100%;
        padding: 1em;
        background: red;
        color: #fff;
    }
}

.component-wisol-module-grid-box-filter-manager {
    @include wisol-popup;

    .popover-container {
        padding: 0;
    }
}
</style>

<script>
import Vue, { getCurrentInstance } from 'vue'
import ColorsMixin from '@/mixins/Colors'
import UIMixin from '@/mixins/UI'
import Registry from '@/Registry'
import ActionReceiverMixin from '@/mixins/ActionReceiver'
import * as filterUtils from '@/utils/filter'
import TabContainer from '../../../generic/TabContainer'
import { ErrorCapturer } from '@wisol/utils-error/components'
import createScriptFunction from '@/utils/createScriptFunction.js'
import Icon from '@wisol/libs-icons'
import StoreModule from '@/store/modules/box'
import useStoreWrapper from '@/composition/useStoreWrapper.js'
import { ObserveVisibility } from 'vue-observe-visibility'
import ConfirmDialog from '../../dialog/Confirm.vue'

export default {
    directives: {
        ObserveVisibility
    },

    components: {
        TabContainer,
        ErrorCapturer,
        Icon,
        ConfirmDialog
    },

    mixins: [
        ColorsMixin,
        UIMixin('box'),
        ActionReceiverMixin('contextActions')
    ],

    inheritAttrs: false,

    props: {
        id: {
            type: Number,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        color: {
            type: String,
            default: '#FFFFFF'
        },
        layout: {
            type: Object,
            required: true
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        showActionBar: {
            type: Boolean,
            default: true
        },
        getDataGroup: {
            type: Function,
            required: true
        }
    },

    setup (props) {
        const {
            getter,
            dispatch
        } = useStoreWrapper(getCurrentInstance().proxy.$store, StoreModule, 'box', {
            id: props.id,
            autoUnregister: false
        })

        return {
            getter,
            dispatch
        }
    },

    data () {
        return {
            showFilterManager: false,
            delayedShowWidget: false,
            isVisible: false
        }
    },

    computed: {
        meta () {
            return this.$store.getters['ui/box/byId'](this.id)
        },
        backgroundColor () {
            return this.whitenColor(this.color)
        },
        headerStyle () {
            return this.headerColorStyle(this.backgroundColor)
        },
        widgets () {
            return this.$store.getters['ui/widget/all'].filter(widget => widget['@box'] === this.id)
        },
        widget () {
            if (this.widgets.length === 0) {
                return undefined
            }

            const widget = this.getter('widget')
            if (widget) {
                return widget
            }

            if (this.widgets.length === 1) {
                return this.widgets[0]
            }
            return this.widgets.find(widget => widget.default) || this.widgets[0]
        },

        dataGroup () {
            return this.getDataGroup()
        },

        showWidget () {
            return this.delayedShowWidget && !this.dataGroup.disabled && this.widgetComponent
        },

        widgetComponent () {
            if (!this.widget) {
                return null
            }
            const { component } = Registry.get('widget', this.widget.type)
            return component
        },

        widgetOptions () {
            if (!this.widget) {
                return null
            }
            return this.widget.options || {}
        },

        widgetLayout () {
            if (this.layout[this.widget.type] && this.layout[this.widget.type]['id:' + this.widget['@id']]) {
                return this.layout[this.widget.type]['id:' + this.widget['@id']]
            }
            return {}
        },

        dataGroupActions () {
            return this.$store.getters['ui/dataGroupAction/all']
                .filter(action => action['@dataGroup'] === this.dataGroup.id)
                .map(action => {
                    const scriptFunction = createScriptFunction(this, action.script)
                    return {
                        icon: action.icon,
                        title: action.label,
                        disabled: false,
                        callback: () => scriptFunction({ dataGroup: this.dataGroup })
                    }
                })
        },

        boxActions () {
            return this.$store.getters['ui/boxAction/all']
                .filter(action => action['@box'] === this.id)
                .map(action => {
                    const scriptFunction = createScriptFunction(this, action.script)
                    return {
                        icon: action.icon,
                        title: action.label,
                        group: 'box',
                        disabled: false,
                        callback: () => scriptFunction({ dataGroup: this.dataGroup })
                    }
                })
        },

        actions () {
            return [
                {
                    icon: 'wisol/new',
                    title: this.$t('data.new'),
                    disabled: this.dataGroup.isLoading ||
                        this.dataGroup.readonly ||
                        !this.dataGroup.moduleMap.has('create'),
                    callback: evt => this.dataGroup.moduleMap.get('create').new()
                },
                {
                    icon: 'wisol/save',
                    title: this.$t('data.save'),
                    disabled: !this.dataGroup.isDirty || this.dataGroup.isLoading,
                    callback: evt => this.dataGroup.saveAll()
                },
                {
                    icon: 'fa/light/trash-alt',
                    title: this.$t('data.delete'),
                    disabled: this.dataGroup.isLoading ||
                        !this.dataGroup.activeRow ||
                        this.dataGroup.readonly ||
                        !this.dataGroup.moduleMap.has('delete'),
                    callback: evt => {
                        this.$refs.confirmDialog.open('Delete ?')
                            .then(() => {
                                this.dataGroup.moduleMap.get('delete').deleteActiveRow()
                            })
                            .catch(() => {})
                    }
                },
                {
                    icon: 'fa/light/sync',
                    title: 'Reload Rows',
                    disabled: false,
                    callback: evt => this.dataGroup.debouncedUpdateRows()
                },
                ...this.dataGroupActions,
                ...this.boxActions,
                ...this.contextActions.map(action => {
                    return {
                        ...action,
                        group: 'context'
                    }
                }),
                ...this.widgets.map(widget => {
                    let { icon } = Registry.get('widget', widget.type)
                    icon = icon || 'fa/solid/question-circle'
                    return {
                        icon,
                        title: this.$t('box.' + widget.type),
                        group: 'widgets',
                        callback: evt => {
                            this.dispatch('selectWidget', {
                                widget: widget['@id']
                            })
                        }
                    }
                })
            ]
        },

        actionGroups () {
            return ['default', 'box', 'context', 'widgets']
        },

        fillGroup () {
            return this.contextActions.length > 0
                ? 'context'
                : this.boxActions.length > 0
                    ? 'box'
                    : 'default'
        },

        headerActions () {
            return [
                {
                    icon: 'fa/solid/filter',
                    title: this.$t('data.filter'),
                    callback: evt => {
                        this.openFilterManager(evt.target)
                    }
                }
            ]
        },

        isDirty () {
            return this.dataGroup.isDirty
        },

        advancedFilterModule () {
            return this.dataGroup.moduleMap.get('advanced-filter')
        },

        advancedFilter () {
            if (!this.advancedFilterModule) {
                return []
            }
            return this.advancedFilterModule.filter || []
        },

        isAdmin () {
            return this.$store.getters['session/isAdmin']
        },

        hasPreFilter () {
            return !filterUtils.isEmpty(filterUtils.normalize(this.dataGroup.preFilter))
        },

        hasLocalFilter () {
            return !filterUtils.isEmpty(filterUtils.normalize(this.dataGroup.localFilter))
        },

        filterTabs () {
            const tabs = [
                {
                    type: 'filter',
                    label: 'Filter'
                }
            ]
            if (this.hasLocalFilter) {
                tabs.push({
                    type: 'local-filter',
                    label: 'Local Filter'
                })
            }
            if (this.isAdmin && this.hasPreFilter) {
                tabs.push({
                    type: 'pre-filter',
                    label: 'Pre Filter'
                })
            }
            return tabs
        },

        mainClasses () {
            return {
                'component-wisol-module-grid-box': true,
                isVisible: this.isVisible
            }
        }
    },

    mounted () {
        Vue.nextTick(() => {
            this.delayedShowWidget = true
        })
    },

    methods: {
        onLayoutUpdate (widgetLayout) {
            const newLayout = {
                ...this.layout,
                [this.widget.type]: {
                    ...(this.layout[this.widget.type] || {}),
                    ['id:' + this.widget['@id']]: widgetLayout
                }
            }
            this.$emit('update:layout', newLayout)
        },

        openFilterManager (target) {
            this.showFilterManager = target
        },

        closeFilterManager () {
            this.showFilterManager = false
        },

        onAdvancedFilterUpdate (filter) {
            if (this.advancedFilterModule) {
                this.advancedFilterModule.setFilter(filter)
            }
        },

        onVisibilityChange (isVisible) {
            if (this.$isVisible !== isVisible) {
                this.$isVisible = isVisible
                cancelAnimationFrame(this.$requestAnimationFrame)
                this.$requestAnimationFrame = requestAnimationFrame(() => {
                    if (this.$isVisible) {
                        this.isVisible = true
                    }
                })
            }
        }
    }
}
</script>
