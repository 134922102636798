import triggerDownload from './triggerDownload.js'

export default async function generatePdf (context, data, mime = 'text/html', name = 'download') {
    const result = await context.$http.post(
        'pdf',
        data,
        {
            headers: {
                'Content-Type': mime
            },
            responseType: 'blob'
        }
    )

    const url = URL.createObjectURL(result.data)
    triggerDownload(url, `${name}.pdf`)
    URL.revokeObjectURL(url)
}
