import { ActionReceiverSymbol } from './ActionReceiver.js'

export default (key = 'actions') => {
    return {
        watch: {
            [key]: {
                handler (newActions, oldActions) {
                    if (this.ActionReceiver) {
                        this.ActionReceiver.onActionUnRegister(oldActions)
                        this.ActionReceiver.onActionRegister(newActions)
                    }
                },
                immediate: true
            }
        },

        inject: {
            ActionReceiver: {
                from: ActionReceiverSymbol,
                default: null
            }
        },

        computed: {
            [key]: () => {
                return []
            }
        },

        beforeDestroy () {
            if (this.ActionReceiver) {
                this.ActionReceiver.onActionUnRegister(this[key])
            }
        }
    }
}
