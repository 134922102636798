import createInput from '../createInput.js'
import UiDateTime from '../Ui/DateTime.vue'
import TypeDateTimeValidator from '../../validator/type/DateTime.js'
import Parser from '../../parser/DateTime.js'

export default createInput(UiDateTime, {
    defaultUiProps: () => ({
        showDatePicker: false
    }),
    Parser,
    defaultParserOptions: () => ({
        format: 'HH:mm'
    }),
    validators: () => [TypeDateTimeValidator()]
})
