<template>
    <div class="component-generic-pagination">
        <div class="current-page">
            <div
                :class="pageDownClasses"
                @click="onPageDown"
            >
                <icon name="wisol/left" />
            </div>
            <div
                class="page-container"
                @click="onPageClick"
            >
                <input-number
                    v-if="showPageInput"
                    v-model="pageInputValue"
                    v-auto-focus
                    class="input"
                    @blur="onInputBlur"
                    @keyup.native.capture.enter="onInputEnter"
                />
                <div v-else>
                    {{ current }} / {{ total }}
                </div>
            </div>
            <div
                :class="pageUpClasses"
                @click="onPageUp"
            >
                <icon name="wisol/right" />
            </div>
        </div>
        <div
            v-if="showCountSelector"
            class="count-selector-container"
        >
            <input-selectbox
                :value="countString"
                :options="countSelectorOptions"
                class="count-selector-input"
                @update:value="onCountChange"
            />
        </div>
    </div>
</template>

<style lang="scss">
    .component-generic-pagination {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .page-icon {
            height: 1em;
            width: 1em;
        }

        &> .current-page {
            display: flex;
            align-items: center;

            &> .button {
                display: block;
                width: 25px;
                height: 25px;
                line-height: 25px;
                flex: 0 0 20px;
                text-align: center;
                cursor: pointer;

                &.disabled {
                    color: #CCCCCC;
                }
            }

            &> .page-container {
                flex: 1;
                text-align: center;
                cursor: pointer;

                .input {
                    height: 100%;
                    width: 70px;
                }
            }
        }

        .count-selector-input {
            width: 70px;
        }
    }
</style>

<script>
import { Input as Inputs } from '@wisol/libs-inputs'
import { AutoFocus } from '@wisol/utils-focus/directives'
import Icon from '@wisol/libs-icons'

export default {
    name: 'GenericPagination',

    directives: {
        AutoFocus
    },

    components: {
        InputSelectbox: Inputs.Selectbox,
        InputNumber: Inputs.Number,
        Icon
    },

    inheritAttrs: false,

    props: {
        page: {
            type: Number,
            required: true
        },

        count: {
            type: Number,
            required: true
        },

        total: {
            type: Number,
            required: true
        },

        showCountSelector: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            showPageInput: false,
            pageInputValue: ''
        }
    },

    computed: {
        from () {
            return ((this.page - 1) * this.count) + 1
        },

        to () {
            return Math.min(this.total, this.page * this.count)
        },

        canPageDown () {
            return this.page > 1
        },

        canPageUp () {
            return this.to < this.total
        },

        pageDownClasses () {
            return {
                button: true,
                'page-icon': true,
                disabled: !this.canPageDown
            }
        },

        pageUpClasses () {
            return {
                button: true,
                'page-icon': true,
                disabled: !this.canPageUp
            }
        },

        current () {
            if (this.count === 1) {
                return this.from
            }
            return this.from + ' - ' + this.to
        },

        countSelectorOptions () {
            const values = [10, 20, 50, 100, 200]
            if (values.indexOf(this.count) === -1) {
                values.push(this.count)
            }
            values.sort((a, b) => a - b)
            const options = {}
            values.forEach(value => {
                const valueString = '' + value
                options[valueString] = valueString
            })
            return options
        },

        countString () {
            return '' + this.count
        }
    },

    methods: {
        onPageDown () {
            if (this.canPageDown) {
                this.$emit('update:page', {
                    page: this.page - 1
                })
            }
        },

        onPageUp () {
            if (this.canPageUp) {
                this.$emit('update:page', {
                    page: this.page + 1
                })
            }
        },

        onPageClick () {
            this.showPageInput = true
        },

        onInputBlur () {
            this.showPageInput = false
        },

        onInputEnter () {
            if (this.pageInputValue) {
                const position = Math.min(this.total, Math.max(1, parseInt(this.pageInputValue, 10)))
                const page = Math.floor((position - 1) / this.count) + 1
                const index = (position - 1) % this.count
                this.$emit('update:page', {
                    page, index
                })
            }
            this.showPageInput = false
            this.pageInputValue = ''
        },

        onCountChange ({ value }) {
            this.$emit('update:count', {
                count: parseInt(value, 10)
            })
        }
    }
}
</script>
