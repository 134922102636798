<script>
export default {
    inject: ['listing', 'listingCell'],

    inheritAttrs: false,

    methods: {
        onMouseDown (evt) {
            evt.dnd = {
                column: this.listingCell.column
            }
            this.listing.bus.$emit('plugin:dnd:startDragging', evt)
        }
    },

    render (h) {
        return this.$scopedSlots.default({
            listeners: {
                mousedown: this.onMouseDown
            }
        })
    }
}
</script>
