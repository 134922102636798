<template>
    <div
        class="component-wisol-editor-menu-detail-widget"
    >
        <breadcrumbs
            :selected="row"
            @showDetail="showDetail"
        />
        <widget-form
            :row="row"
            class="form"
            @updateRow="onUpdateRow"
        />
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-menu-detail-widget {
        padding: 20px 20px 0px 20px;

        &> .form {
            margin-bottom: 20px;
        }
    }

</style>

<script>
import WisolEditorMenuBreadcrumbs from '../Breadcrumbs'
import WisolEditorMenuFormWidget from '../form/Widget'

export default {
    name: 'WisolEditorMenuDetailWidget',

    components: {
        'widget-form': WisolEditorMenuFormWidget,
        breadcrumbs: WisolEditorMenuBreadcrumbs
    },

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            required: true
        }
    },

    methods: {
        showDetail (event) {
            this.$emit('showDetail', event)
        },

        onUpdateRow (event) {
            this.$emit('updateRow', event)
        }
    }
}
</script>
