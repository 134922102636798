<template>
    <div
        :title="action.title"
        :class="classes"
        @click="onClick"
    >
        <icon
            :name="action.icon"
            :class="$style.icon"
        />
        <div
            v-if="busy"
            :class="$style.loadingIconWrapper"
        >
            <icon
                name="fa/light/spinner-third"
                :class="$style.icon"
                :spin="true"
            />
        </div>
    </div>
</template>

<style lang="scss" module>
    @import "@wisol/theme/variables";

    .container {
        position: relative;
        display: flex;
        height: 100%;
        padding: 0 0.4em;
        align-items: center;
        justify-content: center;

        &:not(.disabled) {
            cursor: pointer;
        }
    }

    .loadingIconWrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon {
        height: 1.15em;
        width: 1.15em;
        pointer-events: none;
    }

    .disabled > .icon {
        color: nth($color-palette-grey, 3)
    }
</style>

<script>
import Icon, { register as registerIcons } from '@wisol/libs-icons'
import SpinnerIcon from '@wisol/libs-icons/src/icons/fa/light/spinner-third'

registerIcons(SpinnerIcon)

export default {
    components: {
        Icon
    },

    inheritAttrs: false,

    props: {
        action: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            busy: false
        }
    },

    computed: {
        classes () {
            return {
                [this.$style.container]: true,
                [this.$style.disabled]: this.disabled
            }
        },

        disabled () {
            return this.busy || this.action.disabled
        }
    },

    methods: {
        onClick (evt) {
            if (!this.disabled && this.action.callback) {
                this.busy = true
                Promise.resolve(this.action.callback(evt))
                    .finally(() => {
                        this.busy = false
                    })
            }
        }
    }
}
</script>
