<template>
    <apex-chart
        ref="apexChart"
        :options="options"
        :series="convertedSeries"
        :type="type"
        height="100%"
        width="100%"
    />
</template>

<script>
import Vue from 'vue'
import { merge } from '@wisol/utils-data/functions'
import isString from 'lodash-es/isString'
import ActionProviderMixin from '@/mixins/ActionProvider'
import triggerDownload from '@/utils/triggerDownload.js'
import { svgToImage } from '@wisol/utils-image/functions'

export default {
    name: 'WisolWidgetChartType1d',

    components: {
        ApexChart: () => import('vue-apexcharts')
    },

    mixins: [
        ActionProviderMixin()
    ],

    inheritAttrs: false,

    props: {
        type: {
            type: String,
            required: true
        },

        category: {
            type: String,
            required: true
        },

        series: {
            type: [String, Array],
            required: true
        },

        chartOptions: {
            type: Object,
            default () {
                return {}
            }
        },

        format: {
            type: Object,
            default () {
                return {}
            }
        },

        rows: {
            type: Array,
            required: true
        },

        dataGroup: {
            type: Vue,
            required: true
        }
    },

    computed: {
        categories () {
            return this.rows.map(row => row[this.category] || 'Unknown')
        },

        defaultOptions () {
            return {
                colors: [
                    '#690183',
                    '#00A9CE',
                    '#FFCA83',
                    '#AA1366',
                    '#05994F',
                    '#B9131D'
                ],
                chart: {
                    toolbar: {
                        show: false
                    },
                    parentHeightOffset: 0
                },
                dataLabels: {
                    formatter: (val) => {
                        return this.formatter('dataLabels', val)
                    }
                },
                xaxis: {
                    labels: {
                        formatter: (val) => {
                            return this.formatter('xaxis', val)
                        }
                    }
                },
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return this.formatter('yaxis', val)
                        }
                    }
                }
            }
        },

        formatOptions () {
            return merge(
                {
                    xaxis: {
                        prefix: '',
                        suffix: '',
                        decimalPoint: '.',
                        decimalCount: 0,
                        // decimalForce: false,
                        thousand: ' '
                    },
                    yaxis: {
                        prefix: '',
                        suffix: '',
                        decimalPoint: '.',
                        decimalCount: 2,
                        // decimalForce: false,
                        thousand: ' '
                    },
                    dataLabels: {
                        prefix: '',
                        suffix: '',
                        decimalPoint: '.',
                        decimalCount: 2,
                        // decimalForce: false,
                        thousand: ' '
                    }
                },
                this.format
            )
        },

        options () {
            return merge(
                this.defaultOptions,
                {
                    labels: this.categories
                },
                this.chartOptions
            )
        },

        convertedSeries () {
            return this.convertSeriesConfig(
                isString(this.series)
                    ? this.series
                    : this.series[0]
            )
        },

        actions () {
            return [
                {
                    icon: 'fa/light/file-download',
                    title: 'Download chart',
                    disabled: false,
                    callback: evt => {
                        if (!this.$refs.apexChart) {
                            return
                        }
                        const svgUrl = this.$refs.apexChart.chart.exports.svgUrl()

                        const imagePromise = svgToImage(svgUrl, { scale: 2 })
                            .then(imageUrl => {
                                triggerDownload(imageUrl, 'chart.png')
                                URL.revokeObjectURL(imageUrl)
                            })

                        imagePromise.finally(() => {
                            URL.revokeObjectURL(svgUrl)
                        })

                        return imagePromise
                    }
                }
            ]
        }
    },

    methods: {
        parseValue (value) {
            let parsedValue = parseFloat(value)
            if (isNaN(parsedValue)) {
                parsedValue = null
            }
            return parsedValue
        },

        convertSeriesConfig (config) {
            const field = isString(config)
                ? config
                : config.field
            return this.getSeriesData(field)
        },

        getSeriesData (field) {
            return this.rows
                .map(row => this.getSeriesDataValue(row, field))
        },

        getSeriesDataValue (row, field) {
            return this.parseValue(row[field])
        },

        formatter (target, val) {
            const options = this.formatOptions[target]
            if (options) {
                const {
                    decimalPoint, decimalCount,
                    thousand, prefix, suffix
                } = options

                if (Number.isNaN(val) || typeof val === 'string' || (!val && val !== 0) || val === Infinity) {
                    return prefix + val + suffix
                }

                const getDecimalCount = number => {
                    const [, decimals = ''] = number.toString(10).split('.')
                    return decimals.length
                }

                const fixedNumber = decimalCount === null || getDecimalCount(val) > decimalCount
                    ? val.toString()
                    : val.toFixed(decimalCount)

                const numberParts = fixedNumber.split('.')
                numberParts[0] = numberParts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1' + thousand)
                val = numberParts.join(decimalPoint)

                return prefix + val + suffix
            }
            return val
        }
    }
}
</script>
