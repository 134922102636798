import Vue, { computed, provide, ref, toRef, toRefs, watch } from 'vue'
import useController from './useController.js'
import TypeStringValidator from '../validator/type/String.js'
import ErrorPopover from './Common/ErrorPopover.vue'
import { useFocusTracker } from '@wisol/utils-focus/composition'
import { unrefElement, useCurrentElement } from '@vueuse/core'
import { emptyValue } from '../validator/Required'
import styles from '../style/input.module.scss'

export const InputSymbol = Symbol('Input')

export const model = {
    prop: 'value',
    event: 'update:model-value'
}

export const props = {
    name: {
        type: String,
        default: null
    },

    value: {
        type: null,
        default: emptyValue
    },

    placeholder: {
        type: String,
        default: ''
    },

    readonly: {
        type: Boolean,
        default: false
    },

    required: {
        type: Boolean,
        default: false
    },

    tabindex: {
        type: Number,
        default: 0
    },

    validators: {
        type: Array,
        default: () => []
    }
}

export default function createInput (UIComponent, {
    defaultUiProps = () => ({}),
    Parser = null,
    defaultParserOptions = () => ({}),
    validators = () => [TypeStringValidator()],
    classes = () => ([]),
    compare = (valueA, valueB) => valueA === valueB,
    debounceTimeout = 500
} = {}) {
    return Vue.extend({
        inheritAttrs: false,

        model,

        props: {
            ...props,

            parserOptions: {
                type: Object,
                default: () => ({})
            }
        },

        setup (props, { attrs, emit }) {
            const rootElement = useCurrentElement()
            const { hasFocus } = useFocusTracker(rootElement)

            const uiRef = ref()
            const uiElement = computed(() => unrefElement(uiRef))

            const parser = computed(() => {
                if (!Parser) {
                    return undefined
                }
                return Parser({
                    context: this,
                    ...defaultParserOptions({
                        ...attrs,
                        ...props
                    }),
                    ...props.parserOptions
                })
            })

            const combinedValidators = computed(() => {
                return [
                    ...validators(),
                    ...props.validators
                ]
            })

            const controller = useController({
                readonly: toRef(props, 'readonly'),
                required: toRef(props, 'required'),
                value: toRef(props, 'value'),
                parser,
                validators: combinedValidators,
                debounceTimeout,
                compare,
                emit,
                hasFocus
            })

            const onInput = value => {
                controller.setDisplayedValue(value, true)
            }

            const onInputKeydown = evt => {
                if (hasFocus.value) {
                    if (evt.key === 'Escape' || evt.key === 'Esc') {
                        evt.stopPropagation()
                        document.activeElement.blur()
                    }
                }
            }

            const onInputKeyup = evt => {
                if (hasFocus.value) {
                    if (evt.key === 'Enter') {
                        controller.confirmValue()
                    }
                }
            }

            const defaultClasses = classes()
            const inputClasses = computed(() => {
                return [
                    defaultClasses,
                    {
                        [styles.readonly]: props.readonly,
                        [styles.focus]: hasFocus.value,
                        [styles.invalid]: !controller.isValid.value,
                        [styles.busy]: controller.isBusy.value
                    }
                ]
            })

            const inputProps = computed(() => {
                return {
                    name: props.name,
                    value: controller.displayedValue.value,
                    disabled: props.readonly,
                    tabindex: props.tabindex,
                    placeholder: props.placeholder
                }
            })
            const inputListeners = {
                input: evt => onInput(evt.target.value),
                click: evt => !props.readonly && evt.stopPropagation(),
                keydown: onInputKeydown,
                keyup: onInputKeyup
            }

            provide(InputSymbol, {
                ...attrs, // not reactive ?!? ...toRefs(attrs)
                ...toRefs(props),
                ...controller,
                inputClasses,
                inputProps,
                inputListeners,
                rootElement,
                uiElement,
                hasFocus
            })

            watch(hasFocus, hasFocus => {
                emit(hasFocus ? 'focus' : 'blur')
            })

            return {
                defaultUiProps: defaultUiProps(),
                uiRef,
                controller
            }
        },

        render (h) {
            const scopedSlots = {}
            for (const name in this.$scopedSlots) {
                scopedSlots[name] = props => this.$scopedSlots[name](props)
            }

            const uiComponent = h(UIComponent, {
                ref: 'uiRef',
                attrs: {
                    ...this.defaultUiProps,
                    ...this.$attrs
                },
                on: this.$listeners,
                scopedSlots
            })

            if (!this.$__errorPopover) {
                this.$__errorPopover = h(ErrorPopover)
            }

            // TODO: when upgrading to Vue3 remove wrapper div
            return h('div', {
                class: styles.wrapper
            }, [uiComponent, this.$__errorPopover])
        }
    })
}
