export default () => {
    return {
        methods: {
            getApps () {
                return this.$http
                    .get(
                        'module/api/apps/apps'
                    )
            },

            postApp (data) {
                return this.$http
                    .post(
                        'module/api/apps/app',
                        data
                    )
            },

            deleteApp (id) {
                return this.$http
                    .delete(
                        'module/api/apps/app/' + id
                    )
            },

            // use put to enable app after revoking
            putApp (id) {
                return this.$http
                    .put(
                        'module/api/apps/app/' + id
                    )
            },

            getEndpoints (id) {
                return this.$http
                    .get(
                        'module/api/apps/endpoints/' + id
                    )
            },

            postEndpoint (data) {
                return this.$http
                    .post(
                        'module/api/apps/endpoint',
                        data
                    )
            },

            deleteEndpoint (id) {
                return this.$http
                    .delete(
                        'module/api/apps/endpoint/' + id
                    )
            },

            getUsers (id) {
                return this.$http
                    .get(
                        'module/api/apps/users/' + id
                    )
            },

            postUser (data) {
                return this.$http
                    .post(
                        'module/api/apps/user',
                        data
                    )
            },

            deleteUser (data) {
                return this.$http
                    .post(
                        'module/api/apps/deleteUser',
                        data
                    )
            },

            // DEVICES

            getSubmittedDevices () {
                return this.$http
                    .get(
                        'module/api/apps/submittedDevices'
                    )
            },

            getValidatedDevices (id = '') {
                return this.$http
                    .get(
                        'module/api/apps/validatedDevices/' + id
                    )
            },

            getActiveDevices () {
                return this.$http
                    .get(
                        'module/api/apps/activeDevices/'
                    )
            },

            getAppDevices (id) {
                return this.$http
                    .get(
                        'module/api/apps/appDevices/' + id
                    )
            },

            postDevice (data) {
                return this.$http
                    .post(
                        'module/api/apps/device',
                        data
                    )
            },

            postValidateDevice (data) {
                return this.$http
                    .post(
                        'module/api/apps/validateDevice',
                        data
                    )
            },

            postDeviceToApp (data) {
                return this.$http
                    .post(
                        'module/api/apps/device2App',
                        data
                    )
            },

            deleteDevice (id) {
                return this.$http
                    .delete(
                        'module/api/apps/device/' + id
                    )
            },

            deleteDeviceToApp (data) {
                return this.$http
                    .post(
                        'module/api/apps/removeDevice2App',
                        data
                    )
            }
        }
    }
}
