import createInput from '../createInput.js'
import UiSelectbox from '../Ui/Selectbox.vue'
import { ValueParser as Parser } from '../../parser/Options.js'

export default createInput(UiSelectbox, {
    Parser,
    defaultParserOptions: (props) => ({
        options: props.options || {}
    })
})
