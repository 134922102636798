export default () => {
    return {
        data () {
            return {
                idPasteMap: new Map()
            }
        },

        methods: {
            pastemap (type, id, virtualId = null) {
                if (!this.idPasteMap.has(type)) {
                    this.idPasteMap.set(type, new Map())
                }
                if (virtualId === null) {
                    return this.idPasteMap.get(type).get(id)
                }

                this.idPasteMap
                    .get(type)
                    .set(id, virtualId)

                return this.idPasteMap.get(type).get(id)
            },

            pasteMenus (menu, parent = null) {
                menu = { ...menu }
                // menu['@parent'] = parent['@id']
                menu['@parentIdType'] = 'virtual'
                menu['@valid'] = true
                menu.isDirty = () => true
                menu.order = null
                delete menu.children
                // menu['name'] = null
                const id = ++this.virtualId
                this.pastemap('menu', menu['@id'], id)
                menu['@id'] = id
                this.newRows = [
                    ...this.newRows,
                    menu
                ]
                this.dirtyRows = [
                    ...this.dirtyRows,
                    menu
                ]
            },

            pasteRoot (root, parent = null) {
                root = { ...root }
                root[this.getParentIdParamName(root['@itemtype'])] = parent['@id']
                root['@parentIdType'] = parent['@idType']
                root['@valid'] = true
                root.isDirty = () => true
                if (root['@itemtype'] === 'menu') {
                    root.order = null
                }
                delete root.children
                // root['name'] = null
                const id = ++this.virtualId
                this.pastemap(root['@itemtype'], root['@id'], id)
                root['@id'] = id
                this.newRows = [
                    ...this.newRows,
                    root
                ]
                this.dirtyRows = [
                    ...this.dirtyRows,
                    root
                ]
            },

            pasteModules (modules) {
                modules.forEach((module) => {
                    module = { ...module }
                    module['@menu'] = this.pastemap('menu', module['@menu'])
                    module['@parentIdType'] = 'virtual'
                    module['@valid'] = true
                    module.isDirty = () => true
                    delete module.children
                    const id = ++this.virtualId
                    this.pastemap('module', module['@id'], id)
                    module['@id'] = id
                    this.newRows = [
                        ...this.newRows,
                        module
                    ]
                    this.dirtyRows = [
                        ...this.dirtyRows,
                        module
                    ]
                })
            },

            pasteDatagroups (dataGroups) {
                dataGroups.forEach((dataGroup) => {
                    dataGroup = { ...dataGroup }
                    dataGroup['@module'] = this.pastemap('module', dataGroup['@module'])
                    dataGroup['@parentIdType'] = 'virtual'
                    dataGroup['@valid'] = true
                    dataGroup.isDirty = () => true
                    delete dataGroup.children
                    const id = ++this.virtualId
                    this.pastemap('dataGroup', dataGroup['@id'], id)
                    dataGroup['@id'] = id
                    this.newRows = [
                        ...this.newRows,
                        dataGroup
                    ]
                    this.dirtyRows = [
                        ...this.dirtyRows,
                        dataGroup
                    ]
                })
            },

            pasteDatagroupActions (dataGroupActions) {
                dataGroupActions.forEach((dataGroupAction) => {
                    dataGroupAction = { ...dataGroupAction }
                    dataGroupAction['@dataGroup'] = this.pastemap('dataGroup', dataGroupAction['@dataGroup'])
                    dataGroupAction['@parentIdType'] = 'virtual'
                    dataGroupAction['@valid'] = true
                    dataGroupAction.isDirty = () => true
                    delete dataGroupAction.children
                    const id = ++this.virtualId
                    this.pastemap('dataGroupAction', dataGroupAction['@id'], id)
                    dataGroupAction['@id'] = id
                    this.newRows = [
                        ...this.newRows,
                        dataGroupAction
                    ]
                    this.dirtyRows = [
                        ...this.dirtyRows,
                        dataGroupAction
                    ]
                })
            },

            pasteBoxes (boxes) {
                boxes.forEach((box) => {
                    box = { ...box }
                    box['@dataGroup'] = this.pastemap('dataGroup', box['@dataGroup'])
                    box['@module'] = this.pastemap('module', box['@module'])
                    box['@parentIdType'] = 'virtual'
                    box['@valid'] = true
                    box.isDirty = () => true
                    delete box.children
                    // box['name'] = null
                    const id = ++this.virtualId
                    this.pastemap('box', box['@id'], id)
                    box['@id'] = id
                    this.newRows = [
                        ...this.newRows,
                        box
                    ]
                    this.dirtyRows = [
                        ...this.dirtyRows,
                        box
                    ]
                })
            },

            pasteBoxActions (boxActions) {
                boxActions.forEach((boxAction) => {
                    boxAction = { ...boxAction }
                    boxAction['@box'] = this.pastemap('box', boxAction['@box'])
                    boxAction['@parentIdType'] = 'virtual'
                    boxAction['@valid'] = true
                    boxAction.isDirty = () => true
                    delete boxAction.children
                    const id = ++this.virtualId
                    this.pastemap('boxAction', boxAction['@id'], id)
                    boxAction['@id'] = id
                    this.newRows = [
                        ...this.newRows,
                        boxAction
                    ]
                    this.dirtyRows = [
                        ...this.dirtyRows,
                        boxAction
                    ]
                })
            },

            pasteFields (fields) {
                fields.forEach((field) => {
                    field = { ...field }
                    field['@dataGroup'] = this.pastemap('dataGroup', field['@dataGroup'])
                    field['@parentIdType'] = 'virtual'
                    field['@valid'] = true
                    field.isDirty = () => true
                    delete field.children
                    const id = ++this.virtualId
                    this.pastemap('field', field['@id'], id)
                    field['@id'] = id
                    this.newRows = [
                        ...this.newRows,
                        field
                    ]
                    this.dirtyRows = [
                        ...this.dirtyRows,
                        field
                    ]
                })
            },

            pasteWidgets (widgets) {
                widgets.forEach((widget) => {
                    widget = { ...widget }
                    widget['@box'] = this.pastemap('box', widget['@box'])
                    widget['@parentIdType'] = 'virtual'
                    widget['@valid'] = true
                    widget.isDirty = () => true
                    delete widget.children
                    const id = ++this.virtualId
                    this.pastemap('widget', widget['@id'], id)
                    widget['@id'] = id
                    this.newRows = [
                        ...this.newRows,
                        widget
                    ]
                    this.dirtyRows = [
                        ...this.dirtyRows,
                        widget
                    ]
                })
            },

            getParentIdParamName (type) {
                switch (type) {
                    case 'menu':
                        return '@parent'
                    case 'module':
                        return '@menu'
                    case 'dataGroup':
                        return '@module'
                    case 'dataGroupAction':
                        return '@dataGroup'
                    case 'box':
                        return '@dataGroup'
                    case 'boxAction':
                        return '@box'
                    case 'field':
                        return '@dataGroup'
                    case 'widget':
                        return '@box'
                }
            }
        }
    }
}
