<template>
    <div class="component-wisol-editor-toolbar">
        <generic-editor-toolbar>
            <div class="group">
                <div
                    class="button"
                    title="Create backup"
                    @click="doAction('new')"
                >
                    <icon
                        name="fa/light/plus"
                        class="icon-button-new"
                    />
                </div>
                <div
                    class="button"
                    title="Restore from file"
                    @click="doAction('restoreFile')"
                >
                    <icon
                        name="fa/light/upload"
                        class="icon-button-upload"
                    />
                </div>
                <div
                    class="button"
                    title="Reset to default"
                    @click="doAction('reset')"
                >
                    <icon
                        name="fa/light/broom"
                        class="icon-button-reset"
                    />
                </div>
            </div>
            <div class="group">
                <div
                    :class="{ disabled: isButtonDisabled('download') }"
                    class="button"
                    title="Download backup"
                    @click="doAction('download')"
                >
                    <icon
                        name="fa/light/download"
                        class="icon-button-download"
                    />
                </div>
                <div
                    :class="{ disabled: isButtonDisabled('restoreSelected') }"
                    class="button"
                    title="Restore backup"
                    @click="doAction('restoreSelected')"
                >
                    <icon
                        name="fa/light/undo"
                        class="icon-button-restore"
                    />
                </div>
            </div>
        </generic-editor-toolbar>
    </div>
</template>

<style lang="scss">
    .component-wisol-editor-toolbar {

    }
</style>

<script>
import GenericEditorToolbar from '../generic/Toolbar'
import Icon from '@wisol/libs-icons'

export default {
    name: 'WisolEditorConfigToolbar',

    components: {
        genericEditorToolbar: GenericEditorToolbar,
        Icon
    },

    inheritAttrs: false,

    props: {
        selected: {
            type: Number,
            default: null
        }
    },

    data () {
        return {

        }
    },

    methods: {
        doAction (action) {
            if (!this.isButtonDisabled(action)) {
                this.$emit('action', action)
            }
        },

        isButtonDisabled (item) {
            switch (item) {
                case 'download':
                case 'restoreSelected':
                    return this.selected === null
            }
        }
    }
}
</script>
