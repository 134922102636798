function scrollIntoView (el) {
    el.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
    })
}

export default {
    inserted (el, { value }) {
        if (value) scrollIntoView(el)
    },

    update (el, { value, oldValue }) {
        if (value && value !== oldValue) scrollIntoView(el)
    }
}
