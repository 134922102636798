<template>
    <div
        class="component-wisol-editor-menu-form-dataGroup"
    >
        <generic-form
            :fields="fields"
            :values="values"
            :layout="layout"
            :column-count="24"
            :cell-height="25"
            :min-cell-width="40"
            :max-cell-width="Infinity"
            :readonly="false"
            @update:value="onUpdateFormValue"
            @update:layout="onLayoutUpdate"
        />
        <generic-loading v-show="isLoading" />
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-menu-form-dataGroup {
        padding: 20px 20px 0px 20px;
    }

</style>

<script>
import { Input as Inputs } from '@wisol/libs-inputs'
import WisolInputFilter from '../../../Input/Filter'
import layout from './layouts/datagroup.json'
import Registry from '@/Registry'

const LOCALSTORAGE_KEY = 'layout_datagroup'

export default {
    name: 'WisolEditorMenuFormDatagroup',

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            default: () => {}
        },

        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            layout,
            labels: {
                '@id': 'ID',
                '@module': 'Module ID',
                filter: 'Filter',
                readonly: 'Readonly',
                table: 'Table',
                name: 'Name',
                modules: 'Modules',
                onclick: 'onClick',
                oninit: 'onInit',
                onsave: 'onSave',
                onupdate: 'onUpdate'
            }
        }
    },

    computed: {
        flatrow () {
            const { scripts, ...row } = {
                ...this.row,
                ...this.row.scripts
            }
            return row
        },

        fields () {
            return Object.keys(this.flatrow).map((field) => {
                let component = Inputs.Text
                const props = {}
                switch (field) {
                    case '@id':
                    case '@module':
                        component = Inputs.Number
                        props.readonly = true
                        break
                    case 'table':
                        props.required = true
                        break
                    case 'readonly':
                        component = Inputs.Checkbox
                        props.required = true
                        break
                    case 'modules': {
                        const types = Registry.getByType('data-group-module')
                        const options = {}
                        for (const type in types) {
                            if (!type.match(/^link\//)) {
                                options[type] = type
                            }
                        }
                        component = Inputs.Array
                        props.input = {
                            component: Inputs.Selectbox,
                            props: { options }
                        }
                        props.unique = true
                        props.allowEmptyValue = false
                        break
                    }
                    case 'filter':
                        component = WisolInputFilter
                        break
                    case 'onclick':
                    case 'oninit':
                    case 'onsave':
                    case 'onupdate':
                        component = Inputs.Code
                        props.language = 'javascript'
                        break
                }

                return {
                    id: field,
                    field: field,
                    label: this.labels[field],
                    input: {
                        component,
                        props
                    }
                }
            })
        },

        values () {
            return {
                ...this.flatrow
            }
        }
    },

    mounted () {
        let local = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || []
        local = local.reduce((arr, l) => {
            const foundLayout = arr.find((item) => item.id === l.id)

            if (foundLayout) {
                foundLayout.position = l.position
            } else {
                arr.push(l)
            }

            return arr
        }, layout)

        this.layout = local
    },

    methods: {
        onLayoutUpdate (layout) {
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(layout))
            this.layout = layout
        },

        onUpdateFormValue ({ field, value }) {
            switch (field) {
                case 'onclick':
                case 'oninit':
                case 'onsave':
                case 'onupdate':
                    value = {
                        onclick: this.values.onclick || null,
                        oninit: this.values.oninit || null,
                        onsave: this.values.onsave || null,
                        onupdate: this.values.onupdate || null,
                        [field]: value || null
                    }
                    field = 'scripts'
                    break
            }
            const row = {
                ...this.row,
                [field]: value
            }
            this.$emit('updateRow', this.validateRow(row))
        },

        validateRow (row) {
            return {
                ...row,
                '@valid': !!row.table
            }
        }
    }
}

</script>
