<script>
import isString from 'lodash-es/isString'

export default {
    name: 'WisolWidgetChartSingleToMultiConverter',

    inheritAttrs: false,

    props: {
        category: {
            type: String,
            required: true
        },

        series: {
            type: [String, Array],
            default: () => ([])
        },

        seriesValues: {
            type: [String, Array],
            required: true
        },

        seriesType: {
            type: String,
            required: true
        },

        seriesLabel: {
            type: String,
            default: undefined
        },

        autoAddSeries: {
            type: Boolean,
            default: false
        },

        rows: {
            type: Array,
            required: true
        }
    },

    computed: {
        seriesTypesMap () {
            const map = new Map()
            this.rows.forEach(row => {
                const type = row[this.seriesType]
                if (!map.has(type)) {
                    const label = this.seriesLabel && row[this.seriesLabel]
                        ? row[this.seriesLabel]
                        : type
                    map.set(type, { label })
                }
            })
            return map
        },

        convertedRows () {
            const map = new Map()
            this.rows.forEach(row => {
                const category = row[this.category] || ''
                const seriesType = row[this.seriesType] || ''
                let currentRow = map.get(category)
                if (!currentRow) {
                    currentRow = {
                        [this.category]: category || 'Unknown'
                    }
                    map.set(category, currentRow)
                }
                for (const key in row) {
                    currentRow[seriesType + ':' + key] = row[key]
                }
            })
            return Array.from(map.values())
        },

        convertedSeries () {
            const series = isString(this.series)
                ? [this.series]
                : this.series
            const seriesTypes = new Set()
            const convertedSeries = series.map(config => {
                if (isString(config)) {
                    config = {
                        seriesType: config
                    }
                }
                let { seriesType, ...newConfig } = config
                if (seriesType) {
                    seriesTypes.add(seriesType)
                    newConfig = this.addMissingSeriesKeys(newConfig, seriesType)
                }
                return newConfig
            })
            if (this.autoAddSeries) {
                this.availableSeriesTypes.forEach(type => {
                    if (!seriesTypes.has(type)) {
                        convertedSeries.push(this.addMissingSeriesKeys({}, type))
                    }
                })
            }
            return convertedSeries
        },

        availableSeriesTypes () {
            const set = new Set()
            this.rows.forEach(row => {
                set.add(row[this.seriesType] || '')
            })
            return Array.from(set)
        }
    },

    methods: {
        addMissingSeriesKeys (config, seriesType) {
            if (!config.label) {
                const typeData = this.seriesTypesMap.get(seriesType)
                config = {
                    ...config,
                    label: typeData ? typeData.label : seriesType
                }
            }
            if (!config.field) {
                let field
                if (isString(this.seriesValues)) {
                    field = seriesType + ':' + this.seriesValues
                } else {
                    field = this.seriesValues.map(field => seriesType + ':' + field)
                }
                config = {
                    ...config,
                    field
                }
            }
            return config
        }
    },

    render () {
        return this.$scopedSlots.default({
            category: this.category,
            series: this.convertedSeries,
            rows: this.convertedRows,
            ...this.$attrs
        })
    }
}
</script>
