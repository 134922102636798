import createInput from '../createInput.js'
import UiText from '../Ui/Text.vue'
import inputClasses from '../../style/input.module.scss'
import TypeNumberValidator from '../../validator/type/Number.js'
import Parser from '../../parser/Number.js'

export default createInput(UiText, {
    Parser,
    defaultParserOptions: () => ({
        thousandsSeparator: ' ',
        decimalPoint: ',',
        decimalCount: null
    }),
    validators: () => [
        TypeNumberValidator()
    ],
    classes: () => [
        inputClasses['text-right']
    ]
})
