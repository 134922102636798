import ResizeObserver from '../common/ResizeObserver.js'

export default {
    bind (node, { value }) {
        ResizeObserver.observe(node, value)
    },

    unbind (node) {
        ResizeObserver.unobserve(node)
    }
}
