export default {
    isAuthenticated (state, getters, rootState) {
        return !!state.token
    },

    token (state, getters) {
        return getters.isAuthenticated
            ? state.token
            : null
    },

    status (state) {
        return state.status
    },

    timeout (state) {
        return state.timeout
    },

    username (state, getters) {
        return getters.isAuthenticated
            ? state.username
            : null
    },

    user (state, getters) {
        return getters.isAuthenticated
            ? state.info.user
            : null
    },

    company (state, getters) {
        return getters.isAuthenticated
            ? state.info.companies.find(company => company.id === getters.firme)
            : null
    },

    companies (state, getters) {
        return getters.isAuthenticated
            ? state.info.companies
            : []
    },

    isAdmin (state, getters) {
        return getters.isAuthenticated && state.info.isAdmin
    },

    isEditorUser (state, getters) {
        return getters.isAuthenticated && state.info.isEditorUser
    },

    firme (state, getters) {
        return getters.isAuthenticated
            ? state.firme
            : null
    },

    environment (state) {
        return state.info.environment
    }
}
