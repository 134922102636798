<template>
    <div
        class="component-wisol-editor-tree"
    >
        <icon
            v-if="icon"
            :name="icon"
            class="icon-item"
        />
        <template v-if="children.length > 0">
            <div
                class="panel"
            >
                <div
                    v-if="parent['@id'] !== null"
                    class="icon-toggle-container"
                    @click.stop="toggleMenu(parent)"
                >
                    <icon
                        :name="getToggleIcon"
                        class="icon-toggle"
                    />
                </div>
                <tree-label
                    :selected="select"
                    :style="draggableStyle(parent)"
                    :class="treeLabelClasses"
                    class="heading"
                    @action="doAction"
                />
                <vddl-list
                    v-if="expanded"
                    :list="children"
                    :drop="onDrop"
                >
                    <template v-for="(child, index) in children">
                        <vddl-draggable
                            v-if="child.isDisplayed(child['@label'], child.children(), child.parent ? child.parent() : null)"
                            :key="child['@idType'] + '-' + child['@itemtype'] + '-' + child['@id']"
                            :class="draggableClasses(child, parent, index)"
                            :draggable="child"
                            :index="index"
                            :wrapper="children"
                            :selected="select"
                            :dragend="dragEnded"
                            effect-allowed="move"
                        >
                            <wisol-editor-tree
                                v-if="enableDragging && child['@itemtype'] === 'menu'"
                                :parent="child"
                                :enable-dragging="enableDragging"
                                :clipboard="clipboard"
                                @updatePos="onDrop"
                                @showDetail="select"
                                @toggle="toggleMenu"
                                @enterItem="dragenterItem"
                                @dragEnded="dragEnded"
                                @action="doAction"
                            />
                            <vddl-nodrag
                                v-else
                            >
                                <wisol-editor-tree
                                    :parent="child"
                                    :enable-dragging="enableDragging"
                                    :clipboard="clipboard"
                                    @updatePos="onDrop"
                                    @showDetail="select"
                                    @toggle="toggleMenu"
                                    @enterItem="dragenterItem"
                                    @dragEnded="dragEnded"
                                    @action="doAction"
                                />
                            </vddl-nodrag>
                        </vddl-draggable>
                    </template>
                </vddl-list>
            </div>
        </template>
        <template v-else>
            <div
                @dragenter.stop="dragenterItem(parent)"
                @dragleave.stop="dragEnded"
            >
                <tree-label
                    :selected="select"
                    :style="draggableStyle(parent)"
                    :class="treeLabelClasses"
                    class="heading"
                    @action="doAction"
                />
            </div>
        </template>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-tree {
        font-size: 13px;
        margin-left: 6px;
        font-size: 1.2rem;

        .icon-item {
            position: absolute;
            top: 4px;
            left: -12px;
            z-index: 1;
            background-color: white;
            font-size: 13px;
            cursor: pointer;
        }

        .icon-toggle-container {
            position: absolute;
            top: -3px;
            left: -40px;
            z-index: 1;
            cursor: pointer;
            width: 45px;
            height: 26px;
            padding: 5px 30px 5px 5px;
        }

        .icon-toggle {
            top: -3px;
            left: 8px;
            position: relative;
            background-color: white;
            font-size: 10px;
            cursor: pointer;
        }

        .icon-dirty {
            color: #e84118;
            font-size: 8px;
            margin-left: 8px;
        }

        .heading {
            .label {
                display: inline-block;
                vertical-align: middle;
                line-height: 23px;
                min-height: 23px;
                min-width: 42px;
                padding-left: 3px;
                padding-right: 3px;
                // cursor: pointer;
            }

            &.activeItem {
                background-color: #E3F2FD;
            }

            &:hover {
                background-color: #E3F2FD;
            }

            i {
                color: nth($color-palette-grey, 4);
            }
        }

        .hidden {
            display:none;
        }

        .nobefore {
            &:before {
                border: none !important;
            }
        }

        .panel {

            &> .vddl-list {
                position: relative;
                min-height: 14px;
                margin-left: 30px;

                &> .vddl-draggable {
                    position: relative;
                    min-height: 14px;
                    line-height: 20px;
                    margin: 1px 0px 1px 0px;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 0px;
                        left: -42px;
                        border-left: 1px solid #ccc;
                        border-radius: 0 0 0 0;
                        width: 20px;
                        height: 11px;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        top: 10px;
                        left: -42px;
                        border-left: 1px solid #ccc;
                        border-top: 1px solid #ccc;
                        border-radius: 0 0 0 0;
                        width: 20px;
                        height: 100%;
                    }

                    &:last-child:after {
                        border: none;
                    }

                    &:last-child:before {
                        border-radius: 0 0 0 2px;
                        border-bottom: 1px solid #ccc;
                    }
                }

                &> .vddl-placeholder {
                    position: relative;
                    width: auto;
                    min-height: 14px;
                    line-height: 14px;
                    padding: 0 15px;
                    background: #E1F5FE;
                    box-sizing: border-box;
                    margin-bottom: 2px;
                    border-radius: 2px;

                    &:before {
                        content: "";
                        position: absolute;
                        top: -4px;
                        left: -42px;
                        border-left: 1px solid #ccc;
                        border-radius: 0 0 0 0;
                        width: 20px;
                        height: 11px;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        top: 6px;
                        left: -42px;
                        border-left: 1px solid #ccc;
                        border-top: 1px solid #ccc;
                        border-radius: 0 0 0 0;
                        width: 20px;
                        height: 100%;
                    }
                }

                &> .vddl-dragging {
                    opacity: 0.7;
                }

                &> .vddl-dragging-source {
                    display: none;
                }
            }
        }
    }
</style>

<script>
import Vddl from 'vddl'
import Vue from 'vue'
import Label from './Label'
import Icon from '@wisol/libs-icons'

Vue.use(Vddl)

export default {
    name: 'WisolEditorTree',

    directives: {
        scrollIntoView: {
            update: (el, binding, vnode) => {
                if (el.attributes.class.value === 'activeItem') {
                    el.scrollIntoView({
                        behaviour: 'smooth',
                        block: 'start'
                    })
                }
            }
        }
    },

    components: {
        treeLabel: Label,
        Icon
    },

    inheritAttrs: false,

    props: {
        parent: {
            type: Object,
            required: true
        },
        enableDragging: {
            type: Boolean,
            required: true
        },
        clipboard: {
            type: Object,
            required: true
        }
    },

    computed: {
        children () {
            return this.parent.children()
        },

        getToggleIcon () {
            return this.expanded ? 'fa/light/minus-square' : 'fa/light/plus-square'
        },

        expanded () {
            return this.parent.isExpanded()
        },

        selected () {
            return this.parent.isSelected()
        },

        dirty () {
            return this.parent.isDirty()
        },

        cut () {
            return this.parent.isCut()
        },

        treeLabelClasses () {
            return {
                activeItem: this.selected
            }
        },

        label () {
            return this.parent['@label']
        },

        idType () {
            return this.parent['@idType']
        },

        icon () {
            let name
            switch (this.parent['@itemtype']) {
                case 'menu':
                    name = !this.expanded || this.children.length <= 0
                        ? 'fa/solid/folder'
                        : 'fa/solid/folder-open'
                    break
                case 'module':
                    switch (this.parent.type) {
                        case 'grid':
                            name = 'fa/light/th-large'
                            break
                        case 'timesheet':
                            name = 'fa/light/calendar-alt'
                            break
                        case 'proposal':
                            name = 'fa/light/th-list'
                            break
                        default:
                            name = 'fa/light/cube'
                    }
                    break
                case 'box_group':
                    name = 'fa/light/window-restore'
                    break
                case 'box':
                    name = 'fa/light/window-maximize'
                    break
                case 'field':
                    name = 'fa/light/tag'
                    break
                case 'field_group':
                    name = 'fa/light/tags'
                    break
                case 'dataGroup':
                    name = 'fa/light/object-ungroup'
                    break
                case 'boxAction_group':
                case 'dataGroupAction_group':
                    name = 'fa/light/caret-square-right'
                    break
                case 'link_group':
                    name = 'fa/light/share-alt'
                    break
                case 'dataGroupLink':
                    switch (this.parent.type) {
                        case 'relation':
                            name = 'fa/light/arrows-h'
                            break
                        default:
                            name = 'fa/light/link'
                    }
                    break
                case 'boxAction':
                case 'dataGroupAction':
                    name = this.parent.icon
                    break
                case 'widget':
                    switch (this.parent.type) {
                        case 'listing':
                            name = 'fa/light/list-alt'
                            break
                        case 'form':
                            name = 'wisol/form'
                            break
                    }
                    break
            }
            return name
        }
    },

    methods: {
        doAction (action) {
            this.$emit('action', action)
        },
        dragEnded (event) {
            this.$emit('dragEnded', event)
        },

        dragenterItem (event) {
            this.$emit('enterItem', event)
        },

        onDrop (event) {
            this.$emit('updatePos', event)
        },

        select (event) {
            if (!event.isCut()) {
                this.$emit('showDetail', event)
            }
        },

        draggableClasses (child, parent, index) {
            const children = child.children()
            return {
                bordered: children.length <= 0,
                nobefore: parent['@id'] === 'root',
                toggle: children.length > 0
            }
        },

        draggableStyle (child) {
            return {
                cursor: this.enableDragging && child['@itemtype'] === 'menu' ? 'grab' : 'pointer'
            }
        },

        toggleMenu (id) {
            this.$emit('toggle', id)
        }
    }
}
</script>
