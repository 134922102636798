<template>
    <input-ui-dropdown
        ref="input"
        :class="inputClasses"
        @keydown.native="onKeyDown"
    >
        <data-group
            id="dropdown"
            ref="dataGroup"
            v-slot="{ dataGroup }"
            :table="table"
            :data-provider="dataProvider"
            :pre-filter="filter"
            :fields="columns"
            :modules="modules"
            :initial-active-row-position="null"
            :auto-unregister-store="true"
        >
            <picker
                :initial-filter-value="getAndResetCatchInputValue()"
                :columns="columns"
                :data-group="dataGroup"
                @update="setValue($event); focus()"
            />
        </data-group>
    </input-ui-dropdown>
</template>

<script>
import InputUiDropdown from '@wisol/libs-inputs/src/components/Ui/Dropdown.vue'
import Picker from './Picker.vue'
import DataGroup from '../../Data/Group/index.js'
import TextInput from '@wisol/libs-inputs/src/components/Input/Text.js'
import ModulePagination from '../../Data/Group/module/Pagination.js'
import DataProvider from '../../Data/Provider.js'
import { WisolInputSymbol } from '../index.vue'
import { inject } from 'vue'
import { InputSymbol } from '@wisol/libs-inputs/src/components/createInput.js'

export default {
    components: {
        InputUiDropdown,
        Picker,
        DataGroup
    },

    inject: {
        WisolInput: {
            from: WisolInputSymbol
        }
    },

    props: {
        table: {
            type: String,
            required: true
        },

        fields: {
            type: Array,
            default: () => []
        },

        filter: {
            type: [Object, Array],
            default: () => null
        }
    },

    setup () {
        const { inputClasses, setValue } = inject(InputSymbol)
        return {
            inputClasses,
            setValue
        }
    },

    data () {
        return {
            catchInputValue: '',
            dataProvider: null,
            modules: [
                {
                    name: 'pagination',
                    component: ModulePagination,
                    props: {
                        initialRowsPerPage: 10
                    }
                }
            ]
        }
    },

    computed: {
        columns () {
            return this.fields.map(field => {
                return {
                    label: field,
                    field: field,
                    filter: {
                        component: TextInput,
                        props: {}
                    },
                    width: 200
                }
            })
        }
    },

    watch: {
        table: {
            handler () {
                const oldDataProvider = this.dataProvider
                this.dataProvider = new DataProvider({
                    propsData: {
                        source: 'wisol.dropdown:' + this.WisolInput.id
                    },
                    parent: this
                })
                if (oldDataProvider) {
                    oldDataProvider.$destroy()
                }
            },
            immediate: true
        }
    },

    destroyed () {
        this.dataProvider.$destroy()
        this.dataProvider = null
    },

    methods: {
        openDropdown () {
            if (this.$refs.input && this.$refs.input.openDropdown) {
                this.$refs.input.openDropdown()
            }
        },

        focus () {
            if (this.$refs.input && this.$refs.input.focus) {
                this.$refs.input.focus()
            }
        },

        onKeyDown (evt) {
            let catchInputValue = this.catchInputValue
            if (evt.key.length === 1) {
                catchInputValue += evt.key
            } else if (evt.key === 'Backspace') {
                catchInputValue = this.catchInputValue.slice(0, -1)
            }

            if (catchInputValue !== this.catchInputValue) {
                evt.preventDefault()
                this.catchInputValue = catchInputValue
                this.openDropdown()
            }
        },

        getAndResetCatchInputValue () {
            const catchInputValue = this.catchInputValue
            this.catchInputValue = ''
            return catchInputValue
        }
    }
}
</script>
