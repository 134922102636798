<template>
    <div
        :class="$style.listing"
    >
        <div
            :class="$style.container"
        >
            <component
                :is="listingComponent"
                :class="$style.listingComponent"
                :columns="visibleColumns"
                :rows="rows"
                :tree-id-key="treeIdColumn"
                :tree-parent-key="treeParentColumn"
                column-id-key="id"
                row-id-key="@id"
                :default-column-width="200"
                :get-row-props="getRowProps"
                v-bind="$attrs"
                @row:click="onActiveRowUpdate"
            >
                <template #plugins>
                    <edit-plugin v-if="!readonly" />
                    <resize-plugin
                        v-if="app.layoutMode"
                        @resize="onColumnResize"
                    />
                    <sorting-plugin
                        :sorting="sorting"
                        @update="onUpdateSorting"
                    />
                    <dnd-plugin
                        v-if="app.layoutMode"
                        :can-drop="canDrop"
                        @move="onColumnMove"
                    >
                        <template #default="{ column }">
                            {{ column.label }}
                        </template>
                    </dnd-plugin>
                </template>
                <template #overlay>
                    <status-display
                        v-if="!dataGroup.isReady"
                        :data-group="dataGroup"
                    />
                </template>
                <template #default="{ row, column }">
                    <style-wrapper
                        :class="$style.cell"
                        :data-group="dataGroup"
                        :field="column['@field']"
                        :row="row['@row']"
                    >
                        <component
                            :is="getCellComponent(row, column)"
                            :column="column"
                            :row="row"
                            :data-group="dataGroup"
                        />
                    </style-wrapper>
                </template>
            </component>
        </div>
        <generic-selection-bar
            v-if="showSelectionBar"
            :count="selectionCount"
            :filter-by-selection="filterBySelection"
            :class="$style.selectionBar"
            @reset="onResetSelection"
            @filter-by-selection="onFilterBySelectionUpdate"
        />
        <generic-pagination
            v-if="showPagination"
            :page="page"
            :count="rowsPerPage"
            :total="dataGroup.totalRowCount"
            :class="$style.pagination"
            @update:page="onPageUpdate"
            @update:count="onCountUpdate"
        />
        <generic-popover
            v-if="showColumnSelector"
            :target="showColumnSelector"
            :popover-classes="$style.columnSelector"
            @close="closeColumnSelector"
        >
            <div
                v-for="column in columnsSortedByLabel"
                :key="column['@id']"
                :class="$style.columnSelectorColumn"
            >
                <checkbox-input
                    :value="column.visible ? true : false"
                    :class="$style.columnSelectorCheckbox"
                    @update:value="onColumnVisibiltyChange(column, $event)"
                />
                {{ column.label }}
            </div>
        </generic-popover>
    </div>
</template>

<style lang="scss" module>
    @import "@wisol/theme/variables";
    @import "../../../../../../libs/inputs/src/style/variables/input";

    .listing {
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
    }

    .container {
        position: relative;
        flex: 1 1 0;
        overflow: auto;
    }

    .listingComponent {
        height: 100%;
        width: 100%;
    }

    .pagination,
    .selectionBar {
        flex: 0 0 2.5rem;
        background: nth($color-palette-grey, 2);
    }

    .selectionBar {
        margin-bottom: 1px;
    }

    .columnSelector {
        @include wisol-popup;
    }

    .columnSelectorColumn {
        display: flex;

        &:not(:last-child) {
            margin: 0 0 0.5em 0;
        }
    }

    .columnSelectorCheckbox {
        margin-right: 0.5em;
    }

    .listing .activeRow {
        background-color: #D0ECFF;
    }

    .cell {
        position: relative;
        width: 100%;
        height: 100%;
        background: var(--cell-background, none);
        white-space: nowrap;
        padding: 2px;
    }
</style>

<script>
import Vue from 'vue'
import ActionProviderMixin from '@/mixins/ActionProvider'
import WisolInput from '../../Input'
import { Listing, Tree as ListingTree, ResizePlugin, SortingPlugin, EditPlugin, DndPlugin } from '@wisol/libs-listing'
import StatusDisplay from '../../Data/Group/StatusDisplay'
import Registry from '@/Registry'
import CheckCell from './cell/Check.vue'
import ComputeCell from './cell/Compute.vue'
import LabelCell from './cell/Label.vue'
import FilterCell from './cell/Filter.vue'
import DataCell from './cell/Data.vue'
import StyleWrapper from '../../StyleWrapper.vue'
import { Input as Inputs } from '@wisol/libs-inputs'

export default {
    name: 'WisolWidgetListing',

    components: {
        CheckboxInput: Inputs.Checkbox,
        StatusDisplay,
        ResizePlugin,
        SortingPlugin,
        EditPlugin,
        DndPlugin,
        StyleWrapper
    },

    mixins: [
        ActionProviderMixin()
    ],

    inject: ['app'],

    inheritAttrs: false,

    props: {
        id: {
            type: Number,
            required: true
        },
        layout: {
            type: Object,
            required: true
        },
        dataGroup: {
            type: Vue,
            required: true
        },
        showTree: {
            type: Boolean,
            default: false
        },
        treeIdColumn: {
            type: String,
            default: '@id'
        },
        treeParentColumn: {
            type: String,
            default: '@parent'
        }
    },

    data () {
        return {
            showColumnSelector: false
        }
    },

    computed: {
        listingComponent () {
            if (this.showTree) {
                return ListingTree
            }
            return Listing
        },

        meta () {
            return this.$store.getters['ui/widget/byId'](this.id)
        },

        columns () {
            const columns = []
            const { dataGroup } = this
            dataGroup.fields
                .forEach(field => {
                    const columnLayout = this.getColumnLayout(field)

                    // get compute data
                    let hasCompute = false
                    let computeData = null
                    const computeOptions = ((field.options || {}).listing || {}).compute || null
                    if (computeOptions && computeOptions.type) {
                        computeData = Registry.get('listing-compute', computeOptions.type)
                        if (computeData) {
                            hasCompute = true
                            computeData = {
                                ...computeData,
                                props: {
                                    ...(computeData.props || {}),
                                    ...computeOptions
                                }
                            }
                        }
                    }

                    columns.push({
                        id: field['@id'],
                        type: 'data',
                        field: field.field,
                        '@field': field,
                        label: field.label,
                        pos: columnLayout && 'pos' in columnLayout
                            ? columnLayout.pos
                            : null,
                        visible: columnLayout && columnLayout.visible,
                        input: row => {
                            return {
                                component: WisolInput,
                                props: dataGroup.getInputProps(field, row)
                            }
                        },
                        filter: {
                            component: WisolInput,
                            props: {
                                id: field['@id'],
                                tag: 'filter'
                            }
                        },
                        width: columnLayout && columnLayout.width
                            ? columnLayout.width
                            : null,
                        get editMode () {
                            const { readonly } = dataGroup.getInputProps(field)
                            if (readonly) {
                                return false
                            }
                            return (
                                field.type === 'checkbox' ||
                                field.type === 'ole' ||
                                field.type === 'button'
                            )
                                ? true
                                : 'auto'
                        },
                        resizable: true,
                        hasCompute,
                        computeData
                    })
                })

            columns.sort((a, b) => {
                if (a.visible !== b.visible) {
                    return a.visible ? -1 : 1
                }
                if (a.pos !== b.pos) {
                    if (b.pos === null) {
                        return -1
                    }
                    if (a.pos === null) {
                        return 1
                    }
                    return a.pos - b.pos
                }
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })

            let nextIndex = 1
            columns.forEach(column => {
                column.showTreeHandle = this.showTree && nextIndex === 1
                column.pos = nextIndex++
            })

            if (this.hasSelection) {
                columns.unshift({
                    id: 'check',
                    type: 'check',
                    visible: true,
                    resizable: false,
                    '@field': null,
                    width: 40
                })
            }

            return columns
        },

        hasCompute () {
            return this.columns.find(column => column.hasCompute) || false
        },

        sorting () {
            return this.dataGroup.sorting.map(item => {
                return {
                    ...item,
                    column: this.columns.find(column => column.field === item.field)
                }
            })
        },

        visibleColumns () {
            return this.columns.filter(column => {
                return column.visible
            })
        },

        footerRows () {
            if (this.bodyRows.length === 0) {
                return []
            }
            const rows = []
            if (this.hasCompute) {
                rows.push(this.computeFooterRow)
            }
            return rows
        },

        bodyRows () {
            const { dataGroup, treeIdColumn, treeParentColumn, showTree } = this

            return dataGroup.rows.map((row, index) => {
                const { canUpdate, id, rowId } = dataGroup.getRowData(row)
                const mappedRow = {
                    '@id': id,
                    '@index': index,
                    '@row': row,
                    target: 'body',
                    type: 'data',
                    readonly: !canUpdate,
                    selectable: rowId !== null
                }
                if (showTree) {
                    mappedRow[treeIdColumn] = row.values[treeIdColumn] || null
                    mappedRow[treeParentColumn] = row.values[treeParentColumn] || null
                }
                return mappedRow
            })
        },

        rows () {
            return [
                ...this.headerRows,
                ...this.footerRows,
                ...this.bodyRows
            ]
        },

        activeRow () {
            return this.rows.find(row => {
                return row['@row'] === this.dataGroup.activeRow
            })
        },

        columnsSortedByLabel () {
            return this.columns.slice().sort((a, b) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })
        },

        readonly () {
            return this.dataGroup.readonly
        },

        showPagination () {
            return this.dataGroup.moduleMap.has('pagination')
        },

        page () {
            if (!this.showPagination) {
                return null
            }
            return this.dataGroup.moduleMap.get('pagination').page
        },

        rowsPerPage () {
            if (!this.showPagination) {
                return null
            }
            return this.dataGroup.moduleMap.get('pagination').rowsPerPage
        },

        hasSelection () {
            return this.dataGroup.moduleMap.has('selection')
        },

        showSelectionBar () {
            return this.hasSelection &&
                this.selectionCount > 0
        },

        selectionCount () {
            if (!this.hasSelection) {
                return 0
            }
            return this.dataGroup.moduleMap.get('selection').count
        },

        filterBySelection () {
            if (!this.hasSelection) {
                return false
            }
            return this.dataGroup.moduleMap.get('selection').filterBySelection
        },

        actions () {
            return [
                {
                    icon: 'wisol/pin',
                    title: 'Select Columns',
                    disabled: false,
                    callback: evt => {
                        this.openColumnSelector(evt.target)
                    }
                }
            ]
        }
    },

    created () {
        // this rows will never change
        this.headerRows = Object.freeze([
            Object.freeze({ '@id': 'header:label', type: 'label', target: 'header', '@row': null }),
            Object.freeze({ '@id': 'header:filter', type: 'filter', target: 'header', '@row': null })
        ])
        this.computeFooterRow = Object.freeze({ '@id': 'footer:compute', type: 'compute', target: 'footer', '@row': null })
    },

    methods: {
        getRowProps (row) {
            return {
                class: {
                    [this.$style.activeRow]: row === this.activeRow
                }
            }
        },

        onPageUpdate ({ page }) {
            const pagination = this.dataGroup.moduleMap.get('pagination')
            pagination.selectPage(page)
        },

        onActiveRowUpdate (row) {
            if (row.type === 'data') {
                this.dataGroup.setActiveIndex(row['@index'])
            }
        },

        onUpdateSorting (sorting) {
            this.dataGroup.setSorting(sorting.map(item => {
                return {
                    field: item.column.field,
                    direction: item.direction
                }
            }))
        },

        onCountUpdate ({ count }) {
            const pagination = this.dataGroup.moduleMap.get('pagination')
            pagination.setRowsPerPage(count)
        },

        onResetSelection () {
            if (!this.hasSelection) {
                return
            }
            const selection = this.dataGroup.moduleMap.get('selection')
            selection.reset()
        },

        getColumnLayout (field) {
            if (!this.layout.columns) {
                return null
            }
            return this.layout.columns.find(columnLayout => {
                return columnLayout.id === field['@id']
            }) || null
        },

        onFilterBySelectionUpdate (filterBySelection) {
            if (!this.hasSelection) {
                return
            }
            const selection = this.dataGroup.moduleMap.get('selection')
            selection.setFilterBySelection(filterBySelection)
        },

        openColumnSelector (target) {
            this.showColumnSelector = target
        },

        closeColumnSelector () {
            this.showColumnSelector = false
        },

        onColumnVisibiltyChange (column, { value: visible }) {
            let columns = this.layout.columns || []
            const index = columns.findIndex(columnLayout => {
                return columnLayout.id === column.id
            })

            if (visible) {
                if (index > -1) {
                    if (columns[index].visible) {
                        return
                    }
                    columns = [
                        ...columns.slice(0, index),
                        {
                            ...columns[index],
                            visible: true
                        },
                        ...columns.slice(index + 1)
                    ]
                } else {
                    const nextPos = columns.reduce((pos, columnLayout) => {
                        return Math.max(columnLayout.pos, pos)
                    }, 0) + 1
                    columns = [
                        ...columns,
                        {
                            id: column.id,
                            pos: nextPos,
                            visible: true,
                            width: 0
                        }
                    ]
                }
            } else {
                if (index > -1) {
                    if (!columns[index].visible) {
                        return
                    }
                    columns = [
                        ...columns.slice(0, index),
                        {
                            ...columns[index],
                            visible: false
                        },
                        ...columns.slice(index + 1)
                    ]
                } else {
                    return
                }
            }
            this.$emit('update:layout', {
                ...this.layout,
                columns
            })
        },

        onColumnResize ({ column, width }) {
            let columns = this.layout.columns
            const index = columns.findIndex(columnLayout => {
                return columnLayout.id === column.id
            })
            columns = [
                ...columns.slice(0, index),
                {
                    ...columns[index],
                    width
                },
                ...columns.slice(index + 1)
            ]
            this.$emit('update:layout', {
                ...this.layout,
                columns
            })
        },

        canDrop (column, index) {
            return index !== 0 && column.type === 'data'
        },

        onColumnMove ({ column, index }) {
            index-- // skip check column

            // sort columns by visibility and position
            let columns = this.layout.columns
                .slice(0)
                .sort((a, b) => {
                    if (a.visible !== b.visible) {
                        return a.visible ? -1 : 1
                    }

                    if (!Number.isInteger(b.pos)) {
                        return -1
                    }
                    if (!Number.isInteger(a.pos)) {
                        return 1
                    }
                    return a.pos - b.pos
                })

            // move column to the new index
            const currentIndex = columns.findIndex(columnLayout => {
                return columnLayout.id === column.id
            })
            columns.splice(index, 0, columns.splice(currentIndex, 1)[0])

            // update pos
            let nextPos = 1
            columns = columns.map(columnLayout => {
                return {
                    ...columnLayout,
                    pos: nextPos++
                }
            })

            // update layout
            this.$emit('update:layout', {
                ...this.layout,
                columns
            })
        },

        getCellComponent (row, column) {
            if (row.type === 'compute') {
                return ComputeCell
            }
            if (column.type === 'check') {
                return CheckCell
            }
            if (row.type === 'label') {
                return LabelCell
            }
            if (row.type === 'filter') {
                return FilterCell
            }
            if (row.type === 'data') {
                return DataCell
            }
        }
    }
}
</script>
