<template>
    <div :class="$style.label">
        <dnd-handle v-if="app.layoutMode">
            <template #default="{ listeners }">
                <div
                    :class="$style.dndHandle"
                    v-on="listeners"
                >
                    <icon name="fa/solid/grip-vertical" />
                </div>
            </template>
        </dnd-handle>
        <sorting-handle :class="$style.sortingHandle">
            <template
                #default="{ listeners }"
            >
                <div
                    :class="$style.headerLabel"
                    v-on="listeners"
                >
                    {{ column.label }}
                </div>
            </template>
        </sorting-handle>
    </div>
</template>

<style lang="scss" module>
@import "../../../../../../../libs/inputs/src/style/variables/input";

.label {
    padding: $input-padding;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.dndHandle {
    height: 1em;
    width: 2em;
    padding-right: 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sortingHandle {
    width: 100%;
}

.headerLabel {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
}
</style>

<script>
import Vue from 'vue'
import { SortingHandle, DndHandle } from '@wisol/libs-listing'
import Icon, { register as registerIcons } from '@wisol/libs-icons'
import DndHandleIcon from '@wisol/libs-icons/src/icons/fa/solid/grip-vertical'

registerIcons(DndHandleIcon)

export default {
    components: {
        SortingHandle,
        DndHandle,
        Icon
    },

    inject: ['app'],

    inheritAttrs: false,

    props: {
        column: {
            type: Object,
            required: true,
            validator: column => 'width' in column
        },

        row: {
            type: Object,
            required: true
        },

        dataGroup: {
            type: Vue,
            required: true
        }
    }
}
</script>
