<template>
    <div
        :class="$style.picker"
        @mousedown="onPickerMouseDown"
    >
        <div
            :class="$style.listingContainer"
            @keydown.down="dataGroup.next()"
            @keydown.up="dataGroup.prev()"
            @keydown.left="onPageUpdate({ page: page - 1 })"
            @keydown.right="onPageUpdate({ page: page + 1 })"
            @keydown.enter="emitActiveRow"
        >
            <listing
                v-bind="$attrs"
                :columns="columns"
                :rows="rows"
                column-id-key="field"
                row-id-key="@id"
                :get-row-props="getRowProps"
                @row:click="onActiveRowUpdate"
            >
                <template #default="{ row, column }">
                    <div
                        v-if="row.target === 'header'"
                        :class="$style.headerCell"
                    >
                        <div
                            v-if="row.type === 'label'"
                            :class="$style.inputPadding"
                        >
                            {{ column.label }}
                        </div>
                        <component
                            :is="column.filter.component"
                            v-if="row.type === 'filter'"
                            :class="$style.input"
                            v-bind="column.filter.props"
                            :value="getFilterValue(column)"
                            @update:value="onUpdateFilterValue(column, $event)"
                        />
                    </div>
                    <div
                        v-if="row.target === 'body'"
                        :class="$style.bodyCell"
                    >
                        <div :class="$style.inputPadding">
                            {{ getValue(row, column) }}
                        </div>
                    </div>
                </template>
                <template #overlay>
                    <status-display
                        v-if="!dataGroup.isReady"
                        :data-group="dataGroup"
                    />
                </template>
            </listing>
        </div>
        <generic-pagination
            :class="$style.pagination"
            :page="page"
            :count="rowsPerPage"
            :total="dataGroup.totalRowCount"
            @update:page="onPageUpdate"
            @update:count="onCountUpdate"
        />
    </div>
</template>

<style lang="scss" module>
    @import "@wisol/theme/variables";
    @import "../../../../../../libs/inputs/src/style/variables/input";

    .picker {
        background: #FFF;
        min-height: 200px;
        display: flex;
        flex-direction: column;
        max-height: 100%;
    }

    .listingContainer {
        position: relative;
        flex: 1 1 auto;
    }

    .inputPadding {
        padding: $input-padding;
    }

    .input {
        width: 100%;
    }

    .cell {
        white-space: nowrap;
        position: relative;
        padding: 2px;
    }

    .headerCell {
        composes: cell;
    }

    .bodyCell {
        composes: cell;
        cursor: pointer;
    }

    .pagination {
        flex: 0 0 2.5rem;
        background: nth($color-palette-grey, 2);
    }

    .listingContainer .activeRow {
        background-color: #D0ECFF;
    }
</style>

<script>
import Vue from 'vue'
import { Listing } from '@wisol/libs-listing'
import StatusDisplay from '../../Data/Group/StatusDisplay'
import InputFilterParser from '../Text/Filter/Parser'
import { isTabbable } from 'tabbable'

export default {
    name: 'WisolInputDropdownListing',

    components: {
        Listing,
        StatusDisplay
    },

    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'update'
    },

    props: {
        columns: {
            type: Array,
            required: true
        },

        dataGroup: {
            type: Vue,
            required: true
        },

        value: {
            type: null,
            default: null
        },

        initialFilterValue: {
            type: String,
            default: ''
        }
    },

    computed: {
        parser () {
            return InputFilterParser()
        },

        page () {
            return this.dataGroup.moduleMap.get('pagination').page
        },

        rowsPerPage () {
            return this.dataGroup.moduleMap.get('pagination').rowsPerPage
        },

        activeRow () {
            return this.dataGroup
                ? this.dataGroup.activeRow
                : null
        },

        headerRows () {
            return [
                { '@id': 'header:label', type: 'label', target: 'header', '@row': null },
                { '@id': 'header:filter', type: 'filter', target: 'header', '@row': null }
            ]
        },

        bodyRows () {
            const { dataGroup } = this
            return dataGroup.rows.map((row, index) => {
                const { id } = dataGroup.getRowData(row)
                return {
                    '@id': id,
                    '@index': index,
                    '@row': row,
                    target: 'body',
                    type: 'data'
                }
            })
        },

        rows () {
            return [
                ...this.headerRows,
                ...this.bodyRows
            ]
        }
    },

    mounted () {
        const currentFilter = this.dataGroup.localFilter || {}
        const column = this.columns[0]
        this.setFilterValues(column, this.initialFilterValue + (currentFilter[column.field] || ''))
    },

    beforeDestroy () {
        this.dataGroup.setActivePosition(null)
        this.dataGroup.resetSorting()
        this.dataGroup.resetFilter()
    },

    methods: {
        onPageUpdate ({ page }) {
            const pagination = this.dataGroup.moduleMap.get('pagination')
            pagination.selectPage(page)
        },

        onCountUpdate ({ count }) {
            const pagination = this.dataGroup.moduleMap.get('pagination')
            pagination.setRowsPerPage(count)
        },

        onActiveRowUpdate (row) {
            if (row['@row']) {
                if (this.activeRow === row['@row']) {
                    this.emitActiveRow()
                } else {
                    this.dataGroup.setActiveIndex(row['@index'])
                }
            }
        },

        onUpdateFilterValue (column, { value }) {
            this.setFilterValues(column, value)
        },

        setFilterValues (column, value) {
            this.dataGroup.setFilterValues({
                [column.field]: value ? this.parser.serialize(value) : value
            })
        },

        emitActiveRow () {
            if (this.activeRow) {
                this.$emit('update', this.dataGroup.getRowValues(this.activeRow))
            }
        },

        getValue (row, column) {
            const values = this.dataGroup.getRowValues(row['@row'])
            return values[column.field] || ''
        },

        getFilterValue (column) {
            return column.field in this.dataGroup.localFilter
                ? this.parser.parse(this.dataGroup.localFilter[column.field])
                : null
        },

        getRowProps (row) {
            return {
                class: {
                    [this.$style.activeRow]: row['@row'] === this.activeRow
                }
            }
        },

        onPickerMouseDown (evt) {
            if (!isTabbable(evt.target)) {
                evt.preventDefault()
            }
        }
    }
}
</script>
