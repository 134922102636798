<template>
    <popup
        v-if="visible"
        :title="title"
        v-bind="$attrs"
        @close="visible = false"
    >
        <div :class="$style.buttonContainer">
            <button-input
                label="Cancel"
                @click="cancel()"
            />
            <button-input
                label="OK"
                @click="ok()"
            />
        </div>
    </popup>
</template>

<script>
import Popup from '../Popup.vue'
import { Input as WisolInputs } from '@wisol/libs-inputs'

export default {
    components: {
        Popup,
        ButtonInput: WisolInputs.Button
    },

    data () {
        return {
            visible: false,
            title: 'Confirm'
        }
    },

    methods: {
        open (title) {
            this.title = title || 'Confirm'
            const promise = new Promise((resolve, reject) => {
                this.ok = () => resolve()
                this.cancel = () => reject(new Error('Cancel'))
                this.visible = true
            })
            promise
                .catch(() => {})
                .finally(() => {
                    this.visible = false
                })
            return promise
        }
    }
}
</script>

<style module>
    .buttonContainer {
        display: flex;
        justify-content: center;
        padding: 0.5em;
    }

    .buttonContainer > *:not(:last-child) {
        margin-right: 1em;
    }
</style>
