import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import httpPlugin from './plugins/http'
import i18n from '../i18n'
import i18nConfig from '../i18n/vuex/config'

import AlertModule from './modules/alert'
import LayoutModule from './modules/layout'
import SessionModule from './modules/session'
import SettingsModule from './modules/settings'
import UiModule from './modules/ui'
import WindowsModule from './modules/windows'

Vue.use(Vuex)

const store = new Vuex.Store({
    strict: false, // process.env.NODE_ENV !== 'production',
    plugins: [
        httpPlugin(),
        i18nConfig(i18n)
    ],
    modules: {
        alert: AlertModule,
        layout: LayoutModule,
        session: SessionModule,
        settings: SettingsModule,
        ui: UiModule,
        windows: WindowsModule
    },
    actions,
    getters,
    mutations,
    state () {
        return {}
    }
})

export default async () => {
    return store
}
