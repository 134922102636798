<template>
    <div :class="mainClasses">
        <slot
            v-for="item in visibleItems"
            v-bind="item"
            name="item"
        >
            <generic-menu-item
                :id="item['@id']"
                :key="item['@id']"
                :label="item.label"
                :color="item.color"
                :icon="item.icon"
                :disabled="item.disabled"
                :has-submenu="hasSubmenu(item)"
                :submenu-placement="submenuPlacement"
                class="menu-item"
                @click="onClick(item)"
            >
                <template
                    #submenu
                >
                    <generic-menu
                        v-if="hasSubmenu(item)"
                        :items="items"
                        :parent="item['@id']"
                        :is-popup="true"
                        :placement="submenuPlacement"
                        @click="onClick"
                    />
                </template>
            </generic-menu-item>
        </slot>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-generic-menu {
        margin: 0;
        padding: 0;
        border: 1px solid nth($color-palette-grey, 2);
        background-color: #FFFFFF;
        min-width: 200px;

        &.is-popup {
            z-index: 1000;
        }
    }

    .component-generic-menu > .menu-item:not(:last-child) {
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: nth($color-palette-grey, 2);
    }
</style>

<script>
import { createPopper } from '@popperjs/core'

export default {
    name: 'GenericMenu',

    inheritAttrs: false,

    props: {
        items: {
            required: true,
            type: Array
        },
        parent: {
            type: [Number, String],
            default: null
        },
        placement: {
            type: String,
            default: 'right-start'
        },
        submenuPlacement: {
            type: String,
            default: 'right-start'
        },
        isPopup: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        filteredItems () {
            return this.items
                .filter(item => item['@parent'] === this.parent)
                .sort((itemA, itemB) => {
                    return itemA.order - itemB.order
                })
        },
        visibleItems () {
            return this.filteredItems.filter(item => item.visible)
        },
        mainClasses () {
            return {
                'component-generic-menu': true,
                'is-popup': this.isPopup
            }
        }
    },

    mounted () {
        if (this.isPopup) {
            this.$popper = createPopper(this.$parent.$el, this.$el, {
                placement: this.placement,
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {
                            boundariesElement: 'viewport',
                            mainAxis: true,
                            altAxis: true
                        }
                    }
                ]
            })
        }
    },

    beforeDestroy () {
        if (this.$popper) {
            this.$popper.destroy()
        }
    },

    methods: {
        hasSubmenu (parentItem) {
            return !!this.items.find(item => item['@parent'] === parentItem['@id'])
        },
        onClick (item) {
            this.$emit('click', item)
        }
    }
}
</script>
