<template>
    <div class="component-wisol-editor-apps-view-devices">
        <div class="devices-container">
            <div class="active-device-list">
                <table>
                    <thead>
                        <tr>
                            <td>
                                <h5>#</h5>
                            </td>
                            <td>
                                <h5>Name</h5>
                            </td>
                            <td>
                                <h5>Key</h5>
                            </td>
                            <td>
                                <h5>Last Usage</h5>
                            </td>
                            <td>
                                <h5>Validation</h5>
                            </td>
                            <td />
                        </tr>
                        <tr>
                            <td />
                            <td>
                                <text-input
                                    v-model="newDevice.name"
                                    class="text-input"
                                />
                            </td>
                            <td>
                                <text-input
                                    v-model="newDevice.key"
                                    class="text-input"
                                />
                            </td>
                            <td>
                                <button-input
                                    label="Add"
                                    @click="saveDevice"
                                />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="device in devices"
                            :key="device['@id']"
                            :class="deviceClasses(device)"
                        >
                            <td class="index">
                                {{ device['@id'] }}
                            </td>
                            <td class="name">
                                {{ device.name }}
                            </td>
                            <td class="key">
                                {{ device.key }}
                            </td>
                            <td class="usage">
                                {{ device.lastusage_date }}
                            </td>
                            <td class="validation">
                                {{ device.validation_date }} - {{ device.validation_user }}
                            </td>
                            <td
                                v-if="!isDeviceRevoked(device)"
                                class="action"
                                @click="revokeDevice(device['@id'])"
                            >
                                <icon name="fa/light/ban" />
                            </td>
                            <td v-else />
                        </tr>
                    </tbody>
                </table>
            </div>
            <div
                class="submitted-device-list"
            >
                <table>
                    <thead>
                        <tr>
                            <td>
                                <h5>#</h5>
                            </td>
                            <td>
                                <h5>Name</h5>
                            </td>
                            <td>
                                <h5>Key</h5>
                            </td>
                            <td>
                                <h5>Date</h5>
                            </td>
                            <td>
                                <h5>User</h5>
                            </td>
                            <td>
                                <h5>IP</h5>
                            </td>
                            <td>
                                <h5>Validation</h5>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="device in submittedDevices"
                            :key="device['@id']"
                        >
                            <td class="index">
                                {{ device['@id'] }}
                            </td>
                            <td class="name">
                                <text-input
                                    v-model="device.name"
                                    class="name-input"
                                />
                            </td>
                            <td class="key">
                                {{ device.key }}
                            </td>
                            <td class="date">
                                {{ device.submission_date }}
                            </td>
                            <td class="user">
                                {{ device.submission_user }}
                            </td>
                            <td class="ip">
                                {{ device.submission_ip }}
                            </td>
                            <td
                                class="validation"
                                @click="validateDevice(device['@id'])"
                            >
                                <icon name="fa/light/check-circle" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <template
            v-if="loading"
        >
            <wisol-loading />
        </template>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-apps-view-devices {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;

        & .devices-container {
            padding: 20px 20px 0px 20px;

            & .active-device-list {
                display: flex;
                flex-basis: 100%;
                table {
                    border-collapse: collapse;
                    width: 100%;
                    .index {
                        width: 5%;
                    }
                    .name {
                        width: 20%;
                    }
                    .key {
                        width: 25%;
                    }
                    .usage {
                        width: 23%;
                    }
                    .validation {
                        width: 23%;
                    }
                    .action {
                        width: 4%;
                    }

                    thead {
                        tr:first-child {
                            border-bottom: 1px solid nth($color-palette-grey, 3);
                        }

                        tr:last-child {
                            height: 50px;
                            td {
                                padding-right: 5px;

                                &> .text-input {
                                    width: 100%;
                                    background: none;
                                }
                            }
                        }

                        td {
                            padding-left: 6px;
                        }
                    }

                    tbody {
                        tr {
                            td {
                                height: 60px;
                                background-color: nth($color-palette-grey, 2);
                                padding: 10px;
                                border: 2px solid white;

                                h5 {
                                    font-weight: bold;
                                }

                                &:last-child {
                                    text-align: center;
                                    cursor: pointer;
                                    &:hover {
                                        color: red;
                                    }

                                    svg {
                                        width: 20px;
                                        height: 20px;
                                    }
                                }

                                &:not(:last-child) {
                                    background-color: nth($color-palette-status, 4);
                                }
                            }
                        }

                        tr.revoked {
                            td:not(:last-child) {
                                background-color: nth($color-palette-status, 3);
                            }
                        }
                    }
                }
            }

            & .submitted-device-list {
                margin-top: 50px;
                display: flex;
                flex-basis: 100%;
                table {
                    border-collapse: collapse;
                    width: 100%;
                    .index {
                        width: 5%;
                    }
                    .name {
                        width: 20%;

                        & .name-input {
                            width: 100%;
                            background: none;
                        }
                    }
                    .key {
                        width: 25%;
                    }
                    .date {
                        width: 15%;
                    }
                    .user {
                        width: 15%;
                    }
                    .ip {
                        width: 15%;
                    }
                    .validation {
                        width: 4%;
                    }

                    thead {
                        tr:first-child {
                            border-bottom: 1px solid nth($color-palette-grey, 3);
                        }

                        tr:last-child {
                            height: 50px;
                            td {
                                padding-right: 5px;
                            }
                        }

                        td {
                            padding-left: 6px;
                        }
                    }

                    tbody {
                        tr {
                            td {
                                height: 60px;
                                background-color: nth($color-palette-grey, 2);
                                padding: 10px;
                                border: 2px solid white;

                                h5 {
                                    font-weight: bold;
                                }

                                &:last-child {
                                    text-align: center;
                                    cursor: pointer;
                                    &:hover {
                                        color: limegreen;
                                    }

                                    svg {
                                        width: 20px;
                                        height: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<script>
import requests from '../mixins/requests.js'
import { Input as Inputs } from '@wisol/libs-inputs'
import Icon from '@wisol/libs-icons'

export default {
    name: 'WisolEditorAppsViewDevices',

    components: {
        'text-input': Inputs.Text,
        'button-input': Inputs.Button,
        Icon
    },

    mixins: [
        requests()
    ],

    inheritAttrs: false,

    data () {
        return {
            devices: [],
            submittedDevices: [],
            newDevice: {
                name: '',
                key: ''
            },
            loading: true
        }
    },

    computed: {
        isSaveDeviceDisabled () {
            if (this.newDevice) {
                return this.newDevice.name === '' || this.newDevice.key === ''
            } else {
                return true
            }
        }
    },

    mounted () {
        this.asyncGetValidatedDevices()
        this.asyncGetSubmittedDevices()
    },

    methods: {
        asyncGetSubmittedDevices () {
            this.loading = true
            return this.getSubmittedDevices()
                .then((response) => this.handleGetSubmittedDevicesResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncGetValidatedDevices () {
            this.loading = true
            return this.getValidatedDevices()
                .then((response) => this.handleGetValidatedDevicesResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncSaveDevice (device) {
            this.loading = true
            return this.postDevice(device)
                .then((response) => this.handleSaveDeviceResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncValidateDevice (device) {
            this.loading = true
            return this.postValidateDevice(device)
                .then((response) => this.handleValidateDeviceResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncRevokeDevice (id) {
            this.loading = true
            return this.deleteDevice(id)
                .then((response) => this.handleUpdateDeviceResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        handleGetSubmittedDevicesResponse ({ data }) {
            this.loading = false
            this.submittedDevices = []
            data.forEach((device) => {
                this.submittedDevices.push({
                    ...device,
                    name: ''
                })
            })
        },

        handleGetValidatedDevicesResponse ({ data }) {
            this.loading = false
            this.devices = data
        },

        handleSaveDeviceResponse ({ data }) {
            this.loading = false
            this.devices = [
                data,
                ...this.devices
            ]
            this.initializeNewDevice()
        },

        handleUpdateDeviceResponse ({ data }) {
            this.loading = false
            const index = this.devices.findIndex((device) => {
                return device['@id'] === data['@id']
            })

            this.devices = [
                ...this.devices.slice(0, index),
                data,
                ...this.devices.slice(index + 1, this.devices.length + 1)
            ]
        },

        handleValidateDeviceResponse ({ data }) {
            this.asyncGetValidatedDevices()
            this.asyncGetSubmittedDevices()
        },

        handleRequestError (error) {
            this.loading = false
            const err = error.response.data
            alert(this.$store, err.code + ': ' + err.message)
        },

        saveDevice () {
            if (!this.isSaveDeviceDisabled) {
                this.asyncSaveDevice(this.newDevice)
            }
        },

        revokeDevice (id) {
            this.asyncRevokeDevice(id)
        },

        deviceClasses (device) {
            return {
                revoked: this.isDeviceRevoked(device)
            }
        },

        isDeviceRevoked (device) {
            return device.revokation_date !== null
        },

        initializeNewDevice () {
            this.newDevice = {
                name: '',
                key: ''
            }
        },

        validateDevice (id) {
            const device = this.submittedDevices.find((device) => {
                return device['@id'] === id
            })

            this.asyncValidateDevice(device)
        }
    }
}
</script>
