// this file is used in nodejs/vue.config.js (ES6 imports are not supported)
const chroma = require('chroma-js')

module.exports.textContrastColor = (backgroundColor, textColor = '#000000', altTextColor = '#FFFFFF') => {
    return chroma.contrast(backgroundColor, textColor) > chroma.contrast(backgroundColor, altTextColor)
        ? textColor
        : altTextColor
}

module.exports.whitenColor = (color, ratio = 0.3) => {
    return chroma.mix(color || '#FFFFFF', '#FFFFFF', ratio).hex()
}

module.exports.optimalTextContrastColor = (textColor, backgroundColor = '#FFFFFF', contrastThreshold = 4.5) => {
    let color = textColor
    while (chroma.contrast(color, backgroundColor) < contrastThreshold) {
        color = chroma(color).darken().hex()
    }
    return { color }
}
