export default function throttleRAF (func) {
    let rafId = 0
    let currentArgs
    return (...args) => {
        currentArgs = args
        if (rafId === 0) {
            rafId = requestAnimationFrame(() => {
                rafId = 0
                func(...currentArgs)
            })
        }
    }
}
