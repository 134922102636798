<template>
    <component
        :is="inputComponent"
        v-bind="inputProps"
        v-on="inputListeners"
    />
</template>

<script>
import Registry from '@/Registry'
import { merge } from '@wisol/utils-data/functions'
import { emptyValue } from '@wisol/libs-inputs/src/validator/Required'
import EmptyParserFactory from '@wisol/libs-inputs/src/parser/Empty'
import { createValueData } from '@wisol/libs-inputs/src/components/useController.js'

export const WisolInputSymbol = Symbol('WisolInput')

export default {
    name: 'WisolInput',

    provide () {
        return {
            [WisolInputSymbol]: this
        }
    },

    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'update:model-value'
    },

    props: {
        id: {
            type: Number,
            required: true
        },

        tag: {
            type: String,
            default: ''
        },

        value: {
            type: [String, Number],
            default: null
        },

        options: {
            type: Object,
            default () {
                return {}
            }
        }
    },

    data () {
        return {
            status: 'done',
            parsedValue: null,
            parseError: null,
            inputError: null,
            valueParserPromise: Promise.resolve(null)
        }
    },

    computed: {
        meta () {
            return this.$store.getters['ui/field/getMerged'](
                this.$store.getters['ui/field/byId'](this.id)
            )
        },

        mergedOptions () {
            return merge(this.meta.options, this.options)
        },

        registry () {
            return {
                inputWidget: this.getRegistryEntry('input-widget'),
                valueParser: this.getRegistryEntry('value-parser', false),
                inputParser: this.getRegistryEntry('input-parser', false)
            }
        },

        emptyParser () {
            return EmptyParserFactory({
                emptyValue
            })
        },

        valueParser () {
            if (this.registry.valueParser) {
                const metaParserOptions = (this.mergedOptions || {}).parser || {}
                const props = {
                    context: this,
                    ...this.registry.valueParser.props,
                    ...(metaParserOptions.common || {}),
                    ...(metaParserOptions.in || {})
                }
                return this.registry.valueParser.parser(props)
            }
            return null
        },

        inputParserProps () {
            const parserProps = {}

            const metaParserOptions = (this.mergedOptions || {}).parser || {}
            const parserOptions = {
                ...((this.registry.inputParser || {}).props || {}),
                ...(metaParserOptions.common || {}),
                ...(metaParserOptions.out || {})
            }
            if (Object.keys(parserOptions).length > 0) {
                parserProps.parserOptions = parserOptions
            }

            if (this.registry.inputParser && this.registry.inputParser.parser) {
                parserProps.parserFactory = this.registry.inputParser.parser
            }

            return parserProps
        },

        inputProps () {
            const props = {
                name: this.meta.field,
                ...(this.registry.inputWidget.props || {}),
                ...this.inputParserProps,
                ...((this.mergedOptions || {}).input || {}),
                value: this.valueParserPromise,
                ...this.$attrs
            }
            if (this.hasError) {
                props.placeholder = this.value
            }
            return props
        },

        hasError () {
            return this.status === 'error'
        },

        isReady () {
            return this.status !== 'parsing'
        },

        inputListeners () {
            return {
                ...this.$listeners,
                'update:value': this.onInputValueUpdate
            }
        },

        inputComponent () {
            return this.registry.inputWidget.component
        }
    },

    watch: {
        value: {
            immediate: true,
            handler (value) {
                this.onValueUpdate(value)
            }
        }
    },

    methods: {
        parseValue (value) {
            if (value === '' || value === emptyValue) {
                return this.emptyParser.parse(value)
            }
            return !this.valueParser
                ? Promise.resolve(value)
                : this.valueParser.parse(value)
        },

        onValueUpdate (value) {
            this.status = 'parsing'
            this.parsedValue = null
            this.parseError = null

            if (this.inputValueUpdateCache && this.inputValueUpdateCache.value === value) {
                this.valueParserPromise = Promise.resolve(this.inputValueUpdateCache.parsedValue)
            } else {
                if (value === '' || value === emptyValue) {
                    this.valueParserPromise = this.emptyParser.parse(value)
                } else {
                    this.valueParserPromise = !this.valueParser
                        ? Promise.resolve(value)
                        : this.valueParser.parse(value)
                }
            }
            const valueParserPromise = this.valueParserPromise

            valueParserPromise
                .then(parsedValue => {
                    if (valueParserPromise !== this.valueParserPromise) return
                    this.parsedValue = parsedValue
                    this.status = 'done'
                })
                .catch(err => {
                    if (valueParserPromise !== this.valueParserPromise) return
                    this.parseError = err
                    this.status = 'error'
                })
        },

        onInputValueUpdate ({ value: parsedValue, continuous }) {
            let value
            if (parsedValue === emptyValue) {
                value = this.emptyParser.serialize(parsedValue)
            } else {
                value = this.valueParser
                    ? this.valueParser.serialize(parsedValue)
                    : parsedValue
            }
            this.inputValueUpdateCache = {
                parsedValue,
                value
            }
            this.emitValueData(createValueData(value, continuous))
        },

        emitValueData (valueData) {
            this.$emit('update:value', valueData)
            this.$emit('update:model-value', valueData.value)
        },

        getRegistryEntry (type, required = true) {
            if (
                this.tag &&
                Registry.has(type, this.meta.type + ':' + this.tag)
            ) {
                return Registry.get(type, this.meta.type + ':' + this.tag)
            }
            return this.required || Registry.has(type, this.meta.type)
                ? Registry.get(type, this.meta.type)
                : undefined
        }
    }
}
</script>
