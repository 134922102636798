import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import modules from './modules'

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    modules,
    state () {
        return {
            loading: false,
            isLoaded: false
        }
    }
}
