<template>
    <div class="component-generic-filter">
        <div class="action-bar">
            <button
                v-if="showApplyButton"
                title="apply"
                @click="onApplyFilter"
            >
                <icon
                    class="icon"
                    name="wisol/save"
                />
            </button>
            <button
                v-if="showOptimizeButton"
                title="optimize"
                @click="onOptimizeFilter"
            >
                <icon
                    class="icon"
                    name="fa/light/cog"
                />
            </button>
        </div>
        <tab-container
            :tabs="tabs"
        >
            <template #default="{ activeTab }">
                <group
                    v-if="activeTab.type === 'editor'"
                    :operator="operator"
                    :filter="groupFilter"
                    :readonly="readonly"
                    class="filter-group"
                    @update="onUpdateFilter"
                    @delete="onDeleteFilter"
                />
                <textarea-input
                    v-if="activeTab.type === 'raw'"
                    :value="rawFilter"
                    :readonly="readonly"
                    class="raw-input"
                    @update:value="onUpdateRawFilter"
                />
            </template>
        </tab-container>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-generic-filter {
        border: $box-border;
        min-width: 700px;
        display: flex;

        & > *:last-child {
            flex: 1 auto;
        }

        & > .action-bar {
            color: $text-color;
            background: nth($color-palette-grey, 2);
            padding: 0.5em 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border-bottom: $box-border;
            width: 3em;

            button {
                height: 2em;
                width: 2em;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 0;
                background: none;
                cursor: pointer;

                .icon {
                    height: 100%;
                    width: 100%;
                }

                &:not(:last-child) {
                    margin-bottom: 0.5em;
                }
            }
        }

        .raw-input {
            width: 100%;
            height: 400px;
        }
    }
</style>

<script>
import Group from './Group'
import isEqual from 'lodash-es/isEqual'
import * as filterUtils from '@/utils/filter'
import { Input as Inputs } from '@wisol/libs-inputs'
import TabContainer from '../TabContainer'
import Icon from '@wisol/libs-icons'

export default {
    name: 'GenericFilter',

    components: {
        Group,
        TextareaInput: Inputs.Textarea,
        TabContainer,
        Icon
    },

    inheritAttrs: false,

    props: {
        filter: {
            type: [Object, Array],
            default () {
                return []
            }
        },

        readonly: {
            type: Boolean,
            default: false
        },

        autoApply: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            workingFilter: []
        }
    },

    computed: {
        normalizedFilter () {
            return filterUtils.normalize(this.workingFilter)
        },

        operator () {
            if (!this.normalizedFilter ||
                this.normalizedFilter.length !== 1 ||
                Object.keys(this.normalizedFilter[0])[0][0] !== '$'
            ) {
                return '$and'
            }
            return Object.keys(this.normalizedFilter[0])[0]
        },

        groupFilter () {
            if (!this.normalizedFilter ||
                this.normalizedFilter.length !== 1 ||
                Object.keys(this.normalizedFilter[0])[0][0] !== '$'
            ) {
                return this.normalizedFilter
            }
            return this.normalizedFilter[0][this.operator]
        },

        rawFilter () {
            return JSON.stringify(this.workingFilter, null, 4)
        },

        showApplyButton () {
            return !this.autoApply && !isEqual(this.workingFilter, this.filter)
        },

        showOptimizeButton () {
            return !this.readonly && !isEqual(this.workingFilter, filterUtils.optimize(this.workingFilter))
        },

        tabs () {
            return [
                {
                    type: 'editor',
                    label: 'Editor'
                },
                {
                    type: 'raw',
                    label: 'Raw'
                }
            ]
        }
    },

    watch: {
        filter: {
            immediate: true,
            handler (filter) {
                this.workingFilter = filter
            }
        },

        workingFilter () {
            if (this.autoApply && !isEqual(this.workingFilter, this.filter)) {
                this.$emit('update', this.workingFilter)
            }
        }
    },

    methods: {
        onUpdateFilter (filter) {
            this.workingFilter = filter
        },

        onDeleteFilter () {
            this.workingFilter = []
        },

        onUpdateRawFilter ({ value }) {
            try {
                this.workingFilter = JSON.parse(value)
            } catch (err) {}
        },

        onApplyFilter () {
            this.$emit('update', this.workingFilter)
        },

        onOptimizeFilter () {
            this.workingFilter = filterUtils.optimize(this.workingFilter)
        }
    }
}
</script>
