import Vue from 'vue'

export default function PromiseState (promise) {
    const state = Vue.observable({
        isPending: true,
        isValid: true,
        error: null,
        result: null
    })

    Promise.resolve(promise) // force convert to promise
        .then(result => {
            state.isPending = false
            state.isValid = true
            state.error = null
            state.result = result
        })
        .catch(err => {
            state.isPending = false
            state.isValid = false
            state.error = err
            state.result = null
        })

    return state
}
