import './registerServiceWorker'
import loadStore from './store'
import Vue from 'vue'
import App from './App'
import createRouter from './router'
import i18n from './i18n'
import { fatal } from './utils/alert'
import { sync as storeRouterSync } from 'vuex-router-sync'
import { install as WisolComponents } from '@/components'
import Registry from '@/Registry'
import init from '@/init'
import HttpPlugin from './plugins/Http'
import ApiPlugin from './plugins/Api'
import loadIcons from './utils/loadIcons.js'
import log from 'loglevel'
import packageData from '../package.json'

log.setDefaultLevel(process.env.NODE_ENV === 'production' ? 'error' : 'trace')
log.info(`Wisol UI ${packageData.version}`)

Vue.config.productionTip = false

Vue.use(WisolComponents)
Vue.use(HttpPlugin)
Vue.use(ApiPlugin)

// catch unhandled errors
window.addEventListener('error', error => {
    fatal(error)
})
window.addEventListener('unhandledrejection', error => {
    fatal(error.reason)
})

loadIcons()

init(Registry)

loadStore().then(store => {
    const router = createRouter(store)

    // sync store and router
    storeRouterSync(store, router)

    // load layout when logged in
    if (store.getters['session/isAuthenticated']) {
        Promise.all([
            store.dispatch('ui/load'),
            store.dispatch('layout/load')
        ])
    }

    // eslint-disable-next-line no-new
    new Vue({
        router,
        store,
        i18n,
        components: {
            App
        },
        render: h => h(App)
    }).$mount('#app')
})
