import Vue from 'vue'
import { mergeTarget } from '@wisol/utils-data/functions'

const setPathKeyValue = (object, path, value) => {
    let current = object
    while (path.length > 1) {
        const key = path.shift()
        if (!(key in current)) {
            Vue.set(current, key, {})
        }
        current = current[key]
    }
    Vue.set(current, path[0], value)
}

const removePathKey = (object, path) => {
    let current = object
    while (path.length > 1) {
        const key = path.shift()
        if (!(key in current)) {
            return
        }
        current = current[key]
    }
    if (path[0] in current) {
        Vue.delete(current, path[0])
    }
}

export default ({
    root = 'hashmap',
    autoId = false
}) => {
    const set = (state, key, value) => {
        if (state[root][key] !== value) {
            Vue.set(state[root], key, value)
        }
    }

    const hasKey = (state, key) => {
        return key in state[root]
    }

    const getNextId = state => {
        const id = state[root]['@nextId'] || 1
        set(state, '@nextId', id + 1)
        return id
    }

    const addItemId = (state, item) => {
        if (!('@id' in item)) {
            if (autoId) {
                item['@id'] = getNextId(state)
            } else {
                throw new Error('item is missing an "@id" property (autoId: false)')
            }
        }
        return item
    }

    const itemsToHashmap = (state, items) => {
        const hashmap = {}
        items.forEach(item => {
            item = addItemId(state, item)
            hashmap['id:' + item['@id']] = item
        })
        return hashmap
    }

    return {
        RESET (state) {
            state[root] = {}
        },

        LOAD_ITEMS (state, { items }) {
            const hashmap = itemsToHashmap(state, items)
            state[root] = hashmap
        },

        MERGE_ITEMS (state, { items }) {
            const newHashmap = itemsToHashmap(state, items)
            const rootState = state[root]
            Object.entries(newHashmap).forEach(([key, value]) => {
                set(rootState, key, value)
            })
        },

        ADD_ITEM (state, { item }) {
            item = addItemId(state, item)
            set(state, 'id:' + item['@id'], item)
        },

        REMOVE_ITEM (state, { id }) {
            Vue.delete(state[root], 'id:' + id)
        },

        UPDATE_ITEM (state, { id, data, create = true }) {
            if (!create && !(('id:' + id) in state[root])) {
                return
            }
            mergeTarget(
                state[root]['id:' + id],
                data,
                { '@id': id }
            )
        },

        UPDATE_ITEMS (state, { items }) {
            const newHashmap = itemsToHashmap(state, items)
            mergeTarget(state[root], newHashmap)
        },

        SET_ITEM_KEY (state, { id, key, value }) {
            if (!hasKey(state, 'id:' + id)) {
                return
            }
            setPathKeyValue(state[root], [
                'id:' + id,
                ...key
            ], value)
        },

        REMOVE_ITEM_KEY (state, { id, key }) {
            if (!hasKey(state, 'id:' + id)) {
                return
            }
            removePathKey(state[root], [
                'id:' + id,
                ...key
            ])
        }
    }
}
