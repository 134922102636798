<template>
    <div class="component-wisol-ui-loading">
        <h1>Loading User Interface</h1>
    </div>
</template>

<style>
    .component-wisol-ui-loading {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: #FFF;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>

<script>
export default {
    name: 'WisolUiLoading',

    inheritAttrs: false
}
</script>
