export default name => {
    return {
        inject: ['listing'],

        created () {
            this.listing.registerPlugin(name, this)
        },

        beforeDestroy () {
            this.listing.unregisterPlugin(name)
        }
    }
}
