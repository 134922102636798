import hashmapGetters from '@/store/common/getters/hashmap'

export default {
    ...hashmapGetters({
        root: 'items'
    }),

    visible (state, getters) {
        return getters.all.filter(alert => alert.visible)
    }
}
