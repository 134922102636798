<template>
    <node-item
        v-if="rows.length === 1"
        :key="rows[0][treeIdKey]"
        :row-map="rowMap"
        :tree-id-key="treeIdKey"
        :tree-mode-key="treeModeKey"
        :row="rows[0]"
        :data-group="dataGroup"
        :render-template="renderTemplate"
        :is-expanded="isExpanded"
        @expand="row => $emit('expand', row)"
        @collapse="row => $emit('collapse', row)"
    />
    <div
        v-else-if="rows.length > 1"
        :class="treeStyles[someRowsHaveChildren ? 'node-list' : 'node-group']"
    >
        <node-item
            v-for="row in rows"
            :key="row[treeIdKey]"
            :row-map="rowMap"
            :tree-id-key="treeIdKey"
            :tree-mode-key="treeModeKey"
            :row="row"
            :data-group="dataGroup"
            :render-template="renderTemplate"
            :is-expanded="isExpanded"
            @expand="row => $emit('expand', row)"
            @collapse="row => $emit('collapse', row)"
        />
    </div>
</template>

<script>
import Vue from 'vue'
import treeStyles from './tree.module.css'

export default {
    components: {
        NodeItem: () => import('./Node.vue')
    },

    props: {
        dataGroup: {
            type: Vue,
            required: true
        },

        rowMap: {
            type: Map,
            required: true
        },

        rows: {
            type: Array,
            default: () => ([])
        },

        treeIdKey: {
            type: String,
            default: 'id'
        },

        treeModeKey: {
            type: String,
            default: '@mode'
        },

        renderTemplate: {
            type: Function,
            required: true
        },

        isExpanded: {
            type: Function,
            default: () => false
        }
    },

    data: () => ({
        treeStyles
    }),

    computed: {
        someRowsHaveChildren () {
            return this.rows.find(row => this.hasChildren(row)) !== undefined
        }
    },

    methods: {
        hasChildren (row) {
            const { treeIdKey, dataGroup } = this
            const values = dataGroup.getRowValues(row)

            if (!(treeIdKey in values)) {
                return []
            }

            const id = values[treeIdKey]
            return this.rowMap.has(id)
        }
    }
}
</script>
