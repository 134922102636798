import Vue from 'vue'

const store = Vue.observable({})

const define = (type, name, data) => {
    if (!(type in store)) {
        Vue.set(store, type, {})
    }
    Vue.set(store[type], name, data)
}

const has = (type, name) => {
    return type in store && name in store[type]
}

const get = (type, name) => {
    if (!has(type, name)) {
        throw new Error(`Registry: ${type} "${name}" unknown`)
    }
    return store[type][name]
}

const getByType = type => {
    if (!(type in store)) {
        return {}
    }
    return store[type]
}

const type = (type, func) => {
    func({
        define: (...args) => define(type, ...args),
        get: (...args) => get(type, ...args),
        has: (...args) => has(type, ...args)
    })
}

export default {
    define,
    get,
    getByType,
    has,
    type
}
