<template>
    <div
        v-if="column.type === 'check'"
        :class="$style.container"
    >
        <icon
            :class="$style.icon"
            name="fa/light/function"
        />
    </div>
    <component
        :is="column.computeData.component"
        v-else-if="column.hasCompute"
        :class="$style.input"
        :data-group="dataGroup"
        :column="column"
        v-bind="column.computeData.props"
    />
</template>

<style module>
.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    height: 1.7em;
    width: 1.7em;
}

.input {
    width: 100%;
}
</style>

<script>
import Vue from 'vue'
import Icon, { register as registerIcons } from '@wisol/libs-icons'
import ComputeIcon from '@wisol/libs-icons/src/icons/fa/light/function'

registerIcons(ComputeIcon)

export default {
    components: {
        Icon
    },

    inheritAttrs: false,

    props: {
        column: {
            type: Object,
            required: true,
            validator: column => 'width' in column
        },

        row: {
            type: Object,
            required: true
        },

        dataGroup: {
            type: Vue,
            required: true
        }
    }
}
</script>
