<template>
    <div
        class="component-wisol-editor-menu-detail-dataGroup"
    >
        <breadcrumbs
            :selected="row"
            @showDetail="showDetail"
        />
        <dataGroup-form
            :row="row"
            class="form"
            @updateRow="onUpdateRow"
        />
        <box-listing
            v-if="hasBoxes"
            :parent="row"
            class="listing"
            @showDetail="showDetail"
        />
        <field-listing
            v-if="hasFields"
            :parent="row"
            class="listing"
            @showDetail="showDetail"
        />
        <dataGroupAction-listing
            v-if="hasActions"
            :parent="row"
            class="listing"
            @showDetail="showDetail"
        />
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-menu-detail-dataGroup {
        padding: 20px 20px 0px 20px;

        &> .form,
        &> .listing {
            margin-bottom: 20px;
        }
    }

</style>

<script>
import WisolEditorMenuBreadcrumbs from '../Breadcrumbs'
import WisolEditorMenuFormDatagroup from '../form/Datagroup'
import WisolEditorMenuListingBox from '../listing/Box'
import WisolEditorMenuListingField from '../listing/Field'
import WisolEditorMenuListingDatagroupaction from '../listing/Datagroupaction'

export default {
    name: 'WisolEditorMenuDetailDatagroup',

    components: {
        dataGroupForm: WisolEditorMenuFormDatagroup,
        boxListing: WisolEditorMenuListingBox,
        fieldListing: WisolEditorMenuListingField,
        dataGroupActionListing: WisolEditorMenuListingDatagroupaction,
        breadcrumbs: WisolEditorMenuBreadcrumbs
    },

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            required: true
        }
    },

    computed: {
        hasBoxes () {
            return this.row
                .children()
                .find((child) => {
                    return child['@itemtype'] === 'box_group'
                })
                .children()
                .filter((child) => {
                    return child['@itemtype'] === 'box'
                })
                .length > 0
        },

        hasFields () {
            return this.row
                .children()
                .find((child) => {
                    return child['@itemtype'] === 'field_group'
                })
                .children()
                .filter((child) => {
                    return child['@itemtype'] === 'field'
                })
                .length > 0
        },

        hasActions () {
            return this.row
                .children()
                .find((child) => {
                    return child['@itemtype'] === 'dataGroupAction_group'
                })
                .children()
                .filter((child) => {
                    return child['@itemtype'] === 'dataGroupAction'
                })
                .length > 0
        }
    },

    methods: {
        showDetail (event) {
            this.$emit('showDetail', event)
        },

        onUpdateRow (event) {
            this.$emit('updateRow', event)
        }
    }
}
</script>
