import createInput from '../createInput.js'
import UiArray from '../Ui/Array.vue'
import Parser from '../../parser/Json.js'
import TypeArrayValidator from '../../validator/type/Array.js'

export default createInput(UiArray, {
    Parser,
    defaultParserOptions: () => ({
        beautify: true
    }),
    validators: () => [
        TypeArrayValidator()
    ]
})
