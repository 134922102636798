<template>
    <div
        :class="$style.formMenu"
    >
        <generic-form
            :fields="fields"
            :values="values"
            :layout="layout"
            :column-count="24"
            :cell-height="25"
            :min-cell-width="40"
            :max-cell-width="Infinity"
            :readonly="false"
            @update:value="onUpdateFormValue"
            @update:layout="onLayoutUpdate"
        />
        <generic-loading v-show="isLoading" />
    </div>
</template>

<style lang="scss" module>
    @import '@wisol/theme/color-palette';

    .formMenu {
        padding: 20px 20px 0px 20px;
    }

    :export {
        colors: $color-palette-main;
    }

</style>

<script>
import { Input as Inputs } from '@wisol/libs-inputs'
import IconInput from '@/components/wisol/Input/Icon'
import layout from './layouts/menu.json'
import chroma from 'chroma-js'

const LOCALSTORAGE_KEY = 'layout_menu'

export default {
    name: 'WisolEditorMenuFormMenu',

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            default: () => {}
        },

        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            layout,
            labels: {
                '@id': 'ID',
                '@parent': 'Parent ID',
                label: 'Label',
                icon: 'Icon',
                order: 'Order',
                disabled: 'Disabled',
                visible: 'Visible',
                color: 'Color',
                single_instance: 'Single Instance',
                type: 'Type',
                name: 'Name'
            }
        }
    },

    computed: {
        fields () {
            return Object.keys(this.row).map((field) => {
                let component = Inputs.Text
                const props = {}
                switch (field) {
                    case '@id':
                    case '@parent':
                        component = Inputs.Number
                        props.readonly = true
                        break
                    case 'label':
                        props.required = true
                        break
                    case 'disabled':
                        component = Inputs.Checkbox
                        break
                    case 'single_instance':
                        component = Inputs.Checkbox
                        break
                    case 'visible':
                        component = Inputs.Checkbox
                        props.required = true
                        break
                    case 'color':
                        component = Inputs.Color
                        props['preset-colors'] = this.$style.colors.split(',').map(color => color.trim())
                        break
                    case 'icon':
                        component = IconInput
                        break
                    case 'order':
                        component = Inputs.Number
                        break
                    case 'type':
                        component = Inputs.Selectbox
                        props.options = {
                            module: 'Module'
                        }
                        break
                }

                return {
                    id: field,
                    field: field,
                    label: this.labels[field],
                    input: {
                        component,
                        props
                    }
                }
            })
        },

        values () {
            return {
                ...this.row,
                color: this.row.color
                    ? chroma(this.row.color)
                    : null
            }
        }
    },

    mounted () {
        let local = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || []
        local = local.reduce((arr, l) => {
            const foundLayout = arr.find((item) => item.id === l.id)

            if (foundLayout) {
                foundLayout.position = l.position
            } else {
                arr.push(l)
            }

            return arr
        }, layout)

        this.layout = local
    },

    methods: {
        onLayoutUpdate (layout) {
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(layout))
            this.layout = layout
        },

        onUpdateFormValue ({ field, value }) {
            switch (field) {
                case '@id':
                case '@parent':
                case 'order':
                case 'name':
                case 'type':
                    value = value || null
                    break

                case 'color':
                    value = value.alpha() === 1
                        ? value.name()
                        : value.hex('rgba')
                    break
            }
            const row = {
                ...this.row,
                [field]: value
            }
            this.$emit('updateRow', this.validateRow(row))
        },

        validateRow (row) {
            return {
                ...row,
                '@valid': !!row.label
            }
        }
    }
}

</script>
