import get from 'lodash-es/get'
import showError, { fatal as showFatal } from '@/utils/alert'

export default {
    methods: {
        handleError (err) {
            const messages = get(err, ['response', 'data', 'errors'])
            if (messages) {
                messages.forEach(({ message, info = [] }) => {
                    showError([
                        message,
                        ...info
                    ].join('<br />'))
                })
            } else {
                showFatal(err)
            }
        }
    }
}
