<template>
    <div
        v-if="row.type === 'filter'"
        :class="$style.checkCell"
        @click.capture.stop="onSelectAll"
        @keydown.capture.space.prevent="onSelectAll"
    >
        <checkbox-input
            :value="checkAllValue"
            @mousedown.capture.stop
        />
    </div>
    <div
        v-else-if="row.selectable && row.type === 'data'"
        :class="$style.checkCell"
        @click.capture.stop="onSelectRow"
        @keydown.capture.space.prevent="onSelectRow"
    >
        <checkbox-input
            :value="isSelected"
            @mousedown.capture.stop
        />
    </div>
</template>

<style module>
.checkCell {
    display: flex;
    align-self: center;
    justify-content: center;
}
</style>

<script>
import Vue from 'vue'
import { Input as Inputs } from '@wisol/libs-inputs'

export default {
    components: {
        CheckboxInput: Inputs.Checkbox
    },

    inheritAttrs: false,

    props: {
        column: {
            type: Object,
            required: true,
            validator: column => 'width' in column
        },

        row: {
            type: Object,
            required: true
        },

        dataGroup: {
            type: Vue,
            required: true
        }
    },

    computed: {
        selectionModule () {
            return this.dataGroup.moduleMap.get('selection')
        },

        selectionState () {
            if (!this.selectionModule) {
                return 'none'
            }
            return this.selectionModule.state
        },

        checkAllValue () {
            if (this.selectionState === 'all') {
                return true
            }
            if (this.selectionState === 'partial') {
                return null
            }
            return false
        },

        isSelected () {
            return this.selectionModule && this.selectionModule.isSelected(this.row['@row'])
        }
    },

    methods: {
        onSelectAll () {
            if (this.selectionModule) {
                if (this.selectionState === 'all') {
                    this.selectionModule.reset()
                } else {
                    this.selectionModule.selectAll()
                }
            }
        },

        onSelectRow () {
            if (this.selectionModule) {
                if (this.isSelected) {
                    this.selectionModule.deselect(this.row['@row'])
                } else {
                    this.selectionModule.select(this.row['@row'])
                }
            }
        }
    }
}
</script>
