import Vue from 'vue'
import { focusNode } from '../functions/index.js'

export default {
    inserted (el, { value }) {
        if (value !== false) {
            Vue.nextTick(() => {
                focusNode(el)
            })
        }
    },

    componentUpdated (el, { value, oldValue }) {
        if (oldValue === false && value !== false) {
            Vue.nextTick(() => {
                focusNode(el)
            })
        }
    }
}
