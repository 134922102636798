<template>
    <component
        :is="column.filter.component"
        :class="$style.input"
        v-bind="column.filter.props"
        :value="filterValue"
        @update:value="onUpdateFilterValue"
    />
</template>

<style module>
.input {
    width: 100%;
}
</style>

<script>
import Vue from 'vue'

export default {
    inheritAttrs: false,

    props: {
        column: {
            type: Object,
            required: true,
            validator: column => 'width' in column
        },

        row: {
            type: Object,
            required: true
        },

        dataGroup: {
            type: Vue,
            required: true
        }
    },

    computed: {
        filter () {
            return this.dataGroup.localFilter
        },

        filterValue () {
            return this.column.field in this.filter
                ? this.filter[this.column.field]
                : null
        }
    },

    methods: {
        onUpdateFilterValue ({ value }) {
            this.dataGroup.setFilterValues({
                [this.column.field]: value
            })
        }
    }
}
</script>
