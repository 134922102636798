export default function createPrintHtml (el) {
    // clone element top be printed
    let printEl = el.cloneNode(true)

    // add parent nodes
    while (el.parentNode instanceof Element) {
        el = el.parentNode
        const parentEl = el.cloneNode()
        if (parentEl.matches('html')) {
            Object.assign(parentEl.style, {
                width: 'max-content',
                height: 'max-content',
                padding: 0,
                margin: 0,
                overflow: 'auto'
            })
        } else if (parentEl.matches('body')) {
            Object.assign(parentEl.style, {
                width: 'max-content',
                height: 'max-content',
                padding: 0,
                margin: 0
            })
        } else {
            parentEl.style.display = 'contents'
        }
        parentEl.append(printEl)
        printEl = parentEl
    }

    // get stylesheet HTML
    const stylesheetHTML = Array.from(document.styleSheets)
        .map(stylesheet => {
            const styles = Array.from(stylesheet.cssRules)
                .map(rule => rule.cssText)
                .join('')
            return `<style>${styles}</style>`
        })
        .join('\n')

    // get meta HTML
    const metaHTML = Array.from(document.head.querySelectorAll('meta'))
        .map(el => el.outerHTML)
        .join('')

    // print page size script
    const printScriptHTML = `<script>
        if (typeof PDFGenerator !== 'undefined') {
            PDFGenerator.setMode('script')

            window.addEventListener('DOMContentLoaded', (event) => {
                const pageWidth = document.documentElement.offsetWidth
                const pageHeight = document.documentElement.offsetHeight

                PDFGenerator.insertCSSRule(\`@page {
                    size: calc(\${pageWidth}px + 2cm) calc(\${pageHeight}px + 2cm);
                    margin: 1cm 0 0 1cm;
                }\`)

                PDFGenerator.print({ paged: false })
            });
        }
</script>`

    // add head
    printEl.insertAdjacentHTML('afterbegin', `<head>${metaHTML}${stylesheetHTML}${printScriptHTML}</head>`)

    // remove no-print elements
    printEl.querySelectorAll('.no-print').forEach(el => el.remove())

    return printEl.outerHTML
}
