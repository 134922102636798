import { useFocusTracker } from '../composition/index.js'
import { useCurrentElement } from '@vueuse/core'

export default {
    name: 'FocusTrap',

    setup () {
        const focusElement = useCurrentElement()
        const { hasFocus } = useFocusTracker(focusElement)

        return { focusElement, hasFocus }
    },

    inheritAttrs: false,

    watch: {
        hasFocus () {
            this.$emit(this.hasFocus ? 'focus' : 'blur')
        }
    },

    render (h) {
        const _this = this

        const TrapFactory = (hasFocus, dir) => {
            return h('div', {
                attrs: {
                    tabindex: hasFocus ? '0' : '-1'
                },
                on: {
                    focus () {
                        _this.$emit('trap', { dir })
                    }
                }
            })
        }

        return h('div', {
            attrs: {
                tabindex: this.hasFocus ? '0' : '-1'
            }
        }, [
            TrapFactory(this.hasFocus, 'left'),
            this.$scopedSlots.default(this),
            TrapFactory(this.hasFocus, 'right')
        ])
    }
}
