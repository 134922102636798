import Vue, { getCurrentInstance } from 'vue'
import useStoreWrapper from '@/composition/useStoreWrapper.js'

const StoreModule = {
    actions: {
        setRowsPerPage ({ commit }, { rowsPerPage }) {
            commit('SET_ROWS_PER_PAGE', {
                count: rowsPerPage
            })
        }
    },

    mutations: {
        SET_ROWS_PER_PAGE (state, { count }) {
            state.rowsPerPage = count
        }
    },

    getters: {
        rowsPerPage: state => state.rowsPerPage
    },

    state () {
        return {
            rowsPerPage: 0
        }
    }
}

export default Vue.extend({
    name: 'WisolDataGroupModulePagination',

    inheritAttrs: false,

    props: {
        initialRowsPerPage: {
            type: Number,
            default: 20
        }
    },

    setup () {
        const {
            getter,
            dispatch
        } = useStoreWrapper(getCurrentInstance().proxy.$store, StoreModule, 'pagination', {
            autoUnregister: false
        })

        return {
            getter,
            dispatch
        }
    },

    computed: {
        middleware () {
            return {
                limit: (ctx, next) => {
                    ctx.data = [
                        (this.page - 1) * this.rowsPerPage,
                        this.rowsPerPage
                    ]
                },
                indexByPosition: (ctx, next) => {
                    ctx.data = this.getIndexByPosition(ctx.options.position || 0)
                },
                positionByIndex: (ctx, next) => {
                    ctx.data = this.getPositionByIndex(ctx.options.index || 0)
                }
            }
        },

        /**
         * the current page
         */
        page () {
            return Math.floor(this.$parent.activeRowPosition / this.rowsPerPage) + 1
        },

        /**
        * the number of rows per page
        */
        rowsPerPage () {
            return this.getter('rowsPerPage')
        },

        /**
        * the start position for the current page
        */
        fromPosition () {
            return (this.page - 1) * this.rowsPerPage
        },

        /**
        * the end position for the current page
        */
        toPosition () {
            return this.page * this.rowsPerPage
        }
    },

    created () {
        this.setRowsPerPage(this.initialRowsPerPage)
    },

    methods: {
        /**
         * calculates the page number where the position is in
         * @param  {Number} position the position
         * @return {Number}          the page number where the position is in
         */
        getPageByPosition (position) {
            return Math.floor(position / this.rowsPerPage) + 1
        },

        /**
         * calculates the position index based on rowsPerPage
         * @param  {Number} position the position
         * @return {Number|null}     the position index based on rowsPerPage or null if out of visible range
         */
        getIndexByPosition (position) {
            if (position < this.fromPosition || position >= this.toPosition) {
                return null
            }
            return position % this.rowsPerPage
        },

        getPositionByIndex (index) {
            if (index === null) {
                return null
            }
            return this.fromPosition + index
        },

        /**
         * select page
         * @param  {Number}  page  the page to select
         * @return {Promise}       the promise which resolves when the page has changed
         */
        async selectPage (page) {
            const position = (page - 1) * this.rowsPerPage
            if (position >= 0 && position < this.$parent.totalRowCount) {
                return this.$parent.setActivePosition(position)
            }
        },

        /**
         * updates the rows per page count
         * @param  {Number}  rowsPerPage the rows per page count
         * @return {Promise}             the promise which resolves when the rows per page count has been updated
         */
        async setRowsPerPage (rowsPerPage) {
            return this.dispatch('setRowsPerPage', { rowsPerPage })
        }
    },

    render: h => h()
})
