<template>
    <div
        class="component-wisol-editor-menu-detail-menu"
    >
        <template v-if="row['@id']">
            <breadcrumbs
                :selected="row"
                @showDetail="showDetail"
            />
            <menu-form
                :row="row"
                class="form"
                @updateRow="onUpdateRow"
            />
        </template>
        <menu-listing
            v-if="hasMenus"
            :parent="row"
            class="listing"
            @showDetail="showDetail"
        />
        <module-listing
            v-if="hasModules"
            :parent="row"
            class="listing"
            @showDetail="showDetail"
        />
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-menu-detail-menu {
        padding: 20px 20px 0px 20px;

        &> .form,
        &> .listing {
            margin-bottom: 20px;
        }
    }

</style>

<script>
import WisolEditorMenuBreadcrumbs from '../Breadcrumbs'
import WisolEditorMenuFormMenu from '../form/Menu'
import WisolEditorMenuListingMenu from '../listing/Menu'
import WisolEditorMenuListingModule from '../listing/Module'

export default {
    name: 'WisolEditorMenuDetailMenu',

    components: {
        menuForm: WisolEditorMenuFormMenu,
        menuListing: WisolEditorMenuListingMenu,
        moduleListing: WisolEditorMenuListingModule,
        breadcrumbs: WisolEditorMenuBreadcrumbs
    },

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            required: true
        }
    },

    computed: {
        hasMenus () {
            return this.row
                .children()
                .filter((child) => {
                    return child['@itemtype'] === 'menu'
                })
                .length > 0
        },

        hasModules () {
            return this.row
                .children()
                .filter((child) => {
                    return child['@itemtype'] === 'module'
                })
                .length > 0
        }
    },

    methods: {
        showDetail (event) {
            this.$emit('showDetail', event)
        },

        onUpdateRow (event) {
            this.$emit('updateRow', event)
        }
    }
}
</script>
