export default {

    async load ({ dispatch, commit }) {
        commit('ENABLE_LOADING')
        try {
            await Promise.all([
                dispatch('menu/load'),
                dispatch('module/load'),
                dispatch('box/load'),
                dispatch('widget/load'),
                dispatch('field/load'),
                dispatch('dataGroup/load'),
                dispatch('dataGroupLink/load'),
                dispatch('dataGroupAction/load'),
                dispatch('boxAction/load'),
                dispatch('config/load')
            ])
        } catch (err) { }
        commit('DISABLE_LOADING')
        commit('IS_LOADED')
    }

}
