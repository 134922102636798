import Vue from 'vue'

export default new Vue({
    created () {
        this.observer = new ResizeObserver(this.onResize)
        this.callbackMap = new Map()
        this.sizeMap = new Map()
        this.deferredCallCallbacks = false
    },

    beforeDestroy () {
        this.observer.disconnect()
        this.callbackMap.clear()
    },

    methods: {
        observe (node, callback) {
            this.observer.observe(node)
            this.callbackMap.set(node, callback)
        },

        unobserve (node) {
            this.observer.unobserve(node)
            this.callbackMap.delete(node)
        },

        callCallbacks () {
            if (this.deferredCallCallbacks) {
                return
            }
            this.deferredCallCallbacks = true
            requestAnimationFrame(() => {
                this.sizeMap.forEach((contentRect, node) => {
                    const callback = this.callbackMap.get(node)
                    if (callback) {
                        callback(contentRect)
                    }
                })
                this.sizeMap.clear()
                this.deferredCallCallbacks = false
            })
        },

        onResize (entries) {
            for (let i = 0; i < entries.length; i++) {
                this.sizeMap.set(entries[i].target, {
                    width: entries[i].contentRect.width,
                    height: entries[i].contentRect.height
                })
            }
            this.callCallbacks()
        }
    }
})
