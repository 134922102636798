<template>
    <div :class="$style.container">
        <div :class="$style.filterWrapper">
            <generic-filter
                :filter="value"
                :auto-apply="true"
                @update="onUpdate"
            />
        </div>
    </div>
</template>

<style lang="scss" module>
    .container {
        composes: container from "../../../../../../libs/inputs/src/style/input.module.scss";
        overflow: hidden;
    }

    .filterWrapper {
        height: 100%;
        width: 100%;
        overflow: auto;
    }
</style>

<script>
import GenericFilter from '../../../generic/Filter'
import { inject } from 'vue'
import { InputSymbol } from '@wisol/libs-inputs/src/components/createInput.js'

export default {
    components: {
        GenericFilter
    },

    setup () {
        const { value, setValue, inputClasses } = inject(InputSymbol)
        return {
            value,
            setValue,
            inputClasses
        }
    },

    methods: {
        onUpdate (value) {
            this.setValue(value, true)
        }
    }
}
</script>
