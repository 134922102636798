import Vue, { getCurrentInstance } from 'vue'
import CommonMixin from './CommonMixin'
import useStoreWrapper from '@/composition/useStoreWrapper.js'

const StoreModule = {
    actions: {
        setRowValues ({ commit }, { rowId, values, originalValues }) {
            commit('UPDATE_ROW_VALUES', { rowId, values, originalValues })
        },

        resetRowValues ({ commit }, { rowId }) {
            commit('RESET_ROW_VALUES', { rowId })
        },

        resetAllValues ({ commit }) {
            commit('RESET_ALL_ROW_VALUES')
        }
    },

    mutations: {
        UPDATE_ROW_VALUES (state, { rowId, values, originalValues }) {
            const currentValues = state.valuesOverlay[rowId] || {}
            const mergedValues = {
                ...currentValues,
                ...values
            }

            // remove values which are same as the original values
            for (const key in mergedValues) {
                if (
                    (
                        key in originalValues &&
                        originalValues[key] === mergedValues[key]
                    ) ||
                    (!originalValues[key] && !mergedValues[key])
                ) {
                    delete mergedValues[key]
                }
            }

            Vue.set(state.valuesOverlay, rowId, mergedValues)
        },

        RESET_ROW_VALUES (state, { rowId }) {
            Vue.delete(state.valuesOverlay, rowId)
        },

        RESET_ALL_ROW_VALUES (state) {
            state.valuesOverlay = {}
        }
    },

    getters: {
        valuesOverlay: state => state.valuesOverlay
    },

    state () {
        return {
            valuesOverlay: {}
        }
    }
}

export default Vue.extend({
    name: 'WisolDataGroupModuleUpdate',

    mixins: [
        CommonMixin
    ],

    inheritAttrs: false,

    setup () {
        const {
            getter,
            dispatch
        } = useStoreWrapper(getCurrentInstance().proxy.$store, StoreModule, 'update', {
            autoUnregister: false
        })

        return {
            getter,
            dispatch
        }
    },

    computed: {
        middleware () {
            return {
                canRowUpdate: (ctx, next) => {
                    if (this.$parent.getRowType(ctx.options.row) === 'DEFAULT') {
                        ctx.data = true
                    }
                    next()
                },

                rowValues: (ctx, next) => {
                    if (
                        this.$parent.getRowType(ctx.options.row) === 'DEFAULT' &&
                        ctx.options.row['@id'] &&
                        this.valuesOverlay[ctx.options.row['@id']]
                    ) {
                        Object.assign(ctx.data, this.valuesOverlay[ctx.options.row['@id']])
                    }
                    next()
                },

                isDirty: (ctx, next) => {
                    if (this.isDirty) {
                        ctx.data = true
                    } else {
                        next()
                    }
                },

                dirtyRowCount: (ctx, next) => {
                    ctx.data += this.dirtyRowCount
                    next()
                },

                getDirtyFields: (ctx, next) => {
                    if (
                        this.$parent.getRowType(ctx.options.row) === 'DEFAULT' &&
                        '@id' in ctx.options.row
                    ) {
                        ctx.data = [
                            ...ctx.data,
                            ...Object.keys(this.dirtyValues[ctx.options.row['@id']] || {})
                        ]
                    }
                    next()
                },

                saveAll: (ctx, next) => {
                    ctx.data.push(this.saveAll())
                    next()
                },

                setRowValues: (ctx, next) => {
                    if (
                        this.$parent.getRowType(ctx.options.row) === 'DEFAULT' &&
                        '@id' in ctx.options.row
                    ) {
                        ctx.data = this.setRowValues(ctx.options.row, ctx.options.values)
                    } else {
                        next()
                    }
                }
            }
        },

        /**
         * return the values overlay
         */
        valuesOverlay () {
            return this.getter('valuesOverlay')
        },

        /**
         * return the dirty values from values overlay (virtual fields removed)
         */
        dirtyValues () {
            const dirtyValues = {}
            for (const id in this.valuesOverlay) {
                const dirtyRowValues = this.$parent.removeVirtualFields(this.valuesOverlay[id])
                if (Object.keys(dirtyRowValues).length > 0) {
                    dirtyValues[id] = dirtyRowValues
                }
            }
            return dirtyValues
        },

        dirtyRowCount () {
            return Object.keys(this.dirtyValues).length
        },

        isDirty () {
            return this.dirtyRowCount > 0
        },

        updateScriptFunction () {
            if (!this.$parent.updateScriptFunction) {
                return null
            }

            return (row, values) => {
                return this.$parent.updateScriptFunction(
                    'DEFAULT',
                    row,
                    values
                )
            }
        }
    },

    methods: {
        /**
         * saves all rows with ids from idList
         * @param  {Array}  idList the list of row ids to save
         * @return {Promise}       the promise which resolves when all rows are saved
         */
        async saveByIdList (idList) {
            if (this.$parent.readonly) {
                throw new Error('Datagroup is readonly')
            }

            await this.$parent.startLoading()

            const rows = idList.reduce((rows, id) => {
                if (this.dirtyValues[id]) {
                    rows[id] = this.dirtyValues[id]
                }
                return rows
            }, {})

            if (Object.keys(rows).length > 0) {
                try {
                    const updatePromises = []
                    for (const id in rows) {
                        updatePromises.push(
                            this.$parent.dataProvider.update(id, rows[id])
                                .then(({ row }) => {
                                    return Promise.all([
                                        this.$parent.dispatch('rows/update', { rowId: row['@id'], values: row }),
                                        this.dispatch('resetRowValues', { rowId: row['@id'] })
                                    ])
                                })
                        )
                    }
                    await Promise.all(updatePromises)
                } catch (err) {
                    this.handleError(err)
                }
            }

            await this.$parent.stopLoading()
        },

        /**
         * saves the row with id
         * @param  {[type]}  id the id of the row to save
         * @return {Promise}    the promise which resolves when the row is saved
         */
        async saveById (id) {
            return this.saveByIdList([id])
        },

        /**
         * saves all rows
         * @return {Promise} the promise which resolves when all rows are saved
         */
        async saveAll () {
            await this.saveByIdList(Object.keys(this.dirtyValues))
        },

        /**
         * overwrites the values of the row with id
         * @param  {String}  row    the row to overwrite
         * @param  {Object}  values the values to set
         * @return {Promise}        the promise which resolves when the values are overwritten
         */
        async setRowValues (row, values) {
            if (this.readonly) {
                throw new Error('Datagroup is readonly')
            }

            if (this.updateScriptFunction) {
                await this.updateScriptFunction(row, values)
            }

            const { rowId } = this.$parent.getRowData(row)
            await this.dispatch('setRowValues', {
                rowId,
                values,
                originalValues: row.values
            })
        }
    },

    render: h => h()
})
