import { render, staticRenderFns } from "./Color.vue?vue&type=template&id=3f7e29ae"
import script from "./Color.vue?vue&type=script&lang=js"
export * from "./Color.vue?vue&type=script&lang=js"
import style0 from "./Color.vue?vue&type=style&index=0&id=3f7e29ae&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.9.0_handlebars@4.7.8_vue-template-compiler@2.7.16_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports