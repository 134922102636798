import { tabbable } from 'tabbable'

export default function focusNode (node) {
    let [nodeToFocus] = tabbable(node, {
        includeContainer: true
    })
    if (!nodeToFocus) {
        node.setAttribute('tabindex', '0')
        node.style.outline = 'none'
        nodeToFocus = node
    }
    nodeToFocus.focus()
}
