<template>
    <div
        :class="mainClasses"
        class="component-wisol-layout-item"
        @click="onClick"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
    >
        <span class="label">{{ label }}</span>
        <template v-if="!readonly">
            <div
                v-if="isDeleteMode"
                class="action-icon"
            >
                <icon
                    name="fa/light/trash-alt"
                />
            </div>
            <div
                v-else
                class="action-icon"
                @click.stop.prevent="onSave"
            >
                <icon
                    name="wisol/save"
                />
            </div>
        </template>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-layout-item {
        height: 2.5rem;
        line-height: 2.5rem;
        border-width: 0 0 1px 0;
        border-style: solid;
        border-color: nth($color-palette-grey, 4);
        cursor: pointer;
        display: flex;
        color: $text-color;

        &.delete-mode {
            background: map-get($status-colors, 'exception');
            color: text-contrast-color(map-get($status-colors, 'exception'), $text-color, #FFF);
        }

        &> .action-icon {
            width: 2.5em;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                background: nth($color-palette-grey, 2);
            }
        }

        &> .input {
            border: 0;
            flex-grow: 1;
            height: 100%;
            margin-right: 0.5em;
        }

        &> .label {
            flex-grow: 1;
            padding: 0 0.5em;
        }
    }
</style>

<script>
import Icon from '@wisol/libs-icons'

export default {
    name: 'WisolLayoutItem',

    components: {
        Icon
    },

    inheritAttrs: false,

    props: {
        layout: {
            type: Object,
            required: true
        },

        readonly: {
            type: Boolean,
            default: true
        }
    },

    data () {
        return {
            isMouseEnter: false,
            isCtrKeyDown: false
        }
    },

    computed: {
        label () {
            return this.layout.label
        },

        isDeleteMode () {
            return this.isMouseEnter && this.isCtrKeyDown
        },

        mainClasses () {
            return {
                'delete-mode': this.isDeleteMode
            }
        }
    },

    created: function () {
        window.addEventListener('keydown', this.onKeyDown)
        window.addEventListener('keyup', this.onKeyUp)
    },

    beforeDestroy: function () {
        window.removeEventListener('keydown', this.onKeyDown)
        window.removeEventListener('keyup', this.onKeyUp)
    },

    methods: {
        onClick () {
            if (this.isDeleteMode) {
                this.$emit('delete', this.layout)
            } else {
                this.$emit('select', this.layout)
            }
        },

        onSave () {
            this.$emit('save', this.layout)
        },

        onMouseEnter (evt) {
            this.isMouseEnter = true
        },

        onMouseLeave () {
            this.isMouseEnter = false
        },

        onKeyDown (evt) {
            if (evt.keyCode === 17) {
                this.isCtrKeyDown = true
            }
        },

        onKeyUp (evt) {
            if (evt.keyCode === 17) {
                this.isCtrKeyDown = false
            }
        }
    }
}
</script>
