import isArray from 'lodash-es/isArray'

export const ActionReceiverSymbol = Symbol('ActionReceiver')

export default (key = 'actions') => {
    return {
        data () {
            return {
                [key]: []
            }
        },

        provide () {
            return {
                [ActionReceiverSymbol]: this
            }
        },

        methods: {
            onActionRegister (actions) {
                if (!isArray(actions)) {
                    actions = [actions]
                }
                actions.forEach(action => {
                    if (this[key].indexOf(action) === -1) {
                        this[key] = [
                            ...this[key],
                            action
                        ]
                    }
                })
            },

            onActionUnRegister (actions) {
                if (!isArray(actions)) {
                    actions = [actions]
                }
                actions.forEach(action => {
                    const index = this[key].indexOf(action)
                    if (index > -1) {
                        this[key].splice(index, 1)
                    }
                })
            }
        }
    }
}
