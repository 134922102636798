import createInput from '@wisol/libs-inputs/src/components/createInput.js'
import UiDropdown from './Ui.vue'
import Parser from '@wisol/libs-inputs/src/parser/Template.js'
import TypeObjectValidator from '@wisol/libs-inputs/src/validator/type/Object.js'

export default createInput(UiDropdown, {
    Parser,
    defaultParserOptions: (props) => ({
        table: props.table
    }),
    validators: () => [TypeObjectValidator()]
})
