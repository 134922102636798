<!-- <template>
    <div v-resize="onResize" :style="viewStyle">
        <slot />
    </div>
</template> -->

<script>
import Vue from 'vue'
import { ResizeObserver as ResizeObserverDirective } from '@wisol/utils-resize/directives'

export default Vue.extend({
    directives: {
        resize: ResizeObserverDirective
    },

    inject: {
        itemContainer: {
            from: 'VirtualListItemContainer'
        }
    },

    inheritAttrs: false,

    props: {
        view: {
            type: Object,
            required: true
        },

        getVNodes: {
            type: Function,
            required: true
        }
    },

    computed: {
        viewStyle () {
            const sizeKey = this.itemContainer.direction === 'horizontal'
                ? 'minHeight'
                : 'minWidth'
            return {
                [sizeKey]: '100%'
            }
        }
    },

    methods: {
        onResize (size) {
            this.itemContainer.updateViewSize(this.view, size)
        }
    },

    render (h) {
        return h('div', {
            style: this.viewStyle,
            directives: [{
                name: 'resize',
                value: this.onResize
            }]
        }, this.getVNodes())
    }
})
</script>
