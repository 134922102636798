import Vue from 'vue'
import createScriptFunction from '@/utils/createScriptFunction.js'

export default Vue.extend({
    name: 'WisolDataGroupModuleLinkFilter',

    inheritAttrs: false,

    data () {
        return {
            filter: null
        }
    },

    computed: {
        middleware () {
            return {
                filter: (ctx, next) => {
                    if (this.filter) {
                        ctx.data = [
                            ...ctx.data,
                            ...this.filter
                        ]
                    }
                    next()
                }
            }
        },

        parents () {
            return this.$parent.links
                .filter(link => {
                    return link.type === 'filter' && link.targetId === this.$parent.id
                })
                .map(link => {
                    const dataGroup = link.getSource()
                    const filterScript = link.options.filter || 'return null'
                    const getFilterFunction = createScriptFunction(dataGroup, filterScript)

                    return {
                        dataGroup,
                        oprions: link.options,
                        getFilter: row => {
                            return getFilterFunction({
                                dataGroup,
                                row
                            })
                        }
                    }
                })
        },

        filterPromises () {
            return this.parents.map(parent => this.getFilterFromParent(parent))
        }
    },

    watch: {
        async filterPromises () {
            const filter = (await Promise.all(this.filterPromises))
                .filter(filter => !!filter)
            this.filter = filter.length ? filter : null
        }
    },

    methods: {
        getFilterFromParent ({ dataGroup, getFilter }) {
            if (!dataGroup.activeRow) {
                return null
            }

            return getFilter(dataGroup.activeRow)
        }
    },

    render: h => h()
})
