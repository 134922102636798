import chroma from 'chroma-js'

export default () => {
    return {
        async parse (raw) {
            return chroma(raw)
        },

        serialize (object) {
            return object.alpha() === 1 ? object.name() : object.hex('rgba')
        }
    }
}
