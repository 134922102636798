export default () => {
    return {
        data () {
            return {
                idCopyMap: new Map()
            }
        },

        methods: {
            copymap (type, id = null, autocreate = true) {
                if (!this.idCopyMap.has(type)) {
                    this.idCopyMap.set(type, new Map())
                }
                if (id === null) {
                    return this.idCopyMap.get(type)
                }
                if (!this.idCopyMap
                    .get(type)
                    .has(id)) {
                    if (!autocreate) {
                        return null
                    }
                    this.idCopyMap
                        .get(type)
                        .set(id,
                            this.idCopyMap.get(type).size + 1
                        )
                }
                return this.idCopyMap.get(type).get(id)
            },

            getMenuCopy (menu) {
                return [{
                    ...menu,
                    '@id': this.copymap('menu', menu['@id']),
                    '@idType': 'virtual'
                }]
            },

            getRootCopy (root) {
                return [{
                    ...root,
                    '@id': this.copymap(root['@itemtype'], root['@id']),
                    '@idType': 'virtual'
                }]
            },

            getModulesCopy () {
                const exportList = []
                const ids = Array.from(this.copymap('menu').keys())
                ids.forEach((id) => {
                    exportList.push(
                        ...this.getMappedItems('module')
                            .filter((item) => {
                                return item['@menu'] === id
                            })
                            .map((item) => {
                                return {
                                    ...item,
                                    '@id': this.copymap('module', item['@id']),
                                    '@menu': this.copymap('menu', id),
                                    '@idType': 'virtual'
                                }
                            })
                    )
                })
                return exportList
            },

            getDatagroupsCopy () {
                const exportList = []
                const ids = Array.from(this.copymap('module').keys())
                ids.forEach((id) => {
                    exportList.push(
                        ...this.getMappedItems('dataGroup')
                            .filter((item) => {
                                return item['@module'] === id
                            })
                            .map((item) => {
                                return {
                                    ...item,
                                    '@id': this.copymap('dataGroup', item['@id']),
                                    '@module': this.copymap('module', id),
                                    '@idType': 'virtual',
                                    table: item.table
                                }
                            })
                    )
                })
                return exportList
            },

            getDatagroupActionsCopy () {
                const exportList = []
                const ids = Array.from(this.copymap('dataGroup').keys())
                ids.forEach((id) => {
                    exportList.push(
                        ...this.getMappedItems('dataGroupAction')
                            .filter((item) => {
                                return item['@dataGroup'] === id
                            })
                            .map((item) => {
                                return {
                                    ...item,
                                    '@id': this.copymap('dataGroupAction', item['@id']),
                                    '@dataGroup': this.copymap('', id),
                                    '@idType': 'virtual'
                                }
                            })
                    )
                })
                return exportList
            },

            getBoxesCopy () {
                const exportList = []
                const ids = Array.from(this.copymap('dataGroup').keys())
                ids.forEach((id) => {
                    exportList.push(
                        ...this.getMappedItems('box')
                            .filter((item) => {
                                return item['@dataGroup'] === id
                            })
                            .map((item) => {
                                return {
                                    ...item,
                                    '@id': this.copymap('box', item['@id']),
                                    '@dataGroup': this.copymap('dataGroup', id),
                                    '@module': this.copymap('module', item['@module']),
                                    '@idType': 'virtual'
                                }
                            })
                    )
                })
                return exportList
            },

            getBoxActionsCopy () {
                const exportList = []
                const ids = Array.from(this.copymap('box').keys())
                ids.forEach((id) => {
                    exportList.push(
                        ...this.getMappedItems('boxAction')
                            .filter((item) => {
                                return item['@box'] === id
                            })
                            .map((item) => {
                                return {
                                    ...item,
                                    '@id': this.copymap('boxAction', item['@id']),
                                    '@box': this.copymap('box', id),
                                    '@idType': 'virtual'
                                }
                            })
                    )
                })
                return exportList
            },

            getFieldsCopy () {
                const exportList = []
                const ids = Array.from(this.copymap('dataGroup').keys())
                ids.forEach((id) => {
                    exportList.push(
                        ...this.getMappedItems('field')
                            .filter((item) => {
                                return item['@dataGroup'] === id
                            })
                            .map((item) => {
                                return {
                                    ...item,
                                    '@id': this.copymap('field', item['@id']),
                                    '@dataGroup': this.copymap('dataGroup', id),
                                    '@idType': 'virtual'
                                }
                            })
                    )
                })
                return exportList
            },

            getWidgetsCopy () {
                const exportList = []
                const ids = Array.from(this.copymap('box').keys())
                ids.forEach((id) => {
                    exportList.push(
                        ...this.getMappedItems('widget')
                            .filter((item) => {
                                return item['@box'] === id
                            })
                            .map((item) => {
                                return {
                                    ...item,
                                    '@id': this.copymap('widget', item['@id']),
                                    '@box': this.copymap('box', id),
                                    '@idType': 'virtual'
                                }
                            })
                    )
                })
                return exportList
            }
        }
    }
}
