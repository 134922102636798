<template>
    <div
        :tabindex="tabindex"
        class="component-generic-focus-container"
    >
        <slot />
    </div>
</template>

<script>
import { tabbable } from 'tabbable'

export default {
    name: 'GenericInputDateTimePickerFocusContainer',

    inheritAttrs: false,

    data () {
        return {
            tabbableNodes: false
        }
    },

    computed: {
        tabindex () {
            return this.tabbableNodes.length > 0 ? '-1' : '0'
        }
    },

    mounted () {
        this.$nextTick(() => {
            this.tabbableNodes = tabbable(this.$el)
            if (this.tabbableNodes.length > 0) {
                this.tabbableNodes[0].focus()
            } else {
                this.$el.focus()
            }
        })
    }
}
</script>
