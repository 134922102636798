export default function svgToImage (svgUrl, {
    scale = 1
} = {}) {
    return new Promise((resolve, reject) => {
        const image = new Image()

        image.addEventListener('load', () => {
            const canvas = document.createElement('canvas')
            canvas.width = image.naturalWidth * scale
            canvas.height = image.naturalHeight * scale
            const ctx = canvas.getContext('2d')
            ctx.fillStyle = '#FFF'
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height)
            canvas.toBlob(blob => {
                resolve(URL.createObjectURL(blob))
            })
        })

        image.addEventListener('error', err => {
            reject(err)
        })

        image.src = svgUrl
    })
}
