export default {
    create ({ commit, rootGetters }, data = {}) {
        // check if item is a module and the module exists
        const menu = rootGetters['ui/menu/byId'](data['@menu'])
        if (!menu || menu.type !== 'module') return
        const module = rootGetters['ui/module/all'].find(module => module['@menu'] === menu['@id'])
        if (!module) return

        commit('ADD_ITEM', {
            item: data
        })
    },

    destroy ({ commit }, { id }) {
        commit('REMOVE_ITEM', { id })
    }
}
