<template>
    <div class="component-wisol-window-container">
        <div class="window-container">
            <wisol-window
                v-for="window in windows"
                :id="window['@id']"
                :ref="'window-' + window['@id']"
                :key="window['@id']"
            />
        </div>
        <div class="taskbar">
            <div class="tabs">
                <wisol-window-tab
                    v-for="window in windows"
                    :id="window['@id']"
                    :key="window['@id']"
                    class="tab"
                    @click.native="onTabClick(window)"
                />
            </div>
            <div class="action-buttons">
                <div
                    class="icon-container"
                    @click.prevent="minimizeAll"
                >
                    <icon
                        name="wisol/minimize"
                    />
                </div>
                <div
                    class="icon-container"
                    @click.prevent="maximizeAll"
                >
                    <icon
                        name="wisol/maximize"
                    />
                </div>
                <div
                    class="icon-container"
                    @click.prevent="closeAll"
                >
                    <icon
                        name="wisol/close"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-window-container {
        display: flex;
        flex-direction: column;
        height: 100%;

        &> .window-container {
            flex: 1 1 auto;
            overflow-x: auto;
            overflow-y: scroll;
        }

        &> .taskbar {
            flex: 0 0 2.2em;
            width: 100%;
            z-index: 500;

            font-size: 1.2rem;
            height: 2.2em;
            padding: 0 1em;

            display: flex;
            align-items: center;

            background: nth($color-palette-grey, 1);

            &> .tabs {
                font-size: 0.8em;
                flex-grow: 1;
                display: flex;

                .tab {
                    height: 2.2em;
                    padding: 0 0.8em;
                    margin-right: 0.4em;
                    text-align: center;
                    text-transform: uppercase;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;

                    .icon {
                        font-size: 1.1em;
                        margin-right: 0.5em;
                    }
                }
            }

            &> .action-buttons {
                display: flex;

                &> .icon {
                    margin-left: 0.8em;
                }
            }
        }
    }
</style>

<script>
import Icon from '@wisol/libs-icons'
import WisolWindow from './index.vue'

export default {
    name: 'WisolWindowContainer',

    components: {
        Icon,
        WisolWindow
    },

    inheritAttrs: false,

    computed: {
        windows () {
            return this.$store.getters['windows/all']
        }
    },

    methods: {
        closeAll () {
            this.windows.forEach(meta => {
                this.$store.dispatch('windows/destroy', { id: meta['@id'] })
            })
        },

        minimizeAll () {
            this.windows.forEach(meta => {
                this.$store.dispatch('window(' + meta['@id'] + ')/minimize')
            })
        },

        maximizeAll () {
            this.windows.forEach(meta => {
                this.$store.dispatch('window(' + meta['@id'] + ')/maximize')
            })
        },

        onTabClick (window) {
            if (this.$refs['window-' + window['@id']]) {
                this.$refs['window-' + window['@id']][0].$el.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }
}
</script>
