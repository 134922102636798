<script>
export default {
    inheritAttrs: false,

    data () {
        return {
            error: null
        }
    },

    errorCaptured (error) {
        this.error = error

        this.$emit('error', error)
    },

    methods: {
        reset () {
            this.error = null

            this.$emit('reset')
        }
    },

    render () {
        return this.$scopedSlots.default({
            error: this.error,
            reset: this.reset
        })
    }
}
</script>
