<template>
    <div class="component-wisol-editor-menu">
        <toolbar
            v-bind="settings"
            :selected-item="selectedRow"
            :is-dirty="isDirty"
            class="toolbar"
            @update="updateSetting"
            @action="doAction"
        />
        <div
            class="editor-container"
        >
            <div
                ref="treeContainer"
                :style="treeStyle"
                class="tree-container"
                tabindex="0"
            >
                <input
                    type="text"
                    style="width: 100%; border: none; border-bottom: 1px solid; background-color: #f3f3f3; padding: 10px; font-weight: 700; margin-bottom: 20px;"
                    @input="setSearch"
                >
                <div
                    v-if="settings.enableDragging"
                    class="dragNotice"
                >
                    <icon
                        name="fa/light/exclamation-triangle"
                    />
                    Drag mode enabled
                </div>
                <tree
                    :parent="tree"
                    :expanded="true"
                    :enable-dragging="settings.enableDragging"
                    :clipboard="clipboard"
                    class="tree"
                    @updatePos="onUpdatePos"
                    @showDetail="showDetail"
                    @toggle="toggleItem"
                    @enterItem="dragenterItem"
                    @dragEnded="dragEnded"
                    @action="doAction"
                />
            </div>
            <div
                :is="selectedComponent"
                :row="selectedRow"
                class="detail"
                @showDetail="showDetail"
                @updateRow="onUpdateRow"
            />
        </div>
        <wisol-loading v-if="isLoading" />
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-menu {
        position: relative;
        width: 100%;
        max-height: 100%;
        display: flex;
        width: 100%;
        flex-direction: column;
        background: #fff;
        font-size: 1.1em;

        &> .loading-overlay {
            background-color: nth($color-palette-grey, 6);
            width: 100%;
            height: 100%;
            opacity: 0.5;
            position: absolute;
            z-index: 9999;
        }

        &> .toolbar {
            position: fixed;
            z-index: 2;
            width: 100%;
        }

        &> .editor-container {
            padding-top: 50px;
            display: flex;
            width: 100%;
            height: 100vh;
            overflow: auto;
            position: relative;
            box-sizing: border-box;

            &> .tree-container {
                min-width: 600px;
                padding: 20px 20px 0px 20px;
                heigth: 100%;

                &> .dragNotice {
                    position: fixed;
                    font-size: 1.1em;
                    width: 460px;
                    top: 70px;
                    z-index: 9999;
                    text-align: center;
                    padding: 4px;
                    border: 1px solid nth($color-palette-status, 7);
                    background-color: nth($color-palette-status, 3);
                    margin-bottom: 20px;
                }
            }

            &> .detail {
                width: 80%;
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                overflow-y: scroll;
            }
        }
    }
</style>

<script>
import WisolEditorTree from './Tree'
import WisolEditorMenuToolbar from './Toolbar.vue'
import WisolEditorMenuDetailMenu from './detail/Menu'
import WisolEditorMenuDetailModule from './detail/Module'
import WisolEditorMenuDetailDatagroup from './detail/Datagroup'
import WisolEditorMenuDetailDatagroupaction from './detail/Datagroupaction'
import WisolEditorMenuDetailBox from './detail/Box'
import WisolEditorMenuDetailBoxaction from './detail/Boxaction'
import WisolEditorMenuDetailField from './detail/Field'
import WisolEditorMenuDetailWidget from './detail/Widget'
import WisolEditorMenuDetailDatagrouplink from './detail/Datagrouplink'
import requests from './mixins/requests.js'
import copy from './mixins/copy.js'
import paste from './mixins/paste.js'
import alert, { success } from '@/utils/alert'
import has from 'lodash-es/has'
import Icon from '@wisol/libs-icons'

import debounce from 'lodash-es/debounce'

export default {
    name: 'WisolEditorMenu',

    components: {
        tree: WisolEditorTree,
        toolbar: WisolEditorMenuToolbar,
        Icon
    },

    mixins: [
        requests(),
        copy(),
        paste()
    ],

    inheritAttrs: false,

    data () {
        return {
            mode: 'tree',
            components: {
                menu: WisolEditorMenuDetailMenu,
                module: WisolEditorMenuDetailModule,
                dataGroup: WisolEditorMenuDetailDatagroup,
                dataGroupAction: WisolEditorMenuDetailDatagroupaction,
                dataGroupLink: WisolEditorMenuDetailDatagrouplink,
                box: WisolEditorMenuDetailBox,
                boxAction: WisolEditorMenuDetailBoxaction,
                field: WisolEditorMenuDetailField,
                widget: WisolEditorMenuDetailWidget
            },
            displays: [
                'menu',
                'module'
            ],
            sorting: [{
                field: '@id',
                direction: 'ASC'
            }],
            hasSelection: true,
            showPagination: true,
            rowsPerPage: 10,
            page: 1,
            newRows: [],
            newRowsSnapshot: [],
            filters: {},
            dirtyRows: [],
            isLoading: false,
            rootEl: {
                '@id': null,
                '@idType': 'database',
                '@itemtype': 'menu'
            },
            selected: {},
            expanded: [],
            dragenterItemID: null,
            settings: {
                showBoxes: true,
                showHidden: true,
                enableDragging: false,
                expandAll: false
            },
            isResizing: false,
            treeWidth: 500,
            offsetX: 0,
            virtualId: 0,
            clipboard: {},
            clipboardMode: 'copy',
            clipboardCopy: null,
            showDeleteConfirmation: false,
            searchString: ''
        }
    },

    computed: {
        tree () {
            // Build tree
            return {
                '@id': 'root',
                '@idType': null,
                '@itemtype': null,
                '@label': null,
                children: () => {
                    return [{
                        '@id': null,
                        '@idType': 'database',
                        '@itemtype': 'menu',
                        '@label': 'Wisol Menu',
                        '@parent': 'root',
                        '@component': this.components.menu,
                        children: () => {
                            return this.mappedMenus
                                .filter((item) => {
                                    return item['@parent'] === null
                                })
                        },
                        parent: () => {
                            return []
                        },
                        isExpanded: () => true,
                        isDirty: () => false,
                        isDisplayed: () => true,
                        isSelected: () => this.isRowSelected({
                            '@id': null,
                            '@idType': 'database',
                            '@itemtype': 'menu'
                        }),
                        isCut: () => false
                    }]
                },
                parent: () => {
                    return []
                },
                isExpanded: () => true,
                isDirty: () => false,
                isDisplayed: () => true,
                isSelected: () => false,
                isCut: () => false
            }
        },

        mappedMenus () {
            return this.menus
                .map((menu) => {
                    return {
                        ...menu,
                        '@label': menu.label,
                        '@component': this.components.menu,
                        '@parent': menu['@parent'],
                        '@ref': menu,
                        children: () => {
                            return [
                                ...this.mappedMenus
                                    .filter((item) => {
                                        return item['@parent'] === menu['@id'] &&
                                            item['@parentIdType'] === menu['@idType']
                                    }),

                                ...this.mappedModules
                                    .filter((item) => {
                                        return item['@menu'] === menu['@id'] &&
                                    item['@parentIdType'] === menu['@idType']
                                    }).reduce((arr, mod) => {
                                        arr.push(...this.mappedDatagroups.filter((dtg) => {
                                            return dtg['@module'] === mod['@id'] &&
                                        dtg['@parentIdType'] === mod['@idType']
                                        }))

                                        return arr
                                    }, []).reduce((arr, dtg) => {
                                        arr.push(...this.mappedBoxes.filter((box) => {
                                            return box['@dataGroup'] === dtg['@id'] &&
                                        box['@parentIdType'] === dtg['@idType']
                                        }))

                                        return arr
                                    }, []),

                                ...this.mappedModules
                                    .filter((item) => {
                                        return item['@menu'] === menu['@id'] &&
                                            item['@parentIdType'] === menu['@idType']
                                    })
                            ]
                        },
                        parent: () => {
                            return this.mappedMenus
                                .find((item) => {
                                    return item['@id'] === menu['@parent'] &&
                                        item['@idType'] === menu['@parentIdType']
                                })
                        },
                        isExpanded: () => {
                            return this.expanded.indexOf('menu' + ':' + menu['@idType'] + ':' + menu['@id']) >= 0
                        },
                        isSelected: () => this.isRowSelected(menu),
                        isDirty: () => this.isRowDirty(menu),
                        isDisplayed: (label, children) => {
                            return this.compareSearch(label) ||
                            children.some((child) =>
                                child.isDisplayed(child['@label'], child.children()) && !child['@menu']
                            )
                        },
                        isCut: () => this.isCut(menu)
                    }
                })
                .sort((a, b) => {
                    const x = a.order
                    const y = b.order
                    return ((x < y) ? -1 : ((x > y) ? 1 : 0))
                })
        },

        mappedModules () {
            return this.modules.map((module) => {
                return {
                    ...module,
                    '@label': module.type,
                    '@component': this.components.module,
                    '@parent': module['@menu'],
                    '@ref': module,
                    children: () => {
                        return this.mappedDatagroups
                            .filter((item) => {
                                return item['@module'] === module['@id'] &&
                                    item['@parentIdType'] === module['@idType']
                            })
                    },
                    parent: () => {
                        return this.mappedMenus
                            .find((item) => {
                                return item['@id'] === module['@menu'] &&
                                    item['@idType'] === module['@parentIdType']
                            })
                    },
                    isExpanded: () => {
                        return this.expanded.indexOf('module' + ':' + module['@idType'] + ':' + module['@id']) >= 0
                    },
                    isSelected: () => this.isRowSelected(module),
                    isDirty: () => this.isRowDirty(module),
                    isDisplayed: (label, children, parent) => true,
                    isCut: () => this.isCut(module)
                }
            })
        },

        mappedDatagroups () {
            return this.dataGroups.map((dataGroup) => {
                const name = dataGroup.name ? ' <i>(' + dataGroup.name + ')</i>' : ''
                return {
                    ...dataGroup,
                    '@label': dataGroup.table + name,
                    '@component': this.components.dataGroup,
                    '@parent': dataGroup['@module'],
                    children: () => {
                        return [
                            {
                                '@id': dataGroup['@id'] + ':link_group',
                                '@idType': dataGroup['@idType'],
                                '@label': 'Links',
                                '@itemtype': 'link_group',
                                children: () => {
                                    return this.mappedDatagrouplinks
                                        .filter((item) => {
                                            return item['@source'] === dataGroup['@id'] &&
                                            item['@parentIdType'] === dataGroup['@idType']
                                        })
                                },
                                parent: () => {
                                    return this.mappedDatagroups
                                        .find((item) => {
                                            return item['@id'] === dataGroup['@id'] &&
                                                item['@idType'] === dataGroup['@idType']
                                        })
                                },
                                isExpanded: () => {
                                    return this.expanded.indexOf('link_group' + ':' + dataGroup['@idType'] + ':' + dataGroup['@id'] + ':link_group') >= 0
                                },
                                isSelected: () => false,
                                isDirty: () => false,
                                isDisplayed: (label, children, parent) => true,
                                isCut: () => false
                            },
                            {
                                '@id': dataGroup['@id'] + ':box_group',
                                '@idType': dataGroup['@idType'],
                                '@label': 'Boxes',
                                '@itemtype': 'box_group',
                                children: () => {
                                    return this.mappedBoxes
                                        .filter((item) => {
                                            return item['@dataGroup'] === dataGroup['@id'] &&
                                                item['@parentIdType'] === dataGroup['@idType']
                                        })
                                },
                                parent: () => {
                                    return this.mappedDatagroups
                                        .find((item) => {
                                            return item['@id'] === dataGroup['@id'] &&
                                                item['@idType'] === dataGroup['@idType']
                                        })
                                },
                                isExpanded: () => {
                                    return this.expanded.indexOf('box_group' + ':' + dataGroup['@idType'] + ':' + dataGroup['@id'] + ':box_group') >= 0
                                },
                                isSelected: () => false,
                                isDirty: () => false,
                                isDisplayed: (label, children, parent) => true,
                                isCut: () => false
                            },
                            {
                                '@id': dataGroup['@id'] + ':field_group',
                                '@idType': dataGroup['@idType'],
                                '@label': 'Fields',
                                '@itemtype': 'field_group',
                                children: () => {
                                    return this.mappedFields
                                        .filter((item) => {
                                            return item['@dataGroup'] === dataGroup['@id'] &&
                                                item['@parentIdType'] === dataGroup['@idType']
                                        })
                                },
                                parent: () => {
                                    return this.mappedDatagroups
                                        .find((item) => {
                                            return item['@id'] === dataGroup['@id'] &&
                                                item['@idType'] === dataGroup['@idType']
                                        })
                                },
                                isExpanded: () => {
                                    return this.expanded.indexOf('field_group' + ':' + dataGroup['@idType'] + ':' + dataGroup['@id'] + ':field_group') >= 0
                                },
                                isSelected: () => false,
                                isDirty: () => false,
                                isDisplayed: (label, children, parent) => true,
                                isCut: () => false
                            },
                            {
                                '@id': dataGroup['@id'] + ':dataGroupAction_group',
                                '@idType': dataGroup['@idType'],
                                '@label': 'Actions',
                                '@itemtype': 'dataGroupAction_group',
                                isExpanded: () => {
                                    return this.expanded.indexOf('dataGroupAction_group' + ':' + dataGroup['@idType'] + ':' + dataGroup['@id'] + ':dataGroupAction_group') >= 0
                                },
                                children: () => {
                                    return this.mappedDatagroupactions
                                        .filter((item) => {
                                            return item['@dataGroup'] === dataGroup['@id'] &&
                                                item['@parentIdType'] === dataGroup['@idType']
                                        })
                                },
                                parent: () => {
                                    return this.mappedDatagroups
                                        .find((item) => {
                                            return item['@id'] === dataGroup['@id'] &&
                                                item['@idType'] === dataGroup['@idType']
                                        })
                                },
                                isSelected: () => false,
                                isDirty: () => false,
                                isDisplayed: (label, children, parent) => true,
                                isCut: () => false
                            }
                        ]
                    },
                    parent: () => {
                        return this.mappedModules
                            .find((item) => {
                                return item['@id'] === dataGroup['@module'] &&
                                    item['@idType'] === dataGroup['@parentIdType']
                            })
                    },
                    isExpanded: () => {
                        return this.expanded.indexOf('dataGroup' + ':' + dataGroup['@idType'] + ':' + dataGroup['@id']) >= 0
                    },
                    isSelected: () => this.isRowSelected(dataGroup),
                    isDirty: () => this.isRowDirty(dataGroup),
                    isDisplayed: () => (label) => this.compareSearch(label),
                    isCut: () => this.isCut(dataGroup)
                }
            })
        },

        mappedBoxes () {
            return this.boxes.map((box) => {
                return {
                    ...box,
                    '@label': box.title,
                    '@component': this.components.box,
                    '@parent': box['@dataGroup'],
                    children: () => {
                        return [
                            ...this.mappedWidgets
                                .filter((item) => {
                                    return item['@box'] === box['@id'] &&
                                        item['@parentIdType'] === box['@idType']
                                }),
                            {
                                '@id': box['@id'] + ':boxAction_group',
                                '@idType': box['@idType'],
                                '@label': 'Actions',
                                '@itemtype': 'boxAction_group',
                                isExpanded: () => {
                                    return this.expanded.indexOf('boxAction_group' + ':' + box['@idType'] + ':' + box['@id'] + ':boxAction_group') >= 0
                                },
                                children: () => {
                                    return this.mappedBoxactions
                                        .filter((item) => {
                                            return item['@box'] === box['@id'] &&
                                                item['@parentIdType'] === box['@idType']
                                        })
                                },
                                isSelected: () => false,
                                isDirty: () => false,
                                isDisplayed: (label) => true,
                                isCut: () => false
                            }
                        ]
                    },
                    parent: () => {
                        return this.mappedDatagroups
                            .find((item) => {
                                return item['@id'] === box['@dataGroup'] &&
                                    item['@idType'] === box['@parentIdType']
                            })
                    },
                    isExpanded: () => {
                        return this.expanded.indexOf('box' + ':' + box['@idType'] + ':' + box['@id']) >= 0
                    },
                    isSelected: () => this.isRowSelected(box),
                    isDirty: () => this.isRowDirty(box),
                    isDisplayed: (label) => this.compareSearch(label),
                    isCut: () => this.isCut(box)
                }
            })
        },

        mappedBoxactions () {
            return this.boxActions.map((boxAction) => {
                return {
                    ...boxAction,
                    '@label': boxAction.label,
                    '@component': this.components.boxAction,
                    '@parent': boxAction['@box'],
                    children: () => {
                        return []
                    },
                    parent: () => {
                        return this.mappedBoxes
                            .find((item) => {
                                return item['@id'] === boxAction['@box'] &&
                                    item['@idType'] === boxAction['@parentIdType']
                            })
                    },
                    isExpanded: () => {
                        return this.expanded.indexOf('boxAction' + ':' + boxAction['@idType'] + ':' + boxAction['@id']) >= 0
                    },
                    isSelected: () => this.isRowSelected(boxAction),
                    isDirty: () => this.isRowDirty(boxAction),
                    isDisplayed: (label) => true,
                    isCut: () => this.isCut(boxAction)
                }
            })
        },

        mappedFields () {
            return this.fields.map((field) => {
                const label = field.field !== null ? ' <i>(' + field.field + ')</i>' : ''
                return {
                    ...field,
                    '@label': field.label + label,
                    '@component': this.components.field,
                    '@parent': field['@dataGroup'],
                    children: () => {
                        return []
                    },
                    parent: () => {
                        return this.mappedDatagroups
                            .find((item) => {
                                return item['@id'] === field['@dataGroup'] &&
                                    item['@idType'] === field['@parentIdType']
                            })
                    },
                    isExpanded: () => {
                        return this.expanded.indexOf('field' + ':' + field['@idType'] + ':' + field['@id']) >= 0
                    },
                    isSelected: () => this.isRowSelected(field),
                    isDirty: () => this.isRowDirty(field),
                    isDisplayed: (label) => true,
                    isCut: () => this.isCut(field)
                }
            })
        },

        mappedWidgets () {
            return this.widgets.map((widget) => {
                return {
                    ...widget,
                    '@label': widget.type,
                    '@component': this.components.widget,
                    '@parent': widget['@widget'],
                    children: () => {
                        return []
                    },
                    parent: () => {
                        return this.mappedBoxes
                            .find((item) => {
                                return item['@id'] === widget['@box'] &&
                                    item['@idType'] === widget['@parentIdType']
                            })
                    },
                    isExpanded: () => {
                        return this.expanded.indexOf('widget' + ':' + widget['@idType'] + ':' + widget['@id']) >= 0
                    },
                    isSelected: () => this.isRowSelected(widget),
                    isDirty: () => this.isRowDirty(widget),
                    isDisplayed: (label) => true,
                    isCut: () => this.isCut(widget)
                }
            })
        },

        mappedDatagroupactions () {
            return this.dataGroupActions.map((dataGroupAction) => {
                return {
                    ...dataGroupAction,
                    '@label': dataGroupAction.label,
                    '@component': this.components.dataGroupAction,
                    '@parent': dataGroupAction['@dataGroup'],
                    children: () => {
                        return []
                    },
                    parent: () => {
                        return this.mappedDatagroups
                            .find((item) => {
                                return item['@id'] === dataGroupAction['@dataGroup'] &&
                                    item['@idType'] === dataGroupAction['@parentIdType']
                            })
                    },
                    isExpanded: () => {
                        return this.expanded.indexOf('dataGroupAction' + ':' + dataGroupAction['@idType'] + ':' + dataGroupAction['@id']) >= 0
                    },
                    isSelected: () => this.isRowSelected(dataGroupAction),
                    isDirty: () => this.isRowDirty(dataGroupAction),
                    isDisplayed: (label) => true,
                    isCut: () => this.isCut(dataGroupAction)
                }
            })
        },

        mappedDatagrouplinks () {
            return this.dataGroupLinks.map((dataGroupLink) => {
                return {
                    ...dataGroupLink,
                    '@label': this.getDatagroupLabelById(dataGroupLink['@target']),
                    '@component': this.components.dataGroupLink,
                    '@parent': dataGroupLink['@source'],
                    children: () => {
                        return []
                    },
                    parent: () => {
                        return this.mappedDatagroups
                            .find((item) => {
                                return item['@id'] === dataGroupLink['@source'] &&
                                    item['@idType'] === dataGroupLink['@parentIdType']
                            })
                    },
                    isExpanded: () => {
                        return this.expanded.indexOf('dataGroupLink' + ':' + dataGroupLink['@idType'] + ':' + dataGroupLink['@id']) >= 0
                    },
                    isSelected: () => this.isRowSelected(dataGroupLink),
                    isDirty: () => this.isRowDirty(dataGroupLink),
                    isDisplayed: (label) => true,
                    isCut: () => this.isCut(dataGroupLink)
                }
            })
        },

        rawmenus () {
            let result = []
            if (!this.settings.showHidden) {
                const menus = this.$store.getters['ui/menu/all']
                result = menus.filter((menu) => {
                    return menu.visible
                })
            } else {
                result = this.$store.getters['ui/menu/all']
            }

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return result
        },

        rawmodules () {
            let result = this.$store.getters['ui/module/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return result
        },

        rawboxes () {
            let result = this.$store.getters['ui/box/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return result
        },

        rawfields () {
            let result = this.$store.getters['ui/field/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return result
        },

        rawdataGroups () {
            let result = this.$store.getters['ui/dataGroup/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return result
        },

        rawwidgets () {
            let result = this.$store.getters['ui/widget/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return result
        },

        rawboxActions () {
            let result = this.$store.getters['ui/boxAction/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return result
        },

        rawdataGroupActions () {
            let result = this.$store.getters['ui/dataGroupAction/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return result
        },

        rawdataGroupLinks () {
            let result = this.$store.getters['ui/dataGroupLink/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return result
        },

        rawlayouts () {
            let result = this.$store.getters['layout/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return result
        },

        menus () {
            let result = []
            if (!this.settings.showHidden) {
                const menus = this.$store.getters['ui/menu/all']
                result = menus.filter((menu) => {
                    return menu.visible
                })
            } else {
                result = this.$store.getters['ui/menu/all']
            }

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return [
                ...result,
                ...this.newRows.filter((row) => {
                    return row['@itemtype'] === 'menu'
                })
            ].map((menu) => {
                return {
                    ...menu,
                    '@itemtype': 'menu'
                }
            })
        },

        modules () {
            let result = this.$store.getters['ui/module/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return [
                ...result,
                ...this.newRows.filter((row) => {
                    return row['@itemtype'] === 'module'
                })
            ].map((module) => {
                return {
                    ...module,
                    '@itemtype': 'module',
                    label: module.type
                }
            })
        },

        boxes () {
            let result = this.$store.getters['ui/box/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return [
                ...result,
                ...this.newRows.filter((row) => {
                    return row['@itemtype'] === 'box'
                })
            ].map((box) => {
                return {
                    ...box,
                    '@itemtype': 'box',
                    label: box.title
                }
            })
        },

        fields () {
            let result = this.$store.getters['ui/field/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return [
                ...result,
                ...this.newRows.filter((row) => {
                    return row['@itemtype'] === 'field'
                })
            ].map((field) => {
                return {
                    ...field,
                    '@itemtype': 'field'
                }
            })
        },

        dataGroups () {
            let result = this.$store.getters['ui/dataGroup/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return [
                ...result,
                ...this.newRows.filter((row) => {
                    return row['@itemtype'] === 'dataGroup'
                })
            ].map((dataGroup) => {
                return {
                    ...dataGroup,
                    '@itemtype': 'dataGroup',
                    label: dataGroup.table || null
                }
            })
        },

        widgets () {
            let result = this.$store.getters['ui/widget/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return [
                ...result,
                ...this.newRows.filter((row) => {
                    return row['@itemtype'] === 'widget'
                })
            ].map((widget) => {
                return {
                    ...widget,
                    '@itemtype': 'widget',
                    label: widget.type
                }
            })
        },

        boxActions () {
            let result = this.$store.getters['ui/boxAction/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return [
                ...result,
                ...this.newRows.filter((row) => {
                    return row['@itemtype'] === 'boxAction'
                })
            ].map((boxAction) => {
                return {
                    ...boxAction,
                    '@itemtype': 'boxAction'
                }
            })
        },

        dataGroupActions () {
            let result = this.$store.getters['ui/dataGroupAction/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return [
                ...result,
                ...this.newRows.filter((row) => {
                    return row['@itemtype'] === 'dataGroupAction'
                })
            ].map((dataGroupAction) => {
                return {
                    ...dataGroupAction,
                    '@itemtype': 'dataGroupAction'
                }
            })
        },

        dataGroupLinks () {
            let result = this.$store.getters['ui/dataGroupLink/all']

            // Add id type to distinguish between new (virtual) items and database items
            result = this.addDatabaseType(result)

            return [
                ...result,
                ...this.newRows.filter((row) => {
                    return row['@itemtype'] === 'dataGroupLink'
                })
            ].map((dataGroupLink) => {
                return {
                    ...dataGroupLink,
                    '@itemtype': 'dataGroupLink'
                }
            })
        },

        property () {
            return this.menus.length > 0 ? Object.keys(this.menus[0]) : []
        },

        rowOptions () {
            return this.rows.map((row, index) => {
                return {
                    selectable: true
                }
            })
        },

        activeRow () {
            return this.menus.find((menu) => {
                return menu['@id'] === this.activeRowId
            })
        },

        actions () {
            return [
                {
                    icon: 'fa/light/plus',
                    title: this.$t('data.new'),
                    disabled: false,
                    callback: evt => {
                        this.insertEmptyRow()
                    }
                },
                {
                    icon: 'fa/light/save',
                    title: this.$t('data.save'),
                    disabled: !this.isDirty,
                    callback: evt => {
                        this.save()
                    }
                }
            ]
        },

        actionGroups () {
            return ['default', 'context', 'widgets']
        },

        isDirty () {
            return this.dirtyRows.length > 0 || this.newRows.length > 0
        },

        selectedRow () {
            if (this.selectedId) {
                return this.isRowDirty(this.selected)
                    ? {
                        ...this.getTreeRow(this.selectedId, this.selectedIdType, this.selectedType),
                        ...this.getDirtyRow(this.selectedId, this.selectedIdType, this.selectedType)
                    }
                    : this.getMappedItems(this.selectedType)
                        .find((item) => {
                            return item['@id'] === this.selectedId &&
                                item['@idType'] === this.selectedIdType
                        })
            } else {
                return this.tree.children()[0]
            }
        },

        selectedId () {
            return this.selected['@id'] || null
        },

        selectedIdType () {
            return this.selected['@idType'] || null
        },

        selectedType () {
            return this.selected['@itemtype'] || null
        },

        selectedComponent () {
            return this.components[this.selectedType] || null
        },

        treeStyle () {
            return {
                width: this.isResizing ? this.treeWidth + this.offsetX + 'px' : this.treeWidth + 'px',
                'padding-top': this.settings.enableDragging ? '60px' : '20px'
            }
        },

        selectedTypePlural () {
            switch (this.selectedType) {
                case 'box':
                    return 'boxes'
                default:
                    return this.selectedType + 's'
            }
        },

        isSelectedDirty () {
            const found = this.dirtyRows.findIndex((row) => {
                return row['@id'] === this.selectedId &&
                    row['@idType'] === this.selectedIdType &&
                    row['@itemtype'] === this.selectedType
            })
            return found > -1
        },

        isDatagroupUsedByBox () {
            const found = this.rawboxes.findIndex((box) => {
                return box['@dataGroup'] === this.selectedRow['@id']
            })
            return found > -1
        },

        validDirtyRows () {
            return this.dirtyRows
                .filter((row) => {
                    return row['@valid']
                })
        },

        sanitizedValidDirtyRows () {
            return this.validDirtyRows
                .map((row) => {
                    return this.sanitize(row, true, true)
                })
        },

        sanitizedClipboard () {
            const sanitizedClipboard = {}
            if (Object.keys(this.clipboard).length > 0) {
                Object.keys(this.clipboard).forEach((type) => {
                    sanitizedClipboard[type] = this.clipboard[type]
                        .map((row) => {
                            return this.sanitize(row, true, true)
                        })
                })
            }
            return sanitizedClipboard
        },

        sanitizedSaveClipboard () {
            const sanitizedSaveClipboard = {}
            if (Object.keys(this.sanitizedClipboard).length > 0) {
                Object.keys(this.sanitizedClipboard).forEach((type) => {
                    sanitizedSaveClipboard[type] = this.sanitizedClipboard[type]
                        .map((row) => {
                            return {
                                ...row,
                                '@id': this.getByValue(this.idCopyMap.get(type), row['@id'])
                            }
                        })
                })
            }
            return sanitizedSaveClipboard
        }
    },

    created () {
        window.addEventListener('keydown', this.handleKeyDown)
        window.addEventListener('keyup', this.handleKeyUp)
    },

    mounted () {
        this.selected = this.rootEl
    },

    destroyed () {
        window.removeEventListener('keydown', this.handleKeyDown)
        window.removeEventListener('keyup', this.handleKeyUp)
    },

    methods: {
        getDatagroupLabelById (id) {
            return id !== null
                ? this.dataGroups.find((group) => {
                    return group['@id'] === id
                }).label
                : '<i>no target</i>'
        },

        isCut (row) {
            if (this.clipboardMode === 'cut') {
                if (Object.keys(this.clipboard).length > 0) {
                    return this.clipboard[row['@itemtype']]
                        .filter((item) => {
                            return this.idCopyMap.get(row['@itemtype']).get(row['@id']) !== undefined
                        }).length > 0
                } else {
                    return false
                }
            } else {
                return false
            }
        },

        getByValue (map, searchValue) {
            for (const [key, value] of map.entries()) {
                if (value === searchValue) {
                    return key
                }
            }
        },

        getTreeRow (id, idType, itemtype) {
            return this.getMappedItems(itemtype)
                .find((item) => {
                    return item['@id'] === id &&
                        item['@idType'] === idType
                })
        },

        removeDirtyRowsFromNewRows () {
            const newRowsCopy = [...this.newRowsSnapshot]
            newRowsCopy.forEach((row) => {
                if (row['@isDirty'] && this.isRowDirty(row)) {
                    this.$delete(this.newRows, this.getNewRowIndex(row['@id'], row['@idType'], row['@itemtype']))
                }
            })
            this.newRowsSnapshot = []
        },

        isRowSelected (item) {
            return this.selectedId === item['@id'] &&
                this.selectedIdType === item['@idType'] &&
                this.selectedType === item['@itemtype']
        },

        updateSetting (key, value) {
            this.settings[key] = value
        },

        doAction (action) {
            switch (action) {
                case 'expand':
                    this.expandAll()
                    break
                case 'collapse':
                    this.collapseAll()
                    break
                case 'new_menu':
                    this.newItem('menu')
                    break
                case 'new_module':
                    this.newItem('module')
                    break
                case 'new_box':
                    this.newItem('box')
                    break
                case 'new_field':
                    this.newItem('field')
                    break
                case 'new_dataGroup':
                    this.newItem('dataGroup')
                    break
                case 'new_widget':
                    this.newItem('widget')
                    break
                case 'new_boxAction':
                    this.newItem('boxAction')
                    break
                case 'new_dataGroupAction':
                    this.newItem('dataGroupAction')
                    break
                case 'new_dataGroupLink':
                    this.newItem('dataGroupLink')
                    break
                case 'save':
                    this.save()
                    break
                case 'delete':
                    // this.confirmDelete()
                    if (window.confirm('Do you really want to delete the selected item?')) {
                        this.deleteSelectedItem()
                    }
                    break
                case 'copy':
                    this.clipboardMode = 'copy'
                    this.copyToClipboard()
                    break
                case 'cut':
                    this.clipboardMode = 'cut'
                    this.idCopyMap = new Map()
                    this.clipboard = this.mergeCopyArray(
                        this.recursiveCopy(this.selectedRow)
                    )
                    break
                case 'paste':
                    this.pasteItem()
                    break
                case 'export':
                    this.exportMenu()
                    break
                case 'import':
                    this.importMenu()
                    break
            }
        },

        dragEnded () {
            this.dragenterItemID = null
        },

        dragenterItem (item) {
            this.dragenterItemID = item['@id']
        },

        toggleItem (item) {
            if (this.expanded.indexOf(item['@itemtype'] + ':' + item['@idType'] + ':' + item['@id']) >= 0) {
                this.$delete(
                    this.expanded,
                    this.expanded.indexOf(item['@itemtype'] + ':' + item['@idType'] + ':' + item['@id'])
                )
            } else {
                this.expanded = [
                    ...this.expanded,
                    item['@itemtype'] + ':' + item['@idType'] + ':' + item['@id']
                ]
            }
        },

        expandItem (item) {
            if (this.expanded.indexOf(item['@itemtype'] + ':' + item['@idType'] + ':' + item['@id']) < 0) {
                this.expanded = [
                    ...this.expanded,
                    item['@itemtype'] + ':' + item['@idType'] + ':' + item['@id']
                ]
            }
        },

        isGroupType (item) {
            return item['@itemtype'] === 'box_group' ||
                item['@itemtype'] === 'field_group' ||
                item['@itemtype'] === 'boxAction_group' ||
                item['@itemtype'] === 'dataGroupAction_group' ||
                item['@itemtype'] === 'link_group'
        },

        showDetail (item) {
            if (!this.isGroupType(item)) {
                this.selected = item
            } else {
                this.toggleItem(item)
            }
        },

        switchMode (view) {
            this.mode = view
        },

        onUpdateSorting (sorting) {
            this.sorting = sorting
        },

        onUpdateRow (row) {
            const rowId = row['@id']
            const rowIdType = row['@idType']
            const rowType = row['@itemtype']

            // if selected row is a new row, add dirty function to new row
            if (this.getNewRowIndex(rowId, rowIdType, rowType) >= 0) {
                const newRow = this.getNewRow(rowId, rowIdType, rowType)
                this.$set(
                    this.newRows,
                    this.getNewRowIndex(rowId, rowIdType, rowType),
                    {
                        ...this.getTreeRow(rowId, rowIdType, rowType),
                        ...newRow,
                        isDirty: () => true
                    }
                )
            }
            if (this.getDirtyRowIndex(rowId, rowIdType, rowType) > -1) {
                this.$set(
                    this.dirtyRows,
                    this.getDirtyRowIndex(rowId, rowIdType, rowType),
                    {
                        ...this.getTreeRow(rowId, rowIdType, rowType),
                        ...row
                    }
                )
            } else {
                this.dirtyRows = [
                    ...this.dirtyRows,
                    {
                        ...this.getTreeRow(rowId, rowIdType, rowType),
                        ...row
                    }
                ]
            }

            if (row['@itemtype'] !== 'dataGroupLink') {
                this.selected = this.getDirtyRow(rowId, rowIdType, rowType)
            }
        },

        onUpdateFilterValue ({ field, value }) {
            if (value !== '') {
                if (has(this.filters, field)) {
                    this.$set(this.filters, field, value)
                } else {
                    this.filters = {
                        ...this.filters,
                        [field]: value
                    }
                }
            } else {
                this.$delete(this.filters, field)
            }
        },

        onActiveRowUpdate (row) {
            this.activeRowId = row['@id']
        },

        onSelectRow (row) {
            if (!this.hasSelection) {
                return
            }
            if (this.selectedRows.indexOf(row) > -1) {
                // remove row from selection
                this.selectedRows = [
                    ...this.selectedRows.slice(0, this.selectedRows.indexOf(row)),
                    ...this.selectedRows.slice(this.selectedRows.indexOf(row) + 1, this.selectedRows.length)
                ]
            } else {
                this.selectedRows.push(row)
            }
        },

        getRowIdByIndex (index) {
            return this.rows[index]['@id']
        },

        onPageUpdate ({ page }) {
            this.page = page
        },

        onCountUpdate ({ count }) {
            this.rowsPerPage = count
        },

        insertEmptyRow () {
            // Display new line on last page
            this.page = Math.floor(this.menus.length / this.rowsPerPage) + 1
            this.newRows = [
                ...this.newRows,
                {}
            ]
        },

        isRowDirty (item) {
            const found = this.dirtyRows.findIndex((row) => {
                return row['@id'] === item['@id'] &&
                    row['@idType'] === item['@idType'] &&
                    row['@itemtype'] === item['@itemtype']
            })
            return found >= 0
        },

        getDirtyRow (id, idType, itemType) {
            return this.dirtyRows.find((row) => {
                return row['@id'] === id &&
                    row['@idType'] === idType &&
                    row['@itemtype'] === itemType
            })
        },

        getDirtyRowIndex (id, idType, itemType) {
            return this.dirtyRows.findIndex((row) => {
                return row['@id'] === id &&
                    row['@idType'] === idType &&
                    row['@itemtype'] === itemType
            })
        },

        getNewRow (id, idType, itemType) {
            return this.newRows.find((row) => {
                return row['@id'] === id &&
                    row['@idType'] === idType &&
                    row['@itemtype'] === itemType
            })
        },

        getNewRowIndex (id, idType, itemType) {
            return this.newRows.findIndex((row) => {
                return row['@id'] === id &&
                    row['@idType'] === idType &&
                    row['@itemtype'] === itemType
            })
        },

        getMenuIndexById (id, idType) {
            return this.menus.findIndex((menu) => {
                return menu['@id'] === id &&
                    menu['@idType'] === idType
            })
        },

        getIndexById (id, idType, itemType) {
            return this[this.getPluralType(itemType)].findIndex((item) => {
                return item['@id'] === id &&
                    item['@idType'] === idType
            })
        },

        takeSnapshot () {
            this.newRowsSnapshot = this.newRows.map((row) => {
                return {
                    ...row,
                    '@isDirty': row.isDirty()
                }
            })
        },

        save () {
            this.isLoading = true
            this.takeSnapshot()
            return this.deleteCopiedClipboardItems()
                .then(() => {
                    return this.remoteSave(this.sanitizedValidDirtyRows)
                        .then((response) => this.handleSaveResponse(response))
                        .catch((error) => this.handleRequestError(error))
                })
                .then(() => {
                    if (this.dirtyRows.length > 0 || this.newRows.length > 0) {
                        alert('Some rows couldn\'t be saved')
                    }
                })
        },

        sanitize (row, keepType = false, keepId = false) {
            row = { ...row }
            Object.entries(row).forEach(([key, value]) => {
                // Convert number string values to numbers
                if (key === '@id' ||
                    key === '@parent' ||
                    key === '@menu' ||
                    key === '@module' ||
                    key === '@dataGroup' ||
                    key === 'order') {
                    row[key] = value !== null ? parseInt(value) : null
                }

                // Convert checkbox values
                if (key === 'visible' ||
                    key === 'disabled' ||
                    key === 'single_instance' ||
                    key === 'readonly' ||
                    key === 'virtual' ||
                    key === 'required' ||
                    key === 'default'
                ) {
                    row[key] = value === true ? '1' : '0'
                }

                if (key === '@component' ||
                    key === '@label' ||
                    key === 'children' ||
                    key === 'isDirty' ||
                    key === 'isExpanded' ||
                    key === 'isSelected' ||
                    key === '@ref') {
                    delete row[key]
                }

                if (key === '@itemtype' && !keepType) {
                    delete row[key]
                }

                // Strip id field from row if needed
                if (key === '@id' && !keepId) {
                    delete row[key]
                }

                if (key === 'modules') {
                    row[key] = value || null
                }

                if (key === 'filter') {
                    row[key] = value || null
                }

                if (key === 'table') {
                    row[key] = value || null
                }

                // Delete @valid property
                if (key === '@valid') {
                    delete row[key]
                }
            })
            return row
        },

        onUpdatePos (event) {
            if (this.settings.enableDragging &&
                event.list[0]['@itemtype'] === 'menu') {
                this.isLoading = true
                const dropParent = event.list[0]['@parent']
                if (dropParent !== event.item['@parent']) {
                    this.updateOrder({ ...event.item }, event.index, dropParent)
                } else {
                    this.updateOrder({ ...event.item }, event.index)
                }
            }
        },

        updateOrder (menu, index, parent) {
            const startIndex = menu.order
            const requests = []
            const menuIndex = this.getMenuIndexById(menu['@id'], menu['@idType'])
            // Dragging direction (+) up / (-) down
            const direction = menu.order > index || parent ? 1 : -1
            const oldParent = typeof parent === 'undefined' ? false : menu['@parent']
            const oldOrder = menu.order

            // adjust index
            index = direction < 0 ? index : index + 1

            // update order
            menu.order = index

            // set new parent if parent has changed
            menu['@parent'] = typeof parent === 'undefined' ? menu['@parent'] : parent

            // merge menus
            let items = this.mergeItemAtIndex({ ...menu }, this.menus, menuIndex)

            // add to queue
            requests.push(this.update(menu['@id'], this.sanitize(menu), 'menu'))

            // Filter the remaining menu items with same parent
            let menus = this.menus
                .filter((item) => item['@parent'] === menu['@parent'] && item['@id'] !== menu['@id'])

            // depending on drag direction re-filter menus which are to be updated
            menus = direction > 0
                ? menus.filter((item) => item.order >= index && item.order < startIndex)
                : menus.filter((item) => item.order <= index && item.order > startIndex)

            if (typeof parent !== 'undefined') {
                menus = this.menus
                    .filter((item) => item['@parent'] === parent && item.order >= index)
            }

            // Update order of remaining menus
            menus.forEach((item) => {
                const row = { ...item }
                const menuIndex = this.getMenuIndexById(row['@id'], row['@idType'])
                // Update order according to direction
                row.order += direction
                // merge menus
                items = this.mergeItemAtIndex({ ...row }, items, menuIndex)
                // add to queue
                requests.push(this.update(row['@id'], this.sanitize(row), 'menu'))
            })

            this.updateMenus(requests, items).then(() => {
                if (typeof parent !== 'undefined') {
                    this.updateOrderSource(oldParent, oldOrder)
                }
            })
        },

        updateOrderSource (parent, order) {
            const requests = []
            let items = this.menus
            // Filter the menu items with same parent
            const menus = this.menus
                .filter((item) => item['@parent'] === parent && item.order > order)

            // Update order of remaining menus
            menus.forEach((item) => {
                const row = { ...item }
                const menuIndex = this.getMenuIndexById(row['@id'], row['@idType'])
                // Update order according to direction
                row.order -= 1
                // merge menus
                items = this.mergeItemAtIndex({ ...row }, items, menuIndex)
                // add to queue
                requests.push(this.update(row['@id'], this.sanitize(row), 'menu'))
            })

            this.updateMenus(requests, items)
        },

        handleRequestError (error) {
            const message = error?.response?.data?.message || error.message || error || 'Error'
            const code = error?.response?.data?.code || error.name || 'Unknown'
            alert(code + ': ' + message)
            this.isLoading = false
            return Promise.reject(error)
        },

        // update order
        handleRequestSuccess (response) {
            success('Saved successfully!')
            this.isLoading = false
        },

        loadMenuItems (items) {
            this.$store.commit('ui/menu/LOAD_ITEMS', {
                items: items
            })
        },

        loadItems (items, type) {
            let storeModule
            if (type === 'layout') {
                storeModule = type
            } else {
                storeModule = 'ui/' + type
            }
            this.$store.commit(storeModule + '/LOAD_ITEMS', {
                items: items
            })
        },

        mergeItemAtIndex (item, arr, index) {
            return [
                ...arr.slice(0, index),
                {
                    ...item
                },
                ...arr.slice(index + 1)
            ]
        },

        mergeItemsAtIndex (items, arr, index) {
            return [
                ...arr.slice(0, index),
                ...items,
                ...arr.slice(index + 1)
            ]
        },

        handleSaveResponse (res) {
            const response = res.data
            this.removeDirtyRowsFromNewRows()
            Object.entries(response)
                .forEach(([type, rows]) => {
                    const items = this.mergeItemsAtIndex(
                        rows,
                        this['raw' + this.getPluralType(type)],
                        this['raw' + this.getPluralType(type)].length
                    )
                    this.validDirtyRows
                        .forEach((row) => {
                            this.$delete(
                                this.dirtyRows,
                                this.getDirtyRowIndex(row['@id'], row['@idType'], row['@itemtype'])
                            )
                        })
                    this.loadItems(items, type)
                })
            this.selected = this.rootEl
            this.handleRequestSuccess(response)
        },

        handleResponse (response, id, type, idtype) {
            const row = { ...response.data, '@idType': 'database' }
            const items = this.mergeItemAtIndex(
                row,
                this['raw' + this.getPluralType(type)],
                this['raw' + this.getPluralType(type)].length
            )
            this.$delete(this.dirtyRows, this.getDirtyRowIndex(id, idtype, type))
            this.loadItems(items, type)
            this.selected = this.getMappedItems(type)
                .find((item) => {
                    return item['@id'] === row['@id'] &&
                        item['@idType'] === 'database'
                })
            this.removeDirtyRowsFromNewRows()
            this.handleRequestSuccess(response)
        },

        updateMenus (request, items) {
            // Update all menu items from queue
            this.loadMenuItems(items)
            this.isLoading = true
            return Promise.all(request)
                .then((response) => {
                    this.loadMenuItems(items)
                    this.handleRequestSuccess(response)
                })
                .catch((error) => {
                    return this.handleRequestError(error)
                })
        },

        getPluralType (type) {
            switch (type) {
                case 'box':
                    return 'boxes'
                default:
                    return type + 's'
            }
        },

        addDatabaseType (rows) {
            return rows.map((row) => {
                return {
                    ...row,
                    '@idType': 'database',
                    '@parentIdType': 'database'
                }
            })
        },

        expandAll () {
            this.displays.forEach((type) => {
                if (type === 'dataGroup') {
                    this[this.getPluralType(type)].forEach((item) => {
                        this.expanded = [
                            ...this.expanded,
                            type + ':' + item['@idType'] + ':' + item['@id'],
                            'box_group:' + item['@id'] + ':box_group',
                            'field_group:' + item['@id'] + ':field_group'
                        ]
                    })
                }
                this[this.getPluralType(type)].forEach((item) => {
                    this.expanded = [
                        ...this.expanded,
                        type + ':' + item['@idType'] + ':' + item['@id']
                    ]
                })
            })
        },

        collapseAll () {
            this.expanded = []
        },

        newItem (type) {
            let row
            switch (type) {
                case 'menu':
                    row = {
                        '@id': ++this.virtualId,
                        '@parent': this.selectedId,
                        '@parentIdType': this.selectedIdType,
                        '@component': WisolEditorMenuDetailMenu,
                        color: null,
                        disabled: false,
                        icon: null,
                        label: null,
                        name: null,
                        order: null,
                        single_instance: false,
                        type: 'module',
                        visible: false,
                        '@valid': false,
                        isDirty: () => true
                    }
                    break
                case 'module':
                    row = {
                        '@id': ++this.virtualId,
                        '@menu': this.selectedId,
                        '@parentIdType': this.selectedIdType,
                        '@component': WisolEditorMenuDetailModule,
                        options: null,
                        type: null,
                        '@valid': false,
                        isDirty: () => true
                    }
                    type = 'module'
                    break
                case 'box':
                    row = {
                        '@id': ++this.virtualId,
                        '@dataGroup': this.selectedId,
                        '@parentIdType': this.selectedIdType,
                        '@component': WisolEditorMenuDetailBox,
                        '@module': this.selectedRow.parent()['@id'],
                        name: null,
                        title: null,
                        '@valid': false,
                        isDirty: () => true
                    }
                    type = 'box'
                    break
                case 'field':
                    row = {
                        '@id': ++this.virtualId,
                        '@dataGroup': this.selectedId,
                        '@parentIdType': this.selectedIdType,
                        '@component': WisolEditorMenuDetailField,
                        disabled: false,
                        field: null,
                        filtervalue: null,
                        label: '',
                        readonly: false,
                        required: false,
                        sort: null,
                        tabindex: null,
                        type: null,
                        value: null,
                        virtual: true,
                        '@valid': false,
                        isDirty: () => true
                    }
                    type = 'field'
                    break
                case 'dataGroup':
                    row = {
                        '@id': ++this.virtualId,
                        '@module': this.selectedId,
                        '@parentIdType': this.selectedIdType,
                        '@component': WisolEditorMenuDetailDatagroup,
                        filter: null,
                        readonly: false,
                        table: null,
                        modules: null,
                        scripts: {
                            onclick: null,
                            oninit: null,
                            onsave: null,
                            onupdate: null
                        },
                        '@valid': false,
                        isDirty: () => true
                    }
                    type = 'dataGroup'
                    break
                case 'widget':
                    row = {
                        '@id': ++this.virtualId,
                        '@box': this.selectedId,
                        '@parentIdType': this.selectedIdType,
                        '@component': WisolEditorMenuDetailWidget,
                        options: null,
                        type: null,
                        order: null,
                        default: true,
                        '@valid': false,
                        isDirty: () => true
                    }
                    type = 'widget'
                    break
                case 'boxAction':
                    row = {
                        '@id': ++this.virtualId,
                        '@box': this.selectedId,
                        '@parentIdType': this.selectedIdType,
                        '@component': WisolEditorMenuDetailBoxaction,
                        icon: 'fa/light/bolt',
                        label: null,
                        script: null,
                        '@valid': false,
                        isDirty: () => true
                    }
                    type = 'boxAction'
                    break
                case 'dataGroupAction':
                    row = {
                        '@id': ++this.virtualId,
                        '@dataGroup': this.selectedId,
                        '@parentIdType': this.selectedIdType,
                        '@component': WisolEditorMenuDetailDatagroupaction,
                        icon: 'fa/light/bolt',
                        label: null,
                        script: null,
                        '@valid': false,
                        isDirty: () => true
                    }
                    type = 'dataGroupAction'
                    break
                case 'dataGroupLink':
                    row = {
                        '@id': ++this.virtualId,
                        '@source': this.selectedId,
                        '@parentIdType': this.selectedIdType,
                        '@component': WisolEditorMenuDetailDatagrouplink,
                        type: null,
                        options: null,
                        '@target': null,
                        '@valid': false,
                        isDirty: () => true
                    }
                    type = 'dataGroupLink'
                    break
            }

            row = {
                ...row,
                '@idType': 'virtual',
                '@itemtype': type
            }

            this.newRows = [
                ...this.newRows,
                row
            ]

            this.expandItem({
                ...this.selectedRow
            })

            // Expand groups too
            if (type === 'field' ||
                type === 'box' ||
                type === 'boxAction' ||
                type === 'dataGroupAction') {
                this.expandItem({
                    '@id': this.selectedId + ':' + type + '_group',
                    '@itemtype': type + '_group'
                })
            }

            this.selected = row
        },

        deleteSelectedItem () {
            this.isLoading = true
            if (this.selectedIdType === 'database') {
                // make a copy of all items to be deleted
                this.copyToClipboard(true)
                // sanitize copies
                this.clipboardCopy = this.sanitizedSaveClipboard
                // First remove locally
                this.selected = this.rootEl
                // Request deletion
                this.deleteCopiedClipboardItems(true)
            } else {
                if (this.isRowDirty(this.selectedRow)) {
                    this.$delete(this.dirtyRows, this.getDirtyRowIndex(this.selectedId, this.selectedIdType, this.selectedType))
                }
                this.$delete(this.newRows, this.getNewRowIndex(this.selectedId, this.selectedIdType, this.selectedType))
                this.selected = this.rootEl

                this.isLoading = false
            }
        },

        deleteCopiedClipboardItems (deleteSelected = false) {
            if (!this.clipboardCopy) {
                return Promise.resolve()
            }
            return this.remoteDelete(this.clipboardCopy)
                .then((response) => {
                    this.handleRequestSuccess(response)
                    if (deleteSelected) {
                        this.removeCutItemsLocally(false)
                    }
                    this.clipboardCopy = null
                })
                .catch((error) => this.handleRequestError(error))
        },

        recursiveCopy (item, deleteMode = false) {
            let copyArray = [this.copyItem(item, deleteMode, true)]
            if (item['@itemtype'] === 'menu') {
                this.mappedMenus
                    .filter((submenu) => {
                        return submenu['@parent'] === item['@id']
                    })
                    .forEach((menu) => {
                        menu['@parent'] = copyArray[0].menu[0]['@id']
                        copyArray = [
                            ...copyArray,
                            ...this.recursiveCopy(menu, deleteMode)
                        ]
                    })
            }
            return copyArray
        },

        mergeCopyArray (copyArray) {
            const container = {}
            // copyArray.forEach((item) => {
            //     for (let key in item) {
            //         container[key] = [
            //             ...container[key] || [],
            //             ...item[key]
            //         ]
            //     }
            // })

            copyArray.forEach((item) => {
                container.menu = [
                    ...container.menu || [],
                    ...item.menu
                ]
            })

            for (const key in copyArray[copyArray.length - 1]) {
                if (key !== 'menu') {
                    container[key] = [
                        ...container[key] || [],
                        ...copyArray[copyArray.length - 1][key]
                    ]
                }
            }
            return container
        },

        copyToClipboard (deleteMode = false) {
            this.idCopyMap = new Map()
            this.clipboard = this.copyItem(this.selectedRow, deleteMode)
        },

        copyItem (row, deleteMode = false, recursive = false) {
            let container = {}
            switch (row['@itemtype']) {
                case 'menu':
                    container = {
                        menu: this.getRootCopy(row),
                        module: this.getModulesCopy(),
                        dataGroup: this.getDatagroupsCopy(),
                        dataGroupAction: this.getDatagroupActionsCopy(),
                        box: this.getBoxesCopy(),
                        boxAction: this.getBoxActionsCopy(),
                        field: this.getFieldsCopy(),
                        widget: this.getWidgetsCopy()
                    }
                    break
                case 'module':
                    container = {
                        module: this.getRootCopy(row),
                        dataGroup: this.getDatagroupsCopy(),
                        dataGroupAction: this.getDatagroupActionsCopy(),
                        box: this.getBoxesCopy(),
                        boxAction: this.getBoxActionsCopy(),
                        field: this.getFieldsCopy(),
                        widget: this.getWidgetsCopy()
                    }
                    break
                case 'dataGroup':
                    container = {
                        dataGroup: this.getRootCopy(row),
                        dataGroupAction: this.getDatagroupActionsCopy(),
                        box: this.getBoxesCopy(),
                        boxAction: this.getBoxActionsCopy(),
                        field: this.getFieldsCopy(),
                        widget: this.getWidgetsCopy()
                    }
                    break
                case 'dataGroupAction':
                    container = {
                        dataGroupAction: this.getRootCopy(row)
                    }
                    break
                case 'dataGroupLink':
                    container = {
                        dataGroupLink: this.getRootCopy(row)
                    }
                    break
                case 'box':
                    container = {
                        box: this.getRootCopy(row),
                        boxAction: this.getBoxActionsCopy(),
                        field: this.getFieldsCopy(),
                        widget: this.getWidgetsCopy()
                    }
                    break
                case 'boxAction':
                    container = {
                        boxAction: this.getRootCopy(row)
                    }
                    break
                case 'field':
                    container = {
                        field: this.getRootCopy(row)
                    }
                    break
                case 'widget':
                    container = {
                        widget: this.getRootCopy(row)
                    }
                    break
            }

            if (deleteMode) {
                row.children()
                    .filter((child) => {
                        return child['@itemtype'] === 'menu'
                    })
                    .forEach((row) => {
                        container = {
                            menu: [
                                ...container.menu,
                                ...this.getMenuCopy(row)
                            ],
                            module: [
                                ...this.getModulesCopy()
                            ],
                            dataGroup: [
                                ...this.getDatagroupsCopy()
                            ],
                            dataGroupAction: [
                                ...this.getDatagroupActionsCopy()
                            ],
                            box: [
                                ...this.getBoxesCopy()
                            ],
                            boxAction: [
                                ...this.getBoxActionsCopy()
                            ],
                            field: [
                                ...this.getFieldsCopy()
                            ],
                            widget: [
                                ...this.getWidgetsCopy()
                            ]
                        }
                    })
            }
            return container
        },

        pasteItem () {
            this.idPasteMap = new Map()

            switch (this.selectedType) {
                case 'menu':
                    if ('menu' in this.clipboard) {
                        this.pasteRoot(this.clipboard.menu[0], this.selectedRow)
                        this.clipboard.menu.slice(1, this.clipboard.menu.length).forEach((menu) => {
                            this.pasteMenus(menu)
                        })
                        this.pasteModules(this.clipboard.module)
                        this.pasteDatagroups(this.clipboard.dataGroup)
                        this.pasteDatagroupActions(this.clipboard.dataGroupAction)
                        this.pasteBoxes(this.clipboard.box)
                        this.pasteBoxActions(this.clipboard.boxAction)
                        this.pasteFields(this.clipboard.field)
                        this.pasteWidgets(this.clipboard.widget)
                    } else if ('module' in this.clipboard) {
                        this.pasteRoot(this.clipboard.module[0], this.selectedRow)
                        this.pasteDatagroups(this.clipboard.dataGroup)
                        this.pasteDatagroupActions(this.clipboard.dataGroupAction)
                        this.pasteBoxes(this.clipboard.box)
                        this.pasteBoxActions(this.clipboard.boxAction)
                        this.pasteFields(this.clipboard.field)
                        this.pasteWidgets(this.clipboard.widget)
                    }
                    break
                case 'module':
                    if ('dataGroup' in this.clipboard) {
                        this.pasteRoot(this.clipboard.dataGroup[0], this.selectedRow)
                        this.pasteDatagroupActions(this.clipboard.dataGroupAction)
                        this.pasteBoxes(this.clipboard.box)
                        this.pasteBoxActions(this.clipboard.boxAction)
                        this.pasteFields(this.clipboard.field)
                        this.pasteWidgets(this.clipboard.widget)
                    }
                    break
                case 'dataGroup':
                    if ('dataGroupAction' in this.clipboard) {
                        this.pasteRoot(this.clipboard.dataGroupAction[0], this.selectedRow)
                    } else if ('box' in this.clipboard) {
                        this.pasteRoot(this.clipboard.box[0], this.selectedRow)
                        this.pasteBoxActions(this.clipboard.boxAction)
                        this.pasteWidgets(this.clipboard.widget)
                    } else if ('field' in this.clipboard) {
                        this.pasteRoot(this.clipboard.field[0], this.selectedRow)
                    }
                    break
                case 'box':
                    if ('boxAction' in this.clipboard) {
                        this.pasteRoot(this.clipboard.boxAction[0], this.selectedRow)
                    } else if ('widget' in this.clipboard) {
                        this.pasteRoot(this.clipboard.widget[0], this.selectedRow)
                    }
                    break
            }

            if (this.clipboardMode === 'cut') {
                this.clipboardCopy = { ...this.sanitizedSaveClipboard }
                this.removeCutItemsLocally()
            }
        },

        removeCutItemsLocally (removeNewRows = true) {
            Object.keys(this.clipboardCopy).forEach((type) => {
                if (this.clipboardCopy[type].length > 0) {
                    let items = this[this.getPluralType(type)]
                    // Remove cut items
                    this.clipboardCopy[type].forEach((row) => {
                        items = [
                            ...items.slice(0, this.getIndexById(row['@id'], 'database', row['@itemtype'])),
                            ...items.slice(this.getIndexById(row['@id'], 'database', row['@itemtype']) + 1, items.length)
                        ]
                    })

                    if (removeNewRows) {
                        // Temporarily remove new rows from items
                        // (Prevents double entries of new rows in tree after reload of items)
                        this.newRows
                            .filter((row) => {
                                return row['@itemtype'] === type
                            })
                            .forEach((row) => {
                                const index = items.findIndex((item) => {
                                    return item['@id'] === row['@id'] &&
                                        item['@idType'] === row['@idType'] &&
                                        item['@itemtype'] === row['@itemtype']
                                })
                                items = [
                                    ...items.slice(0, index),
                                    ...items.slice(index + 1, items.length)
                                ]
                            })
                    }
                    this.loadItems(items, type)
                }
            })
        },

        exportMenu () {
            this.copyToClipboard()

            const cleanupText = text => {
                return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '') // remove diacritics: https://stackoverflow.com/a/37511463
                    .replaceAll(/[^a-z0-9]/gi, '-')
                    .replaceAll(/-+/gi, '-')
                    .replaceAll(/-+$/gi, '')
                    .replaceAll(/^-+/gi, '')
                    .toLowerCase()
            }

            const label = cleanupText(this.selectedRow?.['@label'] ?? 'export')
            const date = (new Date()).toISOString().replaceAll(/[^0-9]/g, '')

            // Create anchor tag to download json as file
            const dataStr = 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.sanitizedClipboard))
            const anchor = document.createElement('a')
            anchor.href = dataStr
            anchor.target = '_blank'
            anchor.download = `menu-${label}-${date}.json`
            anchor.style.visibility = 'hidden'
            document.body.appendChild(anchor)
            anchor.click()
            anchor.remove()
        },

        importMenu () {
            // Create file input tag to import json files
            const fileInput = document.createElement('input')
            fileInput.type = 'file'
            fileInput.style.visibility = 'hidden'
            document.body.appendChild(fileInput)
            fileInput.addEventListener('change', () => {
                const file = fileInput.files[0]
                if (file.name.match(/\.(txt|json)$/)) {
                    const reader = new FileReader()

                    reader.onload = () => {
                        this.clipboard = JSON.parse(reader.result)
                        this.pasteItem()
                    }

                    reader.readAsText(file)
                } else {
                    alert('File not supported, .txt or .json files only')
                }
            })

            fileInput.click()
            fileInput.remove()
        },

        cancelAction () {
            this.clipboard = {}
            this.clipboardCopy = null
            this.clipboardMode = 'copy'
        },

        handleKeyDown (e) {
            switch (e.keyCode) {
                case 83:
                    if (e.ctrlKey) {
                        e.preventDefault()
                        e.stopPropagation()
                        this.doAction('save')
                    }
                    break
            }
        },

        handleKeyUp (e) {
            switch (e.keyCode) {
                case 27:
                    this.cancelAction()
                    break
                case 46:
                    if (this.$refs.treeContainer.contains(document.activeElement)) {
                        this.doAction('delete')
                    }
                    break
                case 67:
                    if (e.ctrlKey && this.$refs.treeContainer.contains(document.activeElement)) {
                        this.doAction('copy')
                    }
                    break
                case 86:
                    if (e.ctrlKey && this.$refs.treeContainer.contains(document.activeElement)) {
                        this.doAction('paste')
                    }
                    break
                case 88:
                    if (e.ctrlKey && this.$refs.treeContainer.contains(document.activeElement)) {
                        this.doAction('cut')
                    }
                    break
            }
        },

        getMappedItems (type) {
            switch (type) {
                case 'menu':
                    return this.mappedMenus
                case 'module':
                    return this.mappedModules
                case 'dataGroup':
                    return this.mappedDatagroups
                case 'box':
                    return this.mappedBoxes
                case 'boxAction':
                    return this.mappedBoxactions
                case 'field':
                    return this.mappedFields
                case 'widget':
                    return this.mappedWidgets
                case 'dataGroupAction':
                    return this.mappedDatagroupactions
                case 'dataGroupLink':
                    return this.mappedDatagrouplinks
            }
            throw new Error(`Unknown type "${type}"`)
        },

        setSearch: debounce(function (e) {
            this.searchString = e.target.value.toLowerCase().trim()
        }, 300),

        compareSearch (toCompare) {
            return toCompare.toLowerCase().includes(this.searchString)
        }
    }
}
</script>
