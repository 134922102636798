<script>
import { merge } from '@wisol/utils-data/functions'
import isString from 'lodash-es/isString'
import Type1d from './1d'

export default {
    name: 'WisolWidgetChartType2d',

    extends: Type1d,

    inheritAttrs: false,

    computed: {
        isTimeline () {
            // TODO: return true when category is a time/date value. (Date.parse(categoryValue) should return true)
            // Or: configurable
            // category column must be sorted
            return false
        },

        options () {
            let defaultOptions = this.defaultOptions
            if (this.isTimeline) {
                defaultOptions = merge(defaultOptions, {
                    xaxis: {
                        type: 'datetime'
                    }
                })
            }
            return merge(defaultOptions, this.chartOptions)
        },

        convertedSeries () {
            const series = isString(this.series)
                ? [this.series]
                : this.series
            return series.map(config => this.convertSeriesConfig(config))
        }
    },

    methods: {
        convertSeriesConfig (config) {
            if (isString(config)) {
                config = {
                    field: config
                }
            }
            if (!config.label) {
                const fieldData = this.dataGroup.fields.find(fieldData => fieldData.field === config.field)
                config = {
                    ...config,
                    label: fieldData && fieldData.label ? fieldData.label : config.field
                }
            }
            const { label, field, ...seriesProps } = config
            return {
                name: label,
                data: this.getSeriesData(field),
                ...seriesProps
            }
        },

        getSeriesDataValue (row, field) {
            return {
                x: row[this.category],
                y: this.parseValue(row[field])
            }
        }
    }
}
</script>
