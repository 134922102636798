import isEmpty from 'lodash-es/isEmpty'
import sortBy from 'lodash-es/sortBy'
import reverse from 'lodash-es/reverse'
import { Listing, SortingPlugin } from '@wisol/libs-listing'

export default itemType => {
    return {
        components: {
            Listing,
            SortingPlugin
        },

        props: {
            parent: {
                type: Object,
                required: true
            }
        },

        data () {
            return {
                sorting: [{
                    field: '@id',
                    direction: 'ASC'
                }],
                showPagination: true,
                rowsPerPage: 10,
                page: 1,
                filters: {}
            }
        },

        computed: {
            children () {
                return this.parent.children()
                    .filter((child) => {
                        return child['@itemtype'] === itemType
                    })
            },

            rows () {
                let result = this.children

                // Apply filters
                if (!isEmpty(this.filters)) {
                    for (const filter in this.filters) {
                        result = result.filter((row) => {
                            const isString = typeof this.filters[filter] === 'string' || this.filters[filter] instanceof String
                            let str = this.filters[filter]
                            if (isString) {
                                str = str.replace(/[*]/g, '.*').trim()
                                const search = new RegExp('^' + str + '$', 'i')
                                return row[filter] === null ? false : row[filter].match(search)
                            }
                            return row[filter] === str
                        })
                    }
                }

                // Apply sorting
                if (!isEmpty(this.sorting)) {
                    result = sortBy(result, this.sorting[0].field)
                    if (this.sorting[0].direction === 'DESC') {
                        result = reverse(result)
                    }
                }
                return [
                    ...result.slice((this.rowsPerPage * (this.page - 1)), (this.rowsPerPage * this.page))
                ]
            },

            properties () {
                return this.children.length > 0 ? Object.keys(this.children[0]) : []
            },

            rowOptions () {
                return this.rows.map(() => {
                    return {
                        readonly: true,
                        selectable: false
                    }
                })
            }
        },

        methods: {
            onUpdateSorting (sorting) {
                this.sorting = sorting
            },

            onUpdateFilterValue ({ field }, { value }) {
                if (value === '' || value === null || typeof value === 'undefined') {
                    this.$delete(this.filters, field)
                } else {
                    this.$set(this.filters, field, value)
                }
            },

            onActiveRowUpdate (row) {
                this.$emit('showDetail', row['@ref'])
            },

            onPageUpdate ({ page }) {
                this.page = page
            },

            onCountUpdate ({ count }) {
                this.rowsPerPage = count
            }
        }
    }
}
