export default () => {
    return {
        async parse (raw) {
            if (raw.length) {
                // remove wrapped '*'
                if (raw.match(/^\*[^*]*\*$/)) {
                    return raw.slice(1, -1)
                }
                // search for an exact match
                if (raw && !raw.match(/\*/)) {
                    return '**' + raw
                }
            }

            return raw
        },

        serialize (value) {
            if (value.length) {
                // wrap with '*' when there is no '*' in search string (search for substring)
                if (!value.match(/\*/)) {
                    return '*' + value + '*'
                }
                // when string is prepended with 2 '*', remove them to search for an exact match
                if (value.match(/^\*\*[^*]*$/)) {
                    return value.slice(2)
                }
            }

            return value
        }
    }
}
