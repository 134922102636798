<template>
    <div class="component-generic-filter-group">
        <div
            class="group-operator"
            :tabindex="operatorTabindex"
            @focus="onOperatorFocus"
        >
            <selectbox-input
                v-if="operatorEdit"
                v-auto-focus
                :options="operatorOptions"
                :value="operator"
                class="input"
                @update:value="onOperatorUpdate"
                @blur="onOperatorBlur"
            />
            <div
                v-else
                class="label"
            >
                {{ operatorLabel }}
            </div>
        </div>
        <div>
            <div
                v-if="!readonly"
                class="header"
            >
                <div>
                    <div
                        class="button"
                        @click="onNewCondition"
                    >
                        <icon
                            class="icon"
                            name="wisol/new"
                        />
                        Condition
                    </div>
                    <div
                        class="button"
                        @click="onNewGroup"
                    >
                        <icon
                            class="icon"
                            name="wisol/new"
                        />
                        Group
                    </div>
                </div>
                <div
                    class="delete"
                    @click="onDelete"
                >
                    <icon
                        class="icon"
                        name="fa/regular/trash-alt"
                    />
                </div>
            </div>
            <div class="conditions">
                <div
                    v-for="(data, index) in filterComponents"
                    :key="index"
                    class="condition-container"
                >
                    <div
                        :is="data.component"
                        v-bind="data.props"
                        :readonly="readonly"
                        class="filter-element"
                        @update="onConditionUpdate(index, $event)"
                        @delete="onConditionDelete(index)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-generic-filter-group {
        @include wisol-box;
        display: flex;
        align-items: stretch;
        padding: 0;
        border-width: 1px;
        border-style: solid;
        border-color: lighten($default-border-color, 60%);

        & > *:last-child {
            flex: 1 auto;
        }

        .group-operator {
            background: nth($color-palette-grey, 2);
            border-width: 0 1px 0 0;
            border-style: solid;
            border-color: lighten($default-border-color, 60%);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.5em;
            width: 80px;

            .label {
                font-weight: bold;
                font-size: 1.2em;
            }
        }

        .conditions {
            .condition-container {
                padding: 0.5em;

                &:not(:last-child) {
                    border-width: 0 0 1px 0;
                    border-style: solid;
                    border-color: lighten($default-border-color, 60%);
                }
            }
        }

        .header {
            color: $text-color;
            background: nth($color-palette-grey, 1);
            padding: 0.2rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-width: 0 0 1px 0;
            border-style: solid;
            border-color: lighten($default-border-color, 60%);
            width: 100%;

            .delete {
                height: 28px;
                width: 28px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    font-size: 1.2em;
                }
            }
        }

        .button {
            @include wisol-button;
            background: nth($color-palette-grey, 1);
            display: inline-block;
        }
    }
</style>

<script>
import Condition from './Condition'
import { Input as Inputs } from '@wisol/libs-inputs'
import Icon from '@wisol/libs-icons'
import { AutoFocus } from '@wisol/utils-focus/directives'

const Group = {
    name: 'GenericFilterGroup',

    inheritAttrs: false,

    directives: {
        AutoFocus
    },

    components: {
        TextInput: Inputs.Text,
        SelectboxInput: Inputs.Selectbox,
        Icon
    },

    props: {
        filter: {
            type: [Object, Array],
            default () {
                return []
            }
        },

        operator: {
            type: String,
            default: '$and'
        },

        readonly: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            operatorEdit: false
        }
    },

    computed: {
        filterComponents () {
            const filterComponents = []
            this.filter.forEach(cond => {
                for (const key in cond) {
                    if (key[0] === '$') {
                        filterComponents.push({
                            component: Group,
                            props: {
                                filter: cond[key],
                                operator: key
                            }
                        })
                    } else {
                        filterComponents.push({
                            component: Condition,
                            props: {
                                condition: {
                                    [key]: cond[key]
                                }
                            }
                        })
                    }
                }
            })
            return filterComponents
        },

        operatorLabel () {
            return this.operatorOptions[this.operator]
        },

        operatorOptions () {
            const options = {
                $and: 'AND',
                $or: 'OR'
            }
            if (!(this.operator in options)) {
                options[this.operator] = this.operator
            }
            return options
        },

        operatorTabindex () {
            return this.readonly && this.operatorEdit ? '-1' : '0'
        }
    },

    methods: {
        onOperatorFocus () {
            if (!this.readonly) {
                this.operatorEdit = true
            }
        },

        onOperatorBlur () {
            this.operatorEdit = false
        },

        onConditionUpdate (index, condition) {
            this.$emit('update', {
                [this.operator]: [
                    ...this.filter.slice(0, index),
                    condition,
                    ...this.filter.slice(index + 1)
                ]
            })
        },

        onConditionDelete (index) {
            this.$emit('update', {
                [this.operator]: [
                    ...this.filter.slice(0, index),
                    ...this.filter.slice(index + 1)
                ]
            })
        },

        onOperatorUpdate ({ value }) {
            if (value.match(/\$[a-z]+/)) {
                this.$emit('update', {
                    [value]: this.filter
                })
            }
        },

        onDelete () {
            this.$emit('delete')
        },

        onNewCondition () {
            this.$emit('update', {
                [this.operator]: [
                    ...this.filter,
                    { '': '' }
                ]
            })
        },

        onNewGroup () {
            this.$emit('update', {
                [this.operator]: [
                    ...this.filter,
                    { $and: [] }
                ]
            })
        }
    }
}

export default Group
</script>
