<script>
import PluginMixin from './Mixin.js'

export default {
    mixins: [
        PluginMixin('edit')
    ],

    inject: ['listing'],

    inheritAttrs: false,

    data () {
        return {
            editOnFocus: false,
            columnId: null,
            rowId: null
        }
    },

    computed: {
        x () {
            if (this.columnId !== null) {
                const index = this.listing.columns.findIndex(column => this.listing.getColumnId(column) === this.columnId)
                return index > -1 ? index : null
            }
            return null
        },

        y () {
            if (this.rowId !== null) {
                const index = this.listing.rows.findIndex(row => this.listing.getRowId(row) === this.rowId)
                return index > -1 ? index : null
            }
            return null
        },

        width () {
            return this.listing.columns.length
        },

        height () {
            return this.listing.rows.length
        }
    },

    beforeCreate () {
        this.toggleMap = new Map()
    },

    created () {
        this.listing.bus.$on('blur', this.onBlur)
    },

    beforeDestroy () {
        this.listing.bus.$off('blur', this.onBlur)
    },

    destroyed () {
        this.toggleMap.forEach(vm => vm.$destroy())
        this.toggleMap = null
    },

    methods: {
        edit (column, row, checkToggle = false) {
            const columnId = this.listing.getColumnId(column)
            const rowId = this.listing.getRowId(row)
            if (checkToggle) {
                const toggleRef = JSON.stringify([columnId, rowId])
                const toggle = this.toggleMap.get(toggleRef)
                if (!toggle || !toggle.canEnableEdit) {
                    return false
                }
            }
            this.editOnFocus = true
            this.columnId = columnId
            this.rowId = rowId
            return true
        },

        stopEdit (column, row) {
            const columnId = this.listing.getColumnId(column)
            const rowId = this.listing.getRowId(row)
            if (this.columnId === columnId && this.rowId === rowId) {
                this.columnId = null
                this.rowId = null
            }
        },

        onBlur () {
            this.editOnFocus = false
        },

        left () {
            let x = this.x
            while (x > 0) {
                x--
                if (this.edit(this.listing.columns[x], this.listing.rows[this.y], true)) {
                    return
                }
            }
        },

        right () {
            let x = this.x
            const width = this.width
            while (x < width - 1) {
                x++
                if (this.edit(this.listing.columns[x], this.listing.rows[this.y], true)) {
                    return
                }
            }
        },

        up () {
            let y = this.y
            while (y > 0) {
                y--
                if (this.edit(this.listing.columns[this.x], this.listing.rows[y], true)) {
                    return
                }
            }
        },

        down () {
            let y = this.y
            const height = this.height
            while (y < height - 1) {
                y++
                if (this.edit(this.listing.columns[this.x], this.listing.rows[y], true)) {
                    return false
                }
            }
        }
    },

    render () {
        return null
    }
}
</script>
