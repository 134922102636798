import hashmapGetters from '@/store/common/getters/hashmap'
import { mergeTarget } from '@wisol/utils-data/functions'

export default {
    ...hashmapGetters({
        root: 'items'
    }),

    getMerged: (state, getters) => item => {
        const mainItem = item
        while (item && item['@parent'] !== null) {
            item = getters.byId(item['@id'])
            for (const key in item) {
                if (key[0] !== '@' && item[key] !== null) {
                    switch (key) {
                        case 'options':
                            mergeTarget(mainItem[key], item[key])
                            break

                        default:
                            if (mainItem[key] === null) {
                                mainItem[key] = item[key]
                            }
                            break
                    }
                }
            }
        }
        return mainItem
    }
}
