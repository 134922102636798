import axios from 'axios'

const tokenHasExpired = error => {
    if (!error.response) {
        return false
    }
    return error.response.status === 401
}

const tokenHasChanged = (store, config) => {
    return config._wisol.token !== store.getters['session/token']
}

const sessionHasChanged = (store, config) => {
    return !store.getters['session/isAuthenticated'] ||
        config._wisol.username !== store.getters['session/username']
}

let refreshTokenPromise = null
const refreshToken = (store) => {
    if (!refreshTokenPromise) {
        refreshTokenPromise = new Promise((resolve) => {
            store.commit('session/SET_TIMEOUT')

            const stopWatching = store.watch(() => store.getters['session/token'], () => {
                stopWatching()
                resolve()
            })
        })
            .finally(() => {
                refreshTokenPromise = null
            })
    }
    return refreshTokenPromise
}

const HandleSessionTimeout = (store, http) => error => {
    const config = error.config
    // reject, if no config available
    if (!config || !config._wisol) {
        return Promise.reject(error)
    }

    // reject if token is still valid
    if (!tokenHasExpired(error)) {
        return Promise.reject(error)
    }

    // reject if username do not match anymore
    if (sessionHasChanged(store, config)) {
        return Promise.reject(error)
    }

    // try again if token has changed
    if (tokenHasChanged(store, config)) {
        return http(config)
    }

    return refreshToken(store).then(() => {
        if (!sessionHasChanged(store, config)) {
            return http(config)
        }
        return Promise.reject(error)
    })
}

const WisolConfigAndHeader = store => config => {
    const token = store.getters['session/token']
    const username = store.getters['session/username']
    const firme = store.getters['session/firme']

    if (!config.url.match(/^[a-z0-9]+:\/\//) && config.url[0] !== '/') {
        config.url = '/' + config.url
    }

    config.headers.Authorization ??= 'Wisol -'
    if (firme !== null) {
        config.headers['Wisol-Firme'] = firme
    }
    config._wisol = { username, token }
    return config
}

export const factory = store => {
    const instance = axios.create({
        withCredentials: true
    })
    instance.interceptors.request.use(WisolConfigAndHeader(store))
    instance.interceptors.response.use(null, HandleSessionTimeout(store, instance))
    return instance
}

export default {
    install (Vue) {
        let $http
        Object.defineProperty(Vue.prototype, '$http', {
            get () {
                if (!$http) {
                    $http = factory(this.$store)
                }
                return $http
            }
        })
    }
}
