import Vue from 'vue'

const isEmptyFilterValue = value => {
    return (
        value === undefined ||
        value === null ||
        value === ''
    )
}

export default {
    SET_ACTIVE_POSITION (state, { position }) {
        state.position = position
    },

    SET_FILTER_VALUES (state, { values }) {
        for (const key in values) {
            if (isEmptyFilterValue(values[key])) {
                Vue.delete(state.filter, key)
            } else {
                Vue.set(state.filter, key, values[key])
            }
        }
    },

    RESET_FILTER (state) {
        state.filter = {}
    },

    UPDATE_SORTING (state, { sorting }) {
        state.sorting = sorting
    },

    RESET_SORTING (state) {
        state.sorting = []
    }
}
