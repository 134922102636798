import isString from 'lodash-es/isString'

export const ParserFactory = ({
    emptyValue = null
} = {}) => {
    return {
        async parse (raw) {
            if (raw === '') {
                return emptyValue
            }

            return raw
        },

        serialize (parsedValue) {
            if (parsedValue === emptyValue) {
                return ''
            }
            if (!isString(parsedValue)) {
                throw new Error('value needs to be of type String')
            }
            return parsedValue
        }
    }
}

export const ParserProp = {
    parser: {
        type: Object,
        default: () => {
            return ParserFactory()
        },
        validator: value => {
            return typeof value.parse === 'function' &&
            typeof value.serialize === 'function'
        }
    }
}

export const delayParser = (parser, delay = 1000) => {
    return {
        ...parser,
        parse (...args) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(parser.parse(...args))
                }, delay)
            })
        }
    }
}

export default ParserFactory
