import StackTrace from 'stacktrace-js'
import loadStore from '../store/index.js'
import log from 'loglevel'

export const fatal = async fatalError => {
    let store
    try {
        store = await loadStore()
        let stacktrace
        try {
            stacktrace = await StackTrace.fromError(fatalError)
        } catch {
            log.error('Could not get stacktrace', { error: fatalError })
        }
        await store.dispatch('alert/create', {
            data: {
                type: 'error',
                message: fatalError.message,
                detail: {
                    message: fatalError.message,
                    stacktrace
                },
                autoClose: false
            }
        })
    } catch (err) {
        if (!store) {
            log.error('Could not load store to create error', { error: fatalError })
            log.error(err)
        } else {
            let stacktrace
            try {
                stacktrace = await StackTrace.fromError(err)
            } catch {
                log.error('Could not get stacktrace', { error: err })
            }
            store.dispatch('alert/create', {
                data: {
                    type: 'error',
                    message: err.message,
                    detail: {
                        message: err.message,
                        stacktrace
                    },
                    autoClose: false
                }
            })
        }
    }
}

export const notify = async (message, type = 'info', options = {}) => {
    let store
    try {
        store = await loadStore()
        await store.dispatch('alert/create', {
            data: Object.assign({
                type,
                message,
                autoClose: true
            }, options)
        })
    } catch (err) {
        if (!store) {
            log.error('Could not load store to notify message', { message })
        }
        log.error(err)
    }
}

export const success = (message, options) => notify(message, 'success', options)
export const error = (message, options) => notify(message, 'error', options)
export const info = (message, options) => notify(message, 'info', options)
export const warning = (message, options) => notify(message, 'warning', options)
export default error
