<script>
import { ParserProp } from '../parser/Base'
import { PromiseState } from '@wisol/utils-data/functions'

export default {
    name: 'Parser',

    inheritAttrs: false,

    props: {
        ...ParserProp,

        value: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            parserState: PromiseState()
        }
    },

    watch: {
        value: {
            immediate: true,
            handler () {
                this.parseValue()
            }
        },

        parser () {
            this.parseValue()
        }
    },

    methods: {
        parseValue () {
            this.parserState = PromiseState(this.parser.parse(this.value))
        }
    },

    render () {
        const { parserState } = this
        return this.$scopedSlots.default({
            isParsing: parserState.isPending,
            isValid: parserState.isValid,
            error: parserState.error,
            value: parserState.result
        })
    }
}
</script>
