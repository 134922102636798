<template>
    <div
        v-resize="onResize"
        class="component-wisol-module-grid"
    >
        <generic-popover
            v-if="showLayoutManager"
            ref="layout-manager"
            :target="showLayoutManager"
            popover-classes="component-wisol-module-grid-layout-manager"
            @close="closeLayoutManager"
        >
            <wisol-layout-manager
                :module="id"
                :layouts="layouts"
                :active-layout="layout"
                :color="color"
                @select="onLayoutSelect"
            />
        </generic-popover>
        <dnd-grid-container
            :layout="boxesLayout"
            :cell-size="cellSize"
            :margin="margin"
            :outer-margin="outerMargin"
            :bubble-up="bubbleUp"
            class="grid-container"
            @update:layout="onBoxesLayoutUpdate"
        >
            <dnd-grid-box
                v-for="box in visibleBoxes"
                :key="box['@id']"
                :box-id="box['@id']"
                class="grid-box-container"
                drag-selector=".grid-box > .header *"
            >
                <grid-box
                    :id="box['@id']"
                    :title="box.title"
                    :color="color"
                    :layout="layout"
                    :get-data-group="() => dataGroupManager.getDataGroup(box['@dataGroup'])"
                    v-bind="box.options"
                    class="grid-box"
                    @update:layout="onLayoutUpdate"
                />
            </dnd-grid-box>
        </dnd-grid-container>
        <generic-popover
            v-if="showBoxSelector"
            :target="showBoxSelector"
            popover-classes="component-wisol-module-grid-box-selector"
            @close="closeBoxSelector"
        >
            <div
                v-for="box in boxes"
                :key="box['@id']"
                class="box"
            >
                <checkbox-input
                    :value="box.visible ? true : false"
                    class="checkbox"
                    @update:value="onBoxLayoutVisibiltyChange(box, $event)"
                />
                {{ box.title }}
            </div>
        </generic-popover>
    </div>
</template>

<style lang="scss">
@import "@wisol/theme/variables";

.component-wisol-module-grid {
    overflow-y: hidden;
    width: 100%;

    &>.grid-container>.grid-box-container>.grid-box {
        height: 100%;
        width: 100%;
    }
}

.component-wisol-module-grid-box-selector {
    @include wisol-popup;

    .box {
        display: flex;

        &:not(:last-child) {
            margin: 0 0 0.5em 0;
        }

        .checkbox {
            margin-right: 0.5em;
        }
    }
}

.component-wisol-module-grid-layout-manager {
    @include wisol-popup;
}
</style>

<script>
import { getCurrentInstance } from 'vue'
import { ResizeObserver as ResizeObserverDirective } from '@wisol/utils-resize/directives'
import DataGroupManager from '../../Data/Group/Manager.js'
import ActionProviderMixin from '@/mixins/ActionProvider'
import { utils as dndGridUtils } from '@dattn/dnd-grid'
import { Input as Inputs } from '@wisol/libs-inputs'
import StoreModule from '@/store/modules/grid'
import useStoreWrapper from '@/composition/useStoreWrapper.js'
import GridBox from './Box.vue'

export default {

    directives: {
        resize: ResizeObserverDirective
    },

    components: {
        CheckboxInput: Inputs.Checkbox,
        GridBox
    },

    mixins: [
        ActionProviderMixin()
    ],

    provide () {
        return {
            getLayouts: () => this.layouts,
            loadLayout: layout => this.onLayoutUpdate(layout.layout)
        }
    },

    inheritAttrs: false,

    props: {
        id: {
            type: Number,
            required: true
        },
        color: {
            type: String,
            default: '#FFFFFF'
        },
        columnCount: {
            type: Number,
            default: 12
        },
        cellHeight: {
            type: Number,
            default: 100
        },
        minCellWidth: {
            type: Number,
            default: 100
        },
        maxCellWidth: {
            type: Number,
            default: Infinity
        }
    },

    setup (props) {
        const {
            getter,
            dispatch
        } = useStoreWrapper(getCurrentInstance().proxy.$store, StoreModule, 'grid', {
            id: props.id,
            autoUnregister: false
        })

        return {
            getter,
            dispatch
        }
    },

    data () {
        return {
            minWidth: 100,
            containerSize: {
                width: 0,
                height: 0
            },
            bubbleUp: true,
            margin: 4,
            outerMargin: 4,
            showLayoutManager: false,
            showBoxSelector: false
        }
    },

    computed: {
        boxes () {
            return this.$store.getters['ui/box/all']
                .filter(box => box['@module'] === this.id)
                .map(box => {
                    const boxLayout = this.boxesLayoutMap.get(box['@id'])
                    return {
                        ...box,
                        visible: boxLayout && !boxLayout.hidden
                    }
                })
                .sort((a, b) => {
                    if (a.title < b.title) {
                        return -1
                    }
                    if (a.title > b.title) {
                        return 1
                    }
                    return 0
                })
        },

        visibleBoxes () {
            return this.boxes.filter(box => box.visible)
                // sort array for tabindex
                .sort((a, b) => {
                    const { position: aPosition } = this.boxesLayoutMap.get(a['@id'])
                    const { position: bPosition } = this.boxesLayoutMap.get(b['@id'])
                    return aPosition.y - bPosition.y || aPosition.x - bPosition.x
                })
        },

        layouts () {
            return this.$store.getters['layout/all']
                .filter(layout => layout['@module'] === this.id)
        },

        defaultLayout () {
            return this.layouts.find(layout => layout.default) ||
                this.layouts.find(layout => layout.global) ||
                this.layouts.find(layout => true) ||
                { layout: {} }
        },

        layout () {
            return this.getter('layout') || this.defaultLayout.layout
        },

        boxesLayout () {
            return this.layout.grid && this.layout.grid.boxes
                ? this.layout.grid.boxes
                : []
        },

        boxesLayoutMap () {
            const map = new Map()
            this.boxesLayout.forEach(layout => {
                map.set(layout.id, layout)
            })
            return map
        },

        cellSize () {
            const cellWidth = (this.containerSize.width - (((this.columnCount - 1) * this.margin) + (2 * this.outerMargin))) / this.columnCount
            return {
                h: this.cellHeight,
                w: Math.max(
                    this.minCellWidth,
                    Math.min(
                        cellWidth,
                        this.maxCellWidth
                    )
                )
            }
        },

        actions () {
            return [
                {
                    icon: 'wisol/layout',
                    title: this.$t('grid.layout'),
                    callback: evt => {
                        this.openLayoutManager(evt.target)
                    }
                },
                {
                    icon: 'wisol/pin',
                    title: 'Select Boxes',
                    callback: evt => {
                        this.openBoxSelector(evt.target)
                    }
                }
            ]
        }
    },

    created () {
        this.dataGroupManager = new DataGroupManager({
            parent: this
        })
    },

    mounted () {
        this.containerSize.width = this.$el.clientWidth
        this.containerSize.height = this.$el.clientHeight
    },

    destroyed () {
        this.dataGroupManager.$destroy()
        this.dataGroupManager = null
    },

    methods: {
        openLayoutManager (target) {
            this.showLayoutManager = target
        },

        closeLayoutManager () {
            this.showLayoutManager = false
        },

        openBoxSelector (target) {
            this.showBoxSelector = target
        },

        closeBoxSelector () {
            this.showBoxSelector = false
        },

        onResize ({ width, height }) {
            if (width && height) {
                this.containerSize.width = width
                this.containerSize.height = height
            }
        },

        onBoxesLayoutUpdate (layout) {
            this.dispatch('updateLayout', {
                layout: {
                    ...this.layout,
                    grid: {
                        boxes: layout
                    }
                }
            })
        },

        onLayoutUpdate (layout) {
            this.dispatch('updateLayout', { layout })
        },

        onLayoutSelect (layout) {
            this.onLayoutUpdate(layout)
        },

        onBoxLayoutVisibiltyChange (box, { value: visible }) {
            let boxesLayout = this.boxesLayout
            const index = boxesLayout.findIndex(boxLayout => {
                return boxLayout.id === box['@id']
            })

            if (visible) {
                if (index > -1) {
                    if (!boxesLayout[index].hidden) {
                        return
                    }
                    boxesLayout = [
                        ...boxesLayout.slice(0, index),
                        {
                            ...boxesLayout[index],
                            hidden: false
                        },
                        ...boxesLayout.slice(index + 1)
                    ]
                } else {
                    boxesLayout = [
                        ...boxesLayout,
                        dndGridUtils.moveBoxToFreePlace(
                            boxesLayout,
                            {
                                id: box['@id'],
                                hidden: false,
                                position: {
                                    x: 0,
                                    y: 0,
                                    w: 3,
                                    h: 1
                                }
                            }
                        )
                    ]
                }
            } else {
                if (index > -1) {
                    if (boxesLayout[index].hidden) {
                        return
                    }
                    boxesLayout = [
                        ...boxesLayout.slice(0, index),
                        {
                            ...boxesLayout[index],
                            hidden: true
                        },
                        ...boxesLayout.slice(index + 1)
                    ]
                } else {
                    return
                }
            }
            this.onBoxesLayoutUpdate(boxesLayout)
        }
    }
}
</script>
