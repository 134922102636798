<template>
    <generic-menu
        :items="items"
        :parent="parent"
        :placement="placement"
        v-bind="$attrs"
        submenu-placement="bottom-start"
        class="component-wisol-menu"
        @click="onClick"
    />
</template>

<script>
export default {
    name: 'WisolMenu',

    inheritAttrs: false,

    props: {
        parent: {
            type: Number,
            default: null
        },
        placement: {
            type: String,
            default: 'right-start'
        }
    },

    computed: {
        items () {
            return this.$store.getters['ui/menu/all']
        }
    },

    methods: {
        onClick (item) {
            this.$store.dispatch('openMenuItem', {
                item
            })
        }
    }
}
</script>
