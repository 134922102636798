<template>
    <div class="component-generic-selection-bar">
        <div>
            <span>{{ count }} rows selected</span>
            <input-button
                v-show="showResetButton"
                label="reset"
                @click="onReset"
            />
        </div>
        <div>
            <span>filter by selected rows</span>
            <input-checkbox
                :value="filterBySelection"
                name=""
                @update:value="onFilterBySelectionUpdate"
            />
        </div>
    </div>
</template>

<style lang="scss">
    .component-generic-selection-bar,
    .component-generic-selection-bar > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.3em;

        &> span {
            padding: 0 0.3em 0 0;
        }
    }
</style>

<script>
import { Input as Inputs } from '@wisol/libs-inputs'

export default {
    name: 'GenericSelectionBar',

    components: {
        InputCheckbox: Inputs.Checkbox,
        InputButton: Inputs.Button
    },

    inheritAttrs: false,

    props: {
        count: {
            type: Number,
            required: true
        },

        filterBySelection: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        showResetButton () {
            return this.count > 0
        }
    },

    methods: {
        onReset () {
            this.$emit('reset')
        },

        onFilterBySelectionUpdate ({ value }) {
            this.$emit('filter-by-selection', value)
        }
    }
}
</script>
