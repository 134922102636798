import { provide, inject, onUnmounted } from 'vue'
import { mergeTarget } from '@wisol/utils-data/functions'
import get from 'lodash-es/get'

const PathSymbol = Symbol('path')

const getNestedState = (store, path) => {
    return path.length
        ? get(store.state, path)
        : store.path
}

const moduleSet = new Set()

const registerModule = (store, path, moduleData) => {
    if (!store.hasModule(path)) {
        const moduleName = path[path.length - 1]
        const parentState = getNestedState(store, path.slice(0, -1)) || {}
        const currentState = parentState[moduleName] || {}
        const { modules = {}, ...moduleDataCopy } = moduleData
        moduleDataCopy.namespaced = true

        if (moduleDataCopy.state && typeof moduleDataCopy.state === 'function') {
            moduleDataCopy.state = moduleDataCopy.state()
        }
        mergeTarget(moduleDataCopy.state, currentState)

        delete parentState[moduleName]
        store.registerModule(path, moduleDataCopy)

        // register child modules
        Object.entries(modules).forEach(([moduleName, moduleData]) => {
            registerModule(store, [...path, moduleName], moduleData)
        })
    }
    moduleSet.add(path.join('/'))
}

const unregisterModule = (store, path) => {
    const childNamespace = path.join('/') + '/'
    const modules = [path]
    moduleSet.forEach(childPathString => {
        if (childPathString.startsWith(childNamespace)) {
            modules.push(childPathString.split('/'))
        }
    })

    modules
        .sort((a, b) => b.length - a.length) // make sure to delete child modules first
        .forEach(path => {
            if (store.hasModule(path)) {
                store.unregisterModule(path)
            }
            moduleSet.delete(path.join('/'))
        })
}

export default function useStoreWrapper (store, moduleData, name, {
    id = null,
    autoUnregister = true
} = {}) {
    const singleton = id === null
    const key = singleton ? name : `${name}(${id})`
    const parentPath = inject(PathSymbol, [])
    const path = [
        ...parentPath,
        key
    ]
    const pathString = path.join('/')
    provide(PathSymbol, path)

    registerModule(store, path, moduleData)

    onUnmounted(() => {
        if (store.hasModule(path)) {
            if (autoUnregister && !singleton) {
                unregisterModule(store, path)
            }
        }
    })

    const getter = name => {
        return store.getters[pathString + '/' + name]
    }

    const dispatch = (name, ...args) => {
        return store.dispatch(pathString + '/' + name, ...args)
    }

    return {
        getter,
        dispatch
    }
}
