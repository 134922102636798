<template>
    <transition-group
        class="component-wisol-alerts"
        name="alert"
        tag="div"
    >
        <div
            v-if="showCloseAllButton"
            key="close-all"
            class="close-all"
        >
            Notfications
            <wisol-button
                label="close all"
                @click="closeAll"
            />
        </div>
        <wisol-alert
            v-for="alert in alerts"
            :key="alert['@id']"
            :type="alert.type"
            :auto-close="alert.autoClose"
            :buttons="getButtons(alert)"
            class="alert"
            @close="close(alert)"
            @button:click="onButtonClick(alert, $event)"
        >
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="alert.message" />
        </wisol-alert>
    </transition-group>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-alerts {
        $margin: 1rem;

        position: fixed;
        top: $margin;
        right: $margin;
        z-index: 99999;

        width: 400px;
        max-width: 100%;

        .close-all {
            background: #FFF;
            padding: 0.5rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1.5em;
        }

        .alert-enter-active,
        .alert-leave-active {
            transition: all 0.3s;
        }
        .alert-enter,
        .alert-leave-to {
            transform: translateX(calc(100% + #{$margin}));
        }

        .alert {
            margin: 0;

            &:not(:first-child) {
                margin-top: 2px;
            }
        }
    }
</style>

<script>
import triggerDownload from '@/utils/triggerDownload.js'
import { Input } from '@wisol/libs-inputs'

const DownloadDetailButton = {
    label: 'Download'
}

const ReloadPageButton = {
    label: 'Reload'
}

export default {
    name: 'WisolAlerts',

    components: {
        WisolButton: Input.Button
    },

    inheritAttrs: false,

    computed: {
        alerts () {
            return this.$store.getters['alert/visible'].slice().reverse()
        },

        showCloseAllButton () {
            let count = 0
            for (let i = 0; i < this.alerts.length; i++) {
                if (!this.alerts[i].autoClose) {
                    count++
                    if (count >= 2) {
                        return true
                    }
                }
            }
            return false
        }
    },

    methods: {
        close (alert) {
            return this.$store.dispatch('alert/hide', { id: alert['@id'] })
        },

        detail ({ detail }) {
            const detailJson = JSON.stringify(detail)
            const blob = new Blob([detailJson], {
                type: 'application/json'
            })
            const url = URL.createObjectURL(blob)
            triggerDownload(url, 'errorDetail.json')
            URL.revokeObjectURL(url)
        },

        getButtons (alert) {
            const buttons = []
            if ('detail' in alert) {
                buttons.push(DownloadDetailButton)
            }
            if (alert.reload) {
                buttons.push(ReloadPageButton)
            }
            return buttons
        },

        closeAll () {
            this.$store.dispatch('alert/hideAll')
        },

        onButtonClick (alert, { button }) {
            switch (button) {
                case DownloadDetailButton:
                    this.detail(alert)
                    break

                case ReloadPageButton:
                    this.close(alert)
                        .finally(() => {
                            setTimeout(() => {
                                location.reload(true)
                            }, 500)
                        })
                    break
            }
        }
    }
}
</script>
