const Parser = ({
    options = {},
    mode = 'key'
} = {}) => {
    const keyMap = new Map()
    const valueMap = new Map()
    for (const key in options) {
        keyMap.set(key, options[key])
        valueMap.set(options[key], key)
    }

    return {
        async parse (raw) {
            if (mode === 'key') {
                if (!keyMap.has(raw)) {
                    throw new Error('invalid')
                }
                return raw
            }

            if (!valueMap.has(raw)) {
                throw new Error('invalid')
            }
            return valueMap.get(raw)
        },

        serialize (value) {
            if (mode === 'key') {
                return value
            }
            return keyMap.get(value)
        }
    }
}

export default Parser

export const KeyParser = (options = {}) => {
    return Parser({
        mode: 'key',
        ...options
    })
}

export const ValueParser = (options = {}) => {
    return Parser({
        mode: 'value',
        ...options
    })
}
