<template>
    <div
        class="component-wisol-editor-menu-form-dataGroupAction"
    >
        <generic-form
            :key="'dataGroupAction:' + row['@id']"
            :fields="fields"
            :values="values"
            :layout="layout"
            :column-count="24"
            :cell-height="25"
            :min-cell-width="40"
            :max-cell-width="Infinity"
            :readonly="false"
            @update:value="onUpdateFormValue"
            @update:layout="onLayoutUpdate"
        />
        <generic-loading v-show="isLoading" />
    </div>
</template>

<style lang="scss">
    .component-wisol-editor-menu-form-dataGroupAction {
        padding: 20px 20px 0px 20px;
    }

</style>

<script>
import { Input as Inputs } from '@wisol/libs-inputs'
import IconInput from '@/components/wisol/Input/Icon'
import layout from './layouts/datagroupaction.json'

const LOCALSTORAGE_KEY = 'layout_datagroupaction'

export default {
    name: 'WisolEditorMenuFormDatagroupaction',

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            default: () => {}
        },

        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            layout,
            labels: {
                '@id': 'ID',
                '@dataGroup': 'Datagroup ID',
                icon: 'Icon',
                label: 'Label',
                script: 'Script'
            }
        }
    },

    computed: {
        fields () {
            return Object.keys(this.row).map((field) => {
                let component = Inputs.Text
                const props = {}
                switch (field) {
                    case '@id':
                    case '@dataGroup':
                        component = Inputs.Number
                        props.readonly = true
                        break
                    case 'label':
                        props.required = true
                        break
                    case 'icon':
                        component = IconInput
                        props.required = true
                        break
                    case 'script':
                        component = Inputs.Code
                        props.required = true
                        props.language = 'javascript'
                        break
                }

                return {
                    id: field,
                    field: field,
                    label: this.labels[field],
                    input: {
                        component,
                        props
                    }
                }
            })
        },

        values () {
            return this.row
        }
    },

    mounted () {
        let local = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || []
        local = local.reduce((arr, l) => {
            const foundLayout = arr.find((item) => item.id === l.id)

            if (foundLayout) {
                foundLayout.position = l.position
            } else {
                arr.push(l)
            }

            return arr
        }, layout)

        this.layout = local
    },

    methods: {
        onLayoutUpdate (layout) {
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(layout))
            this.layout = layout
        },

        onUpdateFormValue ({ field, value }) {
            const row = {
                ...this.row,
                [field]: value
            }
            this.$emit('updateRow', this.validateRow(row))
        },

        validateRow (row) {
            return {
                ...row,
                '@valid': !!row.label && !!row.icon && !!row.script
            }
        }
    }
}

</script>
