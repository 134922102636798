import _debounce from 'lodash-es/debounce'

export default function debounce (callback, ...args) {
    let resolveList = []

    const debouncedCallback = _debounce(() => {
        const promise = callback()
        resolveList.forEach(resolve => resolve(promise))
        resolveList = []
    }, ...args)

    return function () {
        return new Promise(resolve => {
            resolveList.push(resolve)
            debouncedCallback()
        })
    }
}
