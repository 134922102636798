export const findPlaceholders = template => {
    return template
        .match(/\{\{\s*([a-z0-9@\-_.]+)\s*\}\}/ig)
        .map(placeholder => {
            return {
                placeholder,
                field: placeholder.match(/[a-z0-9@\-_.]+/i)[0]
            }
        })
}

const getStringParts = (template, placeholders) => {
    const parts = []
    let startIndex = 0
    let endIndex = 0
    placeholders.forEach(({
        placeholder
    }) => {
        endIndex = template.indexOf(placeholder, startIndex)
        parts.push(template.slice(startIndex, endIndex))
        startIndex = endIndex + placeholder.length
    })
    parts.push(template.slice(startIndex))
    return parts
}

const createParser = (stringParts, placeholders) => {
    const regExp = new RegExp(
        '^' +
        stringParts
            // escape string parts. Found on https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
            .map(part => part.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
            .join('(.*)') +
        '$'
    )

    return string => {
        let matches = regExp.exec(string)
        if (!matches) {
            throw new Error('Invalid')
        }
        matches = matches.slice(1)
        const values = {}
        matches.forEach((match, index) => {
            values[placeholders[index].field] = match
        })
        return values
    }
}

export default ({
    template = '{{ value }}',
    search = filter => filter
} = {}) => {
    const placeholders = findPlaceholders(template)
    const stringParts = getStringParts(template, placeholders)
    const parser = createParser(stringParts, placeholders)

    return {
        async parse (raw) {
            let searchPromise
            try {
                searchPromise = Promise.resolve(search(parser(raw)))
            } catch (err) {
                return Promise.reject(err)
            }

            return Promise
                .resolve(searchPromise)
                .then(result => {
                    return result || Promise.reject(new Error('Invalid'))
                })
        },

        serialize (value) {
            return placeholders
                .reduce((acc, {
                    placeholder,
                    field
                }) => {
                    return acc.replace(placeholder, value[field])
                }, template)
        }
    }
}
