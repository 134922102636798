export default {
    create ({ commit, state, dispatch }, { data }) {
        commit('ADD_ITEM', {
            item: {
                visible: true,
                ...data
            }
        })
    },

    hide ({ commit }, { id }) {
        commit('UPDATE_ITEM', {
            id,
            data: {
                visible: false
            }
        })
    },

    hideAll ({ commit, getters }) {
        getters.visible.forEach(({ '@id': id }) => {
            commit('UPDATE_ITEM', {
                id,
                data: {
                    visible: false
                }
            })
        })
    }
}
