<template>
    <generic-loading v-if="dataGroup.isLoading" />
    <div
        v-else-if="dataGroup.loadingError"
        :class="$style.loadingError"
    >
        {{ dataGroup.loadingError }}
    </div>
</template>

<style module>
    .loadingError {
        height: 100%;
        padding: 1em;
        background: red;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<script>
import Vue from 'vue'

export default {
    inheritAttrs: false,

    props: {
        dataGroup: {
            type: Vue,
            required: true
        }
    }
}
</script>
