<template>
    <div :class="treeStyles['node-wrapper']">
        <div :class="treeStyles['node']">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="renderTemplate(dataGroup.getRowValues(row))" />
            <node-list
                v-if="nestedRows.length"
                :row-map="rowMap"
                :tree-id-key="treeIdKey"
                :tree-mode-key="treeModeKey"
                :rows="nestedRows"
                :data-group="dataGroup"
                :render-template="renderTemplate"
                :is-expanded="isExpanded"
                @expand="row => $emit('expand', row)"
                @collapse="row => $emit('collapse', row)"
            />
            <div
                v-if="hasChildren"
                :class="$style.iconContainer"
                class="no-print"
                @click="toggle"
            >
                <icon
                    :name="iconName"
                    :class="$style.icon"
                />
            </div>
        </div>
        <node-list
            v-if="childRows.length && expanded"
            :row-map="rowMap"
            :tree-id-key="treeIdKey"
            :tree-mode-key="treeModeKey"
            :rows="childRows"
            :data-group="dataGroup"
            :render-template="renderTemplate"
            :is-expanded="isExpanded"
            @expand="row => $emit('expand', row)"
            @collapse="row => $emit('collapse', row)"
        />
    </div>
</template>

<style module>
    .node {
        display: flex;
        flex-direction: column;
        align-items: center;
        --margin: 1.5em;
        position: relative;
        text-align: center;
    }

    .content {
        padding: 1em;
        border: 1px solid #AAA;
        margin: var(--margin);
        width: max-content;
        background: #EEE;
    }

    .contentWrapper {
        position: relative;
    }

    .node .node:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: 2px solid #AAA;
    }

    .node .node:first-child:last-child:before {
        width: 0;
    }

    .node .node:first-child:before {
        width: 50%;
        left: 50%;
    }

    .node .node:last-child:before {
        width: 50%;
    }

    .node .node .contentWrapper:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        border-left: 2px solid #AAA;
        height: var(--margin);
    }

    .iconContainer {
        cursor: pointer;
        width: 3em;
        height: 3em;
        flex: 0 0 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto -0.8em auto;
    }

    .icon {
        height: 1em;
        width: 1em;
    }
</style>

<script>
import treeStyles from './tree.module.css'
import Vue from 'vue'
import Icon, { register as registerIcons } from '@wisol/libs-icons'
import DownIcon from '@wisol/libs-icons/src/icons/wisol/down'
import UpIcon from '@wisol/libs-icons/src/icons/wisol/up'

registerIcons(DownIcon, UpIcon)

export default {
    components: {
        NodeList: () => import('./NodeList.vue'),
        Icon
    },

    props: {
        dataGroup: {
            type: Vue,
            required: true
        },

        rowMap: {
            type: Map,
            required: true
        },

        row: {
            type: Object,
            required: true
        },

        treeIdKey: {
            type: String,
            default: 'id'
        },

        treeModeKey: {
            type: String,
            default: '@mode'
        },

        renderTemplate: {
            type: Function,
            required: true
        },

        isExpanded: {
            type: Function,
            default: () => false
        }
    },

    data () {
        return {
            treeStyles
        }
    },

    computed: {
        values () {
            return this.dataGroup.getRowValues(this.row)
        },

        rows () {
            const { treeIdKey, values } = this
            if (!(treeIdKey in values)) {
                return []
            }

            const id = values[treeIdKey]
            const { rows } = this.rowMap.get(id) || {}
            return rows || []
        },

        childRows () {
            const { rows, treeModeKey } = this
            return rows.filter(row => {
                return row.values[treeModeKey] !== 'nested'
            })
        },

        nestedRows () {
            const { rows, treeModeKey } = this
            return rows.filter(row => {
                return row.values[treeModeKey] === 'nested'
            })
        },

        hasChildren () {
            return this.childRows.length > 0
        },

        iconName () {
            return this.expanded
                ? 'wisol/up'
                : 'wisol/down'
        },

        expanded () {
            return this.isExpanded(this.row)
        }
    },

    methods: {
        toggle () {
            this.$emit(this.expanded ? 'collapse' : 'expand', this.row)
        }
    }
}
</script>
