import { ref } from 'vue'

let isSetup = false
let nodeList

const setup = () => {
    if (isSetup) return
    isSetup = true

    nodeList = ref([])
}

export default function useFocusPortalNodeList () {
    setup()

    const add = function (nodeRef) {
        const index = nodeList.value.indexOf(nodeRef)
        if (index === -1) {
            nodeList.value.push(nodeRef)
        }
    }

    const remove = function (nodeRef) {
        const index = nodeList.value.indexOf(nodeRef)
        if (index > -1) {
            nodeList.value.splice(index, 1)
        }
    }

    const hasFocus = function (node) {
        return nodeList.value.find(nodeListNode => {
            return nodeListNode.value &&
                node.contains(nodeListNode.value?.$el || nodeListNode.value)
        }) !== undefined
    }

    return {
        add,
        remove,
        hasFocus
    }
}
