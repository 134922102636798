<template>
    <!-- eslint-disable vue/no-v-html -->
    <div class="component-wisol-editor-menu-breadcrumbs">
        <ul class="breadcrumbs">
            <li
                v-for="(crumb, index) in breadcrumbs"
                :key="index"
                @click="showDetail(crumb)"
            >
                <a
                    v-if="index < breadcrumbs.length-1"
                    v-html="crumb['@label']"
                />
                <span
                    v-else
                    v-html="crumb['@label']"
                />
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";
    .component-wisol-editor-menu-breadcrumbs {
        display: flex;
        align-items: flex-start;
        background: #fff;
        font-size: 2rem;

        &> .breadcrumbs {
            list-style: none;
            padding: 0;
            margin: 0;

            &> li {
                display: inline;

                &> a {
                    color: #0275d8;
                    text-decoration: none;
                    cursor: pointer;
                }

                i {
                    color: nth($color-palette-grey, 4);
                }
            }

            &> li+li:before {
                padding: 0px 6px 0px 6px;
                color: black;
                content: "/\00a0";
            }
        }
    }
</style>

<script>
export default {
    name: 'WisolEditorMenuBreadcrumbs',

    inheritAttrs: false,

    props: {
        selected: {
            type: Object,
            required: true
        }
    },

    data () {
        return {

        }
    },

    computed: {
        breadcrumbs () {
            const breadcrumbs = []
            let current = this.selected
            while (current) {
                breadcrumbs.unshift(current)
                current = current.parent()
            }
            return breadcrumbs
        }
    },

    methods: {
        showDetail (item) {
            this.$emit('showDetail', item)
        }
    }
}
</script>
