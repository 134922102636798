<template>
    <div
        class="component-wisol-editor-menu-form-widget"
    >
        <generic-form
            :key="'widget:' + row['@id']"
            :fields="fields"
            :values="values"
            :layout="layout"
            :column-count="24"
            :cell-height="25"
            :min-cell-width="40"
            :max-cell-width="Infinity"
            :readonly="false"
            @update:value="onUpdateFormValue"
            @update:layout="onLayoutUpdate"
        />
        <generic-loading v-show="isLoading" />
    </div>
</template>

<style lang="scss">
    .component-wisol-editor-menu-form-widget {
        padding: 20px 20px 0px 20px;
    }

</style>

<script>
import { Input as Inputs } from '@wisol/libs-inputs'
import layout from './layouts/widget.json'

const LOCALSTORAGE_KEY = 'layout_widget'

export default {
    name: 'WisolEditorMenuFormWidget',

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            default: () => {}
        },

        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            layout,
            labels: {
                '@id': 'ID',
                '@box': 'Box ID',
                options: 'Options',
                type: 'Type',
                order: 'Order',
                default: 'Default'
            }
        }
    },

    computed: {
        fields () {
            return Object.keys(this.row).map((field) => {
                let component = Inputs.Text
                const props = {}
                switch (field) {
                    case '@id':
                    case '@box':
                        component = Inputs.Number
                        props.readonly = true
                        break
                    case 'type':
                        component = Inputs.Selectbox
                        props.required = true
                        props.options = {
                            form: 'Form',
                            listing: 'Listing',
                            chart: 'Chart',
                            tree: 'Tree',
                            grid: 'Grid'
                        }
                        break
                    case 'options':
                        component = Inputs.Object
                        break
                    case 'default':
                        component = Inputs.Checkbox
                        props.required = true
                        break
                }

                return {
                    id: field,
                    field: field,
                    label: this.labels[field],
                    input: {
                        component,
                        props

                    }
                }
            })
        },

        values () {
            return this.row
        }
    },

    mounted () {
        let local = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || []
        local = local.reduce((arr, l) => {
            const foundLayout = arr.find((item) => item.id === l.id)

            if (foundLayout) {
                foundLayout.position = l.position
            } else {
                arr.push(l)
            }

            return arr
        }, layout)

        this.layout = local
    },

    methods: {
        onLayoutUpdate (layout) {
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(layout))
            this.layout = layout
        },

        onUpdateFormValue ({ field, value }) {
            switch (field) {
                case 'order':
                case 'options':
                    value = value || null
                    break
            }
            const row = {
                ...this.row,
                [field]: value
            }
            this.$emit('updateRow', this.validateRow(row))
        },

        validateRow (row) {
            return {
                ...row,
                '@valid': !!row.type
            }
        }
    }
}

</script>
