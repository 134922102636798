<template>
    <element-portal
        :target="container"
    >
        <popover
            :reference="target"
            :modifiers="modifiers"
            v-bind="$attrs"
        >
            <div
                ref="focusElement"
                v-auto-focus
                :class="classes"
                :style="popoverStyle"
                class="component-generic-popover"
                tabindex="0"
            >
                <div
                    v-if="showArrow"
                    ref="arrow"
                    class="arrow"
                />
                <div class="popover-container">
                    <slot />
                </div>
            </div>
        </popover>
    </element-portal>
</template>

<style lang="scss">
    .component-generic-popover {
        z-index: 9999;

        .popover-container {
            max-width: calc(100vw - 10px);
            max-height: calc(100vh - 10px);
            overflow: auto;
            padding: 0.5em;
        }

        .arrow {
            position: absolute;
            width: 8px;
            border-width: 8px;
            border-style: solid;
            border-color: transparent;
        }

        &[data-popper-placement^=bottom] {
            .arrow {
                top: -16px;
                border-bottom-color: inherit;
            }
        }

        &[data-popper-placement^=top] {
            .arrow {
                bottom: -16px;
                border-top-color: inherit;
            }
        }

        &[data-popper-placement^=left] {
            .arrow {
                right: -16px;
                border-left-color: inherit;
            }
        }

        &[data-popper-placement^=right] {
            .arrow {
                left: -16px;
                border-right-color: inherit;
            }
        }
    }
</style>

<script>
import { Popover } from '@wisol/utils-popover/components'
import { ElementPortal } from '@wisol/utils-element/components'
import { useFocusTracker } from '@wisol/utils-focus/composition'
import { AutoFocus } from '@wisol/utils-focus/directives'
import { ref } from 'vue'

export default {
    name: 'GenericPopover',

    directives: {
        AutoFocus
    },

    components: {
        Popover,
        ElementPortal
    },

    inheritAttrs: false,

    props: {
        target: {
            type: Node,
            required: true
        },

        container: {
            type: Node,
            default: undefined
        },

        jail: {
            type: [String, HTMLElement],
            default: 'viewport'
        },

        placement: {
            type: String,
            default: 'bottom-start'
        },

        showArrow: {
            type: Boolean,
            default: true
        },

        popoverClasses: {
            type: null,
            default: null
        },

        popoverStyle: {
            type: null,
            default: null
        }
    },

    setup () {
        const focusElement = ref()
        const { hasFocus } = useFocusTracker(focusElement)

        return { focusElement, hasFocus }
    },

    data () {
        return {
            arrowNode: null
        }
    },

    computed: {
        classes () {
            return [
                {
                    'has-arrow': this.showArrow
                },
                this.popoverClasses
            ]
        },

        modifiers () {
            return {
                arrow: {
                    enabled: this.showArrow,
                    element: this.arrowNode
                },
                offset: {
                    enabled: true,
                    offset: [8, 8]
                },
                ...(this.$attrs.modifiers || {})
            }
        }
    },

    watch: {
        hasFocus (newValue, oldValue) {
            if (!this.hasFocus) {
                this.close()
            }
        }
    },

    mounted () {
        this.arrowNode = this.$refs.arrow || null
    },

    updated () {
        this.arrowNode = this.$refs.arrow || null
    },

    methods: {
        close () {
            this.$emit('close')
        }
    }
}
</script>
