let autoIncrementId = 0

export default {
    SET_TOTAL_COUNT (state, { count }) {
        state.totalCount = count
    },

    LOAD_ROWS (state, { rows }) {
        state.rows = Object.freeze(rows.map(row => {
            return Object.freeze({
                '@type': 'DEFAULT',
                '@id': '@id' in row
                    ? row['@id']
                    : 'auto-id-' + ++autoIncrementId,
                '@rowId': '@id' in row
                    ? row['@id']
                    : null,
                values: row
            })
        }))
    },

    UPDATE_ROW (state, { row, values }) {
        const { values: rowValues } = row
        for (const key in values) {
            if (key in rowValues && rowValues[key] !== values[key]) {
                rowValues[key] = values[key]
            }
        }
    },

    SET_UPDATE_REQUIRED (state, { required }) {
        state.updateRequired = !!required
    }
}
