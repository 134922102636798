import ModuleGrid from './components/wisol/module/Grid'
import DataGroupModuleDelete from './components/wisol/Data/Group/module/Delete'
import DataGroupModuleCreate from './components/wisol/Data/Group/module/Create'
import DataGroupModuleLinkRelation from './components/wisol/Data/Group/module/link/Relation'
import DataGroupModuleLinkFilter from './components/wisol/Data/Group/module/link/Filter'
import DataGroupModulePagination from './components/wisol/Data/Group/module/Pagination'
import DataGroupModuleSelection from './components/wisol/Data/Group/module/Selection'
import DataGroupModuleUpdate from './components/wisol/Data/Group/module/Update'
import DataGroupModuleAdvancedFilter from './components/wisol/Data/Group/module/AdvancedFilter'
import WidgetForm from './components/wisol/widget/Form'
import WidgetListing from './components/wisol/widget/Listing/index.vue'
import WidgetChart from './components/wisol/widget/Chart'
import WidgetTree from './components/wisol/widget/Tree/index.vue'
import WidgetGrid from './components/wisol/widget/Grid.vue'
import OleInput from './components/wisol/Input/Ole'
import DropdownInput from './components/wisol/Input/Dropdown'
import ScaleInput from './components/wisol/Input/Scale'
import MenuEditorComponent from './components/wisol/editor/Menu'
import ConfigEditorComponent from './components/wisol/editor/Config'
import AppsEditorComponent from './components/wisol/editor/Apps'
import DatabaseEditorComponent from './components/wisol/editor/Database'
import WisolTableDbConnector from './db/connector/wisol/Table.js'
import WisolDatagroupDbConnector from './db/connector/wisol/Datagroup.js'
import WisolDropdownDbConnector from './db/connector/wisol/Dropdown.js'
import InputParsers from '@wisol/libs-inputs/src/parser'
import InputFilterParser from './components/wisol/Input/Text/Filter/Parser'
import InputDropdownParser from './components/wisol/Input/Dropdown/Parser'
import ListingComputeStats from './components/wisol/widget/Listing/compute/Stats.vue'

import { Input as WisolInputs } from '@wisol/libs-inputs'

export default Registry => {
    // register window modules
    Registry.type('module', ({ define }) => {
        define('grid', { component: ModuleGrid })
    })

    // register box widgets
    Registry.type('widget', ({ define }) => {
        define('form', {
            component: WidgetForm,
            icon: 'wisol/box'
        })
        define('listing', {
            component: WidgetListing,
            icon: 'wisol/list'
        })
        define('chart', {
            component: WidgetChart,
            icon: 'fa/solid/chart-bar'
        })
        define('tree', {
            component: WidgetTree,
            icon: 'fa/solid/folder-tree'
        })
        define('grid', {
            component: WidgetGrid,
            icon: 'wisol/grid'
        })
    })

    // register dataGroup modules
    Registry.type('data-group-module', ({ define }) => {
        define('delete', { component: DataGroupModuleDelete })
        define('create', { component: DataGroupModuleCreate })
        define('link/relation', { component: DataGroupModuleLinkRelation })
        define('link/filter', { component: DataGroupModuleLinkFilter })
        define('selection', { component: DataGroupModuleSelection })
        define('pagination', { component: DataGroupModulePagination })
        define('update', { component: DataGroupModuleUpdate })
        define('advanced-filter', { component: DataGroupModuleAdvancedFilter })
    })

    // register input widget
    Registry.type('input-widget', ({ define }) => {
        define('text', { component: WisolInputs.Text })
        define('text-multiline', { component: WisolInputs.Textarea })
        define('text-multiline:filter', { component: WisolInputs.Text })
        define('checkbox', { component: WisolInputs.Checkbox })
        define('checkbox:filter', {
            component: WisolInputs.Checkbox,
            props: {
                toggleIndeterminate: true
            }
        })
        define('number', { component: WisolInputs.Number })
        define('html', { component: WisolInputs.Html })
        define('html:filter', { component: WisolInputs.Text })
        define('url', { component: WisolInputs.Url })
        define('datetime', { component: WisolInputs.DateTime })
        define('date', { component: WisolInputs.Date })
        define('time', { component: WisolInputs.Time })
        define('button', { component: WisolInputs.Button })
        define('ole', { component: OleInput })
        define('ole:filter', { component: null })
        define('dropdown', { component: DropdownInput })
        define('duration', { component: WisolInputs.Duration })
        define('array', { component: WisolInputs.Array })
        define('color', { component: WisolInputs.Color })
        define('code', { component: WisolInputs.Code })
        define('select', { component: WisolInputs.Selectbox })
        define('scale', { component: ScaleInput })
    })

    // register input value parser (values comming from server)
    Registry.type('value-parser', ({ define }) => {
        define('text:filter', { parser: InputFilterParser })
        define('text-multiline:filter', { parser: InputFilterParser })
        define('checkbox', { parser: InputParsers.Boolean })
        define('checkbox:filter', { parser: InputParsers.Boolean })
        define('number', { parser: InputParsers.Number })
        define('datetime', { parser: InputParsers.DateTime })
        define('date', {
            parser: InputParsers.DateTime,
            props: {
                format: 'yyyy-MM-dd'
            }
        })
        define('time', {
            parser: InputParsers.DateTime,
            props: {
                format: 'HH:mm:ss'
            }
        })
        define('ole', { parser: InputParsers.Json })
        define('dropdown', { parser: InputDropdownParser })
        define('duration', { parser: InputParsers.Duration })
        define('array', { parser: InputParsers.Json })
        define('color', { parser: InputParsers.Color })
    })

    // register input displayed value parser (values displayed to the user)
    Registry.type('input-parser', ({ define }) => {
        define('checkbox', { parser: InputParsers.Boolean })
        define('checkbox:filter', { parser: InputParsers.Boolean })
        define('number', {
            parser: InputParsers.Number,
            props: {
                thousandsSeparator: ' ',
                decimalPoint: ',',
                decimalCount: 2,
                forceDecimalCount: false
            }
        })
        define('datetime', {
            parser: InputParsers.DateTime,
            props: {
                format: 'dd/MM/yyyy HH:mm'
            }
        })
        define('date', {
            parser: InputParsers.DateTime,
            props: {
                format: 'dd/MM/yyyy'
            }
        })
        define('time', {
            parser: InputParsers.DateTime,
            props: {
                format: 'HH:mm:ss'
            }
        })
        define('ole', {
            parser: InputParsers.Json,
            props: {
                beautify: true
            }
        })
        define('dropdown', { parser: InputDropdownParser })
        define('duration', {
            parser: InputParsers.Duration,
            props: {
                mode: 'time'
            }
        })
        define('array', {
            parser: InputParsers.Json,
            props: {
                beautify: true
            }
        })
        define('color', { parser: InputParsers.Color })
    })

    // register db connectors
    Registry.type('db-connector', ({ define }) => {
        define('wisol.table', { connector: WisolTableDbConnector })
        define('wisol.dataGroup', { connector: WisolDatagroupDbConnector })
        define('wisol.dropdown', { connector: WisolDropdownDbConnector })
    })

    // register editor pages
    Registry.type('routes', ({ define }) => {
        define('editor', {
            name: 'Editor',
            path: '/editor',
            redirect: {
                name: 'EditorMenu'
            }
        })
        define('editor-menu', {
            name: 'EditorMenu',
            path: '/editor/menu',
            component: MenuEditorComponent,
            meta: {
                title: 'Editor - Menu',
                icon: 'fa/light/folder-open',
                showInEditorNavbar: true,
                openEditorNavbar: true,
                authRequired: true
            }
        })
        define('editor-config', {
            name: 'EditorConfig',
            path: '/editor/config',
            component: ConfigEditorComponent,
            meta: {
                title: 'Editor - Config',
                icon: 'fa/light/edit',
                showInEditorNavbar: true,
                openEditorNavbar: true,
                authRequired: true
            }
        })
        define('editor-apps', {
            name: 'EditorApps',
            path: '/editor/apps',
            component: AppsEditorComponent,
            meta: {
                title: 'Editor - Apps',
                icon: 'fa/light/exchange',
                showInEditorNavbar: true,
                openEditorNavbar: true,
                authRequired: true
            }
        })
        define('editor-database', {
            name: 'EditorDatabase',
            path: '/editor/database',
            component: DatabaseEditorComponent,
            meta: {
                title: 'Editor - Database',
                icon: 'fa/light/database',
                showInEditorNavbar: true,
                openEditorNavbar: true,
                authRequired: true
            }
        })
    })

    // listing compute components
    Registry.type('listing-compute', ({ define }) => {
        define('sum', {
            component: ListingComputeStats,
            props: { type: 'sum' }
        })
        define('min', {
            component: ListingComputeStats,
            props: { type: 'min' }
        })
        define('max', {
            component: ListingComputeStats,
            props: { type: 'max' }
        })
        define('average', {
            component: ListingComputeStats,
            props: { type: 'average' }
        })
        define('median', {
            component: ListingComputeStats,
            props: { type: 'median' }
        })
    })
}
