export default ({
    thousandsSeparator = '',
    decimalPoint = '.',
    decimalCount = null,
    forceDecimalCount = false
} = {}) => {
    const getDecimalCount = number => {
        const [, decimals = ''] = number.toString(10).split('.')
        return decimals.length
    }

    const validateNumber = number => {
        if (isNaN(number)) {
            throw new Error('invalid number')
        }
        if (forceDecimalCount && decimalCount !== null) {
            const currentDecimalCount = getDecimalCount(number)
            if (currentDecimalCount > decimalCount) {
                throw new Error(`"${number}" has wrong decimal count, ${decimalCount} required, ${currentDecimalCount} available`)
            }
        }
    }

    return {
        async parse (raw) {
            // check for allowed chars
            let allowedChars = []
            allowedChars.push('0-9')
            if (decimalPoint.length > 0) {
                allowedChars.push('\\' + decimalPoint)
            }
            if (thousandsSeparator.length > 0) {
                allowedChars.push('\\' + thousandsSeparator)
            }
            allowedChars = allowedChars.join('')
            if (!raw.match(new RegExp(`^([\\+\\-])?[${allowedChars}]+$`)) ||
                raw.split(decimalPoint).length > 2
            ) {
                throw new Error(`"${raw}" is an invalid number`)
            }

            raw = raw.replace(new RegExp(`[^\\-0-9\\${decimalPoint}]+`, 'g'), '')
            if (decimalPoint !== '.') {
                raw = raw.replace(decimalPoint, '.')
            }

            // check if valid number
            const number = parseFloat(raw)
            validateNumber(number)

            return number
        },

        serialize (number) {
            validateNumber(number)

            const fixedNumber = decimalCount === null || getDecimalCount(number) > decimalCount
                ? number.toString()
                : number.toFixed(decimalCount)
            const numberParts = fixedNumber.split('.')
            numberParts[0] = numberParts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1' + thousandsSeparator)
            return numberParts.join(decimalPoint)
        }
    }
}
