<template>
    <element-portal>
        <div
            :class="$style.container"
            @click.self="close"
        >
            <div :class="$style.popup">
                <div
                    v-if="title"
                    :class="$style.header"
                    :style="headerStyle"
                >
                    <span :class="$style.titleText">
                        {{ title }}
                    </span>
                    <generic-action-icons
                        :actions="actions"
                        :groups="actionGroups"
                    />
                </div>
                <slot />
            </div>
        </div>
    </element-portal>
</template>

<style module>
    .container {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #0009;
        z-index: 99999999;
    }

    .popup {
        background: #FFF;
        max-width: calc(100vw - 2rem);
        max-height: calc(100vh - 2rem);
        overflow: auto;
    }

    .header {
        font-size: 1.2rem;
        height: 2.2em;
        display: flex;
        align-items: center;
        padding: 0 0 0 1em;
    }

    .titleText {
        flex-grow: 1;
        text-transform: uppercase;
    }
</style>

<script>
import { ElementPortal } from '@wisol/utils-element/components'
import ColorsMixin from '@/mixins/Colors'

export default {
    components: {
        ElementPortal
    },

    mixins: [ColorsMixin],

    props: {
        title: {
            type: String,
            default: null
        },

        color: {
            type: String,
            default: '#FFFFFF'
        }
    },

    computed: {
        headerStyle () {
            return this.headerColorStyle(this.backgroundColor)
        },

        backgroundColor () {
            return this.color
        },

        actions () {
            return [
                {
                    icon: 'wisol/close',
                    title: this.$t('window.close'),
                    group: 'window',
                    callback: evt => {
                        this.close()
                    }
                }
            ]
        },

        actionGroups () {
            return ['default', 'window']
        }
    },

    methods: {
        close () {
            this.$emit('close')
        }
    }
}
</script>
