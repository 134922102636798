import isPlainObject from 'lodash-es/isPlainObject'
import isObjectLike from 'lodash-es/isObjectLike'

export default (plainObject = true) => value => {
    if (plainObject) {
        if (!isPlainObject(value)) {
            throw new Error('value needs to be of type Object')
        }
    } else {
        if (!isObjectLike(value)) {
            throw new Error('value needs to be of type Object or Array')
        }
    }
}
