<template>
    <div class="component-wisol-loading">
        <generic-loading>
            <div class="loading-container">
                <div class="logo-container">
                    <img
                        class="logo pulse"
                        src="../../assets/logo-icon-black.svg"
                        alt="Wiges Solutions"
                    >
                </div>
            </div>
        </generic-loading>
    </div>
</template>

<style lang="scss">
    .component-wisol-loading {

        .loading-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .logo-container {
                width: 120px;
                height: 120px;

                .logo {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        @keyframes pulse {
            from {
                transform: scale3d(1, 1, 1);
            }

            50% {
                transform: scale3d(1.1, 1.1, 1.1);
            }

            to {
                transform: scale3d(1, 1, 1);
            }
        }

        .pulse {
            animation-name: pulse;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-iteration-count: infinite;
        }
    }
</style>

<script>
export default {
    name: 'WisolLoading',

    inheritAttrs: false
}
</script>
