export default () => {
    return {
        methods: {
            getConfig () {
                return this.$http
                    .get(
                        'module/ui/config/list'
                    )
            },

            update (data) {
                return this.$http
                    .post(
                        'module/ui/config/update',
                        data
                    )
            }
        }
    }
}
