import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state () {
        const state = {
            username: sessionStorage.getItem('session.username'),
            token: sessionStorage.getItem('session.token'),
            firme: sessionStorage.getItem('session.firme'),
            timeout: false,
            status: sessionStorage.getItem('session.status'),
            info: JSON.parse(sessionStorage.getItem('session.info')),
            login: {
                username: localStorage.getItem('login.username'),
                token: localStorage.getItem('login.token'),
                label: localStorage.getItem('login.label')
            }
        }

        window.addEventListener('storage', evt => {
            if (evt.storageArea === localStorage) {
                switch (evt.key) {
                    case 'login.username':
                        state.login.username = evt.newValue
                        break
                    case 'login.token':
                        state.login.token = evt.newValue
                        break
                    case 'login.label':
                        state.login.label = evt.newValue
                        break
                }
            }
        })

        return state
    }
}
