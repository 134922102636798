<template>
    <div :class="$style.container">
        <div :class="$style.chartContainer">
            <status-display
                v-if="!dataGroup.isReady"
                :data-group="dataGroup"
            />
            <template v-else-if="typeComponent">
                <single-to-multi-converter
                    v-if="useSingleToMultiConverter"
                    v-slot="converterData"
                    :type="type"
                    :rows="rows"
                    :data-group="dataGroup"
                    v-bind="$attrs"
                >
                    <component
                        :is="typeComponent"
                        v-bind="converterData"
                    />
                </single-to-multi-converter>
                <component
                    :is="typeComponent"
                    v-else
                    :type="type"
                    :rows="rows"
                    :data-group="dataGroup"
                    v-bind="$attrs"
                />
            </template>
        </div>
        <generic-pagination
            v-if="showPagination"
            :page="page"
            :count="rowsPerPage"
            :total="dataGroup.totalRowCount"
            :class="$style.pagination"
            @update:page="onPageUpdate"
            @update:count="onCountUpdate"
        />
    </div>
</template>

<style lang="scss" module>
    @import "@wisol/theme/variables";

    .container {
        position: relative;
        height: 100%;
        width: 100%;
        max-height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .chartContainer {
        position: relative;
        height: 100%;
        width: 100%;
        flex: 0 auto;
    }

    .pagination {
        flex: 0 0 2.5rem;
        background: nth($color-palette-grey, 2);
    }
</style>

<script>
import Vue from 'vue'
import StatusDisplay from '../../Data/Group/StatusDisplay'
import Type1d from './type/1d'
import Type2d from './type/2d'
import Type3d from './type/3d'
import TypeYList from './type/YList'
import TypeRadialBar from './type/RadialBar'
import SingleToMultiConverter from './SingleToMultiConverter'

export default {
    name: 'WisolWidgetChart',

    components: {
        StatusDisplay,
        SingleToMultiConverter
    },

    inheritAttrs: false,

    props: {
        id: {
            type: Number,
            required: true
        },

        layout: {
            type: Object,
            required: true
        },

        dataGroup: {
            type: Vue,
            required: true
        },

        type: {
            type: String,
            default: 'bar'
        },

        seriesMode: {
            type: String,
            default: 'multi-column'
        }
    },

    computed: {
        rows () {
            return this.dataGroup.rows.map(row => this.dataGroup.getRowValues(row))
        },

        typeComponent () {
            switch (this.type) {
                case 'pie':
                case 'donut':
                    return Type1d
                case 'radialBar':
                    return TypeRadialBar
                case 'line':
                case 'area':
                case 'histogram':
                case 'bar':
                case 'scatter':
                case 'heatmap':
                case 'radar':
                    return Type2d
                case 'bubble':
                    return Type3d
                case 'candlestick':
                case 'rangeBar':
                    return TypeYList
            }
            return null
        },

        showPagination () {
            return this.dataGroup.moduleMap.has('pagination')
        },

        page () {
            if (!this.showPagination) {
                return null
            }
            return this.dataGroup.moduleMap.get('pagination').page
        },

        rowsPerPage () {
            if (!this.showPagination) {
                return null
            }
            return this.dataGroup.moduleMap.get('pagination').rowsPerPage
        },

        useSingleToMultiConverter () {
            return this.seriesMode === 'single-column'
        }
    },

    methods: {
        onPageUpdate ({ page }) {
            const pagination = this.dataGroup.moduleMap.get('pagination')
            pagination.selectPage(page)
        },

        onCountUpdate ({ count }) {
            const pagination = this.dataGroup.moduleMap.get('pagination')
            pagination.setRowsPerPage(count)
        }
    }
}
</script>
