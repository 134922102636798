export const emptyValue = null

export const isEmpty = value => {
    return value === emptyValue
}

export default () => value => {
    if (isEmpty(value)) {
        throw new Error('field is required')
    }
}
