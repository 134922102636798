<template>
    <div class="component-wisol-editor-database">
        <toolbar
            :selected="selected"
            class="toolbar"
            @action="doAction"
        />
        <div
            class="database-container"
        >
            <table>
                <thead>
                    <tr>
                        <td>
                            <h3>Date</h3>
                        </td>
                        <td>
                            <h3>Version</h3>
                        </td>
                        <td>
                            <h3>File</h3>
                        </td>
                        <td>
                            <h3>Comment</h3>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(backupItem, index) in backupList"
                        :key="index"
                        :class="itemClasses(index)"
                        @click="selectBackup(index)"
                    >
                        <td class="date">
                            {{ backupItem.date }}
                        </td>
                        <td class="version">
                            {{ backupItem.version }}
                        </td>
                        <td class="file">
                            {{ backupItem.file }}
                        </td>
                        <td class="comment">
                            {{ backupItem.comment }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="loading">
            <wisol-loading />
        </div>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-database {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        background: #fff;
        overflow-y: auto;
        font-size: 1.1em;

        &> .toolbar {
            position: fixed;
            z-index: 2;
            width: 100%;
        }

        &> .database-container {
            height: 100vh;
            padding: 50px;
            box-sizing: border-box;
            width: 100%;
        }

        table {
            width: 100%;
            margin-top: 20px;
            white-space: nowrap;

            .comment {
                width: 100%;
            }

            td {
                vertical-align: middle;
            }

            thead {
                tr {
                    font-weight: bold;
                }

                td {
                    padding-left: 10px;
                }
            }

            tbody {
                tr {
                    cursor: pointer;

                    td {
                        height: 60px;
                        background-color: nth($color-palette-grey, 2);
                        padding: 10px;
                        border: 2px solid white;

                        h5 {
                            font-weight: bold;
                        }
                    }

                    &:hover {
                        td {
                            background-color: nth($color-palette-grey, 3);
                        }
                    }
                }

                tr.selected {
                    td {
                        background-color: nth($color-palette-status, 2);
                    }
                }
            }
        }
    }

</style>

<script>
import requests from './mixins/requests.js'
import WisolEditorConfigToolbar from './Toolbar'
import { success } from '@/utils/alert'
import triggerDownload from '@/utils/triggerDownload.js'

export default {
    name: 'WisolEditorDatabase',

    components: {
        toolbar: WisolEditorConfigToolbar
    },

    mixins: [
        requests()
    ],

    inheritAttrs: false,

    data () {
        return {
            backupList: [],
            selected: null,
            loading: true
        }
    },

    mounted () {
        this.asyncGetBackups()
    },

    methods: {
        doAction (action) {
            switch (action) {
                case 'new':
                    this.asyncCreateBackup()
                    break
                case 'restoreFile':
                    this.asyncRestoreFile()
                    break
                case 'download':
                    this.asyncDownloadBackup()
                    break
                case 'restoreSelected':
                    this.asyncRestoreSelected()
                    break
                case 'reset':
                    this.asyncResetBackup()
                    break
            }
        },

        asyncGetBackups () {
            this.loading = true
            this.getBackups()
                .then((response) => this.handleGetBackupsResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncCreateBackup () {
            this.loading = true
            this.createBackup()
                .then((response) => this.handleCreateBackupResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncResetBackup () {
            if (window.confirm('All data will be lost! Proceed?')) {
                this.loading = true
                this.resetBackup()
                    .then((response) => this.handleResetBackupResponse(response))
                    .catch((error) => this.handleRequestError(error))
            }
        },

        asyncDownloadBackup () {
            this.loading = true
            this.downloadBackup(this.backupList[this.selected].file)
                .then((response) => this.handleDownloadBackupResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncRestoreSelected () {
            this.loading = true
            this.restoreBackup(this.backupList[this.selected].file)
                .then((response) => this.handleRestoreSelectedResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncRestoreFile () {
            // create temp input element
            const fileInput = document.createElement('input')
            // make it a file input
            fileInput.type = 'file'
            // hide the input
            fileInput.style.visibility = 'hidden'
            // append input to DOM
            document.body.appendChild(fileInput)

            // Callback to handle file selection
            fileInput.addEventListener('change', () => {
                // get selected file
                const file = fileInput.files[0]
                // check if file is a sql or dbbackup file
                if (file.name.match(/\.(sql|dbbackup)$/)) {
                    // create virtual form
                    const formData = new FormData()
                    // append selected file to form under key 'file'
                    formData.append('file', file)

                    // async upload to backend
                    if (window.confirm('All data will be lost! Proceed?')) {
                        this.loading = true
                        this.restoreFile(formData)
                            .then((response) => this.handleRestoreFileResponse(response))
                            .catch((error) => this.handleRequestError(error))
                    }
                } else {
                    // type not supported
                    alert('File not supported, .sql or .dbbackup files only')
                }
            })

            // Open file input dialog
            fileInput.click()

            // Remove file input after usage
            fileInput.remove()
        },

        handleRequestError (error) {
            const err = error.response.data
            alert(this.$store, err.code + ': ' + err.message)
            this.loading = false
        },

        handleGetBackupsResponse ({ data }) {
            this.loading = false
            this.backupList = data
        },

        handleCreateBackupResponse () {
            this.loading = false
            // Refresh backup list
            this.asyncGetBackups()
            // Select created backup
            this.selected = 0
            success('Successfully created backup!')
        },

        handleResetBackupResponse () {
            this.loading = false
            success('Successfully reset database!')
        },

        handleDownloadBackupResponse ({ data }) {
            this.loading = false
            success('Download is ready!')
            const url = URL.createObjectURL(data)
            triggerDownload(url, this.backupList[this.selected].file)
            URL.revokeObjectURL(url)
        },

        handleRestoreSelectedResponse () {
            this.loading = false
            success('Successfully restored backup!')
        },

        handleRestoreFileResponse ({ data }) {
            this.loading = false
            success('Successfully restored backup!')
        },

        selectBackup (index) {
            if (this.selected === index) {
                this.selected = null
            } else {
                this.selected = index
            }
        },

        itemClasses (index) {
            return {
                selected: this.selected === index
            }
        }
    }
}

</script>
