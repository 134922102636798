<template>
    <div :class="$style.container">
        <img
            :class="$style.logo"
            :src="logoUrl"
        >
        <h2>Session</h2>
        <listing
            :class="$style.table"
            v-bind="sessionTable"
        >
            <template #default="{ row, column }">
                <div :class="$style.cell">
                    {{ row[column.id] }}
                </div>
            </template>
        </listing>
        <h2>Wisol</h2>
        <listing
            :class="$style.table"
            v-bind="wisolTable"
        >
            <template #default="{ row, column }">
                <div :class="$style.cell">
                    {{ row[column.id] }}
                </div>
            </template>
        </listing>
        <h2>Wiges</h2>
        <listing
            :class="$style.table"
            v-bind="wigesTable"
        >
            <template #default="{ row, column }">
                <div :class="$style.cell">
                    {{ row[column.id] }}
                </div>
            </template>
        </listing>
    </div>
</template>

<style lang="scss" module>
    .container {
        padding: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 500px;
    }

    .logo {
        max-width: 100%;
    }

    .cell {
        font-size: 1.1em;
        padding: 0.5em;
    }

    .table {
        border: 1px solid #CCC;
    }
</style>

<script>
import { mapGetters } from 'vuex'
import packageData from '../../../package.json'
import logoUrl from '@/assets/logo-black.svg'
import { Listing } from '@wisol/libs-listing'

export default {
    components: {
        Listing
    },

    data () {
        return {
            logoUrl,
            version: packageData.version
        }
    },

    computed: {
        ...mapGetters('session', [
            'user',
            'isEditorUser',
            'isAdmin',
            'firme',
            'environment'
        ]),

        sessionTable () {
            return {
                columns: [
                    { id: 'key' },
                    { id: 'value' }
                ],
                rows: [
                    {
                        id: 'user',
                        key: 'User',
                        value: `${this.user.name} (${this.user.id})`
                    },
                    {
                        id: 'firme',
                        key: 'Firme',
                        value: this.firme
                    },
                    {
                        id: 'admin',
                        key: 'Admin',
                        value: this.isAdmin
                    },
                    {
                        id: 'editor',
                        key: 'Editor',
                        value: this.isEditorUser
                    }
                ]
            }
        },

        wisolTable () {
            return {
                columns: [
                    { id: 'key' },
                    { id: 'value' }
                ],
                rows: [
                    {
                        id: 'backend',
                        key: 'Backend',
                        value: this.environment.wisol.versions.version
                    },
                    {
                        id: 'ui',
                        key: 'UI',
                        value: packageData.version
                    }
                ]
            }
        },

        wigesTable () {
            return {
                columns: [
                    { id: 'key' },
                    { id: 'value' }
                ],
                rows: [
                    {
                        id: 'webservices',
                        key: 'WebServices',
                        value: this.environment.wiges.WebServices
                    },
                    {
                        id: 'version',
                        key: 'Version',
                        value: `${this.environment.wiges.wiges_version} (${this.environment.wiges.wiges_version_date})`
                    }
                ]
            }
        }
    }
}
</script>
