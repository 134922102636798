import Vue from 'vue'

export const Monitor = new Vue({
    data () {
        return {
            dragEnterNode: null,
            dataTransfer: null
        }
    }
})

let dragCounter = 0
document.addEventListener('dragenter', evt => {
    dragCounter++
    Monitor.dragEnterNode = evt.target
    Monitor.dataTransfer = evt.dataTransfer || null
})

document.addEventListener('dragleave', () => {
    dragCounter--
    if (dragCounter === 0) {
        Monitor.dragEnterNode = null
        Monitor.dataTransfer = null
    }
})

document.addEventListener('drop', evt => {
    evt.preventDefault()
    dragCounter = 0
    Monitor.dragEnterNode = null
    Monitor.dataTransfer = null
})

// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
export const isFile = dt => {
    if (!dt) {
        return false
    }

    if (dt.items && dt.items[0] && dt.items[0].kind === 'file') {
        return true
    }

    if (dt.files && dt.files[0]) {
        return true
    }

    return false
}

// https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
export const getFile = dt => {
    if (!dt) {
        return
    }

    if (dt.items && dt.items[0] && dt.items[0].kind === 'file') {
        return dt.items[0].getAsFile(dt.items[0])
    }

    if (dt.files && dt.files[0]) {
        return dt.files[0]
    }
}
