<template>
    <div
        class="component-wisol-layout-manager"
        tabindex="0"
    >
        <div
            v-if="showStandardLayouts"
            class="column"
        >
            <div
                :style="headerStyle"
                class="title"
            >
                {{ $t('layoutmanager.standard') }}
            </div>
            <layout-item
                v-for="layout in standardLayouts"
                :key="layout['@id']"
                :layout="layout"
                :readonly="!isEditorUser"
                @select="onSelect"
                @save="onSave"
                @delete="onDelete"
            />
        </div>
        <div class="column">
            <div
                :style="headerStyle"
                class="title"
            >
                {{ $t('layoutmanager.my') }}
            </div>
            <layout-item
                v-for="layout in myLayouts"
                :key="layout['@id']"
                :layout="layout"
                :readonly="false"
                @select="onSelect"
                @save="onSave"
                @delete="onDelete"
            />
            <div
                class="layout"
            >
                <text-input
                    ref="newLayoutLabelInput"
                    :value="label"
                    class="input"
                    @update:value="onUpdateLabel"
                    @keypress.native.enter.stop="onSaveNew"
                />
                <div
                    class="save-icon"
                    @click="onSaveNew"
                >
                    <icon
                        name="wisol/save"
                    />
                </div>
            </div>
        </div>
        <div class="column">
            <div
                :style="headerStyle"
                class="title"
            >
                {{ $t('layoutmanager.default') }}
            </div>
            <layout-item
                :layout="defaultLayout"
                :readonly="!isEditorUser"
                @select="onSelect"
                @save="onSave"
                @delete="onDelete"
            />
            <div
                :style="headerStyle"
                class="title"
            >
                {{ $t('layoutmanager.last-used') }}
            </div>
            <layout-item
                :layout="lastUsedLayout"
                :readonly="!isEditorUser"
                @select="onSelect"
                @save="onSave"
                @delete="onDelete"
            />
        </div>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-layout-manager {
        display: flex;

        .column:not(:first-child) {
            margin-left: 0.5em;
        }

        .title {
            font-size: 1.2rem;
            padding: 0.5em;
            min-width: 200px;
        }

        .layout {
            height: 2.5rem;
            line-height: 2.5rem;
            border-width: 0 0 1px 0;
            border-style: solid;
            border-color: nth($color-palette-grey, 4);
            cursor: pointer;
            display: flex;

            &> .save-icon {
                width: 2.5em;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    background: nth($color-palette-grey, 2);
                }
            }

            &> .input {
                border: 0;
                flex-grow: 1;
                height: 100%;
            }

            &> .label {
                flex-grow: 1;
                padding: 0 0.5em;
            }
        }
    }
</style>

<script>
import ColorsMixin from '@/mixins/Colors'
import LayoutItem from './Item'
import { Input as Inputs } from '@wisol/libs-inputs'
import { mapGetters } from 'vuex'
import Icon from '@wisol/libs-icons'

export default {
    name: 'WisolLayoutManager',

    components: {
        LayoutItem,
        TextInput: Inputs.Text,
        Icon
    },

    mixins: [
        ColorsMixin
    ],

    inheritAttrs: false,

    props: {
        module: {
            type: Number,
            required: true
        },

        layouts: {
            type: Array,
            required: true
        },

        activeLayout: {
            type: Object,
            required: true
        },

        color: {
            type: String,
            default: '#FFFFFF'
        }
    },

    data () {
        return {
            label: '',
            lastUsedLayout: null
        }
    },

    computed: {
        ...mapGetters('session', [
            'isEditorUser'
        ]),

        standardLayouts () {
            return this.layouts.filter(layout => layout.global)
        },

        myLayouts () {
            return this.layouts.filter(layout => !layout.global)
        },

        defaultLayout () {
            return this.layouts.find(layout => layout.default) ||
                this.layouts.find(layout => layout.global) ||
                this.layouts.find(layout => true) ||
                { layout: {} }
        },

        showStandardLayouts () {
            return this.standardLayouts.length > 0
        },

        backgroundColor () {
            return this.whitenColor(this.color)
        },

        headerStyle () {
            return this.headerColorStyle(this.backgroundColor)
        }
    },

    created () {
        this.lastUsedLayout = this.defaultLayout
    },

    methods: {
        onSelect (layout) {
            this.$emit('select', layout.layout)
            this.lastUsedLayout = layout
        },

        onUpdateLabel ({ value }) {
            this.label = value
        },

        onSaveNew () {
            if (!this.label) {
                return
            }
            this.$store.dispatch('layout/create', {
                module: this.module,
                label: this.label,
                layout: this.activeLayout
            })
            this.label = ''
            this.$el.focus()
        },

        onSave (layout) {
            this.$store.dispatch('layout/update', {
                id: layout['@id'],
                layout: this.activeLayout
            })
        },

        onDelete (layout) {
            this.$store.dispatch('layout/delete', {
                id: layout['@id']
            })
        }
    }
}
</script>
