import Vue, { getCurrentInstance } from 'vue'
import CommonMixin from './CommonMixin.js'
import useStoreWrapper from '@/composition/useStoreWrapper.js'

const StoreModule = {
    actions: {
        setRowValues ({ commit, getters }, { id, values }) {
            const row = getters.findRow(id)
            if (row) {
                commit('UPDATE_ROW_VALUES', { row, values })
            }
        },

        resetRowValues ({ commit, getters }, { id }) {
            const row = getters.findRow(id)
            if (row) {
                commit('RESET_ROW_VALUES', { row })
            }
        },

        removeAllValues ({ commit }) {
            commit('REMOVE_ALL_ROW_VALUES')
        },

        newRow ({ commit, getters }, { values }) {
            commit('ADD_ROW')
            const rows = getters.rows
            const row = rows[rows.length - 1]
            commit('UPDATE_ROW_VALUES', { row, values })
            return row
        },

        deleteRow ({ commit, getters }, { id }) {
            const row = getters.findRow(id)
            if (row) {
                commit('DELETE_ROW', { row })
            }
        }
    },

    mutations: {
        UPDATE_ROW_VALUES (state, { row, values }) {
            if (!(row['@id'] in state.values)) {
                Vue.set(state.values, row['@id'], {})
            }
            const currentValues = state.values[row['@id']]
            for (const key in values) {
                if (!(key in currentValues) || currentValues[key] !== values[key]) {
                    Vue.set(currentValues, key, values[key])
                }
            }
        },

        RESET_ROW_VALUES (state, { row }) {
            if (row['@id'] in state.values) {
                Vue.delete(state.values, row['@id'])
            }
        },

        REMOVE_ALL_ROW_VALUES (state) {
            state.values = {}
        },

        ADD_ROW (state) {
            const id = 'new-' + ++state.autoIncrementId
            state.rows.push(Object.freeze({
                '@type': 'NEW',
                '@id': id,
                '@rowId': null,
                values: Object.freeze({})
            }))
        },

        DELETE_ROW (state, { row }) {
            const index = state.rows.indexOf(row)
            if (index > -1) {
                state.rows.splice(index, 1)
            }
            if (row['@id'] in state.values) {
                Vue.delete(state.values, row['@id'])
            }
        }
    },

    getters: {
        rows: state => state.rows,
        findRow: state => id => {
            return state.rows.find(row => row['@id'] === id)
        },
        getRowValues: state => row => {
            return state.values[row['@id']] || {}
        }
    },

    state () {
        return {
            rows: [],
            values: {},
            autoIncrementId: 0
        }
    }
}

export default Vue.extend({
    name: 'WisolDataGroupModuleCreate',

    mixins: [
        CommonMixin
    ],

    inheritAttrs: false,

    setup () {
        const {
            getter,
            dispatch
        } = useStoreWrapper(getCurrentInstance().proxy.$store, StoreModule, 'create', {
            autoUnregister: false
        })

        return {
            getter,
            dispatch
        }
    },

    data () {
        return {
            rowsCount: 0
        }
    },

    computed: {
        middleware () {
            return {
                rows: (ctx, next) => {
                    let from = 0
                    let to = 0
                    const rowsCount = this.rowsCount
                    if (rowsCount > 0) {
                        const pagination = this.$parent.moduleMap.get('pagination')
                        if (pagination) {
                            const totalRowCount = this.$parent.totalRowCountWithoutMiddlewares
                            if (pagination.toPosition > totalRowCount) {
                                from = Math.max(pagination.fromPosition - totalRowCount, 0)
                                to = Math.min(from + pagination.toPosition - pagination.fromPosition, rowsCount)
                            }
                        } else {
                            to = rowsCount
                        }
                    }

                    if (to > from) {
                        ctx.data = [
                            ...ctx.data,
                            ...this.rows.slice(from, to)
                        ]
                    }

                    next()
                },

                rowValues: (ctx, next) => {
                    if (this.$parent.getRowType(ctx.options.row) === 'NEW') {
                        Object.assign(ctx.data, this.getter('getRowValues')(ctx.options.row))
                    }
                    next()
                },

                isDirty: (ctx, next) => {
                    if (this.dirtyRows.length > 0) {
                        ctx.data = true
                    } else {
                        next()
                    }
                },

                dirtyRowCount: (ctx, next) => {
                    ctx.data += this.dirtyRows.length
                    next()
                },

                totalRowCount: (ctx, next) => {
                    ctx.data += this.rowsCount
                    next()
                },

                saveAll: (ctx, next) => {
                    ctx.data.push(this.saveAll())
                    next()
                },

                canRowUpdate: (ctx, next) => {
                    if (this.$parent.getRowType(ctx.options.row) === 'NEW') {
                        ctx.data = true
                    }
                    next()
                },

                getDirtyFields: (ctx, next) => {
                    if (
                        this.$parent.getRowType(ctx.options.row) === 'NEW'
                    ) {
                        const values = this.$parent.removeVirtualFields(this.getter('getRowValues')(ctx.options.row))
                        const index = this.rows.indexOf(ctx.options.row)
                        if (index > -1) {
                            ctx.data = [
                                ...ctx.data,
                                Object.keys(values)
                            ]
                        }
                    }
                    next()
                },

                setRowValues: (ctx, next) => {
                    if (
                        this.$parent.getRowType(ctx.options.row) === 'NEW'
                    ) {
                        ctx.data = this.setRowValues(ctx.options.row, ctx.options.values)
                    } else {
                        next()
                    }
                }
            }
        },

        rows () {
            return this.getter('rows')
        },

        dirtyRows () {
            return this.rows.map(row => this.$parent.removeVirtualFields(this.getter('getRowValues')(row)))
        },

        updateScriptFunction () {
            if (!this.$parent.updateScriptFunction) {
                return null
            }

            return (id, values) => {
                const row = this.getter('findRow')(id)
                return this.$parent.updateScriptFunction(
                    'NEW',
                    row,
                    values
                )
            }
        }
    },

    watch: {
        rows: {
            handler () {
                // rowsCount gets updated with a watcher to avoid unnecessary dataGroup rows updates (instead of a computed value) (Vue 2 antifeature)
                this.rowsCount = this.rows.length
            },
            immediate: true
        }
    },

    methods: {
        /**
         * creates a new row
         * @return {Promise} the promise which resolves when the new row is created
         */
        async new () {
            if (this.$parent.readonly) {
                throw new Error('Datagroup is readonly')
            }

            const row = await this.dispatch('newRow', {
                values: this.$parent.fixedValues
            })

            if (this.$parent.initScriptFunction) {
                this.$parent.initScriptFunction('NEW', row)
            }

            return this.$parent.setActivePosition(this.$parent.totalRowCount - 1)
        },

        /**
         * saves all new rows
         * @return {Promise} the promise which resolves when all new are saved
         */
        async saveAll () {
            if (this.$parent.readonly) {
                throw new Error('Datagroup is readonly')
            }

            await this.$parent.startLoading()

            if (this.rowsCount > 0) {
                // prepare rows (remove @type)
                const rows = this.rows
                    .map(row => this.$parent.removeVirtualFields(this.getter('getRowValues')(row)))

                try {
                    const insertResult = await Promise.all(
                        rows.map(row => {
                            return this.$parent.dataProvider
                                .create(row)
                                .then(result => {
                                    return {
                                        status: 'success',
                                        result
                                    }
                                })
                                .catch(err => {
                                    return {
                                        status: 'error',
                                        result: err
                                    }
                                })
                        })
                    )
                    for (let index = insertResult.length - 1; index >= 0; index--) {
                        const { status, result } = insertResult[index]
                        if (status === 'error') {
                            this.handleError(result)
                        } else {
                            await this.deleteRow(this.rows[index])
                        }
                    }
                    await this.$parent.debouncedUpdateRows()
                } catch (err) {
                    this.handleError(err)
                }
            }

            await this.$parent.stopLoading()
        },

        /**
         * removes all new values
         * @return {Promise} the promise which resolves when all new values are removed
         */
        async removeAllValues () {
            return this.dispatch('removeAllValues')
        },

        /**
         * overwrites the values of the new row
         * @param  {Object}  row    the new row to overwrite
         * @param  {Object}  values the values to set
         * @return {Promise}        the promise which resolves when the values are overwritten
         */
        async setRowValues (row, values) {
            if (this.$parent.readonly) {
                throw new Error('Datagroup is readonly')
            }

            const id = row['@id']
            if (this.updateScriptFunction) {
                await this.updateScriptFunction(id, values)
            }

            await this.dispatch('setRowValues', { id, values })
        },

        async deleteRow (row) {
            if (this.$parent.readonly) {
                throw new Error('Datagroup is readonly')
            }

            const id = row['@id']
            if (id !== null) {
                return this.dispatch('deleteRow', { id })
            }
        }
    },

    render: h => h()
})
