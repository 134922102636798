<template>
    <div class="component-wisol-editor-toolbar">
        <generic-editor-toolbar>
            <div class="group">
                <div
                    :class="newButtonClass"
                    class="button"
                    title="New item"
                    @click="openNewSubMenu"
                >
                    <icon
                        name="fa/light/plus"
                        class="icon-button-new"
                    />
                    <generic-menu
                        v-if="showNewSubMenu"
                        :is-popup="true"
                        :items="newSubMenu"
                        placement="bottom-start"
                        @click="onMenuClick"
                    />
                </div>
                <div
                    :class="saveButtonClass"
                    class="button"
                    title="Save"
                    @click="doAction('save')"
                >
                    <icon
                        name="fa/light/save"
                        class="icon-button-save"
                    />
                </div>
                <div
                    :class="deleteButtonClass"
                    class="button"
                    title="Delete selected"
                    @click="doAction('delete')"
                >
                    <icon
                        name="fa/light/trash-alt"
                        class="icon-button-delete"
                    />
                </div>
            </div>
            <div class="group">
                <div
                    class="button"
                    title="Expand all"
                    @click="doAction('expand')"
                >
                    <icon
                        name="fa/light/expand-alt"
                        class="icon-toggle-expand"
                    />
                </div>
                <div
                    class="button"
                    title="Collapse all"
                    @click="doAction('collapse')"
                >
                    <icon
                        name="fa/light/compress-alt"
                        class="icon-toggle-compress"
                    />
                </div>
                <div
                    :class="{ active: showHidden }"
                    class="button toggle"
                    title="Show/Hide hidden"
                    @click="updateSetting('showHidden', !showHidden)"
                >
                    <icon
                        name="fa/light/eye-slash"
                        class="icon-toggle-hidden"
                    />
                </div>
            </div>
            <div class="group">
                <div
                    :class="copyButtonClass"
                    class="button"
                    title="Copy"
                    @click="doAction('copy')"
                >
                    <icon
                        name="fa/light/copy"
                        class="icon-button-copy"
                    />
                </div>
                <div
                    :class="cutButtonClass"
                    class="button"
                    title="Cut"
                    @click="doAction('cut')"
                >
                    <icon
                        name="fa/light/cut"
                        class="icon-button-cut"
                    />
                </div>
                <div
                    :class="pasteButtonClass"
                    class="button"
                    title="Paste"
                    @click="doAction('paste')"
                >
                    <icon
                        name="fa/light/paste"
                        class="icon-button-paste"
                    />
                </div>
            </div>
            <div class="group">
                <div
                    :class="exportButtonClass"
                    class="button"
                    title="Export Menu"
                    @click="doAction('export')"
                >
                    <icon
                        name="fa/light/download"
                        class="icon-button-export"
                    />
                </div>
                <div
                    :class="importButtonClass"
                    class="button"
                    title="Import Menu"
                    @click="doAction('import')"
                >
                    <icon
                        name="fa/light/upload"
                        class="icon-button-import"
                    />
                </div>
            </div>
        </generic-editor-toolbar>
    </div>
</template>

<style lang="scss">
.component-wisol-editor-toolbar {
}
</style>

<script>
import Vue from 'vue'
import GenericEditorToolbar from '../generic/Toolbar'
import Icon from '@wisol/libs-icons'

const subMenuData = new Vue({
    inheritAttrs: false,

    data () {
        return {
            component: null
        }
    }
})

document.addEventListener('click', (evt) => {
    if (subMenuData.component) {
        if (!subMenuData.component.$el.contains(evt.target)) {
            subMenuData.component = null
        }
    }
}, true)

export default {
    name: 'WisolEditorMenuToolbar',

    components: {
        genericEditorToolbar: GenericEditorToolbar,
        Icon
    },

    props: {
        showBoxes: {
            type: Boolean,
            default: true
        },
        showHidden: {
            type: Boolean,
            default: true
        },
        enableDragging: {
            type: Boolean,
            default: true
        },
        selectedItem: {
            type: Object,
            default: () => { }
        },
        isDirty: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            // showNewSubmenu: false,
            newMenuItems: [
                {
                    '@id': 'new_menu',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/folder',
                    label: 'Add menu item',
                    visible: true
                },
                {
                    '@id': 'new_module',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/cube',
                    label: 'Add module',
                    visible: true
                },
                {
                    '@id': 'new_box',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/window-maximize',
                    label: 'Add box',
                    visible: true
                },
                {
                    '@id': 'new_field',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/tag',
                    label: 'Add field',
                    visible: true
                },
                {
                    '@id': 'new_dataGroup',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/object-group',
                    label: 'Add dataGroup',
                    visible: true
                },
                {
                    '@id': 'new_widget',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/list-alt',
                    label: 'Add widget',
                    visible: true
                },
                {
                    '@id': 'new_boxAction',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/caret-square-right',
                    label: 'Add Action',
                    visible: true
                },
                {
                    '@id': 'new_dataGroupAction',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/caret-square-right',
                    label: 'Add Action',
                    visible: true
                },
                {
                    '@id': 'new_dataGroupLink',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/share-alt',
                    label: 'Add Link',
                    visible: true
                }
            ]
        }
    },

    computed: {
        showNewSubMenu () {
            return this === subMenuData.component
        },

        newSubMenu () {
            return this.newMenuItems.map((item) => {
                return {
                    ...item,
                    visible: this.isNewSubMenuItemVisible(item)
                }
            })
        },

        newButtonClass () {
            return {
                active: this.showNewSubMenu,
                disabled: this.isButtonDisabled('new') || this.selectedItem.isCut()
            }
        },

        deleteButtonClass () {
            return {
                disabled: this.isButtonDisabled('delete') || this.selectedItem.isCut()
            }
        },

        saveButtonClass () {
            return {
                disabled: this.isButtonDisabled('save')
            }
        },

        exportButtonClass () {
            return {
                disabled: this.isButtonDisabled('export') || this.selectedItem.isCut()
            }
        },

        importButtonClass () {
            return {
                disabled: this.isButtonDisabled('import') || this.selectedItem.isCut()
            }
        },

        copyButtonClass () {
            return {
                disabled: this.isButtonDisabled('copy') || this.selectedItem.isCut()
            }
        },

        cutButtonClass () {
            return {
                disabled: this.isButtonDisabled('cut') || this.selectedItem.isCut()
            }
        },

        pasteButtonClass () {
            return {
                disabled: this.isButtonDisabled('paste') || this.selectedItem.isCut()
            }
        },

        hasGrid () {
            if (this.selectedItem.children()) {
                return this.selectedItem.children().find((child) => {
                    return child['@itemtype'] === 'module'
                }) !== undefined
            } else {
                return false
            }
        },

        isPasteAllowed () {
            const clipboard = this.$parent.clipboard
            if (Object.keys(clipboard).length > 0) {
                switch (this.selectedItem['@itemtype']) {
                    case 'menu':
                        return ('menu' in clipboard)
                    case 'module':
                        return !('menu' in clipboard) &&
                            !('module' in clipboard) &&
                            ('dataGroup' in clipboard)
                    case 'dataGroup':
                        return !('menu' in clipboard) &&
                            !('module' in clipboard) &&
                            !('dataGroup' in clipboard) &&
                            ('dataGroupAction' in clipboard ||
                                'box' in clipboard ||
                                'field' in clipboard)
                    case 'box':
                        return !('menu' in clipboard) &&
                            !('module' in clipboard) &&
                            !('dataGroup' in clipboard) &&
                            !('box' in clipboard) &&
                            ('boxAction' in clipboard ||
                                'widget' in clipboard)
                    case 'widget':
                    case 'dataGroupAction':
                    case 'field':
                    case 'boxAction':
                        return false
                }
            }
            return false
        }
    },

    destroyed () {
        if (subMenuData.component === this) {
            subMenuData.component = null
        }
    },

    methods: {
        openNewSubMenu () {
            if (!this.isButtonDisabled('new')) {
                subMenuData.component = this
            }
        },

        doAction (action) {
            if (!this.isButtonDisabled(action)) {
                if (action !== 'delete') {
                    this.$emit('action', action)
                } else if (this.selectedItem['@id'] !== null) {
                    this.$emit('action', action)
                }
            }
        },

        updateSetting (key, value) {
            this.$emit('update', key, value)
        },

        isNewSubMenuItemVisible (item) {
            switch (item['@id']) {
                case 'new_menu':
                    return this.selectedItem['@itemtype'] === 'menu' ||
                        this.selectedItem['@id'] === null
                case 'new_module':
                    return this.selectedItem['@itemtype'] === 'menu' &&
                        this.selectedItem['@id'] !== null &&
                        !this.hasGrid
                case 'new_dataGroup':
                    return this.selectedItem['@itemtype'] === 'module'
                case 'new_box':
                case 'new_field':
                case 'new_dataGroupAction':
                case 'new_dataGroupLink':
                    return this.selectedItem['@itemtype'] === 'dataGroup'
                case 'new_widget':
                case 'new_boxAction':
                    return this.selectedItem['@itemtype'] === 'box'
            }
        },

        isButtonDisabled (item) {
            switch (item) {
                case 'new':
                    return this.selectedItem['@idType'] === 'virtual' ||
                        this.selectedItem['@itemtype'] === 'dataGroupLink' ||
                        this.selectedItem['@itemtype'] === 'dataGroupAction' ||
                        this.selectedItem['@itemtype'] === 'boxAction' ||
                        this.selectedItem['@itemtype'] === 'field' ||
                        this.selectedItem['@itemtype'] === 'widget'
                case 'save':
                    return !this.isDirty
                case 'delete':
                    return !this.selectedItem['@id']
                case 'copy':
                case 'cut':
                    return !this.selectedItem['@id'] || this.selectedItem['@id'] === null
                case 'export':
                    return !this.selectedItem['@id'] || this.selectedItem['@itemtype'] !== 'menu'
                case 'import':
                    return this.selectedItem['@itemtype'] !== 'menu'
                case 'paste':
                    return !this.isPasteAllowed
            }
        },

        onMenuClick (event) {
            this.doAction(event['@id'])
            this.closeSubMenus()
        },

        closeSubMenus () {
            subMenuData.component = null
        }
    }
}
</script>
