<template>
    <div :class="$style.container">
        <div
            v-if="label"
            :class="$style.labelContainer"
        >
            <span :class="$style.label">{{ label }}</span>
        </div>
        <component
            :is="wisolInputInstance.inputComponent"
            :class="$style.input"
            v-bind="wisolInputInstance.inputProps"
            :value="resultPromise"
            :readonly="true"
            v-on="wisolInputInstance.inputListeners"
        />
    </div>
</template>

<style lang="scss" module>
@import "../../../../../../../libs/inputs/src/style/variables/input";

.container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.labelContainer {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    overflow: hidden;
}

.label {
    padding: $input-padding;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.input {
    flex: 1 1 auto;
    width: auto;
}
</style>

<script>
import Vue from 'vue'
import NumberValidator from '@wisol/libs-inputs/src/validator/type/Number.js'

const validateNumber = NumberValidator()

export default {
    inheritAttrs: false,

    props: {
        dataGroup: {
            type: Vue,
            required: true
        },

        column: {
            type: Object,
            required: true
        },

        type: {
            type: String,
            required: true,
            validator: value => ['sum', 'min', 'max', 'average', 'median'].includes(value)
        },

        label: {
            type: String,
            default: null
        },

        precision: {
            type: Number,
            default: 5
        }
    },

    data () {
        return {
            resultPromise: Promise.resolve(null)
        }
    },

    computed: {
        rawValues () {
            return this.dataGroup.rows
                .map(({ values }) => {
                    if (!(this.column.field in values)) {
                        return null
                    }
                    return values[this.column.field]
                })
                .filter(value => value !== null)
        },

        inputData () {
            return this.column.input()
        },

        wisolInputClass () {
            return Vue.extend(this.inputData.component)
        },

        wisolInputInstance () {
            // eslint-disable-next-line new-cap
            return new this.wisolInputClass({
                propsData: this.inputData.props,
                parent: this
            })
        },

        handleResult () {
            const capitalizedType = this.type.charAt(0).toUpperCase() + this.type.slice(1).toLowerCase()
            return this['handle' + capitalizedType]
        }
    },

    watch: {
        wisolInputInstance (newVm, oldVm) {
            oldVm.$destroy()
        },

        rawValues: {
            handler () {
                this.updateResult()
            },
            immediate: true
        },

        type: {
            handler () {
                this.updateResult()
            },
            immediate: true
        }
    },

    destroyed () {
        if (this.wisolInputInstance) {
            this.wisolInputInstance.$destroy()
        }
    },

    methods: {
        applyPrecision (value) {
            const { precision } = this
            const multiplier = Math.pow(10, precision)
            return Math.round(value * multiplier) / multiplier
        },

        updateResult () {
            this.resultPromise = Promise.all(
                this.rawValues.map(value => this.wisolInputInstance.parseValue(value))
            )
                .then(values => {
                    if (values.length === 0) {
                        return null
                    }
                    values.forEach(value => {
                        if (value !== null) {
                            validateNumber(value)
                        }
                    })
                    return this.handleResult(values)
                })
        },

        handleSum (values) {
            let sum = 0
            values.forEach(value => {
                sum += value
            })
            return this.applyPrecision(sum)
        },

        handleMin (values) {
            let min = values[0]
            values.forEach(value => {
                min = Math.min(value, min)
            })
            return min
        },

        handleMax (values) {
            let max = values[0]
            values.forEach(value => {
                max = Math.max(value, max)
            })
            return max
        },

        handleAverage (values) {
            const sum = this.handleSum(values)
            return sum / values.length
        },

        handleMedian (values) {
            if (values.length === 1) {
                return values[0]
            }
            const sortedValues = values.slice(0).sort()
            const middleIndex = Math.ceil(sortedValues.length / 2) - 1
            if (sortedValues.length % 2 === 0) {
                return (sortedValues[middleIndex] + sortedValues[middleIndex + 1]) / 2
            }
            return sortedValues[middleIndex]
        }
    }
}
</script>
