<template>
    <div
        :style="mainStyle"
        :class="mainClasses"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
        @click.stop.prevent="onClick"
    >
        <div class="item-container">
            <slot>
                <icon
                    v-if="icon"
                    :name="icon"
                    class="icon"
                />
                <span class="label">{{ label }}</span>
            </slot>
            <div
                v-if="hasSubmenu"
                class="submenu-arrow"
            >
                <icon
                    :name="arrowIconName"
                    class="icon"
                />
            </div>
        </div>
        <slot
            v-if="hasSubmenu && showSubmenu"
            name="submenu"
        />
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-generic-menu-item {
        display: block;
        padding: 0px;
        margin: 0px;
        white-space: nowrap;

        &> .item-container {
            font-size: 1rem;
            padding: 0.85rem 1.50rem 0.85rem 1.25rem;
            color: nth($color-palette-grey, 5);
            position: relative;
            cursor: pointer;
            user-select: none;

            &> .label {
                text-transform: uppercase;
            }

            &> .submenu-arrow {
                height: 100%;
                position: absolute;
                top: 0px;
                right: 4px;
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    height: 0.8em;
                    width: 0.8em;
                }
            }

            &> .icon {
                text-align: center;
                height: 1.2rem;
                width: 1.2rem;
                display: inline-block;
                vertical-align: middle;
                margin-right: 0.5em;
            }
        }

        &:not(.disabled) > .item-container:hover {
            color: inherit;
        }

        &.disabled > .item-container {
            color: nth($color-palette-grey, 2);
            cursor: default;

            &> .submenu-arrow {
                color: nth($color-palette-grey, 5);
            }
        }
    }
</style>

<script>
import { optimalTextContrastColor } from '@/utils/color'
import Icon from '@wisol/libs-icons'

export default {
    name: 'GenericMenuItem',

    components: {
        Icon
    },

    inheritAttrs: false,

    props: {
        id: {
            required: true,
            type: [Number, String]
        },
        label: {
            required: false,
            type: String,
            default: ''
        },
        icon: {
            required: false,
            type: String,
            default: ''
        },
        color: {
            required: false,
            type: String,
            default: '#000000'
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        hasSubmenu: {
            type: Boolean,
            default: true
        },
        submenuPlacement: {
            type: String,
            default: 'right-start'
        }
    },

    data () {
        return {
            showSubmenu: false
        }
    },

    computed: {
        mainStyle () {
            const color = this.color || '#FFFFFF'
            // 4.5 contrast is recommended (using 2 to not darken the color to much)
            return optimalTextContrastColor(color, '#FFFFFF', 2)
        },
        mainClasses () {
            return {
                'component-generic-menu-item': true,
                disabled: this.disabled
            }
        },
        iconClasses () {
            return [
                'icon',
                this.icon
            ]
        },
        arrowIconName () {
            const [direction] = this.submenuPlacement.split('-', 1)
            switch (direction) {
                case 'left':
                    return 'wisol/left'

                case 'bottom':
                    return 'wisol/down'

                case 'top':
                    return 'wisol/up'

                case 'right':
                default:
                    return 'wisol/right'
            }
        }
    },

    methods: {
        onClick () {
            if (!this.disabled) {
                this.$emit('click')
            }
        },
        onMouseEnter () {
            this.showSubmenu = true
        },
        onMouseLeave () {
            this.showSubmenu = false
        }
    }
}
</script>
