import { register as registerIcons } from '@wisol/libs-icons'

let promise
export default function loadIcons () {
    if (!promise) {
        promise = import('@wisol/libs-icons/src/icons' /* webpackChunkName: 'icons' */)
            .then(result => {
                registerIcons(result.default)
                return Object.keys(result.default)
            })
    }
    return promise
}
