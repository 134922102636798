<script>
import { Input as WisolInputs } from '@wisol/libs-inputs'
import { io } from 'socket.io-client'

export default WisolInputs.Number.extend({
    props: {
        deviceManager: {
            type: String,
            default: null
        },

        scaleName: {
            type: String,
            default: 'SCALE'
        },

        allowUnstable: {
            type: Boolean,
            default: false
        }
    },

    created () {
        const deviceManager = this.deviceManager || this.$store.getters['ui/config/get']('wisol.deviceManager')
        if (deviceManager) {
            this.socket = io(`${deviceManager}/scale`)
            this.socket.on('update', this.handleScaleUpdate)
            this.socket.on('disconnect', () => this.controller.setValue(null))
        }
    },

    beforeDestroy () {
        this.socket?.off()
        this.socket?.off()
    },

    methods: {
        handleScaleUpdate (scaleData) {
            if (scaleData.name !== this.scaleName) {
                return
            }

            const weight = parseFloat(scaleData.weight)
            const stable = scaleData.stable

            this.controller.setValue((this.allowUnstable || stable) ? weight : null)
        }
    }
})
</script>
