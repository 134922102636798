export default () => value => {
    if (
        typeof value !== 'object' ||
        typeof value.alpha !== 'function' ||
        typeof value.name !== 'function' ||
        typeof value.hex !== 'function'
    ) {
        throw new Error('value needs to be a chroma color object')
    }
}
