import createInput from '@wisol/libs-inputs/src/components/createInput.js'
import UiOle from './Ui.vue'
import Parser from '@wisol/libs-inputs/src/parser/Json.js'
import TypeOleValidator from './Validator.js'

export default createInput(UiOle, {
    Parser,
    defaultParserOptions: () => ({
        beautify: true
    }),
    validators: () => [TypeOleValidator()]
})
