<template>
    <div class="component-generic-loading">
        <slot>loading ...</slot>
    </div>
</template>

<style>
    .component-generic-loading {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 9999;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>

<script>
export default {
    name: 'GenericLoading',

    inheritAttrs: false
}
</script>
