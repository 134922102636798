<script>
import Type2d from './2d'
import isString from 'lodash-es/isString'

export default {
    name: 'WisolWidgetChartTypeYList',

    extends: Type2d,

    inheritAttrs: false,

    methods: {
        getSeriesDataValue (row, field) {
            if (isString(field)) {
                field = [field]
            }
            return {
                x: row[this.category],
                y: field.map(field => parseFloat(row[field] || 0))
            }
        }
    }
}
</script>
