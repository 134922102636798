<template>
    <selectbox
        v-slot="{ item }"
        v-bind="selectboxProps"
        v-on="$listeners"
    >
        <icon
            :class="{[$style.icon]: true, [$style.selected]: item.selected}"
            :name="item.value"
        />
        {{ item.value }}
    </selectbox>
</template>

<style lang="scss" module>
    .icon {
        height: 1em;
        width: 1em;
        max-width: 50%;
        max-height: 50%;
        margin-right: 10px;
    }

    .selected {
        height: 3em;
        width: 3em;
    }
</style>

<script>
import {
    Input as WisolInputs
} from '@wisol/libs-inputs'
import { model } from '@wisol/libs-inputs/src/components/createInput.js'
import Icon from '@wisol/libs-icons'
import loadIcons from '@/utils/loadIcons.js'

const { Selectbox } = WisolInputs

export default {
    name: 'WisolInputIcon',

    components: {
        Selectbox,
        Icon
    },

    inheritAttrs: false,

    model,

    data () {
        return {
            icons: []
        }
    },

    computed: {
        options () {
            const options = {}
            this.icons.forEach(name => {
                options[name] = name
            })
            return options
        },

        selectboxProps () {
            return {
                ...this.$attrs,
                options: this.options
            }
        }
    },

    created () {
        loadIcons()
            .then(icons => {
                this.icons = icons
            })
    }
}
</script>
