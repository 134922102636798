<template>
    <div :class="$style.container">
        <node-list
            :row-map="rowMap"
            :tree-id-key="treeIdColumn"
            :tree-mode-key="treeModeColumn"
            :rows="rootRows"
            :data-group="dataGroup"
            :render-template="renderTemplate"
            :is-expanded="isExpanded"
            @expand="expand"
            @collapse="collapse"
        />
    </div>
</template>

<style module>
.container > * {
    margin: 0 auto;
    min-width: max-content;
    padding: 1em;
}
</style>

<script>
import Vue from 'vue'
import NodeList from './NodeList.vue'
import handlebars from 'handlebars'
import ActionProviderMixin from '@/mixins/ActionProvider'
import createPrintHtml from '@/utils/createPrintHtml.js'
import generatePdf from '@/utils/generatePdf.js'

export default {
    name: 'WisolWidgetTree',

    components: {
        NodeList
    },

    mixins: [
        ActionProviderMixin()
    ],

    inheritAttrs: false,

    props: {
        id: {
            type: Number,
            required: true
        },

        dataGroup: {
            type: Vue,
            required: true
        },

        treeIdColumn: {
            type: String,
            default: '@id'
        },

        treeParentColumn: {
            type: String,
            default: '@parent'
        },

        treeModeColumn: {
            type: String,
            default: '@mode'
        },

        template: {
            type: String,
            required: true
        }
    },

    data: () => ({
        expandedRows: []
    }),

    computed: {
        meta () {
            return this.$store.getters['ui/widget/byId'](this.id)
        },

        rows () {
            return this.dataGroup.rows
        },

        rootRows () {
            const { rows } = this.rowMap.get(null) || {}
            return rows || []
        },

        rowMap () {
            const treeIdKey = this.treeIdColumn
            const treeParentKey = this.treeParentColumn

            const map = new Map()
            const treeIdMap = new Map()
            const rootSet = new Set()
            rootSet.add(null)

            const rootRows = []
            map.set(null, {
                level: 0,
                rows: rootRows
            })

            this.rows.forEach(row => {
                const rowValues = this.dataGroup.getRowValues(row)

                const treeId = rowValues[treeIdKey]
                const treeParent = treeParentKey in rowValues ? rowValues[treeParentKey] : null

                treeIdMap.set(treeId, rowValues)

                let { rows: rowsByParent } = map.get(treeParent) || {}
                if (!rowsByParent) {
                    rowsByParent = []
                    map.set(treeParent, {
                        level: null,
                        rows: rowsByParent
                    })
                }
                rowsByParent.push(row)
            })

            // fix childs where there is no parent
            map.forEach(({ rows }, parent) => {
                if (parent !== null && !treeIdMap.has(parent)) {
                    rootRows.push(...rows)
                    map.delete(parent)
                    rootSet.add(parent)
                }
            })

            // update level
            map.forEach((data, parent) => {
                let current = parent
                let level = 0
                while (!rootSet.has(current) && treeIdMap.has(current)) {
                    level++
                    current = treeIdMap.get(current)[treeParentKey]
                    const parentData = map.get(current)
                    if (parentData && parentData.level !== null) {
                        level += parentData.level
                        break
                    }
                }
                data.level = level
            })

            return map
        },

        renderTemplate () {
            return handlebars.compile(this.template)
        },

        actions () {
            return [
                {
                    icon: 'fa/light/expand-arrows-alt',
                    title: 'Expand all',
                    disabled: false,
                    callback: _ => {
                        this.expandAll()
                    }
                },
                {
                    icon: 'fa/light/compress-arrows-alt',
                    title: 'Collapse all',
                    disabled: false,
                    callback: _ => {
                        this.collapseAll()
                    }
                },
                {
                    icon: 'fa/light/file-pdf',
                    title: 'Generate PDF',
                    disabled: false,
                    callback: _ => {
                        const printHtml = createPrintHtml(this.$el)
                        return generatePdf(this, printHtml, 'text/html', 'organigramme')
                    }
                }
            ]
        }
    },

    watch: {
        rows () {
            this.collapseAll()
        }
    },

    methods: {
        isExpanded (row) {
            return this.expandedRows.includes(row)
        },

        expand (row) {
            if (!this.isExpanded(row)) {
                this.expandedRows.push(row)
            }
        },

        collapse (row) {
            const index = this.expandedRows.indexOf(row)
            if (index > -1) {
                this.expandedRows.splice(index, 1)
            }
        },

        expandAll () {
            this.expandedRows = this.rows.slice(0)
        },

        collapseAll () {
            this.expandedRows = []
        }
    }
}
</script>
