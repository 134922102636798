<template>
    <!-- eslint-disable vue/no-v-html -->
    <div
        v-if="$parent.parent['@id'] !== 'root'"
        :style="mainStyle"
        class="component-wisol-editor-menu-tree-label"
    >
        <span
            :class="{ clipboard: $parent.cut }"
            class="label"
            @click.right.prevent="openContextMenu($parent.parent)"
            v-html="$parent.label"
        />
        <icon
            v-if="$parent.dirty || $parent.idType === 'virtual'"
            name="fa/solid/circle"
            class="icon-dirty"
        />
        <generic-menu
            v-if="showContextMenu"
            :is-popup="true"
            :items="contextMenuItems"
            placement="bottom-start"
            @click="onMenuClick"
        />
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-menu-tree-label {
        .label {
            &.clipboard {
                color: nth($color-palette-grey, 4);
            }
        }
    }
</style>

<script>
import Vue from 'vue'
import { optimalTextContrastColor } from '@/utils/color'
import Icon from '@wisol/libs-icons'

const contextMenuData = new Vue({
    data () {
        return {
            component: null
        }
    }
})

document.addEventListener('click', (evt) => {
    if (contextMenuData.component) {
        if (!contextMenuData.component.$el.contains(evt.target)) {
            contextMenuData.component = null
        }
    }
}, true)

export default {
    components: {
        Icon
    },

    inheritAttrs: false,

    data () {
        return {
            showMenu: false,
            contextMenu: [],
            menuItems: [
                {
                    '@id': 'copy',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/copy',
                    label: 'Copy',
                    visible: true
                },
                {
                    '@id': 'cut',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/cut',
                    label: 'Cut',
                    visible: true
                },
                {
                    '@id': 'paste',
                    '@parent': null,
                    color: '#FFFFFF',
                    disabled: false,
                    icon: 'fa/light/paste',
                    label: 'Paste',
                    visible: true
                }
            ]
        }
    },

    computed: {
        mainStyle () {
            const color = this.$parent.parent.color || '#000000'
            return optimalTextContrastColor(color, '#FFFFFF', 2)
        },

        showContextMenu () {
            const parentItemtype = this.$parent.parent['@itemtype']
            return this === contextMenuData.component &&
                // this.$parent.parent['@id'] !== null &&
                parentItemtype !== 'box_group' &&
                parentItemtype !== 'field_group' &&
                parentItemtype !== 'boxAction_group' &&
                parentItemtype !== 'dataGroupAction_group'
        },

        contextMenuItems () {
            return this.menuItems.map((item) => {
                return {
                    ...item,
                    visible: this.isContextMenuItemVisible(item)
                }
            })
        }
    },

    destroyed () {
        if (contextMenuData.component === this) {
            contextMenuData.component = null
        }
    },

    methods: {
        onMenuClick (event) {
            this.$emit('action', event['@id'])
        },

        openContextMenu (parent) {
            if (!this.$parent.cut) {
                // only show context menu for this component
                this.$parent.select(parent)
                contextMenuData.component = this
                this.contextMenu = []
            }
        },

        isContextMenuItemVisible (item) {
            const parentItemtype = this.$parent.parent['@itemtype']
            switch (item['@id']) {
                case 'copy':
                    return parentItemtype === 'menu'
                case 'cut':
                    return parentItemtype === 'menu'
                case 'paste':
                    if (Object.keys(this.$parent.clipboard).length > 0 &&
                        !this.$parent.cut) {
                        switch (parentItemtype) {
                            case 'menu':
                                return true
                        }
                    } else {
                        return false
                    }
            }
        }
    }
}

</script>
