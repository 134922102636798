export default {
    ENABLE_LOADING (state) {
        state.loading = true
    },

    DISABLE_LOADING (state) {
        state.loading = false
    },

    IS_LOADED (state) {
        state.isLoaded = true
    }
}
