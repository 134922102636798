<template>
    <nav
        class="component-wisol-navbar"
        role="navigation"
    >
        <div class="wisol-gradient" />
        <div class="wisol-navbar">
            <a
                class="wisol-logo"
                href="/"
            >
                <img
                    alt="Wiges Solutions"
                    src="../../assets/logo-white.svg"
                >
            </a>
            <wisol-session-widget
                v-if="isAuthenticated"
                class="session-widget"
            />
        </div>
    </nav>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-navbar {
        color: #FFFFFF;

        &> .wisol-gradient {
            height: 5px;
            background: $wisol-gradient;
        }

        &> .wisol-navbar {
            background-color: #000;
            height: 50px;
            padding: 0 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
        }

        .wisol-logo {
            padding: 5px 0;
            img {
                height: 35px;
            }
        }

        .session-widget {
            height: 100%;
        }
    }
</style>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'WisolNavbar',

    inheritAttrs: false,

    computed: {
        ...mapGetters('session', [
            'isAuthenticated'
        ])
    }
}
</script>
