<template>
    <dnd-grid-box
        :box-id="boxId"
        drag-selector="label"
    >
        <div :class="$style.inputBox">
            <label :style="labelStyle">{{ label }}</label>
            <component
                :is="inputComponent"
                :value="value"
                v-bind="inputProps"
                :class="$style.inputWidget"
                @update:value="onValueUpdate"
                @click="onInputClick"
            />
        </div>
    </dnd-grid-box>
</template>

<style lang="scss" module>
    .inputBox {
        display: flex;
        height: 100%;

        &:hover {
            background-color: #EEEEEE;
        }

        &> label {
            line-height: 25px;
            margin: 0 5px 0 0;
            padding: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: right;
        }
    }

    .inputWidget {
        flex: 1 1 auto;
        height: 100%;
        width: 100%;
    }
</style>

<script>
export default {
    name: 'InputBox',

    inheritAttrs: false,

    props: {
        boxId: {
            type: [Number, String],
            required: true
        },

        field: {
            type: Object,
            required: true
        },

        value: {
            type: null,
            required: true
        }
    },

    data () {
        return {
            localComponentCopy: null
        }
    },

    computed: {
        label () {
            return this.field.label
        },

        labelStyle () {
            const boxLayout = this.$parent.layoutMap.get(this.field.id)
            const labelGridSize = boxLayout && boxLayout.labelSize
                ? boxLayout.labelSize
                : 2
            return {
                flex: '0 0 ' + (this.$parent.cellSize.w * labelGridSize) + 'px'
            }
        },

        inputComponent () {
            return this.field.input.component
        },

        inputProps () {
            return this.field.input.props
        }
    },

    watch: {
        component () {
            if (this.component !== this.localComponentCopy) {
                this.localComponentCopy = this.component
            }
        }
    },

    methods: {
        onValueUpdate (valueData) {
            this.$emit('update:value', valueData)
        },

        onInputClick (evt) {
            this.$emit('click', evt)
        }
    }
}
</script>
