<script>
import CommonList from '../Common/List'

export default {
    name: 'OptionsPicker',

    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'update'
    },

    props: {
        options: {
            type: Object,
            default () {
                return {}
            }
        },

        value: {
            type: null,
            default: null
        }
    },

    methods: {
        onUpdateList (key) {
            this.$emit('update', key)
        }
    },

    render (h) {
        const scopedSlots = {}
        for (const name in this.$scopedSlots) {
            scopedSlots[name] = props => this.$scopedSlots[name](props)
        }

        return h(CommonList, {
            props: {
                items: this.options,
                value: this.value
            },

            on: {
                update: this.onUpdateList
            },

            scopedSlots
        })
    }
}
</script>
