export default () => value => {
    if (typeof value !== 'object') {
        throw new Error('value needs to be an ole data object')
    }
    if (value?.data) {
        if (
            typeof value.data !== 'string' ||
            typeof value.type !== 'string' ||
            typeof value.name !== 'string'
        ) {
            throw new Error('value needs to be an ole data object')
        }
    } else {
        if (
            typeof value.table !== 'string' ||
            typeof value.id !== 'string'
        ) {
            throw new Error('value needs to be an ole data object')
        }
    }
}
