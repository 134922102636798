import createInput from '@wisol/libs-inputs/src/components/createInput.js'
import UiFilter from './Ui.vue'
import Parser from '@wisol/libs-inputs/src/parser/Json.js'
import TypeObjectValidator from '@wisol/libs-inputs/src/validator/type/Object.js'

export default createInput(UiFilter, {
    Parser,
    defaultParserOptions: () => ({
        beautify: true
    }),
    validators: () => [TypeObjectValidator(false)]
})
