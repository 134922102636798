export default () => {
    return {
        async parse (raw) {
            if (raw === '1') {
                return true
            }
            if (raw === '0') {
                return false
            }
            throw new Error(`"${raw}" is not a valid boolean ("0", "1")`)
        },

        serialize (value) {
            if (value === true) {
                return '1'
            }
            if (value === false) {
                return '0'
            }
            throw new Error(`"${value}" is not a valid boolean`)
        }
    }
}
