<template>
    <div
        class="component-wisol-editor-menu-form-dataGroupLink"
    >
        <generic-form
            :fields="fields"
            :values="values"
            :layout="layout"
            :column-count="24"
            :cell-height="25"
            :min-cell-width="40"
            :max-cell-width="Infinity"
            :readonly="false"
            @update:value="onUpdateFormValue"
            @update:layout="onLayoutUpdate"
        />
        <generic-loading v-show="isLoading" />
    </div>
</template>

<style lang="scss">
    .component-wisol-editor-menu-form-dataGroupLink {
        padding: 20px 20px 0px 20px;
    }

</style>

<script>
import { Input as Inputs } from '@wisol/libs-inputs'
import layout from './layouts/datagrouplink.json'
import Relation from '../../../Input/Relation'

const LOCALSTORAGE_KEY = 'layout_datagrouplink'

export default {
    name: 'WisolEditorMenuFormDatagrouplink',

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            default: () => {}
        },

        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            layout,
            labels: {
                '@id': 'ID',
                '@source': 'Source ID',
                '@target': 'Target',
                options: 'Options',
                type: 'Type'
            }
        }
    },

    computed: {
        fields () {
            const fields = Object.keys(this.row).map((field) => {
                let component = Inputs.Text
                const props = {}
                switch (field) {
                    case '@id':
                        component = Inputs.Number
                        props.readonly = true
                        break
                    case '@source':
                        component = Inputs.Selectbox
                        props.readonly = true
                        props.options = this.dataGroupsList
                        break
                    case '@target':
                        component = Inputs.Selectbox
                        props.required = true
                        props.options = this.dataGroupsList
                        break
                    case 'type':
                        component = Inputs.Selectbox
                        props.required = true
                        props.options = {
                            filter: 'Filter',
                            relation: 'Relation'
                        }
                        break
                    case 'options':
                        component = Inputs.Object
                        break
                }

                return {
                    id: field,
                    field: field,
                    label: this.labels[field],
                    input: {
                        component,
                        props
                    }
                }
            })

            fields.push({
                id: '@relation',
                field: '@relation',
                label: 'Relation',
                input: {
                    component: Relation
                }
            })

            return fields
        },

        values () {
            return {
                ...this.row,
                '@source': this.row['@source']
                    ? this.row['@source'].toString()
                    : null,
                '@target': this.row['@target']
                    ? this.row['@target'].toString()
                    : null,
                '@relation': this.row?.options?.mapping
                    ? this.row.options.mapping
                    : null
            }
        },

        piblings () {
            // get all sibling dataGroups of parent dataGroup
            // parent siblings = piblings
            return this.row.parent().parent().children()
        },

        dataGroupsList () {
            const options = {}
            this.piblings.forEach((pibling) => {
                options[pibling['@id']] = pibling['@label'] + ' (' + pibling['@id'] + ')'
            })
            return options
        },

        parentDatagroup () {
            return this.row.parent()
        }
    },

    mounted () {
        let local = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || []

        local = local.reduce((arr, l) => {
            const foundLayout = arr.find((item) => item.id === l.id)

            if (foundLayout) {
                foundLayout.position = l.position
            } else {
                arr.push(l)
            }

            return arr
        }, layout)

        this.layout = local
    },

    methods: {
        onLayoutUpdate (layout) {
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(layout))
            this.layout = layout
        },

        onUpdateFormValue ({ field, value }) {
            switch (field) {
                case '@target':
                    value = value
                        ? parseInt(value)
                        : null
                    break
                case '@relation':
                    value = {
                        ...this.row.options,
                        mapping: value
                    }
                    field = 'options'
                    break
            }
            this.$emit('updateRow', this.validateRow({
                ...this.row,
                [field]: value
            }))
        },

        validateRow (row) {
            return {
                ...row,
                '@valid': !!row.type
            }
        }
    }
}

</script>
