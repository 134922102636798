export default {
    setActivePosition ({ commit }, { position = 0 }) {
        commit('SET_ACTIVE_POSITION', { position })
    },

    setFilterValues ({ commit }, { values }) {
        commit('SET_FILTER_VALUES', { values })
    },

    resetFilter ({ commit }) {
        commit('RESET_FILTER')
    },

    setSorting ({ commit }, { sorting }) {
        commit('UPDATE_SORTING', { sorting })
    },

    resetSorting ({ commit }) {
        commit('RESET_SORTING')
    }
}
