<template>
    <div class="component-wisol-reconnect">
        <div :class="cardClasses">
            <div class="wisol-gradient" />
            <div class="card-body">
                <div class="logo-container">
                    <img
                        :class="logoClasses"
                        src="../../assets/logo-icon-black.svg"
                        alt="Wiges Solutions"
                    >
                </div>
                <form
                    class="login-form"
                    role="form"
                    @keydown.enter="onSubmit"
                >
                    <div
                        v-if="useToken"
                        class="form-line"
                    >
                        <button-input
                            :readonly="isPending"
                            :label="$t('session.loginAs', { label: loginLabel })"
                            class="form-input loginAs"
                            @click="onSubmit"
                        />
                    </div>
                    <template v-else>
                        <div class="form-line">
                            <text-input
                                v-model="username"
                                :placeholder="$t('session.username')"
                                :readonly="true"
                                class="form-input"
                            />
                        </div>
                        <div class="form-line">
                            <password-input
                                v-model="password"
                                v-auto-focus
                                :placeholder="$t('session.password')"
                                :readonly="isPending"
                                class="form-input"
                            />
                        </div>
                        <div class="form-line">
                            <button-input
                                :readonly="isPending"
                                :label="$t('session.login')"
                                class="form-input"
                                @click="onSubmit"
                            />
                        </div>
                    </template>
                    <div class="form-line">
                        <button-input
                            :readonly="isPending"
                            :label="$t('session.logout')"
                            class="form-input"
                            @click="onLogout"
                        />
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-reconnect {
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;

        @keyframes shake {
            from, to {
                transform: translate3d(0, 0, 0);
            }

            20%, 60% {
                transform: translate3d(-10px, 0, 0);
            }

            40%, 80% {
                transform: translate3d(10px, 0, 0);
            }
        }

        .shake {
            animation-name: shake;
            animation-duration: 0.5s;
            animation-fill-mode: both;
        }

        @keyframes pulse {
            from {
                transform: scale3d(1, 1, 1);
            }

            50% {
                transform: scale3d(1.1, 1.1, 1.1);
            }

            to {
                transform: scale3d(1, 1, 1);
            }
        }

        .pulse {
            animation-name: pulse;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-iteration-count: infinite;
        }

        .wisol-gradient {
            height: 5px;
            background: $wisol-gradient;
        }

        .card {
            background: #FFF;
            border: 1px solid rgba(0,0,0,.125);
        }

        .card-body {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 2rem;

            .logo-container {
                width: 120px;
                height: 120px;
                margin-bottom: 2rem;

                .logo {
                    width: 100%;
                    height: 100%;
                }
            }

            .login-form {
                width: 100%;
            }

            .form-line:not(:last-child) {
                margin-bottom: 1em;
            }

            .form-input {
                font-size: 1.2em;
                max-width: 100%;
                width: 300px;
                --input-width: 300px;
            }

            .form-input.loginAs {
                font-size: 2em;
                height: 6em;
            }
        }
    }
</style>

<script>
import { Input as WisolInputs } from '@wisol/libs-inputs'
import { AutoFocus } from '@wisol/utils-focus/directives'

export default {
    name: 'WisolReconnect',

    directives: {
        AutoFocus
    },

    components: {
        TextInput: WisolInputs.Text,
        PasswordInput: WisolInputs.Password,
        ButtonInput: WisolInputs.Button
    },

    inheritAttrs: false,

    data () {
        return {
            password: ''
        }
    },

    computed: {
        username () {
            return this.$store.getters['session/username']
        },

        isPending () {
            return this.$store.getters['session/status'] === 'PENDING'
        },

        isError () {
            return this.$store.getters['session/status'] === 'ERROR'
        },

        cardClasses () {
            return {
                card: true,
                shake: this.isError
            }
        },

        logoClasses () {
            return {
                logo: true,
                pulse: this.isPending
            }
        },

        loginToken () {
            return this.$store.state.session.login.token
        },

        loginLabel () {
            return this.$store.state.session.login.label
        },

        useToken () {
            return this.loginToken && this.username === this.$store.state.session.login.username
        }
    },

    created () {
        this.$store.commit('session/RESET_STATUS')
    },

    mounted () {
        if (this.$refs.focus) {
            this.$refs.focus.select()
            this.$refs.focus.focus()
        }
    },

    methods: {
        onSubmit () {
            if (!this.isPending) {
                this.$store.dispatch('session/login', this.useToken
                    ? {
                        loginToken: this.loginToken
                    }
                    : {
                        username: this.username,
                        password: this.password
                    }
                ).then(() => {
                    this.$store.commit('session/RESET_TIMEOUT')
                })
            }
        },

        onLogout () {
            this.$store.commit('session/RESET_TIMEOUT')
            this.$router.push({
                name: 'Logout'
            })
        }
    }
}
</script>
