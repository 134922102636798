import Vue, { getCurrentInstance } from 'vue'
import useStoreWrapper from '@/composition/useStoreWrapper.js'

const StoreModule = {
    actions: {
        setFilter ({ commit }, { filter }) {
            commit('SET_FILTER', { filter })
        }
    },

    mutations: {
        SET_FILTER (state, { filter }) {
            state.filter = filter
        }
    },

    getters: {
        filter: state => state.filter
    },

    state () {
        return {
            filter: null
        }
    }
}

export default Vue.extend({
    name: 'WisolDataGroupModuleAdvancedFilter',

    inheritAttrs: false,

    setup () {
        const {
            getter,
            dispatch
        } = useStoreWrapper(getCurrentInstance().proxy.$store, StoreModule, 'advanced-filter', {
            autoUnregister: false
        })

        return {
            getter,
            dispatch
        }
    },

    computed: {
        middleware () {
            return {
                filter: (ctx, next) => {
                    if (this.filter) {
                        ctx.data = [
                            ...ctx.data,
                            this.filter
                        ]
                    }
                    next()
                }
            }
        },

        /**
        * the filter
        */
        filter () {
            return this.getter('filter')
        }
    },

    methods: {
        setFilter (filter) {
            return this.dispatch('setFilter', { filter })
        }
    },

    render: h => h()
})
