<template>
    <div class="generic-component-editor-toolbar">
        <slot />
    </div>
</template>

<style lang="scss">
    .generic-component-editor-toolbar {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-width: 0 0 1px 0;
        border-style: solid;
        font-size: 2em;
        height: 50px;
        background-color: #494949;

        .group {
            display: flex;
            box-sizing: border-box;
            padding-right: 5px;
            padding-left: 5px;

            &:not(:last-child) {
                border-right: 1px solid #212121;
            }
        }

        .button {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            background-color: inherit;
            border-radius: 1px;
            border: 1px solid #494949;
            color: white;
            font-size: 0.8em;
            cursor: pointer;

            &.active {
                background: #313131;
                border: 1px solid black;
            }

            &:not(:last-child) {
                margin-right: 5px;
            }

            &:hover:not(.disabled) {
                background: #313131;
            }

            &:focus {
                outline: none;
            }

            &.toggle {
                &.active {
                    background: #313131;
                    border: 1px solid black;
                }
            }

            &.disabled {
                color: #232323;
                cursor: not-allowed;
            }
        }
    }

</style>

<script>
export default {
    name: 'GenericEditorToolbar',

    inheritAttrs: false
}
</script>
