<template>
    <generic-window
        :title="menu.label"
        :icon="menu.icon"
        :color="menu.color"
        :is-minimized="isMinimized"
        :actions="actions"
        class="component-wisol-window"
        @close="close"
        @minimize="minimize"
        @maximize="maximize"
    >
        <div
            :is="moduleComponent"
            v-if="moduleComponent"
            :id="module['@id']"
            :color="menu.color"
            v-bind="moduleOptions"
        />
    </generic-window>
</template>

<style lang="scss">
</style>

<script>
import { getCurrentInstance } from 'vue'
import Registry from '@/Registry'
import { mapGetters } from 'vuex'
import ActionReceiverMixin from '@/mixins/ActionReceiver'
import StoreModule from '@/store/modules/window'
import useStoreWrapper from '@/composition/useStoreWrapper.js'

export default {
    mixins: [
        ActionReceiverMixin()
    ],

    inheritAttrs: false,

    props: {
        id: {
            type: Number,
            required: true
        }
    },

    setup (props) {
        const {
            getter,
            dispatch
        } = useStoreWrapper(getCurrentInstance().proxy.$store, StoreModule, 'window', {
            id: props.id,
            autoUnregister: true
        })

        return {
            getter,
            dispatch
        }
    },

    computed: {
        meta () {
            return this.$store.getters['windows/byId'](this.id)
        },

        menu () {
            return this.$store.getters['ui/menu/byId'](this.meta['@menu'])
        },

        module () {
            return this.modules.find(module => module['@menu'] === this.menu['@id'])
        },

        isMinimized () {
            return this.getter('isMinimized')
        },

        iconClasses () {
            return [
                'icon',
                this.menu.icon
            ]
        },

        ...mapGetters('ui/module', {
            modules: 'all'
        }),

        moduleComponent () {
            if (this.menu.type !== 'module' || !this.module) {
                return null
            }
            const { component } = Registry.get('module', this.module.type)
            return component
        },

        moduleOptions () {
            if (this.menu.type !== 'module' || !this.module) {
                return {}
            }
            return this.module.options || {}
        }
    },

    methods: {
        close () {
            this.$store.dispatch('windows/destroy', { id: this.id })
        },

        minimize () {
            this.dispatch('minimize')
        },

        maximize () {
            this.dispatch('maximize')
        }
    }
}
</script>
