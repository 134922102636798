import { DateTime } from 'luxon'

export default ({
    format = 'yyyy-MM-dd HH:mm:ss.SSS'
} = {}) => {
    return {
        async parse (raw) {
            const value = DateTime.fromFormat(raw, format)
            if (!value.isValid) {
                throw new Error(value.invalidExplanation || value.invalidReason || 'invalid')
            }
            return value
        },

        serialize (dateTime) {
            if (!DateTime.isDateTime(dateTime)) {
                throw new Error('invalid type: luxon DateTime required')
            }
            if (!dateTime.isValid) {
                throw new Error(dateTime.invalidExplanation || dateTime.invalidReason || 'invalid')
            }
            return dateTime.toFormat(format)
        }
    }
}
