import { ref, watch } from 'vue'
import { useFocusPortalNodeList } from '../composition/index.js'

let isSetup = false
let focusElementRef

const setup = () => {
    if (isSetup) return
    isSetup = true

    let checkActiveElementTimeout
    let isVisible = true

    let currentFocusElement = document.activeElement
    focusElementRef = ref(currentFocusElement)

    const updateFocusElement = focusElement => {
        if (focusElement !== currentFocusElement) {
            currentFocusElement = focusElement
            focusElementRef.value = currentFocusElement
        }
    }

    const checkActiveElement = () => {
        clearTimeout(checkActiveElementTimeout)
        if (isVisible) {
            checkActiveElementTimeout = setTimeout(() => {
                updateFocusElement(document.activeElement)
                checkActiveElement()
            }, 250)
        }
    }

    const onFocus = evt => {
        updateFocusElement(evt.target)
        checkActiveElement()
    }

    const onVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            isVisible = true
            checkActiveElement()
        } else {
            isVisible = false
        }
    }

    document.addEventListener('focus', onFocus, true)
    document.addEventListener('visibilitychange', onVisibilityChange)

    checkActiveElement()
}

export default function useFocusTracker (targetElementRef) {
    setup()

    const hasFocusRef = ref(false)

    const { hasFocus: hasFocusPortalFocus } = useFocusPortalNodeList()
    watch(
        () => {
            var element = targetElementRef.value
            return (
                element?.contains &&
                (
                    element.contains(focusElementRef.value) ||
                    hasFocusPortalFocus(element) ||
                    false
                )
            )
        },
        hasFocus => {
            if (hasFocus !== hasFocusRef.value) {
                hasFocusRef.value = hasFocus
            }
        },
        {
            flush: 'sync'
        }
    )

    return {
        hasFocus: hasFocusRef
    }
}
