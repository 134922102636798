<script>
import Type2d from './2d'
import isString from 'lodash-es/isString'

export default {
    name: 'WisolWidgetChartType3d',

    extends: Type2d,

    inheritAttrs: false,

    methods: {
        getSeriesDataValue (row, field) {
            if (isString(field)) {
                field = {
                    y: field,
                    z: field
                }
            } else if (Array.isArray(field)) {
                field = {
                    y: field[0],
                    z: field[1]
                }
            }
            return {
                x: row[this.category],
                y: parseFloat(row[field.y] || 0),
                z: parseFloat(row[field.z] || 0)
            }
        }
    }
}
</script>
