<template>
    <div
        class="component-wisol-editor-menu-detail-box"
    >
        <breadcrumbs
            :selected="row"
            @showDetail="showDetail"
        />
        <box-form
            :row="row"
            class="form"
            @updateRow="onUpdateRow"
        />
        <widget-listing
            v-if="hasWidgets"
            :parent="row"
            class="listing"
            @showDetail="showDetail"
        />
        <boxAction-listing
            v-if="hasActions"
            :parent="row"
            class="listing"
            @showDetail="showDetail"
        />
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-menu-detail-box {
        padding: 20px 20px 0px 20px;

        &> .form,
        &> .listing {
            margin-bottom: 20px;
        }
    }

</style>

<script>
import WisolEditorMenuBreadcrumbs from '../Breadcrumbs'
import WisolEditorMenuFormBox from '../form/Box'
import WisolEditorMenuListingWidget from '../listing/Widget'
import WisolEditorMenuListingBoxaction from '../listing/Boxaction'

export default {
    name: 'WisolEditorMenuDetailBox',

    components: {
        boxForm: WisolEditorMenuFormBox,
        widgetListing: WisolEditorMenuListingWidget,
        boxActionListing: WisolEditorMenuListingBoxaction,
        breadcrumbs: WisolEditorMenuBreadcrumbs
    },

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            required: true
        }
    },

    computed: {
        hasWidgets () {
            return this.row
                .children()
                .filter((child) => {
                    return child['@itemtype'] === 'widget'
                })
                .length > 0
        },

        hasActions () {
            // special case if box is virtual it has no actions
            if (this.row['@idType'] === 'virtual') return false
            return this.row
                .children()
                .find((child) => {
                    return child['@itemtype'] === 'boxAction_group'
                })
                .children()
                .filter((child) => {
                    return child['@itemtype'] === 'boxAction'
                })
                .length > 0
        }

    },

    methods: {
        showDetail (event) {
            this.$emit('showDetail', event)
        },

        onUpdateRow (event) {
            this.$emit('updateRow', event)
        }
    }
}
</script>
