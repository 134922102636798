import apiRequestActions from '@/store/common/actions/apiRequest'

export default {
    ...apiRequestActions({
        action: 'ui.layout.list'
    }),

    create ({ commit, getters }, { module, label, layout }) {
        return this.$http.post('module/ui/layout/create/' + encodeURIComponent(module) + '/' + encodeURIComponent(label), layout)
            .then(({ data }) => {
                commit('LOAD_ITEMS', {
                    items: [
                        ...getters.all,
                        data
                    ]
                })
            })
    },

    update ({ commit, getters }, { id, layout }) {
        return this.$http.post('module/ui/layout/update/' + encodeURIComponent(id), layout)
            .then(({ data }) => {
                const index = getters.all.findIndex(layout => {
                    return layout['@id'] === id
                })

                if (index > -1) {
                    commit('LOAD_ITEMS', {
                        items: [
                            ...getters.all.slice(0, index),
                            data,
                            ...getters.all.slice(index + 1)
                        ]
                    })
                } else {
                    commit('LOAD_ITEMS', {
                        items: [
                            ...getters.all,
                            data
                        ]
                    })
                }
            })
    },

    delete ({ commit, getters }, { id }) {
        return this.$http.post('module/ui/layout/delete/' + encodeURIComponent(id))
            .then(({ data }) => {
                const index = getters.all.findIndex(layout => {
                    return layout['@id'] === id
                })

                if (index > -1) {
                    commit('LOAD_ITEMS', {
                        items: [
                            ...getters.all.slice(0, index),
                            ...getters.all.slice(index + 1)
                        ]
                    })
                }
            })
    }
}
