<template>
    <div
        v-resize="onResize"
        class="component-generic-form"
    >
        <dnd-grid-container
            :layout="layout"
            :cell-size="cellSize"
            :margin="margin"
            :outer-margin="outerMargin"
            :bubble-up="bubbleUp"
            @update:layout="onLayoutUpdate"
        >
            <input-box
                v-for="field in visibleFields"
                :key="getBoxId(field)"
                :box-id="getBoxId(field)"
                :field="field"
                :value="getValue(field)"
                @update:value="onValueUpdate(field, $event)"
                @click="onInputClick(field, $event)"
            />
        </dnd-grid-container>
    </div>
</template>

<script>
import { ResizeObserver as ResizeObserverDirective } from '@wisol/utils-resize/directives'
import InputBox from './InputBox'

export default {
    name: 'GenericForm',

    directives: {
        resize: ResizeObserverDirective
    },

    components: {
        InputBox
    },

    inheritAttrs: false,

    props: {
        fields: {
            type: Array,
            required: true
        },

        values: {
            type: Object,
            required: false,
            default () {
                return {}
            }
        },

        layout: {
            type: Array,
            required: true
        },

        columnCount: {
            type: Number,
            default: 24
        },

        cellHeight: {
            type: Number,
            default: 25
        },

        minCellWidth: {
            type: Number,
            default: 40
        },

        maxCellWidth: {
            type: Number,
            default: Infinity
        },

        tabOrderMode: {
            type: String,
            default: 'horizontal',
            validator (value) {
                return ['horizontal', 'vertical', 'manual'].indexOf(value) !== -1
            }
        }
    },

    data () {
        return {
            containerSize: {
                width: 0,
                height: 0
            },
            bubbleUp: true,
            margin: 5,
            outerMargin: 5
        }
    },

    computed: {
        cellSize () {
            const cellWidth = (this.containerSize.width - (((this.columnCount - 1) * this.margin) + (2 * this.outerMargin))) / this.columnCount
            return {
                h: this.cellHeight,
                w: Math.max(
                    this.minCellWidth,
                    Math.min(
                        cellWidth,
                        this.maxCellWidth
                    )
                )
            }
        },

        layoutMap () {
            const map = new Map()
            this.layout.forEach(layout => {
                map.set(layout.id, layout)
            })
            return map
        },

        visibleFields () {
            return this.fields.filter(field => {
                const boxLayout = this.layoutMap.get(field.id)
                return boxLayout && !boxLayout.hidden
            })
                // sort array for tabindex
                .sort((a, b) => {
                    const { position: aPosition, tabindex: aTabindex } = this.layoutMap.get(a.id)
                    const { position: bPosition, tabindex: bTabindex } = this.layoutMap.get(b.id)

                    const sortHorizontal = () => aPosition.y - bPosition.y || aPosition.x - bPosition.x
                    const sortVertical = () => aPosition.x - bPosition.x || aPosition.y - bPosition.y
                    const sortManual = () => {
                        if (typeof aTabindex === 'undefined' && typeof bTabindex === 'undefined') {
                            return sortHorizontal()
                        }
                        if (typeof aTabindex === 'undefined') {
                            return 1
                        }
                        if (typeof bTabindex === 'undefined') {
                            return -1
                        }
                        return aTabindex - bTabindex
                    }

                    switch (this.tabOrderMode) {
                        case 'horizontal':
                            return sortHorizontal()
                        case 'vertical':
                            return sortVertical()
                        case 'manual':
                            return sortManual()
                    }

                    return 0
                })
        }
    },

    mounted () {
        this.containerSize.width = this.$el.clientWidth
        this.containerSize.height = this.$el.clientHeight
    },

    methods: {
        getBoxId (field) {
            return field.id || this.fields.indexOf(field)
        },

        getValue (field) {
            return field.field in this.values
                ? this.values[field.field]
                : null
        },

        onValueUpdate (field, { value, continuous }) {
            if (!continuous) {
                this.$emit('update:value', {
                    field: field.field,
                    value
                })
            }
        },

        onInputClick (field, evt) {
            this.$emit('click', {
                ...evt,
                field
            })
        },

        onResize ({ width, height }) {
            if (width && height) {
                this.containerSize.width = width
                this.containerSize.height = height
            }
        },

        onLayoutUpdate (layout) {
            this.$emit('update:layout', layout)
        }
    }
}
</script>
