<template>
    <div
        :class="classes"
        tabindex="0"
        @drop="onDrop"
        @dragover="onDragOver"
        @mousedown.stop
    >
        <div v-if="dropHere">
            <icon
                :class="$style.actionIcon"
                name="fa/light/cloud-download"
            />
            {{ $t('input.ole-drop-here') }}
        </div>
        <div v-else-if="dropNow">
            <icon
                :class="$style.actionIcon"
                name="fa/light/cloud-download"
            />
            {{ $t('input.ole-drop-now') }}
        </div>
        <div
            v-else-if="!hasFile"
            :class="$style.selectFile"
            @click="selectFile"
        >
            <div>
                <icon
                    :class="$style.actionIcon"
                    name="fa/light/upload"
                />
                {{ $t('input.ole-select-file') }}
            </div>
        </div>
        <div
            v-if="url"
            :class="$style.input"
            :title="$t('input.ole-open-file').replace('\{\{file\}\}', value.name)"
            @click.stop="download"
        >
            <div
                v-if="previewStyle"
                :style="previewStyle"
                :class="$style.image"
            />
            <span v-else>
                <icon
                    :class="$style.actionIcon"
                    name="fa/light/download"
                />
                {{ $t('input.ole-open-file').replace('\{\{file\}\}', value.name) }}
            </span>
        </div>
    </div>
</template>

<style lang="scss" module>
    .container {
        composes: container from "../../../../../../libs/inputs/src/style/input.module.scss";

        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .dropHere {
        background: #FFC107;
    }

    .dropNow {
        background: #8BC34A;
    }

    .actionIcon {
        font-size: 1.2em;
    }

    .input {
        composes: input from "../../../../../../libs/inputs/src/style/input.module.scss";
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .image {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 0;
        margin: 0;
    }

    .pdfContainer {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        overflow-y: auto;
    }

    .selectFile {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
</style>

<script>
import dataUriToBlob from 'datauritoblob'
import * as DragUtils from '@/utils/drag'
import Icon from '@wisol/libs-icons'
import { WisolInputSymbol } from '../index.vue'
import { inject } from 'vue'
import { InputSymbol } from '@wisol/libs-inputs/src/components/createInput.js'
import { pdfToImage } from '@wisol/utils-image/functions'

const OlePromiseMap = new Map()

export default {
    name: 'OleUi',

    components: {
        Icon
    },

    inject: {
        WisolInput: {
            from: WisolInputSymbol
        }
    },

    inheritAttrs: false,

    setup () {
        const { value, setValue, inputClasses } = inject(InputSymbol)
        return {
            value,
            setValue,
            inputClasses
        }
    },

    data () {
        return {
            type: null,
            url: null,
            previewUrl: null,
            valueToBlobPromise: null
        }
    },

    computed: {
        hasFile () {
            return !!this.value
        },

        canDropFile () {
            return !this.readonly && !this.disabled && !this.hasFile && DragUtils.isFile(DragUtils.Monitor.dataTransfer)
        },

        dropHere () {
            return this.canDropFile && !this.dropNow && !!DragUtils.Monitor.dragEnterNode
        },

        dropNow () {
            return this.canDropFile && this.$el && this.$el.contains(DragUtils.Monitor.dragEnterNode)
        },

        previewStyle () {
            return this.previewUrl
                ? {
                    backgroundImage: 'url(' + this.previewUrl + ')'
                }
                : null
        },

        classes () {
            return [
                this.inputClasses,
                {
                    [this.$style.container]: true,
                    [this.$style.dropHere]: this.dropHere,
                    [this.$style.dropNow]: this.dropNow
                }
            ]
        }
    },

    watch: {
        value: {
            immediate: true,
            handler (value, oldValue) {
                if (value === oldValue) {
                    return
                }
                this.type = null
                this.url = null
                this.previewUrl = null
                if (value) {
                    const valueToBlobPromise = this.valueToBlobPromise = Promise.resolve(this.valueToBlob(value))
                        .then(blob => {
                            if (valueToBlobPromise === this.valueToBlobPromise) {
                                const type = this.type = blob.type
                                const url = this.url = URL.createObjectURL(blob)

                                // update previewUrl
                                if (type.match(/^image\//)) {
                                    this.previewUrl = url
                                } else if (type === 'application/pdf') {
                                    return pdfToImage(url)
                                        .then(previewUrl => {
                                            if (valueToBlobPromise === this.valueToBlobPromise) {
                                                this.previewUrl = previewUrl
                                            }
                                        })
                                        // ignore pdf preview image errors (a simple link will be displayed)
                                        .catch(() => {})
                                }
                            }
                        })
                }
            }
        },

        url (_, oldUrl) {
            if (oldUrl) {
                URL.revokeObjectURL(oldUrl)
            }
        },

        previewUrl (_, oldUrl) {
            if (oldUrl) {
                URL.revokeObjectURL(oldUrl)
            }
        }
    },

    beforeDestroy () {
        if (this.url) {
            URL.revokeObjectURL(this.url)
        }
    },

    methods: {
        valueToBlob (value) {
            if (!value) {
                return null
            }
            if (value.data) {
                return dataUriToBlob(value.data)
            }
            return this.getOleBlob(value)
        },

        onDrop (evt) {
            if (!this.canDropFile) {
                return
            }

            evt.preventDefault()

            const file = DragUtils.getFile(evt.dataTransfer)
            this._loadFile(file)
        },

        onDragOver (evt) {
            if (!this.canDropFile) {
                return
            }

            evt.preventDefault()
        },

        getOleBlob (value) {
            const mapId = value.table + ':' + value.id
            if (!OlePromiseMap.has(mapId)) {
                OlePromiseMap.set(
                    mapId,
                    this.$api.blob('ui.field.ole', {
                        table: value.table,
                        id: value.id,
                        field: this.WisolInput.id
                    })
                )
            }
            return OlePromiseMap.get(mapId)
        },

        _loadFile (file) {
            if (file) {
                const reader = new FileReader()
                reader.addEventListener('load', () => {
                    this.setValue({
                        data: reader.result,
                        type: file.type,
                        name: file.name
                    })
                }, {
                    once: true
                })

                reader.readAsDataURL(file)
            }
        },

        selectFile () {
            const input = document.createElement('input')
            input.type = 'file'

            input.addEventListener('change', e => {
                this._loadFile(e.target.files[0])
            }, {
                once: true
            })

            input.click()
        },

        download () {
            if (this.url) {
                window.open(this.url, 'ole-input')
            }
        }
    }
}
</script>
