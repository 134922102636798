import { createPopper } from '@popperjs/core'
import { ResizeObserver } from '@wisol/utils-resize/components'

export default {
    name: 'Popover',

    inheritAttrs: false,

    props: {
        reference: {
            type: Node,
            required: true
        },

        jail: {
            type: [String, HTMLElement],
            default: 'viewport'
        },

        placement: {
            type: String,
            default: 'bottom-start'
        },

        modifiers: {
            type: Object,
            default () {
                return {}
            }
        }
    },

    data () {
        return {
            rootElement: null
        }
    },

    watch: {
        reference () {
            this.updatePopper()
        },

        modifiers () {
            this.updatePopper()
        },

        placement () {
            this.updatePopper()
        }
    },

    computed: {
        modifiersArray () {
            const modifiers = {
                preventOverflow: {
                    enabled: true,
                    boundariesElement: this.jail,
                    padding: 5,
                    mainAxis: true,
                    altAxis: true
                },
                ...this.modifiers
            }
            const modifiersArray = []
            for (const name in modifiers) {
                const options = modifiers[name]
                if (!('enabled' in options) || options.enabled) {
                    modifiersArray.push({ name, options })
                }
            }
            return modifiersArray
        }
    },

    mounted () {
        this.rootElement = this.$el
        this.$nextTick(() => { // wait for component to be mounted to document
            this.resetPopper()
        })
    },

    updated () {
        if (this.$el !== this.rootElement) {
            this.rootElement = this.$el
            this.resetPopper()
        }
    },

    beforeDestroy () {
        this.removePopper()
    },

    methods: {
        onResize () {
            if (this.popper) {
                this.popper.update()
            }
        },

        updatePopper () {
            if (this.popper && !this._isBeingDestroyed) {
                this.popper.state.elements.reference = this.reference
                this.popper.setOptions({
                    placement: this.placement,
                    modifiers: this.modifiersArray
                })
                this.popper.update()
            }
        },

        resetPopper () {
            if (this.popper) {
                this.popper.destroy()
            }
            this.popper = createPopper(this.reference, this.$el, {
                placement: this.placement,
                modifiers: this.modifiersArray
            })
        },

        removePopper () {
            if (this.popper) {
                this.popper.destroy()
            }
        }
    },

    render (h) {
        const vnodes = this.$scopedSlots.default(this)
        const vnode = Array.isArray(vnodes)
            ? vnodes[0]
            : vnodes

        return h(ResizeObserver, {
            on: {
                resize: this.onResize
            }
        }, [
            !vnode.tag
                ? h('div', {}, [vnode])
                : vnode
        ])
    }
}
