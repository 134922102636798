import { ref, computed } from 'vue'

export default function useLoading () {
    const level = ref(0)
    const status = ref('SUCCESS')
    const lastError = ref(null)

    const start = async () => {
        level.value++
        status.value = 'LOADING'
    }

    const stop = async (error = null) => {
        level.value = Math.max(0, level.value - 1)
        if (level.value === 0) {
            status.value = error ? 'ERROR' : 'SUCCESS'
            lastError.value = error || null
        }
    }

    const isLoading = computed(() => {
        return level.value > 0 || status.value === 'LOADING'
    })

    const isReady = computed(() => {
        return status.value === 'SUCCESS'
    })

    const error = computed(() => {
        if (status.value === 'ERROR') {
            return lastError.value || new Error('Unknown loading error')
        }
        return null
    })

    return {
        start,
        stop,
        isLoading,
        isReady,
        error
    }
}
