import Vue from 'vue'

const isObject = function isObject (value) {
    return typeof value === 'object' && value !== null && !(value instanceof Array)
}

const setValue = function setValue (obj, key, value, updateTarget = false) {
    if (updateTarget) {
        Vue.set(obj, key, value)
    } else {
        obj = Object.assign({}, obj, { [key]: value })
    }
    return obj
}

const merge2 = function merge2 (current, toMerge, updateTarget = false) {
    if (current === toMerge) {
        return current
    }
    if (!isObject(current) || !isObject(toMerge)) {
        return toMerge
    }

    return Object.entries(toMerge)
        .reduce((merged, [key, newValue]) => {
            if (key in merged) {
                const currentValue = merged[key]
                const mergedValue = merge2(currentValue, newValue, updateTarget)
                if (mergedValue !== currentValue) {
                    merged = setValue(merged, key, mergedValue, updateTarget)
                }
            } else {
                merged = setValue(merged, key, newValue, updateTarget)
            }

            return merged
        }, current)
}

export function merge (...args) {
    if (args.length < 2) {
        return args[0] || {}
    }
    return args.reduce((merged, value) => merge2(merged, value), args[0])
}

export function mergeTarget (...args) {
    if (args.length < 2) {
        return args[0] || {}
    }
    return args.reduce((merged, value) => merge2(merged, value, true), args[0])
}
