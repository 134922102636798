import Vue from 'vue'
import CommonMixin from './CommonMixin'

export default Vue.extend({
    name: 'WisolDataGroupModuleDelete',

    mixins: [
        CommonMixin
    ],

    inheritAttrs: false,

    methods: {
        /**
         * deletes the active row
         * @return {Promise} the promise which resolves when the row has been deleted
         */
        async deleteActiveRow () {
            return this.deleteRow(this.$parent.activeRow)
        },

        /**
         * deletes the row
         * @param  {Object}  row the row to delete
         * @return {Promise}     the promise which resolves when the row has been deleted
         */
        async deleteRow (row) {
            if (this.$parent.readonly) {
                throw new Error('Datagroup is readonly')
            }

            const type = this.$parent.getRowType(row)

            if (type === 'NEW') {
                const createModule = this.$parent.moduleMap.get('create')
                if (createModule) {
                    return createModule.deleteRow(row)
                }
            } else if (type === 'DEFAULT') {
                await this.$parent.startLoading()

                if (row['@id']) {
                    try {
                        await this.$parent.dataProvider.delete(row['@id'])

                        // reload rows
                        await this.$parent.updateRows()
                    } catch (err) {
                        this.handleError(err)
                    }
                }

                await this.$parent.stopLoading()
            }
        }
    },

    render: h => h()
})
