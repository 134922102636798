<script>
import PluginMixin from './Mixin.js'

export default {
    mixins: [
        PluginMixin('sorting')
    ],

    inheritAttrs: false,

    props: {
        sorting: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        sortingMap () {
            const map = new Map()
            this.sorting.forEach((item, index) => {
                map.set(item.column, { item, index })
            })
            return map
        },

        itemCount () {
            return this.sorting.length
        }
    },

    created () {
        this.listing.bus.$on('plugin:sorting:sort', this.onSort)
    },

    beforeDestroy () {
        this.listing.bus.$off('plugin:sorting:sort', this.onSort)
    },

    methods: {
        getSortingIndex (column) {
            const data = this.sortingMap.get(column)
            if (data) {
                return data.index
            }
            return -1
        },

        hasSorting (column) {
            return this.sortingMap.has(column)
        },

        getSortingItem (column) {
            const data = this.sortingMap.get(column)
            if (data) {
                return data.item
            }
            return {}
        },

        getSortingDirection (column) {
            const data = this.getSortingItem(column)
            return data
                ? data.direction
                : undefined
        },

        onSort (evt) {
            const sorting = []
            const column = evt.sorting.column

            if (evt.ctrlKey) {
                const index = this.getSortingIndex(column)
                if (index > -1) {
                    sorting.push(...this.sorting.slice(0, index))
                    sorting.push(...this.sorting.slice(index + 1))
                } else {
                    sorting.push(...this.sorting)
                }
            }

            // append new sorting
            sorting.push({
                column,
                direction: this.getSortingDirection(column) === 'ASC' ? 'DESC' : 'ASC'
            })

            this.$emit('update', sorting)
        }
    },

    render () {
        return null
    }

}
</script>
