<template>
    <div
        class="component-wisol-widget-form"
    >
        <div
            v-if="isDirty"
            class="is-dirty-message"
        >
            unsaved
        </div>
        <div
            class="form-container"
        >
            <generic-form
                v-if="row"
                v-show="!dataGroup.isLoading && !dataGroup.loadingError"
                :key="rowId"
                :fields="fields"
                :values="values"
                :layout="boxesLayout"
                :column-count="columnCount"
                :cell-height="cellHeight"
                :min-cell-width="minCellWidth"
                :max-cell-width="maxCellWidth"
                :tab-order-mode="tabOrderMode"
                v-bind="$attrs"
                @update:value="onUpdateValue"
                @update:layout="onBoxesLayoutUpdate"
                @click="onInputClick"
            />
            <status-display
                v-if="!dataGroup.isReady"
                :data-group="dataGroup"
            />
        </div>
        <generic-pagination
            :page="page"
            :count="1"
            :total="dataGroup.totalRowCount"
            :show-count-selector="false"
            class="pagination"
            @update:page="onPageUpdate"
        />
        <generic-popover
            v-if="showFieldSelector"
            :target="showFieldSelector"
            popover-classes="component-wisol-widget-form-field-selector"
            @close="closeFieldSelector"
        >
            <div
                v-for="field in fields"
                :key="field['@id']"
                class="field"
            >
                <checkbox-input
                    :value="field.visible ? true : false"
                    class="checkbox"
                    @update:value="onFieldVisibiltyChange(field, $event)"
                />
                {{ field.label }}
            </div>
        </generic-popover>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-widget-form {
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        &> .form-container {
            position: relative;
            flex: 1 1 0;
            overflow: auto;
        }

        &> .pagination {
            flex: 0 0 2.5rem;
            background: nth($color-palette-grey, 2);
        }

        .is-dirty-message {
            background: map-get($status-colors, 'exception');
            color: text-contrast-color(map-get($status-colors, 'exception'), $text-color);
            height: 2.5em;
            line-height: 2.5em;
            padding: 0 1em;
            font-weight: bold;
        }
    }

    .component-wisol-widget-form-field-selector {
        @include wisol-popup;

        .field {
            display: flex;

            &:not(:last-child) {
                margin: 0 0 0.5em 0;
            }

            .checkbox {
                margin-right: 0.5em;
            }
        }
    }
</style>

<script>
import Vue from 'vue'
import { merge } from '@wisol/utils-data/functions'
import ActionProviderMixin from '@/mixins/ActionProvider'
import { utils as dndGridUtils } from '@dattn/dnd-grid'
import { Input as Inputs } from '@wisol/libs-inputs'
import WisolInput from '../Input'
import StatusDisplay from '../Data/Group/StatusDisplay'

export default {
    name: 'WisolWidgetForm',

    components: {
        CheckboxInput: Inputs.Checkbox,
        StatusDisplay
    },

    mixins: [
        ActionProviderMixin()
    ],

    inheritAttrs: false,

    props: {
        id: {
            type: Number,
            required: true
        },
        layout: {
            type: Object,
            required: true
        },
        dataGroup: {
            type: Vue,
            required: true
        },
        columnCount: {
            type: Number,
            default: 24
        },
        cellHeight: {
            type: Number,
            default: 25
        },
        minCellWidth: {
            type: Number,
            default: 40
        },
        maxCellWidth: {
            type: Number,
            default: Infinity
        }
    },

    data () {
        return {
            showFieldSelector: false
        }
    },

    computed: {
        meta () {
            return this.$store.getters['ui/widget/byId'](this.id)
        },

        boxId () {
            return this.meta['@box']
        },

        boxesLayout () {
            return this.layout.grid && this.layout.grid.boxes
                ? this.layout.grid.boxes
                : []
        },

        tabOrderMode () {
            return this.layout.grid && this.layout.grid.tabOrderMode
                ? this.layout.grid.tabOrderMode
                : 'horizontal'
        },

        row () {
            return this.dataGroup.activeRow
        },

        rowId () {
            if (!this.row) {
                return null
            }
            return this.dataGroup.getRowId(this.row)
        },

        values () {
            if (this.row) {
                return this.dataGroup.getRowValues(this.row)
            }
            return {}
        },

        isDirty () {
            return this.row && this.dataGroup.isRowDirty(this.row)
        },

        page () {
            return this.dataGroup.activeRowPosition + 1
        },

        readonly () {
            if (!this.row) {
                return true
            }
            return !this.dataGroup.canRowUpdate(this.row)
        },

        fields () {
            return this.dataGroup.fields
                .map(field => {
                    const boxLayout = this.boxesLayout.find(boxLayout => {
                        return boxLayout.id === field['@id']
                    })

                    let inputProps = this.dataGroup.getInputProps(field, this.row)
                    inputProps = {
                        ...inputProps,
                        readonly: inputProps.readonly || this.readonly
                    }

                    return {
                        id: field['@id'],
                        field: field.field,
                        label: field.label,
                        visible: boxLayout && !boxLayout.hidden,
                        input: {
                            component: WisolInput,
                            props: inputProps
                        }
                    }
                })
                .sort((a, b) => {
                    if (a.label < b.label) {
                        return -1
                    }
                    if (a.label > b.label) {
                        return 1
                    }
                    return 0
                })
        },

        actions () {
            return [
                {
                    icon: 'wisol/pin',
                    title: 'Select Fields',
                    disabled: false,
                    callback: evt => {
                        this.openFieldSelector(evt.target)
                    }
                }
            ]
        }
    },

    methods: {
        onUpdateValue ({ field, value }) {
            this.dataGroup.setRowValues(this.row, {
                [field]: value
            })
        },

        onBoxesLayoutUpdate (boxesLayout) {
            this.$emit('update:layout', merge(
                this.layout,
                {
                    grid: {
                        boxes: boxesLayout
                    }
                }
            ))
        },

        onPageUpdate ({ page }) {
            this.dataGroup.setActivePosition(page - 1)
        },

        onInputClick ({ field, setBusyPromise }) {
            if (this.dataGroup.clickScriptFunction) {
                setBusyPromise(
                    this.dataGroup.clickScriptFunction(
                        this.rowType,
                        this.row,
                        field.field
                    )
                )
            }
        },

        openFieldSelector (target) {
            this.showFieldSelector = target
        },

        closeFieldSelector () {
            this.showFieldSelector = false
        },

        onFieldVisibiltyChange (field, { value: visible }) {
            let boxesLayout = this.boxesLayout
            const index = boxesLayout.findIndex(boxLayout => {
                return boxLayout.id === field.id
            })

            if (visible) {
                if (index > -1) {
                    if (!boxesLayout[index].hidden) {
                        return
                    }
                    boxesLayout = [
                        ...boxesLayout.slice(0, index),
                        {
                            ...boxesLayout[index],
                            hidden: false
                        },
                        ...boxesLayout.slice(index + 1)
                    ]
                } else {
                    boxesLayout = [
                        ...boxesLayout,
                        dndGridUtils.moveBoxToFreePlace(
                            boxesLayout,
                            {
                                id: field.id,
                                hidden: false,
                                position: {
                                    x: 0,
                                    y: 0,
                                    w: 3,
                                    h: 1
                                }
                            }
                        )
                    ]
                }
            } else {
                if (index > -1) {
                    if (boxesLayout[index].hidden) {
                        return
                    }
                    boxesLayout = [
                        ...boxesLayout.slice(0, index),
                        {
                            ...boxesLayout[index],
                            hidden: true
                        },
                        ...boxesLayout.slice(index + 1)
                    ]
                } else {
                    return
                }
            }
            this.onBoxesLayoutUpdate(boxesLayout)
        }
    }
}
</script>
