export default name => {
    return {
        props: {
            id: {
                type: Number,
                required: true
            }
        },

        computed: {
            ui () {
                return this.$store.getters['ui/' + name + '/byId'](this.id)
            }
        }
    }
}
