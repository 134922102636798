<template>
    <div class="wisol">
        <wisol-alerts />
        <wisol-ui-loading v-if="isLoadingUI" />
        <div
            v-else
            :class="$style.contentContainer"
        >
            <editor-navbar v-if="showEditorNavbar" />
            <keep-alive :include="keepAlive">
                <router-view />
            </keep-alive>
        </div>
        <wisol-reconnect v-if="showReconnectDialog" />
    </div>
</template>

<style lang="scss" module>
:global {
    @import "style/wisol";

    .wisol {
        display: flex;
        flex-direction: column;
    }
}

.contentContainer {
    flex: 1;
    display: flex;
    flex-direction: row;
}
</style>

<script>
import Vue from 'vue'
import smoothscrollPolyfill from 'smoothscroll-polyfill'
import { Box as GridBox, Container as GridContainer } from '@dattn/dnd-grid'
import EditorNavbar from '@/components/wisol/editor/Navbar.vue'
import '@dattn/dnd-grid/dist/dnd-grid.css'

// register components
Vue.component('DndGridContainer', GridContainer)
Vue.component('DndGridBox', GridBox)

// polyfill for smooth scrolling
smoothscrollPolyfill.polyfill()

export default {
    components: {
        EditorNavbar
    },

    provide () {
        return {
            app: this
        }
    },

    inheritAttrs: false,

    data () {
        return {
            layoutMode: false,
            showEditorNavbar: false
        }
    },

    computed: {
        isLoadingUI () {
            return this.$store.state.ui.loading
        },

        showReconnectDialog () {
            return this.$store.getters['session/timeout']
        },

        keepAlive () {
            const keepAlive = [
                'PageMain'
            ]
            if (this.showEditorNavbar) {
                keepAlive.push('WisolEditorMenu')
            }
            return keepAlive
        }
    },

    watch: {
        $route: {
            handler (to) {
                if (to.meta?.openEditorNavbar) {
                    this.showEditorNavbar = true
                }
            },
            immediate: true
        }
    },

    created () {
        window.addEventListener('keydown', this.handleKeyDownEvent, true)
        window.addEventListener('keyup', this.handleKeyUpEvent, true)
    },

    beforeDestroy () {
        window.removeEventListener('keydown', this.handleKeyDownEvent, true)
        window.removeEventListener('keyup', this.handleKeyUpEvent, true)
    },

    methods: {
        handleKeyDownEvent (evt) {
            if (
                (evt.altKey || evt.key === 'Alt' || evt.key === 'Meta') &&
                (evt.shiftKey || evt.key === 'Shift')
            ) {
                this.layoutMode = true
            }
        },

        handleKeyUpEvent () {
            this.layoutMode = false
        },

        openEditorNavbar () {
            this.showEditorNavbar = true
            if (this.$route.name !== 'Editor') {
                this.$router.push({ name: 'Editor' })
            }
        },

        closeEditorNavbar () {
            if (this.$route.name !== 'Main') {
                this.$router.push({ name: 'Main' })
            }
            this.showEditorNavbar = false
        }
    }
}
</script>
