<template>
    <div :class="$style.navbar">
        <div>
            <div
                v-for="item in routeItems"
                :key="item.name"
                :class="itemClasses(item)"
                :title="item.meta.title"
                @click="onClick(item)"
            >
                <icon
                    :name="item.meta.icon"
                    :class="$style.icon"
                />
            </div>
        </div>
        <div
            :class="$style.item"
            title="Close"
            @click="close"
        >
            <icon
                :class="$style.icon"
                name="fa/light/times"
            />
        </div>
    </div>
</template>

<style lang="scss" module>
    .navbar {
        height: 100%;
        background: #000;
        border-width: 0 1px 0 0;
        border-style: solid;
        border-color: #FFF;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .item {
        width: 50px;
        height: 50px;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 0 0 0 4px;
        border-style: solid;
        border-color: #333;
        cursor: pointer;
        margin-bottom: 1px;

        &:hover {
            background-color: #222;
        }

        &.active {
            background-color: #222;
            border-color: #F00;
        }
    }

    .icon {
        font-size: 2em;
    }
</style>

<script>
import Icon from '@wisol/libs-icons'

export default {
    components: {
        Icon
    },

    inject: ['app'],

    inheritAttrs: false,

    computed: {
        routeItems () {
            return Object.values(this.$router.routes)
                .filter(route => route.meta?.showInEditorNavbar)
        }
    },

    methods: {
        itemClasses (item) {
            return {
                [this.$style.item]: true,
                [this.$style.active]: item.name === this.$route.name
            }
        },

        onClick ({ name }) {
            if (this.$route.name !== name) {
                this.$router.push({ name })
            }
        },

        close () {
            this.app.closeEditorNavbar()
        }
    }
}
</script>
