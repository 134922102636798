import createInput from '@wisol/libs-inputs/src/components/createInput.js'
import UiRelation from './Relation.vue'
import Parser from '@wisol/libs-inputs/src/parser/Json.js'
import TypeObjectValidator from '@wisol/libs-inputs/src/validator/type/Object.js'

export default createInput(UiRelation, {
    validators: () => [
        TypeObjectValidator()
    ],
    Parser
})
