import Vue from 'vue'
import DataProvider from '../Provider.js'
import DataGroup, { getModulesInfo } from './index.js'

export default Vue.extend({
    name: 'WisolDataGroupFactory',

    inheritAttrs: false,

    props: {
        id: {
            type: Number,
            required: true
        },

        getDataGroup: {
            type: Function,
            required: true
        }
    },

    data () {
        return {
            dataProvider: null,
            dataGroup: null
        }
    },

    computed: {
        meta () {
            return this.$store.getters['ui/dataGroup/byId'](this.id)
        },

        tableName () {
            return this.meta.table
        },

        fields () {
            return this.$store.getters['ui/field/all']
                .filter(field => field['@dataGroup'] === this.id)
                .map(field => this.$store.getters['ui/field/getMerged'](field))
        },

        links () {
            const _this = this
            return this.$store.getters['ui/dataGroupLink/all']
                .filter(meta => {
                    return meta['@source'] === this.id ||
                        meta['@target'] === this.id
                })
                .map(meta => {
                    return {
                        type: meta.type,
                        options: meta.options,
                        sourceId: meta['@source'],
                        targetId: meta['@target'],
                        getSource () {
                            return _this.getDataGroup(meta['@source'])
                        },
                        getTarget () {
                            return _this.getDataGroup(meta['@target'])
                        }
                    }
                })
        },

        modules () {
            const modules = Array.from(new Set([
                ...this.meta.modules || [], // modules from layout
                ...this.links.map(link => `link/${link.type}`) // add modules required by relations
            ]))
            return getModulesInfo(modules)
        },

        dataGroupProps () {
            return {
                id: this.id,
                table: this.tableName,
                fields: this.fields,
                dataProvider: this.dataProvider,
                preFilter: this.meta.filter,
                links: this.links,
                modules: this.modules,
                readonly: this.meta.readonly,
                scripts: this.meta.scripts
            }
        }
    },

    watch: {
        tableName: {
            handler () {
                const oldDataProvider = this.dataProvider
                this.dataProvider = new DataProvider({
                    propsData: {
                        source: 'wisol.dataGroup:' + this.id
                    },
                    parent: this
                })
                if (oldDataProvider) {
                    oldDataProvider.$destroy()
                }
            },
            immediate: true
        },

        dataProvider: {
            handler () {
                const oldDataGroup = this.dataGroup
                this.dataGroup = new DataGroup({
                    propsData: this.dataGroupProps,
                    parent: this
                })
                if (oldDataGroup) {
                    oldDataGroup.$destroy()
                }
            },
            immediate: true
        }
    },

    destroyed () {
        if (this.dataGroup) {
            this.dataGroup.$destroy()
            this.dataGroup = null
        }
        if (this.dataProvider) {
            this.dataProvider.$destroy()
            this.dataProvider = null
        }
    }
})
