<template>
    <div
        class="component-generic-action-icons"
    >
        <div
            v-for="group in visibleGroups"
            :key="group"
            :class="groupClasses(group)"
        >
            <action-icon
                v-for="(action, index) in groupedActions[group]"
                :key="index"
                :action="action"
            />
        </div>
    </div>
</template>

<style lang="scss">
    .component-generic-action-icons {
        font-size: 1em;
        display: flex;
        height: 100%;
        margin: 0 0.5em;

        .action-group {
            display: flex;
            height: 100%;

            &:not(:first-child)::before {
                content: "";
                height: 100%;
                border-right: 1px solid #FFF;
                margin: 0 0.5em;
            }
        }

        .action-group-fill {
            flex: 1;
        }
    }
</style>

<script>
import ActionIcon from './ActionIcon.vue'

export default {
    name: 'GenericActionIcons',

    components: {
        ActionIcon
    },

    inheritAttrs: false,

    props: {
        actions: {
            type: Array,
            default () {
                return []
            }
        },

        groups: {
            type: Array,
            default () {
                return ['default']
            }
        },

        fillGroup: {
            type: String,
            default: 'default'
        }
    },

    computed: {
        visibleGroups () {
            return this.groups.filter(group => this.hasActions(group))
        },

        groupedActions () {
            return this.actions.reduce((groupedActions, action) => {
                const group = action.group || 'default'
                if (!groupedActions[group]) {
                    groupedActions[group] = []
                }
                groupedActions[group].push(action)
                return groupedActions
            }, {})
        }
    },

    methods: {
        hasActions (group) {
            return this.groupedActions[group] && this.groupedActions[group].length
        },

        groupClasses (group) {
            return {
                'action-group': true,
                ['action-group--' + group]: true,
                'action-group-fill': group === this.fillGroup
            }
        }
    }
}
</script>
