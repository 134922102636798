<template>
    <div
        class="component-wisol-editor-menu-graph-dataGroup-link"
    >
        <link-graph
            :parent="row"
            class="graph"
            @showDetail="showDetail"
        />
    <!-- <link-form
        v-if="selectedLink !== null"
        :row="selectedLink"
        @updateRow="onUpdateRow"/> -->
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-menu-graph-dataGroup-link {
        padding: 20px 20px 0px 20px;
        // display: flex;
    }

</style>

<script>
import WisolEditorMenuGraphDatagroupLinkGraph from './Graph'
// import WisolEditorMenuFormDatagrouplink from '../../../form/Datagrouplink.vue'

export default {
    name: 'WisolEditorMenuGraphDatagroupLink',

    components: {
        'link-graph': WisolEditorMenuGraphDatagroupLinkGraph
        // 'link-form': WisolEditorMenuFormDatagrouplink
    },

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            selectedLink: null
        }
    },

    methods: {
        showDetail (event) {
            this.$emit('showDetail', event)
        },

        onUpdateRow (event) {
            this.$emit('updateRow', event)
        }
    }
}
</script>
