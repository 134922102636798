import ResizeObserver from '../common/ResizeObserver.js'

export default {
    name: 'ResizeObserver',

    inheritAttrs: false,

    data () {
        return {
            rootElement: null,
            width: 0,
            height: 0
        }
    },

    watch: {
        contentRect () {
            this.$emit('resize', this.contentRect)
        },

        rootElement (newElement, oldElement) {
            if (oldElement) {
                ResizeObserver.unobserve(oldElement)
            }
            if (newElement) {
                ResizeObserver.observe(newElement, this.onResize)
            }
        }
    },

    mounted () {
        this.rootElement = this.$el
    },

    updated () {
        if (this.$el !== this.rootElement) {
            this.rootElement = this.$el
        }
    },

    beforeDestroy () {
        ResizeObserver.unobserve(this.rootElement)
    },

    methods: {
        onResize ({ width, height }) {
            if (this.width !== width || this.height !== height) {
                this.width = width
                this.height = height
                this.$emit('resize', {
                    width, height
                })
            }
        }
    },

    render (h) {
        return this.$scopedSlots.default({
            width: this.width,
            height: this.height
        })
    }
}
