import { register } from 'register-service-worker'
import { info, error } from '@/utils/alert.js'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready () {
            // App is being served from cache by a service worker
            // For more details, visit https://goo.gl/AFskqB
        },
        registered () {
            // Service worker has been registered
        },
        cached () {
            // Content has been cached for offline use
        },
        updatefound () {
            // New content is downloading
        },
        updated (registration) {
            const worker = registration.waiting
            worker.postMessage({
                action: 'SKIP_WAITING'
            })
            info('New Wisol version is available. please reload', {
                autoClose: false,
                reload: true
            })
        },
        offline () {
            // No internet connection found. App is running in offline mode
        },
        error (err) {
            error('Error during service worker registration: ' + err.message)
        }
    })
}
