import Vue from 'vue'
import Router from 'vue-router'
import LoginView from '@/views/Login'
import Registry from '@/Registry'

Vue.use(Router)

export default store => {
    const routes = [
        {
            path: '/login/:mode?',
            name: 'Login',
            component: LoginView
        },
        {
            path: '/logout',
            name: 'Logout',
            beforeEnter () {
                store.dispatch('session/logout')
            }
        },
        {
            path: '/',
            name: 'Main',
            component: () => import('@/views/Main'),
            meta: {
                title: 'Wisol',
                icon: 'wisol/logo/medium',
                showInEditorNavbar: true,
                authRequired: true
            }
        },
        {
            path: '/menu/:menuId',
            name: 'Menu',
            component: () => import('@/views/Menu'),
            meta: {
                title: 'Wisol',
                icon: 'wisol/logo/medium',
                authRequired: true
            }
        },
        ...Object.values(Registry.getByType('routes')),
        {
            path: '*',
            redirect: {
                name: 'Main'
            }
        }
    ]
    const router = new Router({
        mode: 'history',
        routes
    })

    router.beforeEach((to, from, next) => {
        if (!to.meta?.authRequired || store.getters['session/isAuthenticated']) {
            next()
        } else {
            next({
                name: 'Login',
                query: to.name !== 'Main'
                    ? {
                        to: to.path
                    }
                    : {}
            })
        }
    })

    router.routes = routes
    return router
}
