<template>
    <div
        :class="classes"
        @click="cancelAutoClose"
        @dblclick="close"
    >
        <div :class="$style.iconContainer">
            <icon
                :name="icon"
                class="icon"
            />
        </div>
        <div
            :class="$style.closeIcon"
            @click="close"
        >
            <icon name="wisol/close" />
        </div>
        <div :class="$style.content">
            <slot />
        </div>
        <div
            v-if="hasButtons"
            :class="$style.buttons"
        >
            <wisol-button
                v-for="(button, index) in buttons"
                :key="index"
                :label="button.label"
                @click="onButtonClick(button)"
            />
        </div>
        <div
            v-if="showProgress"
            :class="progressClasses"
        />
    </div>
</template>

<style lang="scss" module>
    @import "@wisol/theme/variables";

    @mixin alert-style($color) {
        --alert-color: #{$color};
        --alert-background-color: #{whiten-color($color, 0.3)};
        --alert-text-color: #{text-contrast-color(whiten-color($color, 0.3), $text-color)};
        --alert-icon-color: #{text-contrast-color($color, $text-color)};
        --alert-icon-background-color: var(--alert-color);
    }

    .container {
        color: var(--alert-text-color);
        background: var(--alert-background-color);
        margin: 0;
        border-radius: 0;
        border: 0;
        position: relative;
        padding: 0 0 0 3rem;
    }

    .buttons {
        text-align: right;
        padding: 0.5em;
    }

    .content {
        min-height: 2em;
        padding: 0.8em 0.5em;
    }

    .closeIcon {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.5em;
        cursor: pointer;
    }

    .iconContainer {
        color: var(--alert-icon-color);
        background: var(--alert-icon-background-color);
        position: absolute;
        top: 0px;
        left: 0px;
        height: 100%;
        width: 3rem;
        padding: 0.5rem 0;
        text-align: center;
        font-size: 1.5rem;
    }

    .info {
        @include alert-style(map-get($status-colors, 'info'));
    }

    .warning {
        @include alert-style(map-get($status-colors, 'warning'));
    }

    .error {
        @include alert-style(map-get($status-colors, 'error'));
    }

    .success {
        @include alert-style(map-get($status-colors, 'success'));
    }

    .progress {
        height: 5px;
        width: 0;
        background: var(--alert-color);
        border-width: 1px 0 0 0;
        border-style: solid;
        border-color: #0009;
        transition: width 5s linear;
    }

    .progressStart {
        width: 100%;
    }
</style>

<script>
import Icon from '@wisol/libs-icons'
import { Input } from '@wisol/libs-inputs'

export default {
    name: 'WisolAlert',

    components: {
        Icon,
        WisolButton: Input.Button
    },

    inheritAttrs: false,

    props: {
        type: {
            type: String,
            default: 'info'
        },

        autoClose: {
            type: Boolean,
            default: true
        },

        autoCloseDelay: {
            type: Number,
            default: 5000
        },

        buttons: {
            type: Array,
            default: () => []
        }
    },

    data () {
        return {
            startProgress: false,
            autoCloseCancelled: false
        }
    },

    computed: {
        hasButtons () {
            return this.buttons.length > 0
        },

        classes () {
            return [
                this.$style.container,
                this.$style[this.type]
            ]
        },

        icon () {
            switch (this.type) {
                case 'success':
                    return 'fa/solid/check'

                case 'error':
                    return 'fa/solid/exclamation'

                case 'info':
                default:
                    return 'fa/solid/info'
            }
        },

        progressClasses () {
            return {
                [this.$style.progress]: true,
                [this.$style.progressStart]: this.startProgress
            }
        },

        showProgress () {
            return this.autoClose && !this.autoCloseCancelled
        }
    },

    mounted () {
        if (this.autoClose) {
            setTimeout(() => {
                this.autoCloseTimeout = setTimeout(() => {
                    this.close()
                }, this.autoCloseDelay)
                this.startProgress = true
            }, 50)
        }
    },

    methods: {
        close () {
            this.$emit('close')
        },

        onButtonClick (button) {
            this.$emit('button:click', { button })
        },

        cancelAutoClose () {
            this.autoCloseCancelled = true
            if (this.autoCloseTimeout) {
                clearTimeout(this.autoCloseTimeout)
            }
        }
    }
}
</script>
