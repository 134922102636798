import createInput from '../createInput.js'
import UiCode from '../Ui/Code.vue'
import Parser from '../../parser/Json.js'
import TypeObjectValidator from '../../validator/type/Object.js'

export default createInput(UiCode, {
    defaultUiProps: () => ({
        language: 'json'
    }),
    Parser,
    defaultParserOptions: () => ({
        beautify: true
    }),
    validators: () => [
        TypeObjectValidator()
    ]
})
