const rowHasId = row => {
    return '@rowId' in row && row['@rowId'] !== null
}

export default {
    rows (state) {
        return state.rows
    },

    map (state, getters) {
        const map = new Map()
        getters.rows.forEach(row => {
            if (rowHasId(row)) {
                map.set(row['@id'], row)
            }
        })
        return map
    },

    indexMap (state, getters) {
        const map = new Map()
        getters.rows.forEach((row, index) => {
            if (rowHasId(row)) {
                map.set(row['@id'], index)
            }
        })
        return map
    },

    count (state, getters) {
        return getters.rows.length
    },

    totalCount (state) {
        return state.totalCount
    },

    updateRequired (state) {
        return state.updateRequired
    },

    has (state, getters) {
        return id => getters.map.has(id)
    },

    get (state, getters) {
        return id => (getters.map.get(id) || null)
    }
}
