<template>
    <div class="component-view-login">
        <div :class="cardClasses">
            <div class="wisol-gradient" />
            <div class="card-body">
                <div class="logo-container">
                    <img
                        :class="logoClasses"
                        src="../assets/logo-icon-black.svg"
                        alt="Wiges Solutions"
                    >
                </div>
                <form
                    class="login-form"
                    role="form"
                    @keydown.enter="onSubmit"
                >
                    <template v-if="loginToken && mode !== 'user'">
                        <div class="form-line">
                            <button-input
                                :readonly="isPending"
                                :label="$t('session.loginAs', { label: loginLabel })"
                                class="form-input loginAs"
                                @click="doLoginWithToken"
                            />
                        </div>
                        <div class="form-line">
                            <button-input
                                :readonly="isPending"
                                :label="$t('session.switchLogin')"
                                class="form-input"
                                @click="switchUser"
                            />
                        </div>
                    </template>
                    <template v-else>
                        <div class="form-line">
                            <text-input
                                v-model="username"
                                v-auto-focus
                                :placeholder="$t('session.username')"
                                :readonly="isPending"
                                class="form-input"
                            />
                        </div>
                        <div class="form-line">
                            <password-input
                                v-model="password"
                                :placeholder="$t('session.password')"
                                :readonly="isPending"
                                class="form-input"
                            />
                        </div>
                        <div class="form-line">
                            <button-input
                                :readonly="isPending"
                                :label="$t('session.login')"
                                class="form-input"
                                @click="onSubmit"
                            />
                        </div>
                        <div
                            v-if="loginToken"
                            class="form-line"
                        >
                            <button-input
                                :readonly="isPending"
                                :label="$t('session.loginAs', { label: loginLabel })"
                                class="form-input"
                                @click="doLoginWithToken"
                            />
                        </div>
                    </template>
                </form>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-view-login {
        margin: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        @keyframes shake {
            from, to {
                transform: translate3d(0, 0, 0);
            }

            20%, 60% {
                transform: translate3d(-10px, 0, 0);
            }

            40%, 80% {
                transform: translate3d(10px, 0, 0);
            }
        }

        .shake {
            animation-name: shake;
            animation-duration: 0.5s;
            animation-fill-mode: both;
        }

        @keyframes pulse {
            from {
                transform: scale3d(1, 1, 1);
            }

            50% {
                transform: scale3d(1.1, 1.1, 1.1);
            }

            to {
                transform: scale3d(1, 1, 1);
            }
        }

        .pulse {
            animation-name: pulse;
            animation-duration: 1s;
            animation-fill-mode: both;
            animation-iteration-count: infinite;
        }

        .wisol-gradient {
            height: 5px;
            background: $wisol-gradient;
        }

        .card {
            background: #FFF;
            border: 1px solid rgba(0,0,0,.125);
        }

        .card-body {
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 2rem;

            .logo-container {
                width: 120px;
                height: 120px;
                margin-bottom: 2rem;

                .logo {
                    width: 100%;
                    height: 100%;
                }
            }

            .login-form {
                width: 100%;
            }

            .form-line:not(:last-child) {
                margin-bottom: 1em;
            }

            .form-input {
                font-size: 1.2em;
                max-width: 100%;
                width: 300px;
                --input-width: 300px;
            }

            .form-input.loginAs {
                font-size: 2em;
                height: 6em;
            }
        }
    }
</style>

<script>
import { Input as WisolInputs } from '@wisol/libs-inputs'
import { AutoFocus } from '@wisol/utils-focus/directives'

export default {
    name: 'PageLogin',

    directives: {
        AutoFocus
    },

    components: {
        TextInput: WisolInputs.Text,
        PasswordInput: WisolInputs.Password,
        ButtonInput: WisolInputs.Button
    },

    inheritAttrs: false,

    data () {
        return {
            username: '',
            password: ''
        }
    },

    computed: {
        isPending () {
            return this.$store.getters['session/status'] === 'PENDING'
        },

        isError () {
            return this.$store.getters['session/status'] === 'ERROR'
        },

        cardClasses () {
            return {
                card: true,
                shake: this.isError
            }
        },

        logoClasses () {
            return {
                logo: true,
                pulse: this.isPending
            }
        },

        loginToken () {
            return this.$store.state.session.login.token
        },

        loginLabel () {
            return this.$store.state.session.login.label
        },

        mode () {
            return this.$route.params.mode || 'auto'
        }
    },

    created () {
        this.$store.commit('session/RESET_STATUS')
    },

    methods: {
        doLogin (useToken = false) {
            if (!this.isPending) {
                this.$store.dispatch('session/login', useToken
                    ? {
                        loginToken: this.loginToken
                    }
                    : {
                        username: this.username,
                        password: this.password,
                        generateLoginToken: this.mode === 'token'
                    }
                )
                    .then(() => {
                        this.$router.push(this.$route.query?.to || {
                            name: 'Main'
                        })
                    })
                    .then(() => {
                        return Promise.all([
                            this.$store.dispatch('ui/load'),
                            this.$store.dispatch('layout/load')
                        ])
                    })
            }
        },

        doLoginWithToken () {
            return this.doLogin(true)
        },

        onSubmit () {
            return this.doLogin()
        },

        switchUser () {
            this.$router.push({
                name: this.$route.name,
                params: {
                    mode: 'user'
                },
                query: this.$route.query
            })
        }
    }
}
</script>
