<template>
    <wisol-menu
        v-bind="$attrs"
        class="component-wisol-main-menu"
    />
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-main-menu.component-generic-menu {
        overflow-x: auto;
        white-space: nowrap;
        background: #FFFFFF;
        width: 100%;
        max-width: 100%;
        margin: 0;
        padding: 0;
        border: 0;

        &> .component-generic-menu-item:not(:last-child) {
            border: 0;
        }

        &> .component-generic-menu-item {
            display: inline-block;
            vertical-align: middle;
            overflow: visible;
            text-transform: uppercase;
            -webkit-touch-callout: none;
            user-select: none;
            cursor: pointer;

            &> .item-container {
                text-align: center;
                display: inline-block;
                overflow: visible;
                height: 124px;
                color: nth($color-palette-grey, 5);
                outline: 0;
                text-decoration: none;
                padding: 10px 15px;
                position: relative;
                font-size: 11px;

                &> .icon {
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 5px auto 10px auto;
                    width: 60px;
                }

                &> .submenu-arrow {
                    width: 100%;
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    top: auto;
                    height: 1rem;
                    align-items: baseline;
                    justify-content: center;
                }
            }

            &:not(.disabled) > .item-container:hover {
                color: inherit;
            }

            &.disabled > .item-container {
                color: nth($color-palette-grey, 1);
                cursor: default;
            }
        }
    }
</style>

<script>
export default {
    name: 'WisolMainMenu',

    inheritAttrs: false,

    props: {
        isPopup: {
            type: Boolean,
            default: false
        }
    }
}
</script>
