export default {
    LOGIN (state, { token, info }) {
        state.token = token
        state.info = info
        sessionStorage.setItem('session.token', token)
        sessionStorage.setItem('session.info', JSON.stringify(info))
    },

    SET_STATUS (state, { status }) {
        state.status = status
        sessionStorage.setItem('session.status', status)
    },

    RESET_STATUS (state) {
        state.status = null
        sessionStorage.removeItem('session.status')
    },

    SET_TIMEOUT (state) {
        state.timeout = true
    },

    RESET_TIMEOUT (state) {
        state.timeout = false
    },

    SET_USERNAME (state, { info }) {
        state.username = info.user?.id
        sessionStorage.setItem('session.username', state.username)
    },

    SET_FIRME (state, { firme }) {
        state.firme = firme
        sessionStorage.setItem('session.firme', firme)
    },

    RESET_USERNAME (state) {
        state.username = null
        sessionStorage.removeItem('session.username')
    },

    SET_LOGIN_TOKEN (state, { loginToken = null, info }) {
        if (loginToken) {
            state.login.username = info.user?.id
            state.login.label = info.user?.name || state.login.username
            state.login.token = loginToken

            localStorage.setItem('login.username', state.login.username)
            localStorage.setItem('login.label', state.login.label)
            localStorage.setItem('login.token', loginToken)
        }
    },

    RESET_LOGIN_TOKEN (state, { username }) {
        if (state.login.username === username) {
            state.login.username = null
            state.login.label = null
            state.login.token = null

            localStorage.removeItem('login.username')
            localStorage.removeItem('login.label')
            localStorage.removeItem('login.token')
        }
    }
}
