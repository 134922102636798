<template>
    <div
        :class="$style.container"
        @mouseenter="showSubmenu = true"
        @mouseleave="showSubmenu = false"
    >
        <table>
            <tbody>
                <tr>
                    <td
                        rowspan="2"
                        :class="$style.avatar"
                    >
                        <img
                            alt="avatar.svg"
                            src="../../assets/avatar.svg"
                        >
                    </td>
                    <td>
                        <div :class="$style.username">
                            {{ user.name }}
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div :class="$style.company">
                            {{ company.name }}
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <generic-menu
            v-if="showSubmenu"
            :is-popup="true"
            :items="menuItems"
            placement="bottom-start"
            @click="onMenuClick"
        />
        <popup
            v-if="showAboutPopup"
            title="About"
            :color="popupColor"
            @close="showAboutPopup = false"
        >
            <about />
        </popup>
    </div>
</template>

<style lang="scss" module>
    @import "@wisol/theme/variables";
    @import "@wisol/theme/color-palette";

    .container {
        display: flex;
        align-items: center;
        width: 200px;
    }

    .avatar img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }

    .username,
    .company {
        font-size: 0.87em;
        text-transform: uppercase;

    }

    .company {
        color: $text-grey;
        white-space: nowrap;
    }

    :export {
        wisolColors: $color-palette-wisol;
    }
</style>

<script>
import { mapGetters } from 'vuex'
import About from './About.vue'
import Popup from './Popup.vue'

export default {
    name: 'WisolSessionWidget',

    components: {
        About,
        Popup
    },

    inject: ['app'],

    inheritAttrs: false,

    data () {
        return {
            showSubmenu: false,
            showAboutPopup: false
        }
    },

    computed: {
        ...mapGetters('session', [
            'user',
            'company',
            'companies',
            'isEditorUser'
        ]),

        menuItems () {
            return [
                ...this.companiesMenu,
                {
                    '@id': 'about',
                    label: 'About',
                    icon: 'fa/light/info',
                    visible: true,
                    disabled: false,
                    color: '#000000',
                    '@parent': null
                },
                {
                    '@id': 'settings',
                    label: 'Settings',
                    icon: 'fa/light/cog',
                    visible: true,
                    disabled: true,
                    color: '#000000',
                    '@parent': null
                },
                ...this.editorMenu,
                {
                    '@id': 'logout',
                    label: 'Logout',
                    url: '/logout',
                    icon: 'fa/light/sign-out',
                    visible: true,
                    disabled: false,
                    color: '#000000',
                    '@parent': null
                }
            ]
        },

        companiesMenu () {
            if (this.companies.length <= 1) {
                return []
            }
            return [
                {
                    '@id': 'companies',
                    label: 'Switch Company',
                    icon: 'fa/light/exchange',
                    visible: true,
                    disabled: false,
                    color: '#000000',
                    '@parent': null
                },
                ...this.companies.map(company => {
                    return {
                        '@id': 'company:' + company.id,
                        label: company.name,
                        icon: 'fa/light/building',
                        visible: true,
                        disabled: false,
                        color: '#000000',
                        '@parent': 'companies'
                    }
                })
            ]
        },

        editorMenu () {
            if (!this.isEditorUser) {
                return []
            }
            return [
                {
                    '@id': 'editor',
                    label: 'Editor',
                    icon: 'fa/light/edit',
                    visible: true,
                    disabled: false,
                    color: '#000000',
                    '@parent': null
                }
            ]
        },

        popupColor () {
            return this.$style.wisolColors.split(',')[3].trim()
        }
    },

    methods: {
        onMenuClick (item) {
            if (item.url) {
                this.$router.push({
                    path: item.url
                })
                return
            }
            if (item['@id'] === 'about') {
                this.showAboutPopup = true
                return
            }
            if (item['@id'] === 'editor') {
                this.app.openEditorNavbar()
                return
            }
            if (item['@id'].match(/^company:/)) {
                this.$store.dispatch('session/switchFirme', {
                    firme: item['@id'].substr(8)
                })
            }
        }
    }
}
</script>
