import { ref, computed, watch } from 'vue'
import RequiredValidator, { isEmpty, emptyValue } from '../validator/Required'
import EmptyParserFactory from '../parser/Empty'
import ParserFactory from '../parser/Base'
import { PromiseState } from '@wisol/utils-data/functions'

export const createValueData = function (value, continuous = false) {
    return {
        value,
        continuous
    }
}

const requiredValidator = RequiredValidator()
const emptyParser = EmptyParserFactory({
    emptyValue
})
const defaultParser = ParserFactory({
    emptyValue
})

export default function useController ({ readonly, required, value, parser, validators, debounceTimeout, compare, emit, hasFocus }) {
    const serializedValue = ref('')
    const serializeError = ref(null)
    const workingValue = ref(null)
    const parserState = ref(null)
    const valueState = ref(PromiseState(null))
    const localValue = ref(emptyValue)
    const lastEmitedValueData = ref(null)

    const validateValue = value => {
        try {
            if (required.value) {
                requiredValidator(value)
            }
            // only apply validators when value is not empty
            if (!isEmpty(value)) {
                validators.value.forEach(validator => validator(value))
            }
        } catch (err) {
            return err
        }
        return null
    }

    const handleParsedValueDataUpdate = () => {
        if (!parserState.value.isPending) {
            setValueData(parserState.value.result)
        }
    }

    let currentParserPromise
    const parseValueData = valueData => {
        const { value, continuous } = valueData
        const currentParser = (value === '' || value === emptyValue)
            ? emptyParser
            : (parser.value || defaultParser)

        const parserPromise = currentParser.parse(value)
            .then(parsedValue => createValueData(parsedValue, continuous))
        currentParserPromise = parserPromise
        parserState.value = PromiseState(parserPromise)
        parserPromise
            .then(() => {
                if (currentParserPromise === parserPromise) {
                    handleParsedValueDataUpdate()
                }
            })
            .catch(() => { }) // ignore error, error message will be in the parserState
    }

    let debounceTimeoutHandle
    const debounceValue = (value, callback) => {
        if (debounceTimeoutHandle) {
            clearTimeout(debounceTimeoutHandle)
        }
        debounceTimeoutHandle = setTimeout(() => {
            debounceTimeoutHandle = null
            callback(value, false)
        }, debounceTimeout)
        callback(value, true)
    }

    const setValue = (value, debounce = false) => {
        if (debounce) {
            debounceValue(value, (value, continuous) => {
                setValueData(createValueData(value, continuous))
            })
        } else {
            setValueData(createValueData(value))
        }
    }

    const setDisplayedValueData = valueData => {
        if (readonly.value) {
            return
        }
        workingValue.value = valueData.value
        parseValueData(valueData)
    }

    const setDisplayedValue = (value, debounce = false) => {
        if (debounce) {
            debounceValue(value, (value, continuous) => {
                setDisplayedValueData(createValueData(value, continuous))
            })
        } else {
            setDisplayedValueData(createValueData(value))
        }
    }

    const workingError = ref(null)
    const validatorError = computed(() => {
        const error = validateValue(localValue.value)
        return error
    })
    const valueError = computed(() => valueState.value.error)
    const parserError = computed(() => parserState.value?.error || null)
    const error = computed(() => parserError.value || workingError.value || validatorError.value || serializeError.value || valueError.value || null)

    const isBusy = computed(() => parserState.value?.isPending || !isValueReady.value || false)
    const isValueReady = computed(() => !valueState.value.isPending)
    const isValid = computed(() => !error.value)

    const validatedValue = computed(() => {
        if (validatorError.value) {
            return null
        }
        return localValue.value
    })
    const displayedValue = computed(() => {
        if (!readonly.value && hasFocus.value) {
            if (workingValue.value === null) {
                return serializedValue.value
            }
            return workingValue.value
        }
        return serializedValue.value
    })

    const serializeValue = () => {
        const value = validatedValue.value
        try {
            if (value === emptyValue) {
                serializedValue.value = emptyParser.serialize(value)
            } else {
                serializedValue.value = (parser.value || defaultParser).serialize(value)
            }
            serializeError.value = null
        } catch (err) {
            serializeError.value = err
            serializedValue.value = ''
        }
    }

    const emitValueData = valueData => {
        emit('update:value', valueData)
        emit('update:model-value', valueData.value)
    }

    const setValueData = valueData => {
        if (readonly.value) {
            return
        }

        workingError.value = validateValue(valueData.value)
        if (
            !workingError.value &&
            !compare(validatedValue.value, valueData.value)
        ) {
            lastEmitedValueData.value = valueData
            emitValueData(valueData)
        } else {
            lastEmitedValueData.value = null
        }
    }

    watch(value, () => {
        valueState.value = PromiseState(value.value)
        lastEmitedValueData.value = null
    }, {
        immediate: true
    })

    watch(validatedValue, () => serializeValue(), {
        immediate: true
    })

    watch(isValueReady, () => {
        if (isValueReady.value) {
            localValue.value = valueState.value.result
        }
    }, {
        immediate: true
    })

    const handlePendingDebouncedValue = () => {
        // send value again if last value was continuous, but as non continuous
        if (debounceTimeoutHandle) {
            clearTimeout(debounceTimeoutHandle)
        }
        if (lastEmitedValueData.value) {
            const { continuous, value } = lastEmitedValueData.value
            lastEmitedValueData.value = null
            if (continuous) {
                emitValueData(createValueData(value))
            }
        }
    }

    const onBlur = () => {
        handlePendingDebouncedValue()
        workingValue.value = null
        parserState.value = null
        workingError.value = null
    }

    const confirmValue = () => handlePendingDebouncedValue()

    watch(hasFocus, () => {
        if (!hasFocus.value) {
            onBlur()
        }
    })

    return {
        value: validatedValue,
        displayedValue,
        isBusy,
        isValid,
        error,
        setDisplayedValue,
        setValue,
        confirmValue
    }
}
