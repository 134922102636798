const context = require.context('.', true, /\.vue$/)
const components = context.keys()
    .reduce((components, key) => {
        const component = context(key).default
        const name = component instanceof Function && component.options
            ? component.options.name
            : component.name
        if (name) {
            components[name] = component
        }
        return components
    }, {})

export default components

export function install (Vue) {
    Object.keys(components).forEach(name => {
        Vue.component(name, components[name])
    })
}
