import { Duration } from 'luxon'

export default ({
    mode = 'duration'
}) => {
    return {
        async parse (raw) {
            return new Promise((resolve, reject) => {
                let hours = 0
                if (mode === 'duration') {
                    if (isNaN(raw)) {
                        reject(new Error(`"${raw}" is not a number`))
                        return
                    }
                    hours = parseFloat(raw)
                } else if (mode === 'time') {
                    if (!raw.match(/^[0-9]+:[0-9]{1,2}$/)) {
                        reject(new Error(`invalid time format "${raw}"`))
                        return
                    }
                    const parts = raw.split(':')
                    hours = parseFloat(parts[0]) + (parseFloat(parts[1]) / 60)
                }
                if (isNaN(hours)) {
                    reject(new Error(`"${hours}" is not a number`))
                    return
                }
                const millis = hours * (60 * 60 * 1000)
                const value = Duration.fromMillis(millis)
                if (!value.isValid) {
                    reject(new Error(value.invalidExplanation || value.invalidReason || 'invalid'))
                } else {
                    resolve(value.normalize())
                }
            })
        },

        serialize (duration) {
            if (!Duration.isDuration(duration)) {
                throw new Error('invalid type: luxon Duration required')
            }
            if (!duration.isValid) {
                throw new Error(duration.invalidExplanation || duration.invalidReason || 'invalid')
            }

            if (mode === 'duration') {
                return '' + (duration.as('hours'))
            }
            if (mode === 'time') {
                duration = duration.shiftTo('hours', 'minutes', 'milliseconds').normalize()
                const hours = ('' + duration.hours).padStart(2, '0')
                const minutes = ('' + duration.minutes).padStart(2, '0')
                return `${hours}:${minutes}`
            }
            return '0'
        }
    }
}
