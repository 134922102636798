export default ({
    emptyValue = null
} = {}) => {
    return {
        async parse (raw) {
            if (raw === '' || raw === emptyValue) {
                return emptyValue
            }

            throw new Error('value is not empty')
        },

        serialize (parsedValue) {
            if (parsedValue === emptyValue) {
                return ''
            }

            throw new Error('value is not empty')
        }
    }
}
