<template>
    <div class="component-wisol-editor-toolbar">
        <generic-editor-toolbar>
            <div class="group">
                <div
                    :class="saveButtonClass"
                    class="button"
                    title="Save"
                    @click="doAction('save')"
                >
                    <icon
                        name="fa/regular/save"
                        class="icon-button-save"
                    />
                </div>
            </div>
        </generic-editor-toolbar>
    </div>
</template>

<style lang="scss">
    .component-wisol-editor-toolbar {

    }
</style>

<script>
import GenericEditorToolbar from '../generic/Toolbar'
import Icon from '@wisol/libs-icons'

export default {
    name: 'WisolEditorConfigToolbar',

    components: {
        genericEditorToolbar: GenericEditorToolbar,
        Icon
    },

    inheritAttrs: false,

    props: {
        isDirty: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {

        }
    },

    computed: {
        saveButtonClass () {
            return {
                disabled: !this.isDirty
            }
        }
    },

    methods: {
        doAction (action) {
            this.$emit('action', action)
        },
        onMenuClick (event) {
            this.doAction(event['@id'])
        }
    }
}
</script>
