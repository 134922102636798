export default ({
    root = 'hashmap'
}) => {
    const exists = (state, id) => {
        return ('id:' + id) in state[root]
    }

    const byId = (state, id) => {
        if (!exists(state, id)) {
            return undefined
        }
        return state[root]['id:' + id]
    }

    const byIdList = (state, idList) => {
        return idList
            .map(id => byId(state, id))
            .filter(item => item !== undefined)
    }

    const all = (state) => {
        return Object.keys(state[root])
            .filter(key => key.substr(0, 3) === 'id:')
            .map(rawId => state[root][rawId])
    }

    const nextId = (state) => {
        return state[root]['@nextId'] || 1
    }

    return {
        exists: state => id => exists(state, id),

        byId: state => id => byId(state, id),

        byIdList: (state, getters) => idList => byIdList(state, idList),

        all: state => all(state),

        nextId: state => nextId(state)
    }
}
