<template>
    <div
        :style="style"
        class="component-wisol-window-tab"
    >
        <div
            :class="iconClasses"
        />
        <div class="title">
            {{ menu.label }}
        </div>
    </div>
</template>

<style>
</style>

<script>
import ColorsMixin from '@/mixins/Colors'

export default {
    name: 'WisolWindowTab',

    mixins: [ColorsMixin],

    inheritAttrs: false,

    props: {
        id: {
            type: Number,
            required: true
        }
    },

    computed: {
        meta () {
            return this.$store.getters['windows/byId'](this.id)
        },

        menu () {
            return this.$store.getters['ui/menu/byId'](this.meta['@menu'])
        },

        iconClasses () {
            return [
                'icon',
                this.menu.icon
            ]
        },

        style () {
            return this.headerColorStyle(this.menu.color)
        }
    }
}
</script>
