<template>
    <div class="component-wisol-editor-apps-view-apps">
        <div
            class="app-list"
        >
            <table>
                <tbody>
                    <tr
                        v-if="newApp === null"
                        class="addApp"
                        title="New App"
                        @click="addApp"
                    >
                        <td>
                            <icon
                                class="newAppIcon"
                                name="fa/light/plus"
                            />
                        </td>
                    </tr>
                    <tr
                        v-else
                        :class="saveButtonClasses"
                        class="addApp"
                        title="Save App"
                        @click="saveApp"
                    >
                        <td>
                            <icon
                                class="newAppIcon"
                                name="fa/light/save"
                            />
                        </td>
                    </tr>
                    <tr
                        v-if="newApp !== null"
                        class="newApp active"
                    >
                        <td
                            @key.down.enter="saveApp"
                        >
                            <div class="text-input">
                                <text-input
                                    v-model="newApp.name"
                                    class="field"
                                />
                            </div>
                            <div class="text-input">
                                <text-input
                                    v-model="newApp.key"
                                    class="field"
                                />
                            </div>
                        </td>
                    </tr>
                    <tr
                        v-for="app in appsList"
                        :key="app.key"
                        :class="listItemClasses(app)"
                        @click="selectApp(app)"
                    >
                        <td>
                            <h5>{{ app.name }}</h5>
                            {{ app.key }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div
            v-if="selectedApp"
            class="app-detail"
        >
            <div class="header">
                <div class="name">
                    <h1>{{ selectedApp.name }}</h1>
                    <div
                        v-if="isAppRevoked(selectedApp)"
                        class="button enable"
                        title="Enable App"
                        @click="enableApp(selectedApp['@id'])"
                    >
                        Enable
                    </div>
                    <div
                        v-else
                        class="button revoke"
                        title="Revoked App"
                        @click="revokeApp(selectedApp['@id'])"
                    >
                        Disable
                    </div>
                </div>
                <hr>
                <div class="key-container">
                    <div class="key">
                        <pre>{{ selectedApp.key }}</pre>
                    </div>
                </div>
            </div>
            <div class="detail">
                <div class="access">
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <h5>Users</h5>
                                </td>
                                <td>
                                    <h5>Devices</h5>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="users">
                                    <array
                                        :value="userList"
                                        :unique="true"
                                        class="array"
                                        placeholder="Add user..."
                                        @add="addUser"
                                        @remove="removeUser"
                                    />
                                </td>
                                <td class="devices">
                                    <array
                                        :value="deviceList"
                                        :unique="true"
                                        :input="deviceSelectBox"
                                        class="array"
                                        placeholder="Add device..."
                                        @add="addDevice"
                                        @remove="removeDevice"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="methods">
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <h5>#</h5>
                                </td>
                                <td>
                                    <h5>Method</h5>
                                </td>
                                <td>
                                    <h5>URI</h5>
                                </td>
                                <td>
                                    <h5>Last usage</h5>
                                </td>
                                <td>
                                    <h5>Creation</h5>
                                </td>
                                <td />
                            </tr>
                            <tr>
                                <td />
                                <td>
                                    <selectbox
                                        v-model="newEndpoint.method"
                                        :options="methods"
                                        class="selectbox"
                                    />
                                </td>
                                <td>
                                    <text-input
                                        v-model="newEndpoint.uri"
                                        class="selectbox"
                                    />
                                </td>
                                <td>
                                    <button-input
                                        label="Add"
                                        @click="saveEndpoint"
                                    />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="endpoint in endpointsList"
                                :key="endpoint['@id']"
                                :class="endpointClasses(endpoint)"
                            >
                                <td class="index">
                                    {{ endpoint['@id'] }}
                                </td>
                                <td class="method">
                                    {{ endpoint.method.toUpperCase() }}
                                </td>
                                <td class="uri">
                                    {{ endpoint.uri }}
                                </td>
                                <td class="usage">
                                    {{ endpoint.lastusage_date }}
                                </td>
                                <td class="creation">
                                    {{ endpoint.creation_date }} - {{ endpoint.creation_user }}
                                </td>
                                <td
                                    class="action"
                                    @click="revokeEndpoint(endpoint['@id'])"
                                >
                                    <icon name="fa/light/ban" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <template
            v-if="loading"
        >
            <wisol-loading />
        </template>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-apps-view-apps {
        display: flex;
        width: 100%;
        height: 100vh;
        overflow: auto;
        box-sizing: border-box;

        & .app-list {
            min-width: 600px;
            padding: 20px 20px 0px 20px;
            heigth: 100%;

            table {
                .name {
                    width: 100%;
                }

                width: 100%;

                tbody {
                    tr {
                        cursor: pointer;

                        &:hover {
                            td {
                                background-color: nth($color-palette-grey, 3);
                            }
                        }
                        &:active {
                            td {
                                background-color: nth($color-palette-grey, 2);
                            }
                        }
                        &.active {
                            td {
                                background-color: nth($color-palette-grey, 3);
                            }
                        }

                        &.revoked {
                            td {
                                background-color: nth($color-palette-status, 3);
                            }
                            &:hover {
                                td {
                                    background-color: darken(nth($color-palette-status, 3), 5%);
                                }
                            }
                            &:active {
                                td {
                                    background-color: nth($color-palette-status, 3);
                                }
                            }
                            &.active {
                                td {
                                    background-color: darken(nth($color-palette-status, 3), 5%);
                                }
                            }
                        }

                        &.addApp {
                            text-align: center;

                            &.disabled {
                                color: nth($color-palette-grey, 4);
                                cursor: not-allowed;

                                &:hover {
                                    td {
                                        background-color: nth($color-palette-grey, 2);
                                    }
                                }
                            }

                            &:active {
                                td {
                                    background-color: nth($color-palette-grey, 2);
                                }
                            }
                        }

                        &.newApp {
                            .text-input {
                                .field{
                                    width: 100%;
                                }
                            }
                            .text-input:first-child {
                                margin-bottom: 5px;
                            }
                        }

                        td {
                            height: 60px;
                            background-color: nth($color-palette-grey, 2);
                            padding: 10px;
                            border: 2px solid white;

                            h5 {
                                font-weight: bold;
                            }

                            .newAppIcon {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }

        &> .app-detail {
            width: 80%;
            padding: 20px 20px 0px 20px;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            overflow-y: scroll;

            &> .header {
                margin-bottom: 50px;

                &> .name {
                    display: flex;
                    align-items: center;

                    h1 {
                        margin: 0px 10px 0px 0px;
                    }

                    &> .button {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: 60px;
                        height: 30px;
                        cursor: pointer;
                        border-radius: 5px;

                        &.revoke {
                            border: 1px solid nth($color-palette-status, 7);
                            color: nth($color-palette-status, 7);

                            &:hover {
                                background-color: nth($color-palette-status, 7);
                                color: white;
                            }
                        }

                        &.enable {
                            border: 1px solid nth($color-palette-main, 15);
                            color: nth($color-palette-main, 15);

                            &:hover {
                                background-color: nth($color-palette-main, 15);
                                color: white;
                            }
                        }
                    }
                }

                &> .key-container {
                    display: flex;
                    align-items: center;

                    &> .key {
                        display: inline-flex;
                        border: 1px solid nth($color-palette-grey, 3);
                        padding: 10px;
                        border-radius: 5px 5px 5px 5px;
                        font-size: 1.3em;
                        background-color: nth($color-palette-grey, 1);
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                        margin: 5px 0px 5px 0px;
                    }
                }
            }

            &> .detail {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;

                &> .access {
                    display: flex;
                    flex-basis: 100%;
                    margin-bottom: 50px;

                    table {
                        width: 100%;

                        .users {
                            width: 50%;
                        }
                        .devices {
                            width: 50%;
                        }

                        .array {
                            width: 100%;
                        }

                        thead {
                            tr:first-child {
                                border-bottom: 1px solid nth($color-palette-grey, 3);
                            }

                            td {
                                padding-left: 2px;
                            }
                        }

                        tbody {
                            tr {
                                td {
                                    height: 60px;
                                    border: 1px solid white;
                                }
                            }
                        }
                    }

                }
                &> .methods {
                    display: flex;
                    flex-basis: 100%;
                    table {
                        width: 100%;
                        .index {
                            width: 5%;
                        }
                        .method {
                            width: 10%;
                        }
                        .uri {
                            width: 35%;
                        }
                        .usage {
                            width: 23%;
                        }
                        .creation {
                            width: 23%;
                        }
                        .action {
                            width: 4%;
                        }

                        thead {
                            tr:first-child {
                                border-bottom: 1px solid nth($color-palette-grey, 3);
                            }

                            tr:last-child {
                                height: 50px;
                                td {
                                    border: 2px solid white;

                                    &> .selectbox {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                td {
                                    height: 60px;
                                    background-color: nth($color-palette-grey, 2);
                                    padding: 10px;
                                    border: 2px solid white;

                                    h5 {
                                        font-weight: bold;
                                    }

                                    &:last-child {
                                        text-align: center;
                                        cursor: pointer;
                                        &:hover {
                                            color: red;
                                        }

                                        svg {
                                            width: 20px;
                                            height: 20px;
                                        }
                                    }

                                    &:not(:last-child) {
                                        background-color: nth($color-palette-status, 4);
                                    }
                                }
                            }

                            tr.revoked {
                                td:not(:last-child) {
                                    background-color: nth($color-palette-status, 3);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>

<script>
import requests from '../mixins/requests.js'
import { Input as Inputs } from '@wisol/libs-inputs'
import { success } from '@/utils/alert'
import Icon from '@wisol/libs-icons'

export default {
    name: 'WisolEditorAppsViewApps',

    components: {
        selectbox: Inputs.Selectbox,
        'text-input': Inputs.Text,
        'button-input': Inputs.Button,
        array: Inputs.Array,
        Icon
    },

    mixins: [
        requests()
    ],

    inheritAttrs: false,

    data () {
        return {
            appsList: [],
            newApp: null,
            selectedApp: null,
            endpointsList: [],
            newEndpoint: {
                '@app': null,
                method: 'get',
                uri: ''
            },
            methods: {
                get: 'GET',
                put: 'PUT',
                post: 'POST',
                delete: 'DELETE'
            },
            defaultMethod: 'get',
            devices: [],
            activeDevices: [],
            users: [],
            currUserRequestHandler: null,
            currDeviceRequestHandler: null,
            loading: true
        }
    },

    computed: {
        isSaveAppDisabled () {
            if (this.newApp) {
                return this.newApp.name === '' || this.newApp.key === ''
            } else {
                return true
            }
        },

        isSaveEndpointDisabled () {
            if (this.newEndpoint) {
                return this.newEndpoint.method === '' || this.newEndpoint.uri === ''
            } else {
                return true
            }
        },

        saveButtonClasses () {
            return {
                disabled: this.isSaveAppDisabled
            }
        },

        userList () {
            return JSON.stringify(this.users)
        },

        deviceList () {
            return JSON.stringify(this.devices.map((device) => {
                return device.name
            }))
        },

        deviceSelectBox () {
            const props = {
                options: {}
            }

            // set options for selectbox
            this.activeDevices.forEach((device) => {
                props.options[device.name] = device.name
            })

            return {
                component: Inputs.Selectbox,
                props
            }
        }
    },

    mounted () {
        this.asyncGetList()
    },

    methods: {
        asyncGetList () {
            this.loading = true
            return this.getApps()
                .then((response) => this.handleGetAppsResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncSaveApp (app) {
            this.loading = true
            return this.postApp(app)
                .then((response) => this.handleSaveAppResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncRevokeApp (id) {
            this.loading = true
            return this.deleteApp(id)
                .then((response) => this.handleUpdateAppResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncEnableApp (id) {
            this.loading = true
            return this.putApp(id)
                .then((response) => this.handleUpdateAppResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncGetEndpoints (id) {
            this.loading = true
            return this.getEndpoints(id)
                .then((response) => this.handleGetEndpointsResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncSaveEndpoint (endpoint) {
            this.loading = true
            return this.postEndpoint(endpoint)
                .then((response) => this.handleSaveEndpointResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncRevokeEndpoint (id) {
            this.loading = true
            return this.deleteEndpoint(id)
                .then((response) => this.handleUpdateEndpointResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncGetUsers (id) {
            return this.getUsers(id)
                .then(this.createUserRequestHandler())
                .catch((error) => this.handleRequestError(error))
        },

        asyncSaveUser (user) {
            return this.postUser(user)
                .then(this.createUserRequestHandler())
                .catch((error) => this.handleRequestError(error))
        },

        asyncRemoveUser (user) {
            return this.deleteUser(user)
                .then(this.createUserRequestHandler())
                .catch((error) => this.handleRequestError(error))
        },

        asyncGetDevices (id) {
            return this.getAppDevices(id)
                .then((response) => this.handleDeviceRequestResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncGetActiveDevices () {
            return this.getActiveDevices()
                .then((response) => this.handleGetActiveDevicesResponse(response))
                .catch((error) => this.handleRequestError(error))
        },

        asyncGetValidatedDevices (id) {
            return this.getValidatedDevices(id)
                .then(this.createDeviceRequestHandler())
                .catch((error) => this.handleRequestError(error))
        },

        asyncSaveDevice (device) {
            return this.postDeviceToApp(device)
                .then(this.createDeviceRequestHandler())
                .catch((error) => this.handleRequestError(error))
        },

        asyncRemoveDeviceFromApp (device) {
            return this.deleteDeviceToApp(device)
                .then(this.createDeviceRequestHandler())
                .catch((error) => this.handleRequestError(error))
        },

        createUserRequestHandler () {
            const handler = ({ data }) => {
                if (this.currUserRequestHandler === handler) {
                    this.loading = false
                    this.users = data
                }
            }
            this.currUserRequestHandler = handler
            return handler
        },

        createDeviceRequestHandler () {
            const handler = ({ data }) => {
                if (this.currDeviceRequestHandler === handler) {
                    this.loading = false
                    this.devices = data
                }
            }
            this.currDeviceRequestHandler = handler
            return handler
        },

        handleGetAppsResponse ({ data }) {
            this.loading = false
            this.appsList = data
        },

        handleSaveAppResponse ({ data }) {
            this.loading = false
            success('Successfully saved app!')
            this.appsList = [
                data,
                ...this.appsList
            ]
            this.selectApp(this.appsList[0])
            this.newApp = null
        },

        handleUpdateAppResponse ({ data }) {
            this.loading = false
            const index = this.appsList.findIndex((app) => {
                return app['@id'] === data['@id']
            })

            this.appsList = [
                ...this.appsList.slice(0, index),
                data,
                ...this.appsList.slice(index + 1, this.appsList.length + 1)
            ]

            this.selectApp(this.appsList[index])
        },

        handleGetEndpointsResponse ({ data }) {
            this.loading = false
            this.endpointsList = data
        },

        handleSaveEndpointResponse ({ data }) {
            this.loading = false
            this.endpointsList = data
            this.initializeNewEndpoint()
        },

        handleUpdateEndpointResponse ({ data }) {
            this.loading = false
            const index = this.endpointsList.findIndex((endpoint) => {
                return endpoint['@id'] === data['@id']
            })

            this.endpointsList = [
                ...this.endpointsList.slice(0, index),
                data,
                ...this.endpointsList.slice(index + 1, this.endpointsList.length + 1)
            ]
        },

        handleGetActiveDevicesResponse ({ data }) {
            this.loading = false
            this.activeDevices = data
        },

        handleDeviceRequestResponse ({ data }) {
            this.loading = false
            this.devices = data
        },

        handleRequestError (error) {
            this.loading = false
            const err = error.response.data
            alert(this.$store, err.code + ': ' + err.message)
        },

        addApp () {
            this.newApp = {
                name: '',
                key: ''
            }
        },

        saveApp () {
            if (!this.isSaveAppDisabled) {
                this.asyncSaveApp(this.newApp)
            }
        },

        selectApp (app) {
            this.selectedApp = app
            // Get Endpoints for selected App
            this.asyncGetEndpoints(app['@id'])

            // Get Users for selected App
            this.asyncGetUsers(app['@id'])

            // Get Devices for selected App
            this.asyncGetValidatedDevices(app['@id'])

            // Get active devices available
            this.asyncGetActiveDevices()

            this.initializeNewEndpoint()
        },

        initializeNewEndpoint () {
            this.newEndpoint = {
                '@app': this.selectedApp['@id'],
                method: this.defaultMethod,
                uri: ''
            }
        },

        isAppActive (app) {
            return this.selectedApp === app
        },

        isAppRevoked (app) {
            return app.revokation_date !== null
        },

        isEndpointRevoked (endpoint) {
            return endpoint.revokation_date !== null
        },

        listItemClasses (app) {
            return {
                active: this.isAppActive(app),
                revoked: this.isAppRevoked(app)
            }
        },

        endpointClasses (endpoint) {
            return {
                revoked: this.isEndpointRevoked(endpoint)
            }
        },

        revokeApp (id) {
            this.asyncRevokeApp(id)
        },

        enableApp (id) {
            this.asyncEnableApp(id)
        },

        saveEndpoint () {
            if (!this.isSaveEndpointDisabled) {
                this.asyncSaveEndpoint(this.newEndpoint)
            }
        },

        revokeEndpoint (id) {
            this.asyncRevokeEndpoint(id)
        },

        addUser (user) {
            this.currUserPromise = this.asyncSaveUser({
                '@app': this.selectedApp['@id'],
                user: user
            })
        },

        removeUser (user) {
            this.currUserPromise = this.asyncRemoveUser({
                '@app': this.selectedApp['@id'],
                user: user
            })
        },

        getActiveDeviceIdByName (name) {
            return this.activeDevices.find((device) => {
                return device.name === name
            })['@id']
        },

        addDevice (device) {
            this.asyncSaveDevice({
                '@id': this.getActiveDeviceIdByName(device),
                '@app': this.selectedApp['@id']
            })
        },

        removeDevice (name) {
            const device = this.devices.find((device) => {
                return device.name === name
            })

            this.asyncRemoveDeviceFromApp({
                '@id': device['@id'],
                '@app': this.selectedApp['@id']
            })
        }
    }
}
</script>
