import log from 'loglevel'

export default {
    async login ({ commit, dispatch }, { username, password, generateLoginToken = false, loginToken = null }) {
        commit('SET_STATUS', {
            status: 'PENDING'
        })
        try {
            const requestConfig = {
                headers: {
                    'Wisol-Api-App-Key': '0ddb93f7-ba08-4675-ab33-331b306a8c86',
                    'Wisol-Api-Device-Key': '9a256af7-e588-45c5-8295-c30b31b1d3a1'
                }
            }
            if (loginToken) {
                requestConfig.headers.Authorization = 'Wisol-Login-Token ' + loginToken
            } else {
                requestConfig.auth = { username, password }
            }

            const { data } = await this.$http.post('api/ui/endpoint', {
                '@endpoint': 'token.generate',
                generateLoginToken,
                getWisolInfo: true,
                loadPermissions: true
            }, requestConfig)
            commit('SET_USERNAME', data)
            commit('LOGIN', data)
            commit('SET_LOGIN_TOKEN', data)
            commit('SET_FIRME', {
                firme: data.info.company.id
            })
            commit('RESET_STATUS')
        } catch (err) {
            commit('SET_STATUS', {
                status: 'ERROR'
            })
            commit('RESET_LOGIN_TOKEN', { username })
            await dispatch('alert/create', {
                data: {
                    type: 'error',
                    message: 'Login Failed'
                }
            }, {
                root: true
            })
            return Promise.reject(err)
        }
    },

    async logout () {
        this.$api.endpoint('token.revoke')
            .catch(err => {
                log.error('Unable to logout from server', err)
            })
            .then(() => {
                Object.keys(sessionStorage).forEach(key => {
                    if (key.match(/^session\./)) {
                        sessionStorage.removeItem(key)
                    }
                })
                Object.keys(localStorage).forEach(key => {
                    if (key.match(/^session\./)) {
                        localStorage.removeItem(key)
                    }
                })
            })
            .then(() => {
                const urlParams = new URLSearchParams(window.location.search)
                const redirect = urlParams.get('to')
                location.href = redirect
            })
            .catch(err => {
                log.error('Error while clearing state from local storage', err)
            })
    },

    async switchFirme ({ commit }, { firme }) {
        commit('SET_FIRME', { firme })
    }
}
