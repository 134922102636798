<template>
    <tree-handle-wrapper v-if="readonly">
        <component
            :is="column.input(row).component"
            :class="$style.input"
            v-bind="column.input(row).props"
            :readonly="true"
            :value="value"
        />
    </tree-handle-wrapper>
    <edit-toggle-container
        v-else
        :edit-mode="column.editMode"
    >
        <template #default="{ showEdit, edit, canEnableEdit }">
            <div :class="$style.editContainer">
                <tree-handle-wrapper>
                    <component
                        :is="column.input(row).component"
                        :class="$style.input"
                        v-bind="column.input(row).props"
                        :readonly="!showEdit"
                        :value="value"
                        @update:value="onUpdateValue"
                        @click="onInputClick"
                    />
                </tree-handle-wrapper>
                <div
                    v-if="canEnableEdit && !showEdit"
                    :class="$style.editIcon"
                    @click.stop="edit"
                >
                    <icon name="fa/solid/pencil" />
                </div>
            </div>
        </template>
    </edit-toggle-container>
</template>

<style lang="scss" module>
.input {
    width: 100%;
}

.editIcon {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 2em;
    height: 2em;
    max-height: 100%;
    text-align: center;
    cursor: pointer;

    path {
        fill: #999;
    }

    &:hover {
        path {
            fill: #000;
        }
    }
}

.editContainer {
    height: 100%;
    width: 100%;
    display: flex;
}

.editContainer:hover .editIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>

<script>
import Vue from 'vue'
import { TreeHandle, EditToggleContainer } from '@wisol/libs-listing'
import Icon, { register as registerIcons } from '@wisol/libs-icons'
import EditIcon from '@wisol/libs-icons/src/icons/fa/solid/pencil'

registerIcons(EditIcon)

// small helper components
const TreeHandleWrapper = {
    functional: true,
    inject: ['listingCell'],
    render (h, ctx) {
        if (ctx.injections.listingCell.column.showTreeHandle) {
            return h(TreeHandle, ctx.scopedSlots.default({}))
        }
        return ctx.scopedSlots.default({})
    }
}

export default {
    components: {
        TreeHandleWrapper,
        EditToggleContainer,
        Icon
    },

    inheritAttrs: false,

    props: {
        column: {
            type: Object,
            required: true,
            validator: column => 'width' in column
        },

        row: {
            type: Object,
            required: true
        },

        dataGroup: {
            type: Vue,
            required: true
        }
    },

    computed: {
        readonly () {
            return this.dataGroup.readonly || this.column.readonly || this.row.readonly
        },

        value () {
            const values = this.dataGroup.getRowValues(this.row['@row'])
            if (this.column.field in values) {
                return values[this.column.field]
            }
            return ''
        }
    },

    methods: {
        onUpdateValue ({ value, continuous }) {
            if (!continuous) {
                this.dataGroup.setRowValues(this.row['@row'], {
                    [this.column.field]: value
                })
            }
        },

        onInputClick ({ field, setBusyPromise }) {
            if (this.dataGroup.clickScriptFunction) {
                setBusyPromise(
                    this.dataGroup.clickScriptFunction(
                        this.dataGroup.getRowType(this.row['@row']),
                        this.row['@row'],
                        field.field
                    )
                )
            }
        }
    }
}
</script>
