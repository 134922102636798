export default ({ action, params = {} }) => {
    return {
        async load ({ commit }) {
            const { result } = await this.$api.call(action, params)
            commit('LOAD_ITEMS', {
                items: result
            })
        }
    }
}
