export default () => {
    return {
        methods: {
            getBackups () {
                return this.$http
                    .get(
                        'module/ui/backup/index'
                    )
            },

            createBackup () {
                return this.$http
                    .get(
                        'module/ui/backup/create'
                    )
            },

            restoreBackup (file) {
                return this.$http
                    .get(
                        'module/ui/backup/restore/' + file
                    )
            },

            downloadBackup (file) {
                return this.$http
                    .get(
                        'module/ui/backup/download/' + file,
                        {
                            responseType: 'blob'
                        }
                    )
            },

            restoreFile (data) {
                return this.$http
                    .post(
                        'module/ui/backup/upload/',
                        data,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    )
            },

            resetBackup () {
                return this.$http
                    .get(
                        'module/ui/backup/reset'
                    )
            }
        }
    }
}
