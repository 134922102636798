<template>
    <div
        :ref="'graph:' + parent['@id']"
        class="component-wisol-editor-menu-graph-dataGroup-link-graph"
    >
        <div
            class="toolbar"
        >
            <div
                class="center"
                title="Center & fit"
                @click="fit"
            >
                <icon
                    width="20"
                    height="20"
                    name="fa/light/crosshairs"
                />
            </div>
            <div
                class="refresh"
                title="Refresh"
                @click="refresh"
            >
                <icon
                    width="20"
                    height="20"
                    name="fa/light/sync-alt"
                />
            </div>
        </div>

        <network
            v-if="isRefreshed"
            v-show="isReady"
            ref="network-graph"
            :nodes="network.nodes"
            :edges="network.edges"
            :options="network.options"
            class="network"
            @double-click="handleDblClick"
            @start-stabilizing="startStabilizing"
            @stabilized="stabilized"
            @stabilization-iterations-done="iterationsDone"
        />
        <div v-if="!isReady">
            Loading graph...
        </div>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-menu-graph-dataGroup-link-graph {
        .network {
            height: 600px;
            margin: 5px 0;
        }

        .toolbar {
            display: flex;
            position: absolute;
            right: 50px;

            .refresh {
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 9999;
                cursor: pointer;
                padding: 10px;
            }

            .center {
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 9999;
                cursor: pointer;
                padding: 10px;
            }
        }
    }

</style>

<script>
import Icon from '@wisol/libs-icons'

export default {
    name: 'WisolEditorMenuGraphDatagroupLinkGraph',

    components: {
        Network: () => import('./VisNetwork.js'),
        Icon
    },

    inheritAttrs: false,

    props: {
        parent: {
            type: Object,
            required: true
        }
    },

    data () {
        return {
            isReady: false,
            isRefreshed: true
        }
    },

    computed: {
        network () {
            return {
                nodes: this.nodes,
                edges: this.edges,
                options: this.options
            }
        },

        options () {
            return {
                nodes: {
                    shape: 'box',
                    font: {
                        color: 'black',
                        size: 14,
                        face: 'Lato'
                    }
                },
                edges: {
                    font: {
                        size: 12
                    }
                },
                interaction: {
                    hover: true,
                    dragNodes: false,
                    zoomView: false
                },
                physics: {
                    enabled: true,
                    forceAtlas2Based: {
                        avoidOverlap: 1
                    },
                    stabilization: {
                        enabled: true,
                        iterations: 500,
                        fit: true
                    }
                }
            }
        },

        nodes () {
            const nodes = []
            this.parentDatagroups.forEach((dg) => {
                nodes.push({
                    id: dg['@id'],
                    label: dg['@label'],
                    widthConstraint: {
                        minimum: 80,
                        maximum: 150
                    },
                    color: {
                        background: '#B2DFDB',
                        border: '#80CBC4',
                        highlight: {
                            background: '#FFECB3',
                            border: '#FFD54F'
                        },
                        hover: {
                            background: '#E0F2F1',
                            border: '#80CBC4'
                        }
                    },
                    chosen: {
                        label: false
                    }
                })
            })

            return nodes
        },

        edges () {
            const edges = []
            this.dataGroupLinks.forEach((link) => {
                edges.push({
                    id: link['@id'],
                    from: link['@source'],
                    to: link['@target'],
                    arrows: 'to',
                    label: link.type,
                    font: {
                        align: 'top'
                    },
                    length: '150',
                    hoverWidth: 1,
                    labelHighlightBold: false
                })
            })

            return edges
        },

        allDatagrouplinks () {
            const result = this.$store.getters['ui/dataGroupLink/all']

            return result.map((dataGroupLink) => {
                return {
                    ...dataGroupLink,
                    '@idType': 'database',
                    '@itemtype': 'dataGroupLink'
                }
            })
        },

        parentDatagroups () {
            return this.parent.children()
                .filter((child) => {
                    return child['@itemtype'] === 'dataGroup'
                })
        },

        parentDatagroupIds () {
            return this.parentDatagroups
                .map((dataGroup) => {
                    return dataGroup['@id']
                })
        },

        dataGroupLinks () {
            return this.allDatagrouplinks
                .filter((dataGroupLink) => {
                    return this.parentDatagroupIds.includes(dataGroupLink['@source'])
                })
        }
    },

    methods: {
        handleNodeClick (event, linkObject) {
            const dg = this.parentDatagroups.find((group) => {
                return group['@id'] === linkObject.id
            })
            this.$emit('showDetail', dg)
        },

        handleLinkClick (event, linkObject) {
            this.selectedLink = {}
            this.$set(this.selectedLink, linkObject.id, linkObject)
            const link = this.dataGroupLinks.find((link) => {
                return link['@source'] === linkObject.sid &&
                    link['@target'] === linkObject.tid
            })
            this.$emit('showLink', link)
        },

        handleDblClick (event) {
            if (event.nodes.length > 0) {
                const dg = this.parentDatagroups.find((group) => {
                    return group['@id'] === event.nodes[0]
                })
                this.$emit('showDetail', dg)
            } else {
                const edge = this.network.edges.find((edge) => {
                    return edge.id === event.edges[0]
                })
                const link = this.dataGroupLinks.find((link) => {
                    return link['@source'] === edge.from &&
                        link['@target'] === edge.to
                })
                this.$emit('showDetail', link)
            }
        },

        startStabilizing () {
            this.isReady = false
        },

        iterationsDone () {
            this.isReady = true
            this.fit()
        },

        stabilized () {
            this.isReady = true
            this.fit()
        },

        fit () {
            this.$nextTick(() => {
                this.$refs['network-graph'].fit()
            })
        },

        refresh () {
            this.isRefreshed = false
            this.$nextTick(() => {
                this.isRefreshed = true
            })
        }
    }
}

</script>
