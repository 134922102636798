<template>
    <div class="component-generic-filter-condition">
        <div class="key">
            <text-input
                :value="key"
                :readonly="readonly"
                class="input key-input"
                @update:value="onUpdateKey"
            />
        </div>
        <div
            ref="focusElement"
            :tabindex="operatorTabindex"
            class="operator"
        >
            <selectbox-input
                v-if="operatorEdit"
                v-auto-focus
                :options="operatorOptions"
                :value="operator"
                class="input operator-input"
                @update:value="onUpdateOperator"
            />
            <div
                v-else
                class="label"
            >
                {{ operatorLabel }}
            </div>
        </div>
        <div class="value">
            <component
                :is="valueComponent"
                :value="value"
                :readonly="readonly"
                class="input value-input"
                @update:value="onUpdateValue"
            />
        </div>
        <div
            v-if="!readonly"
            class="delete"
            @click="onDelete"
        >
            <icon
                class="icon"
                name="fa/regular/trash-alt"
            />
        </div>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-generic-filter-condition {
        display: flex;
        align-items: center;

        .operator,
        .value {
            margin-left: 0.5rem;
        }

        .operator {
            width: 4em;
            display: flex;
            align-items: center;
            justify-content: center;

            .label {
                font-weight: bold;
                font-size: 1.2em;
            }
        }

        .input {
            max-width: 100%;
        }

        .delete {
            height: 28px;
            width: 28px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                font-size: 1.2em;
            }
        }
    }
</style>

<script>
import isObject from 'lodash-es/isObject'
import { Input as Inputs } from '@wisol/libs-inputs'
import { useFocusTracker } from '@wisol/utils-focus/composition'
import Icon from '@wisol/libs-icons'
import { AutoFocus } from '@wisol/utils-focus/directives'
import { ref } from 'vue'

export default {
    name: 'GenericFilterCondition',

    components: {
        TextInput: Inputs.Text,
        SelectboxInput: Inputs.Selectbox,
        Icon
    },

    directives: {
        AutoFocus
    },

    inheritAttrs: false,

    props: {
        condition: {
            type: Object,
            default () {
                return {}
            }
        },

        readonly: {
            type: Boolean,
            default: false
        }
    },

    setup () {
        const focusElement = ref()
        const { hasFocus } = useFocusTracker(focusElement)

        return { focusElement, hasFocus }
    },

    data () {
        return {
            operatorEdit: false
        }
    },

    computed: {
        key () {
            return Object.keys(this.condition)[0]
        },

        operator () {
            const value = this.condition[this.key]
            return isObject(value)
                ? Object.keys(value)[0]
                : '$eq'
        },

        value () {
            let value = this.condition[this.key]
            value = isObject(value)
                ? value[this.operator]
                : value
            if (this.isArrayOperator(this.operator)) {
                if (!value) {
                    value = []
                }
                if (!Array.isArray(value)) {
                    value = [value]
                }
            }
            if (Array.isArray(value)) {
                value = JSON.stringify(value)
            }

            return value
        },

        operatorLabel () {
            return this.operatorOptions[this.operator]
        },

        operatorOptions () {
            const options = {
                $eq: '=',
                $gt: '>',
                $gte: '>=',
                $lt: '<',
                $lte: '<=',
                $ne: '!=',
                $in: 'in',
                $nin: '!in'
            }
            if (!(this.operator in options)) {
                options[this.operator] = this.operator
            }
            return options
        },

        operatorTabindex () {
            return this.readonly && this.operatorEdit ? '-1' : '0'
        },

        valueComponent () {
            return this.isArrayOperator(this.operator)
                ? Inputs.Array
                : Inputs.Text
        }
    },

    watch: {
        hasFocus () {
            this.hasFocus ? this.onOperatorFocus() : this.onOperatorBlur()
        }
    },

    methods: {
        isArrayOperator (operator) {
            return operator === '$in' || operator === '$nin'
        },

        onUpdateKey ({ value }) {
            this.$emit('update', {
                [value]: {
                    [this.operator]: this.value
                }
            })
        },

        onUpdateOperator ({ value }) {
            if (value.match(/\$[a-z]+/)) {
                this.$emit('update', {
                    [this.key]: {
                        [value]: this.value
                    }
                })
            }
        },

        onUpdateValue ({ value }) {
            if (this.isArrayOperator(this.operator)) {
                value = JSON.parse(value) || []
            }
            this.$emit('update', {
                [this.key]: {
                    [this.operator]: value
                }
            })
        },

        onDelete () {
            this.$emit('delete')
        },

        onOperatorFocus () {
            if (!this.readonly) {
                this.operatorEdit = true
            }
        },

        onOperatorBlur () {
            this.operatorEdit = false
        }
    }
}
</script>
