export default (context, field) => {
    return {
        find (where, order, fields, limit) {
            return context.$api
                .call('ui.field.dropdown', {
                    field,
                    fields,
                    limit,
                    where,
                    order
                })
                .then(({ result, meta }) => {
                    return {
                        total: meta.totalCount ?? result.length,
                        rows: result
                    }
                })
        }
    }
}
