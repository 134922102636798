import Vue from 'vue'
import DataGroupFactory from './Factory.js'

export default Vue.extend({
    name: 'WisolDataGroupManager',

    beforeCreate () {
        this.dataGroupFactoryMap = new Map()
    },

    destroyed () {
        this.dataGroupFactoryMap.forEach(vm => vm.$destroy())
        this.dataGroupFactoryMap.clear()
        this.dataGroupFactoryMap = null
    },

    methods: {
        getDataGroup (id) {
            const factory = this.dataGroupFactoryMap.get(id) || this.createDataGroupFactory(id)
            return factory.dataGroup
        },

        createDataGroupFactory (id) {
            const factory = new DataGroupFactory({
                propsData: {
                    id: id,
                    getDataGroup: this.getDataGroup
                },
                parent: this
            })
            this.dataGroupFactoryMap.set(id, factory)
            return factory
        }
    },

    render: h => h()
})
