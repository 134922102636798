import Vue from 'vue'
import triggerDownload from './triggerDownload.js'
import log from 'loglevel'
import { error as alertError } from './alert.js'

export default function createScriptFunction (context, script) {
    // eslint-disable-next-line no-new-func
    const scriptFunction = new Function(`
        return async scriptApiParams => {
            with (scriptApiParams) { ${script} }
        }
    `)()

    const openWindow = function (url) {
        const newWindow = window.open()
        if (newWindow) {
            newWindow.location = url
        } else {
            log.error('could not open new window')
        }
    }

    const generateReport = function (name, options = {}, filename = null, printerName = null) {
        let url = `report/generate/${name}`
        if (printerName) {
            url += `/${printerName}`
        }

        const reportPromise = context.$http.post(
            url,
            options,
            {
                responseType: printerName ? 'json' : 'blob'
            }
        )

        if (!printerName) {
            reportPromise.then(result => {
                const url = URL.createObjectURL(result.data)
                triggerDownload(url, filename || `${name}.pdf`)
                URL.revokeObjectURL(url)
            })
        }

        return reportPromise
    }

    const generateExcel = function (table, options = {}, filename = null) {
        return context.$http.post(
            `excel/generate/${table}`,
            options,
            {
                responseType: 'blob'
            }
        ).then(result => {
            const url = URL.createObjectURL(result.data)
            triggerDownload(url, filename || `${table}.xlsx`)
            URL.revokeObjectURL(url)
        })
    }

    const transfer = function (transferConfig, options = {}) {
        const data = { ...options }
        data.options ??= transferConfig ?? []

        if (context.dataGroup) {
            data.sourceTable ??= context.dataGroup.table
        }

        const transferPromise = context.$http.post(
            'transfer',
            data,
            {
                responseType: 'json'
            }
        )

        transferPromise
            .then(({ data }) => {
                data.forEach(result => {
                    result.forEach(({ status, error }) => {
                        if (status !== 'SUCCESS') {
                            alertError(error.message || error || 'Unknown transfer error')
                        }
                    })
                })
            })
            .catch(err => {
                alertError(err.message || err || 'Unknown transfer error')
            })

        return transferPromise
    }

    const transferActiveRow = function (transferConfig, options = {}) {
        if (!context.dataGroup) {
            return Promise.reject(new Error('No dataGroup available'))
        }
        const { dataGroup } = context

        if (!dataGroup.activeRow) {
            return Promise.reject(new Error('No active row'))
        }
        options.items ??= [
            {
                id: dataGroup.getRowId(dataGroup.activeRow),
                values: dataGroup.getRowValues(dataGroup.activeRow)
            }
        ]
        return transfer(transferConfig, options)
    }

    const transferSelectedRows = function (transferConfig, options = {}) {
        if (!context.dataGroup) {
            return Promise.reject(new Error('No dataGroup available'))
        }
        const { dataGroup } = context

        const selectionModule = dataGroup.moduleMap.get('selection')
        if (!selectionModule) {
            return Promise.reject(new Error('Datagroup has no selection module'))
        }
        if (selectionModule.invert) {
            return Promise.reject(new Error('Inverted selection is not supported'))
        }

        const idList = selectionModule.idList
        if (idList.length === 0) {
            return Promise.reject(new Error('No rows selected'))
        }

        options.items ??= idList.map(id => {
            return {
                id,
                values: {}
            }
        })
        return transfer(transferConfig, options)
    }

    const loadLayout = function (idOrLabel) {
        const helper = new Vue({
            parent: context,
            inject: {
                getLayouts: { default: null },
                loadLayout: { default: null }
            }
        })

        if (!helper.getLayouts || !helper.loadLayout) {
            helper.$destroy()
            return Promise.reject(new Error('Cannot switch layout'))
        }

        const layouts = helper.getLayouts()
        const layout = layouts.find(layout => {
            return layout['@id'] === idOrLabel || layout.label === idOrLabel
        })
        if (!layout) {
            helper.$destroy()
            return Promise.reject(new Error(`Cannot find layout "${idOrLabel}"`))
        }

        helper.loadLayout(layout)

        helper.$destroy()
    }

    return (params) => {
        return scriptFunction({
            ...params,
            http: context.$http,
            api: context.$api,
            openWindow,
            triggerDownload,
            generateReport,
            generateExcel,
            transfer,
            transferActiveRow,
            transferSelectedRows,
            loadLayout
        })
    }
}
