import Vue from 'vue'
import Registry from '@/Registry'

export default Vue.extend({
    name: 'WisolDataProvider',

    inheritAttrs: false,

    props: {
        source: {
            type: String,
            required: true
        }
    },

    data () {
        return {
            status: 'COMPLETED',
            total: 0,
            rows: []
        }
    },

    computed: {
        dbConnectorKey () {
            const index = this.source.indexOf(':')
            return index > 0
                ? this.source.slice(0, index)
                : 'wisol.table'
        },

        reference () {
            return this.source.slice(this.source.indexOf(':') + 1)
        },

        connector () {
            const { connector } = Registry.get('db-connector', this.dbConnectorKey)
            return connector(this, this.reference)
        }
    },

    methods: {
        find (where, order, fields, limit) {
            const findPromise = this.connector.find(where, order, fields, limit)
            findPromise.then(({ rows, total }) => {
                this.rows = rows.map(row => row)
                this.total = total
            })
            return findPromise
        },

        create (values) {
            return this.connector.create(values)
        },

        update (id, values) {
            return this.connector.update(id, values)
        },

        delete (id) {
            return this.connector.delete(id)
        }
    },

    render: h => h()
})
