import { watch, onUnmounted } from 'vue'
import { useFocusTracker, useFocusPortalNodeList } from '../composition/index.js'

export default function useFocusPortal (sourceNodeRef, targetNodeRef) {
    const { add, remove } = useFocusPortalNodeList()
    const { hasFocus: hasFocusRef } = useFocusTracker(sourceNodeRef)

    watch(hasFocusRef, () => {
        if (hasFocusRef.value) {
            add(targetNodeRef)
        } else {
            remove(targetNodeRef)
        }
    }, {
        flush: 'sync'
    })

    onUnmounted(() => { remove(targetNodeRef) })
}
