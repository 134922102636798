<template>
    <!-- eslint-disable vue/no-v-html -->
    <div
        :class="$style.grid"
        :style="gridStyle"
    >
        <div :class="$style.boxContainer">
            <div :class="$style.overlay">
                <status-display
                    v-if="!dataGroup.isReady"
                    :data-group="dataGroup"
                />
            </div>
            <div :class="$style.boxGrid">
                <div
                    v-for="(row, index) in rows"
                    :key="index"
                    :class="{
                        [$style.box]: true,
                        [$style.active]: isActive(row)
                    }"
                    @click="setActiveIndex(index)"
                    v-html="getBoxHtml(row)"
                />
            </div>
        </div>
        <generic-pagination
            v-if="showPagination"
            :page="page"
            :count="rowsPerPage"
            :total="dataGroup.totalRowCount"
            :class="$style.pagination"
            @update:page="onPageUpdate"
            @update:count="onCountUpdate"
        />
    </div>
</template>

<style lang="scss" module>
    @import "@wisol/theme/variables";
    $base-color: #FFF;

    .grid {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .boxContainer {
        flex: 1 1 0;
        overflow: auto;
        position: relative;
    }

    .boxGrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(var(--grid-box-width), 1fr));
        gap: 1em;
        padding: 1em;
        grid-auto-columns: 1fr;
        grid-auto-rows: 1fr;
    }

    .box {
        padding: 1em;
        border: 1px solid #{darken($base-color, 15%)};
        aspect-ratio: var(--grid-box-ratio);
        background: #{darken($base-color, 3%)};
        cursor: pointer;
        overflow: hidden;
    }

    .box:hover {
        background: #{darken($base-color, 8%)};
    }

    .box.active {
        background: #D0ECFF;
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 2;

        &:empty {
            display: none;
        }
    }

    .pagination {
        flex: 0 0 2.5rem;
        background: nth($color-palette-grey, 2);
    }
</style>

<script>
import Vue from 'vue'
import handlebars from 'handlebars'
import StatusDisplay from '../Data/Group/StatusDisplay'

export default {
    name: 'WisolWidgetGrid',

    components: {
        StatusDisplay
    },

    inheritAttrs: false,

    props: {
        id: {
            type: Number,
            required: true
        },

        dataGroup: {
            type: Vue,
            required: true
        },

        template: {
            type: String,
            default: '{{designation}}'
        },

        boxWidth: {
            type: String,
            default: '200px'
        },

        boxRatio: {
            type: String,
            default: '2 / 1'
        }
    },

    computed: {
        rows () {
            return this.dataGroup.rows
        },

        renderTemplate () {
            return handlebars.compile(this.template)
        },

        gridStyle () {
            return {
                '--grid-box-ratio': this.boxRatio,
                '--grid-box-width': this.boxWidth
            }
        },

        showPagination () {
            return this.dataGroup.moduleMap.has('pagination')
        },

        page () {
            if (!this.showPagination) {
                return null
            }
            return this.dataGroup.moduleMap.get('pagination').page
        },

        rowsPerPage () {
            if (!this.showPagination) {
                return null
            }
            return this.dataGroup.moduleMap.get('pagination').rowsPerPage
        }
    },

    methods: {
        isActive (row) {
            return row === this.dataGroup.activeRow
        },

        getValues (row) {
            return this.dataGroup.getRowValues(row)
        },

        setActiveIndex (index) {
            const { dataGroup } = this

            dataGroup.setActiveIndex(index)

            if (dataGroup.clickScriptFunction) {
                const row = this.rows[index]
                dataGroup.clickScriptFunction(
                    dataGroup.getRowType(row),
                    row
                )
            }
        },

        getBoxHtml (row) {
            return this.renderTemplate(this.dataGroup.getRowValues(row))
        },

        onPageUpdate ({ page }) {
            const pagination = this.dataGroup.moduleMap.get('pagination')
            pagination.selectPage(page)
        },

        onCountUpdate ({ count }) {
            const pagination = this.dataGroup.moduleMap.get('pagination')
            pagination.setRowsPerPage(count)
        }
    }
}
</script>
