export default () => {
    return {
        methods: {
            update (id, data, type) {
                return this.$http
                    .post(
                        'module/ui/' + type + '/update/' + id,
                        data
                    )
            },

            create (data, type) {
                return this.$http
                    .put(
                        'module/ui/' + type + '/create/',
                        data
                    )
            },

            delete (id, type) {
                return this.$http
                    .delete(
                        'module/ui/' + type + '/delete/' + id,
                        null
                    )
            },

            remoteSave (data) {
                return this.$http
                    .post(
                        'module/ui/editor/save',
                        data
                    )
            },

            remoteDelete (data) {
                return this.$http
                    .post(
                        'module/ui/editor/delete',
                        data
                    )
            }
        }
    }
}
