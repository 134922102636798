<template>
    <!-- eslint-disable vue/no-v-html -->
    <div class="component-wisol-editor-config">
        <toolbar
            :is-dirty="isDirty"
            class="toolbar"
            @action="doAction"
        />
        <div
            class="config-container"
        >
            <table>
                <template
                    v-for="(configItem, group) in groupedList"
                >
                    <thead :key="'head' + group">
                        <tr colspan="4">
                            <td>
                                <h2>{{ group }}</h2>
                            </td>
                        </tr>
                        <tr>
                            <td><h5>Description</h5></td>
                            <td><h5>Required</h5></td>
                            <td><h5>Default</h5></td>
                            <td><h5>Value</h5></td>
                        </tr>
                    </thead>
                    <tbody :key="'body' + group">
                        <tr
                            v-for="(item, name) in configItem"
                            :key="name"
                            :class="{ dirty: getDirtyValue(name) !== undefined }"
                        >
                            <td
                                :key="'description:' + name"
                                class="description"
                            >
                                <h5>{{ name }}</h5>
                                <span v-html="item.description" />
                            </td>
                            <td
                                :key="'required:' + name"
                                class="required"
                            >
                                <span>
                                    <icon
                                        v-if="!('default' in item)"
                                        name="fa/solid/check"
                                        class="icon-required"
                                    />
                                </span>
                            </td>
                            <td
                                :key="'defaultValue:' + name"
                                class="default"
                            >
                                <span>
                                    {{ item.default }}
                                </span>
                            </td>
                            <td
                                :key="'value:' + name"
                                class="value"
                            >
                                <div
                                    :is="getInputComponent(item.type)"
                                    :value="getValue(name, item)"
                                    :readonly="false"
                                    :required="!('default' in item)"
                                    class="input-widget"
                                    @update:value="onValueUpdate(name, $event)"
                                />
                            </td>
                        </tr>
                    </tbody>
                </template>
            </table>
        </div>
        <template v-if="loading">
            <wisol-loading />
        </template>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-config {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        background: #fff;
        overflow-y: auto;
        font-size: 1.1em;

        .loading {
            padding: 50px;
        }

        &> .toolbar {
            position: fixed;
            z-index: 2;
            width: 100%;
        }

        &> .config-container {
            height: 100vh;
            padding: 50px;
            box-sizing: border-box;
            width: 100%;
        }

        table {
            margin-bottom: 50px;

            .description {
                width: 70%;
            }

            .required {
                width: 10%;
            }

            .default {
                width: 10%;
            }

            .value {
                width: 10%;
            }

            width: 100%;

            td {
                vertical-align: middle;
            }

            thead {
                tr:first-child {
                    &> td {
                        padding-top: 50px;
                        padding-bottom: 10px;
                    }
                }

                tr:last-child {
                    font-weight: bold;
                    height: 40px;
                    td:not(:first-child) {
                        text-align: center;
                    }
                }

                td {
                    padding-left: 6px;
                }
            }

            tbody {
                tr {
                    td {
                        height: 60px;
                        background-color: nth($color-palette-grey, 2);
                        padding: 10px;
                        border: 2px solid white;

                        h5 {
                            font-weight: bold;
                        }

                        .icon-required {
                            color: green;
                            width: 16px;
                            height: 16px;
                        }

                        .input-widget {
                            width: 100%;
                        }
                    }

                    td:not(:first-child) {
                        text-align: center;
                    }

                    &:hover {
                        td {
                            background-color: nth($color-palette-grey, 3);
                        }
                    }
                }

                tr.dirty {
                    td {
                        background-color: nth($color-palette-status, 2);
                    }
                }
            }
        }
    }
</style>

<script>
import requests from './mixins/requests.js'
import { Input as Inputs } from '@wisol/libs-inputs'
import WisolEditorConfigToolbar from './Toolbar'
import alert, { success } from '@/utils/alert'
import has from 'lodash-es/has'
import Icon from '@wisol/libs-icons'

export default {
    name: 'WisolEditorConfig',

    components: {
        toolbar: WisolEditorConfigToolbar,
        Icon
    },

    mixins: [
        requests()
    ],

    inheritAttrs: false,

    data () {
        return {
            configList: {},
            dirtyValues: {},
            loading: true
        }
    },

    computed: {
        isDirty () {
            return Object.keys(this.dirtyValues).length > 0
        },

        groupedList () {
            const groups = {
                General: {}
            }
            for (const key in this.configList) {
                const group = this.configList[key].group || 'General'
                let config = {
                    ...this.configList[key]
                }
                const dirtyValue = this.getDirtyValue(this.configList[key]['@id'])
                if (dirtyValue) {
                    config = {
                        ...config,
                        value: {
                            default: dirtyValue
                        }
                    }
                }

                groups[group] = {
                    ...groups[group],
                    [this.configList[key]['@id']]: config
                }
            }

            return groups
        }
    },

    mounted () {
        this.getConfig()
            .then((response) => this.handleRequestResponse(response))
            .catch((error) => this.handleRequestError(error))
    },

    methods: {
        doAction (action) {
            switch (action) {
                case 'save':
                    this.save()
                    break
            }
        },

        save () {
            this.loading = true
            this.update(this.dirtyValues)
                .then((response) => {
                    success('Saved successfully!')
                    this.handleRequestResponse(response)
                })
                .catch((error) => this.handleRequestError(error))
        },

        getValue (field, item) {
            const value = this.getDirtyValue(field) !== undefined
                ? this.getDirtyValue(field)
                : item.value
                    ? item.value.default
                    : ''
            return value
        },

        getDirtyValue (field) {
            return this.dirtyValues[field] !== undefined
                ? this.dirtyValues[field]
                : undefined
        },

        getInputComponent (type) {
            switch (type) {
                case 'text':
                    return Inputs.Text
                case 'number':
                    return Inputs.Number
                case 'boolean':
                    return Inputs.Checkbox
                case 'array':
                    return Inputs.Array
                default:
                    return Inputs.Text
            }
        },

        onValueUpdate (field, { value }) {
            if (has(this.dirtyValues, field)) {
                this.$delete(this.dirtyValues, field)
            }
            this.dirtyValues = {
                ...this.dirtyValues,
                [field]: value
            }
        },

        handleRequestResponse (response) {
            this.loading = false
            this.configList = {}
            this.dirtyValues = {}
            this.configList = response.data
        },

        handleRequestError (error) {
            const err = error.response.data
            alert(err.code + ': ' + err.message)
        }
    }
}
</script>
