<template>
    <div>
        <div :class="$style.relationGrid">
            <label :class="$style.relationLabel">Parent</label>
            <span />
            <label :class="$style.relationLabel">Child</label>
            <span />

            <div
                v-for="(relation, i) in mapping"
                :key="'relation'+i"
                style="display: contents;"
            >
                <text-input
                    v-model.lazy="relation.parent"
                    type="text"
                    :style="{ backgroundColor: relation.duplicate ?'#FFA2A2' : ''}"
                />
                <span>&rarr;</span>
                <text-input
                    v-model.lazy="relation.child"
                    type="text"
                />
                <button @click="removeRelation(i)">
                    X
                </button>
            </div>

            <div :class="$style.relationGridButton">
                <button @click="addRelation">
                    Add Relation
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
.input {
    composes: input from "../../../../../../libs/inputs/src/style/input.module.scss";
}

.container {
    composes: container from "../../../../../../libs/inputs/src/style/input.module.scss";
}

.type {
    composes: type-text from "../../../../../../libs/inputs/src/style/input.module.scss";
}

.relationGrid{
    display: grid;
    grid-template-columns: 210px 20px 210px 25px;
    grid-gap: 10px;
    justify-items: center;
    align-items: center;
}
.relationGridButton{
    width: 100%;
    display: flex;
    justify-content: center;
    grid-column: 1 / span 3;
}

.relationLabel{
    font-weight: 700;
    margin-top: 10px;
}

</style>

<script>
import { Input as WisolInputs } from '@wisol/libs-inputs'

import { InputSymbol } from '@wisol/libs-inputs/src/components/createInput.js'
import { ref, inject } from 'vue'

import _ from 'lodash-es'

export default {
    name: 'RelationUi',

    components: {
        textInput: WisolInputs.Text
    },

    inheritAttrs: false,

    setup () {
        const { inputClasses, inputProps, inputListeners, value, setValue } = inject(InputSymbol)

        const mapping = ref([])
        const mapMemory = ref({})

        if (value?.value) {
            Object.entries(value.value).forEach(([parent, child]) => {
                mapping.value.push({ parent, child })
            })
        }

        return {
            inputClasses,
            inputProps,
            inputListeners,
            mapping,
            value,
            mapMemory,
            setValue
        }
    },

    computed: {
        mainClasses () {
            return [
                this.inputClasses,
                this.$style.type,
                this.$style.container
            ]
        }
    },

    watch: {
        value: {
            handler () {
                this.mapping = []
                if (this.value) {
                    Object.entries(this.value).forEach(([parent, child]) => {
                        this.mapping.push({ parent, child })
                    })
                    this.mapMemory = this.value
                }
            }
        },

        mapping: {
            deep: true,
            handler () {
                let mapping = null
                const keyExists = []
                this.mapping.forEach((relation) => {
                    relation.duplicate = false
                    if (!keyExists.includes(relation.parent) && relation.parent) {
                        keyExists.push(relation.parent)
                        if (!mapping) {
                            mapping = {}
                        }
                        mapping[relation.parent] = relation.child
                    } else {
                        relation.duplicate = true
                    }
                })

                if (!_.isEqual(this.mapMemory, mapping) && mapping) {
                    this.setValue(mapping, true)
                }
            }
        }
    },

    methods: {
        addRelation () {
            this.mapping.push({ parent: '', child: '' })
        },
        removeRelation (i) {
            this.mapping.splice(i, 1)
        }
    }
}
</script>
