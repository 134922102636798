import Vue, { getCurrentInstance } from 'vue'
import isEqual from 'lodash-es/isEqual'
import debounce from '@/utils/debounce'
import { fatal as showFatal } from '@/utils/alert'
import Registry from '@/Registry'
import * as filterUtils from '@/utils/filter'
import { merge, mergeTarget } from '@wisol/utils-data/functions'
import useLoading from '@/composition/useLoading'
import createScriptFunction from '@/utils/createScriptFunction.js'
import StoreModule from '@/store/modules/dataGroup'
import useStoreWrapper from '@/composition/useStoreWrapper.js'
import DataProvider from '../Provider.js'

export function getModulesInfo (modules) {
    return modules
        .map(moduleName => {
            const { component } = Registry.get('data-group-module', moduleName)
            return {
                component,
                name: moduleName
            }
        })
}

function __getCustomOptions (map, id) {
    const options = map.get(id) || Vue.observable({ data: {} })
    map.set(id, options)
    return options
}

function __setCustomOptions (map, id, options, reset = false) {
    const currentOptions = __getCustomOptions(map, id)
    if (reset) {
        currentOptions.data = options
    } else {
        mergeTarget(currentOptions.data, options)
    }
    return currentOptions
}

function __resetCustomOptions (map, id) {
    return __setCustomOptions(map, id, {}, true)
}

function __resetAllCustomOptions (map) {
    map.forEach((options, id) => {
        __resetCustomOptions(map, id)
    })
}

// eslint-disable-next-line vue/one-component-per-file
const RowData = Vue.extend({
    name: 'RowData'
})

const createNewRowData = (dataGroup, row) => {
    const rowDataVm = new RowData({
        parent: dataGroup,

        data () {
            return {
                options: {},
                cellOptions: {}
            }
        },

        created () {
            this.row = row
        },

        computed: {
            id () {
                return row['@id']
            },

            rowId () {
                if (row && '@rowId' in row) {
                    return row['@rowId']
                }
                return null
            },

            type () {
                if (row && '@type' in row) {
                    return row['@type']
                }
                return null
            },

            updatedValues () {
                const updatedValues = {}
                dataGroup.callModuleMiddlewares('rowValues', { row }, updatedValues)
                return updatedValues
            },

            values () {
                const { updatedValues } = this
                const values = {}
                if (updatedValues) {
                    Object.assign(values, row.values || {}, updatedValues)
                }
                return values
            },

            canUpdate () {
                return !dataGroup.readonly && dataGroup.callModuleMiddlewares('canRowUpdate', { row }, false)
            },

            dirtyFields () {
                return dataGroup.callModuleMiddlewares('getDirtyFields', { row }, [])
            },

            isDirty () {
                return this.dirtyFields.length > 0
            }
        },

        destroyed () {
            dataGroup.$off('hook:destroyed', cleanup)
        }
    })

    const cleanup = () => {
        rowDataVm.$destroy()
    }
    dataGroup.$on('hook:destroyed', cleanup)

    return rowDataVm
}

// eslint-disable-next-line vue/one-component-per-file
export default Vue.extend({
    name: 'WisolDataGroup',

    inheritAttrs: false,

    props: {
        id: {
            type: [String, Number],
            default: null
        },

        table: {
            type: String,
            required: true
        },

        fields: {
            type: Array,
            required: true
        },

        dataProvider: {
            type: DataProvider,
            required: true
        },

        preFilter: {
            type: [Object, Array],
            default () {
                return null
            }
        },

        links: {
            type: Array,
            default () {
                return []
            }
        },

        modules: {
            type: Array,
            default () {
                return []
            }
        },

        initialActiveRowPosition: {
            type: Number,
            default: 0
        },

        readonly: {
            type: Boolean,
            default: true
        },

        scripts: {
            type: Object,
            default () {
                return {}
            }
        },

        autoUnregisterStore: {
            type: Boolean,
            default: false
        }
    },

    setup (props) {
        const {
            start: startLoading,
            stop: stopLoading,
            isLoading,
            isReady,
            error: loadingError
        } = useLoading()

        const store = getCurrentInstance().proxy.$store
        const {
            getter,
            dispatch
        } = useStoreWrapper(store, StoreModule, 'dataGroup', {
            id: props.id,
            autoUnregister: props.autoUnregisterStore
        })

        return {
            startLoading,
            stopLoading,
            isLoading,
            isReady,
            loadingError,
            getter,
            dispatch
        }
    },

    data () {
        return {
            moduleInstances: [],
            destroyed: false,
            fieldOptions: {}
        }
    },

    computed: {
        firme () {
            return this.$store.getters['session/firme']
        },

        fieldMap () {
            const map = new Map()
            this.fields.forEach(field => {
                map.set(field.field, field)
            })
            return map
        },

        rows () {
            return this.callModuleMiddlewares('rows', {}, this.getter('rows/rows'))
        },

        rowsSet () {
            return new Set(this.rows)
        },

        moduleMap () {
            const map = new Map()
            this.moduleInstances.forEach(({ name, module }) => {
                map.set(name, module)
            })
            return map
        },

        localFilter () {
            return this.getter('filter') || {}
        },

        /**
         * the filter to be applied
         */
        filter () {
            const filter = []

            // add pre filter from props
            if (this.preFilter) {
                filter.push(this.preFilter)
            }

            // add local filter
            if (Object.keys(this.localFilter).length > 0) {
                filter.push(this.localFilter)
            }

            return filterUtils.optimize(this.callModuleMiddlewares('filter', {}, filter))
        },

        /**
         * the sorting as Array
         * @return {Array} the sort order
         */
        sorting () {
            return this.callModuleMiddlewares('sorting', {}, this.getter('sorting'))
        },

        /**
         * the sorting as Object
         * @return {Object} the sort order
         */
        sortingAsObject () {
            return (this.sorting || []).reduce((sorting, { field, direction }) => {
                sorting[field] = direction
                return sorting
            }, {})
        },

        /**
         * defines if dataGroup is disabled
         */
        disabled () {
            return this.callModuleMiddlewares('disabled', {}, false)
        },

        /**
         * the active row
         */
        activeRow () {
            if (this.activeRowIndex === null) {
                return null
            }
            return this.rows[this.activeRowIndex] || null
        },

        /**
         * active row index
         */
        activeRowIndex () {
            if (this.rows.length === 0 || this.activeRowPosition === null) {
                return null
            }
            return this.getIndexByPosition(this.activeRowPosition)
        },

        /**
         * active row position
         */
        activeRowPosition () {
            return this.getter('position')
        },

        /**
         * active row id
         */
        activeRowId () {
            if (this.activeRow) {
                return this.getRowId(this.activeRow)
            }
            return null
        },

        /**
         * active row type (DEFAULT || NEW)
         */
        activeRowType () {
            return this.getRowType(this.activeRow)
        },

        /**
         * is the active row dirty
         */
        isActiveRowDirty () {
            if (!this.activeRow) {
                return false
            }
            return this.isRowDirty(this.activeRow)
        },

        /**
         * are the dirty values
         * new rows included
         */
        isDirty () {
            return this.callModuleMiddlewares('isDirty', {}, false)
        },

        /**
         * number of dirty rows
         * new rows included
         */
        dirtyRowCount () {
            return this.callModuleMiddlewares('dirtyRowCount', {}, 0)
        },

        /**
         * the number of rows available without new rows
         */
        totalRowCountWithoutMiddlewares () {
            return this.getter('rows/totalCount')
        },

        /**
         * the number of rows available
         */
        totalRowCount () {
            return this.callModuleMiddlewares('totalRowCount', {}, this.totalRowCountWithoutMiddlewares)
        },

        limit () {
            return this.callModuleMiddlewares('limit')
        },

        nonVirtualFieldList () {
            return this.fields
                .filter(field => !field.virtual)
                .map(field => field.field)
        },

        updateRowsRequestOptions () {
            return {
                table: this.table,
                fields: this.nonVirtualFieldList,
                limit: this.limit,
                where: this.filter,
                order: this.sortingAsObject,
                firme: this.firme
            }
        },

        groupedFields () {
            const map = new Map()
            this.fields.forEach(field => {
                map.set(field.field, [
                    ...(map.get(field.field) || []),
                    field
                ])
            })
            return map
        },

        virtualFields () {
            const set = new Set()
            this.groupedFields.forEach((fields, field) => {
                if (fields.filter(field => !field.virtual).length === 0) {
                    set.add(field)
                }
            })
            return set
        },

        updateScriptFunction () {
            if (!this.scripts.onupdate) {
                return null
            }

            const updateFunction = createScriptFunction(this, this.scripts.onupdate)
            return (type, row, values) => {
                return updateFunction({
                    dataGroup: this,
                    type,
                    row,
                    values
                })
            }
        },

        initScriptFunction () {
            if (!this.scripts.oninit) {
                return null
            }

            const initFunction = createScriptFunction(this, this.scripts.oninit)
            return (type, row) => {
                return initFunction({
                    dataGroup: this,
                    type,
                    row
                })
            }
        },

        saveScriptFunction () {
            if (!this.scripts.onsave) {
                return null
            }

            const saveFunction = createScriptFunction(this, this.scripts.onsave)
            return () => {
                return saveFunction({
                    dataGroup: this
                })
            }
        },

        clickScriptFunction () {
            if (!this.scripts.onclick) {
                return null
            }

            const clickFunction = createScriptFunction(this, this.scripts.onclick)
            return (type, row, field) => {
                return clickFunction({
                    dataGroup: this,
                    type,
                    row,
                    field
                })
            }
        },

        fixedValues () {
            return this.callModuleMiddlewares('fixedValues', {}, {})
        }
    },

    watch: {
        modules: {
            handler () {
                this.moduleInstances.forEach(({ module }) => module.$destroy())
                this.moduleInstances = this.modules.map(({ name, component, props }) => {
                    const ComponentClass = Vue.extend(component)
                    return {
                        name,
                        module: new ComponentClass({
                            propsData: props || {},
                            parent: this
                        })
                    }
                })
            },
            immediate: true
        },

        updateRowsRequestOptions: {
            handler (options, oldOptions) {
                if (!isEqual(options, oldOptions)) {
                    return this.debouncedUpdateRows()
                }
            },
            deep: true
        },

        filter: {
            handler (options, oldOptions) {
                if (
                    !isEqual(options, oldOptions) &&
                    this.activeRowPosition !== null &&
                    this.activeRowPosition > 0
                ) {
                    return this.setActivePosition(0)
                }
            },
            deep: true
        }
    },

    beforeCreate () {
        this.fieldOptionsMap = new Map()
        this.rowDataMap = new WeakMap()
        this.autoIncrementId = 0
    },

    created () {
        this._debouncedUpdateRows = debounce(this.updateRows, 500)

        if (this.activeRowPosition === null && this.initialActiveRowPosition !== null) {
            this.setActivePosition(this.initialActiveRowPosition)
        }
        if (this.getter('rows/updateRequired')) {
            this.debouncedUpdateRows()
        }

        this._cleanupRowDataHandle = setInterval(() => this.cleanupRowData(), 5000)
    },

    beforeDestroy () {
        this.destroyed = true

        clearInterval(this._cleanupRowDataHandle)
        this.moduleInstances.forEach(({ module }) => module.$destroy())
        this.moduleInstances = null
        this.fieldOptionsMap.clear()
        this.fieldOptionsMap = null
        this.rowDataMap = null
    },

    methods: {
        field (field) {
            return this.fieldMap.get(field)
        },

        getRowData (row) {
            let data = this.rowDataMap.get(row)
            if (!data) {
                data = createNewRowData(this, row)
                this.rowDataMap.set(row, data)
            }
            return data
        },

        getRowValues (row) {
            return this.getRowData(row).values
        },

        getRowId (row) {
            return this.getRowData(row).rowId
        },

        getIndexByPosition (position) {
            return this.callModuleMiddlewares('indexByPosition', { position }, position)
        },

        getPositionByIndex (index) {
            return this.callModuleMiddlewares('positionByIndex', { index }, index)
        },

        getRowByPosition (position) {
            const index = this.getIndexByPosition(position)
            if (index === null || !this.rows[index]) {
                return null
            }
            return this.rows[index]
        },

        /**
         * set active position
         * @param  {Number}  [position=0] the position to set active
         * @return {Promise}              the promise which resolves when the active position has changed
         */
        async setActivePosition (position = 0) {
            await this.dispatch('setActivePosition', { position })
        },

        async setActiveIndex (index) {
            const position = this.callModuleMiddlewares('positionByIndex', { index }, index)
            return this.setActivePosition(position)
        },

        /**
         * returns the row type of the row
         * @param  {Object} row the row
         * @return {String}     the row type (DEFAULT || NEW)
         */
        getRowType (row) {
            return this.getRowData(row).type
        },

        /**
         * can the row be updated / edited ?
         * @param  {Object} row the row to check
         * @return {Boolean}    true if the row can be edited, else false
         */
        canRowUpdate (row) {
            return this.getRowData(row).canUpdate
        },

        /**
         * fetch rows from server
         * @return {Promise}  the promise which resolves when the rows are fetched and saved
         */
        async updateRows () {
            const beforeRequest = async () => {
                await this.startLoading()
            }

            const isOutdated = () => {
                return this.$updateRowsPromise !== updateRowsPromise
            }

            const afterRequest = async (err) => {
                await this.stopLoading(err)
                if (isOutdated()) {
                    return this.$updateRowsPromise
                }
            }

            const updateRowsPromise = beforeRequest()
                .then(() => {
                    if (this.disabled) {
                        // wait until dataGroup is enabled
                        return new Promise(resolve => {
                            const unwatch = this.$watch(() => this.disabled, disabled => {
                                if (!disabled) {
                                    unwatch()
                                    resolve()
                                }
                            })
                        })
                    }
                })
                .then(() => {
                    if (!isOutdated()) {
                        const { where, order, fields, limit } = this.updateRowsRequestOptions
                        return this.dataProvider.find(where, order, fields, limit)
                    }
                })
                .then(({ rows, total } = {}) => {
                    if (!isOutdated()) {
                        const promises = [
                            this.dispatch('rows/load', { rows, total })
                        ]
                        // update position if out of bounds
                        const { activeRowPosition, totalRowCount } = this
                        if (
                            activeRowPosition !== null &&
                            activeRowPosition > 0 &&
                            activeRowPosition >= totalRowCount
                        ) {
                            promises.push(this.setActivePosition(Math.max(total - 1, 0)))
                        }
                        return Promise.all(promises)
                    }
                })
                .then(() => {
                    if (this.initScriptFunction) {
                        return Promise.allSettled(
                            this.rows.map(row => {
                                return this.initScriptFunction('DEFAULT', row)
                            })
                        )
                    }
                })
                .then(() => this.dispatch('rows/setUpdateRequired', { required: false }))
                .catch(err => {
                    if (!this.destroyed) {
                        showFatal(err)
                    }
                    return err
                })
                .then(err => afterRequest(err))

            this.$updateRowsPromise = updateRowsPromise
            return this.$updateRowsPromise
        },

        reload () {
            this.debouncedUpdateRows()
        },

        debouncedUpdateRows () {
            const promise = this.startLoading()
                .then(() => this.dispatch('rows/setUpdateRequired', { required: true }))
                .then(() => this._debouncedUpdateRows())

            promise
                .finally(() => this.stopLoading())

            return promise
        },

        /**
         * go to next position
         * @return {Promise} the promise which resolves when the position has changed
         */
        async next () {
            const { activeRowPosition, totalRowCount } = this
            const newPosition = activeRowPosition === null ? 0 : activeRowPosition + 1
            if (newPosition < totalRowCount) {
                return this.setActivePosition(newPosition)
            }
        },

        /**
         * go to previous position
         * @return {Promise} the promise which resolves when the position has changed
         */
        async prev () {
            const { activeRowPosition } = this
            const newPosition = activeRowPosition === null ? 0 : activeRowPosition - 1
            if (newPosition >= 0) {
                return this.setActivePosition(newPosition)
            }
        },

        /**
         * overwrites the values of the row
         * @param  {String}  row    the row to overwrite
         * @param  {Object}  values the values to set
         * @return {Promise}        the promise which resolves when the values are overwritten
         */
        async setRowValues (row, values) {
            return this.callModuleMiddlewares('setRowValues', { row, values }) ||
                Promise.reject(new Error('Could not set values'))
        },

        /**
         * checks if the row is dirty
         * @param  {Object}  row the row to check
         * @return {Boolean}     true if the row is dirty
         *                       false if row is unchanged
         */
        isRowDirty (row) {
            return this.getRowData(row).isDirty
        },

        /**
         * saves all dirty rows
         * @return {Promise} the promise which resolves when everything is saved
         */
        async saveAll () {
            if (this.saveScriptFunction) {
                await this.saveScriptFunction()
            }
            return Promise.all(this.callModuleMiddlewares('saveAll', {}, []))
        },

        /**
         * updates the simple filter
         * @param  {Object}  values the filter values to set
         * @return {Promise}        the promise which resolves when the filter has been updated
         */
        async setFilterValues (values) {
            return this.dispatch('setFilterValues', { values })
        },

        async resetFilter () {
            return this.dispatch('resetFilter')
        },

        /**
         * updates the sort order
         * @param  {Array}  sorting the sorting to set
         * @return {Promise}        the promise which resolves when the sort order has been updated
         */
        async setSorting (sorting) {
            return this.dispatch('setSorting', { sorting })
        },

        async resetSorting () {
            return this.dispatch('resetSorting')
        },

        callModuleMiddlewares (name, options = {}, data = null) {
            const ctx = { data, options }
            const methodStack = []
            this.moduleMap.forEach(module => {
                if (module.middleware && module.middleware[name]) {
                    methodStack.push(module.middleware[name].bind(module))
                }
            })

            const next = () => {
                const method = methodStack.shift()
                if (method) {
                    method(ctx, next)
                }
            }

            next()
            return ctx.data
        },

        removeVirtualFields (values) {
            const cleanedValues = {}
            for (const field in values) {
                if (!this.virtualFields.has(field)) {
                    cleanedValues[field] = values[field]
                }
            }
            return cleanedValues
        },

        getFieldOptions (field) {
            return __getCustomOptions(this.fieldOptionsMap, field['@id']).data
        },

        getRowOptions (row) {
            return this.getRowData(row).options
        },

        getCellOptions (field, row) {
            return this.getRowData(row).cellOptions[field['@id']] || {}
        },

        setFieldOptions (field, options, reset = false) {
            return __setCustomOptions(this.fieldOptionsMap, field['@id'], options, reset).data
        },

        setRowOptions (row, options, reset = false) {
            const data = this.getRowData(row)
            if (reset) {
                data.options = options
            } else {
                mergeTarget(data.options, options)
            }
            return data.options
        },

        setCellOptions (field, row, options, reset = false) {
            const cellOptions = this.getRowData(row).cellOptions
            if (reset) {
                Vue.set(cellOptions, field['@id'], options)
            } else {
                mergeTarget(cellOptions, { [field['@id']]: options })
            }
            return cellOptions[field['@id']]
        },

        resetFieldOptions (field) {
            return __resetCustomOptions(this.fieldOptionsMap, field['@id']).data
        },

        resetRowOptions (row) {
            return this.setRowOptions(row, {}, true)
        },

        resetCellOptions (field, row) {
            return this.setRowOptions(field, row, {}, true)
        },

        resetAllFieldOptions () {
            __resetAllCustomOptions(this.fieldOptionsMap)
        },

        resetAllRowOptions () {
            this.rows.forEach(row => this.resetRowOptions(row))
        },

        resetAllCellOptions () {
            this.rows.forEach(row => {
                const data = this.getRowData(row)
                data.cellOptions = {}
            })
        },

        getCombinedOptions (field, row) {
            return merge(
                field ? this.getFieldOptions(field) : {},
                row ? this.getRowOptions(row) : {},
                field && row ? this.getCellOptions(field, row) : {}
            )
        },

        getInputProps (field, row) {
            return {
                id: field['@id'],
                required: field.required,
                readonly: field.readonly || field.field in this.fixedValues,
                options: this.getCombinedOptions(field, row)
            }
        },

        cleanupRowData () {
            this.$children.forEach(vm => {
                if (vm instanceof RowData && !this.rowsSet.has(vm.row)) {
                    vm.$destroy()
                }
            })
        }
    },

    render (createElement) {
        return createElement('div', {
            class: [
                'component-wisol-data-group'
            ]
        }, this.$scopedSlots.default({
            dataGroup: this
        }))
    }
})
