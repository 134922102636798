<template>
    <div
        class="component-wisol-editor-menu-detail-module"
    >
        <breadcrumbs
            :selected="row"
            @showDetail="showDetail"
        />
        <module-form
            :row="row"
            class="form"
            @updateRow="onUpdateRow"
        />
        <dataGroup-listing
            v-if="hasDatagroups"
            :parent="row"
            class="listing"
            @showDetail="showDetail"
        />
        <dataGroupLink-graph
            :row="row"
            @showDetail="showDetail"
            @updateRow="onUpdateRow"
        />
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-menu-detail-module {
        padding: 20px 20px 0px 20px;

        &> .form,
        &> .listing {
            margin-bottom: 20px;
        }
    }

</style>

<script>
import WisolEditorMenuBreadcrumbs from '../Breadcrumbs'
import WisolEditorMenuFormModule from '../form/Module'
import WisolEditorMenuListingDatagroup from '../listing/Datagroup'
import WisolEditorMenuGraphDatagroupLink from '../graph/datagroup/Link'

export default {
    name: 'WisolEditorMenuDetailModule',

    components: {
        'module-form': WisolEditorMenuFormModule,
        'dataGroup-listing': WisolEditorMenuListingDatagroup,
        'dataGroupLink-graph': WisolEditorMenuGraphDatagroupLink,
        breadcrumbs: WisolEditorMenuBreadcrumbs
    },

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            required: true
        }
    },

    computed: {
        hasDatagroups () {
            return this.row.children()
                .filter((child) => {
                    return child['@itemtype'] === 'dataGroup'
                })
                .length > 0
        }
    },

    methods: {
        showDetail (event) {
            this.$emit('showDetail', event)
        },

        onUpdateRow (event) {
            this.$emit('updateRow', event)
        }
    }
}
</script>
