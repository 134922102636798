<template>
    <div class="component-wisol-editor-menu-listing-box">
        <div
            class="header"
        >
            Boxes
        </div>
        <listing
            :columns="columns"
            :filter="filters"
            :rows="mappedRows"
            column-id-key="id"
            row-id-key="@id"
            @row:click="onActiveRowUpdate"
        >
            <template #plugins>
                <sorting-plugin
                    :sorting="sorting"
                    @update="onUpdateSorting"
                />
            </template>
            <template #default="{ row, column }">
                <div
                    v-if="row['@id'] === 'header:label'"
                    class="label"
                >
                    {{ column.label }}
                </div>
                <component
                    :is="column.filter.component"
                    v-if="row['@id'] === 'header:filter'"
                    class="input"
                    v-bind="column.filter.props"
                    :value="filters[column.field]"
                    @update:value="onUpdateFilterValue(column, $event)"
                />
                <component
                    :is="column.input.component"
                    v-if="row.target === 'body'"
                    class="input"
                    v-bind="column.input.props"
                    :readonly="true"
                    :value="row[column.field]"
                />
            </template>
        </listing>
        <generic-pagination
            v-if="showPagination"
            :page="page"
            :count="rowsPerPage"
            :total="children.length"
            class="pagination"
            @update:page="onPageUpdate"
            @update:count="onCountUpdate"
        />
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-wisol-editor-menu-listing-box {
        display: flex;
        flex-direction: column;

        .label {
            padding: 0.4em;
        }

        &> .pagination,
        &> .selection-bar {
            flex: 0 0 2.5rem;
            border-width: 1px 0 0 0;
            border-style: solid;
            border-color: nth($color-palette-grey, 3);
        }

        .input {
            width: 100%;
        }
    }

</style>

<script>
import Base from './Base.js'
import { Input as Inputs } from '@wisol/libs-inputs'

export default {
    name: 'WisolEditorMenuListingBox',

    extends: Base('box'),

    inheritAttrs: false,

    computed: {
        children () {
            return this.parent
                .children()
                .find((child) => {
                    return child['@itemtype'] === 'box_group'
                })
                .children()
                .filter((child) => {
                    return child['@itemtype'] === 'box'
                })
        },

        mappedRows () {
            return [
                { '@id': 'header:label', target: 'header' },
                { '@id': 'header:filter', target: 'header' },
                ...this.rows.map((row) => {
                    return {
                        '@id': row['@id'] && row['@idType'] !== 'virtual' ? row['@id'].toString() : null,
                        '@dataGroup': row['@dataGroup'] ? row['@dataGroup'].toString() : null,
                        '@module': row['@module'] ? row['@module'].toString() : null,
                        '@itemtype': row['@itemtype'],
                        '@idType': row['@idType'],
                        target: 'body',
                        name: row.name,
                        title: row.title,
                        '@ref': row
                    }
                })
            ]
        },

        columns () {
            const columns = []
            this.properties.forEach((field, index) => {
                const component = Inputs.Text
                const props = {}
                switch (field) {
                    case '@ref':
                    case '@dataGroup':
                    case '@parentIdType':
                    case '@parent':
                    case '@idType':
                    case '@itemtype':
                    case '@label':
                    case '@component':
                    case 'children':
                    case 'parent':
                    case 'isExpanded':
                    case 'isSelected':
                    case 'isDirty':
                    case 'isCut':
                        return
                }
                const column = {
                    id: index,
                    field: field,
                    label: field,
                    input: {
                        component,
                        props
                    },
                    filter: {
                        component,
                        props
                    },
                    width: 100,
                    mode: 'auto'
                }
                columns.push(column)
            })
            return columns
        }
    }
}

</script>
