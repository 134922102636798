import pdfjs from 'pdfjs-dist/lib/pdf.js'
import pdfjsWorkerUrl from '!url-loader!pdfjs-dist/build/pdf.worker.min.js'

export default async function pdfToImage (pdfUrl, {
    scale = 1
} = {}) {
    if (!pdfUrl) {
        return null
    }

    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorkerUrl

    const loadingTask = pdfjs.getDocument(pdfUrl)
    const pdf = await loadingTask.promise
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale })

    const canvas = document.createElement('canvas')
    canvas.width = viewport.width
    canvas.height = viewport.height

    const renderTask = page.render({
        canvasContext: canvas.getContext('2d'),
        viewport: viewport
    })
    await renderTask.promise

    const blob = await new Promise(resolve => canvas.toBlob(resolve))
    if (!blob) {
        return null
    }

    return URL.createObjectURL(blob)
}
