<template>
    <iframe
        :src="url"
        class="component-wisol-frame"
    />
</template>

<style>
    .component-wisol-frame {
        border: 0;
        width: 100%;
    }
</style>

<script>
export default {
    name: 'WisolFrame',

    inheritAttrs: false,

    props: {
        url: {
            required: true,
            type: String
        }
    }
}
</script>
