<script>
import Type1d from './1d'
import { merge } from '@wisol/utils-data/functions'
import isString from 'lodash-es/isString'

export default {
    name: 'WisolWidgetChartTypeRadialBar',

    extends: Type1d,

    inheritAttrs: false,

    props: {
        maxValue: {
            type: Number,
            default: undefined
        },

        maxPercent: {
            type: Number,
            default: 100
        }
    },

    computed: {
        multiplier () {
            return this.maxPercent / 100
        },

        options () {
            return merge(
                this.defaultOptions,
                {
                    labels: this.categories,
                    plotOptions: {
                        radialBar: {
                            dataLabels: {
                                value: {
                                    formatter: value => {
                                        return (Math.round(value * 100 * this.multiplier) / 100) + '%'
                                    }
                                }
                            }
                        }
                    }
                },
                this.chartOptions
            )
        }
    },

    methods: {
        convertSeriesConfig (config) {
            const field = isString(config)
                ? config
                : config.field
            const seriesData = this.getSeriesData(field)
            const maxValue = this.maxValue || Math.max(...seriesData)

            return seriesData.map(value => {
                return (value * 100) / (maxValue * this.multiplier)
            })
        }
    }
}
</script>
