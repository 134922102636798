import Vue, { getCurrentInstance } from 'vue'
import useStoreWrapper from '@/composition/useStoreWrapper.js'

const StoreModule = {
    actions: {
        selectRow ({ commit, getters }, { rowId }) {
            const invert = getters.mode === 'invert'
            if (invert) {
                commit('REMOVE_SELECTED_ROW', { rowId })
            } else {
                commit('ADD_SELECTED_ROW', { rowId })
            }
            return rowId
        },

        deselectRow ({ commit, getters }, { rowId }) {
            const invert = getters.mode === 'invert'
            if (invert) {
                commit('ADD_SELECTED_ROW', { rowId })
            } else {
                commit('REMOVE_SELECTED_ROW', { rowId })
            }
            return rowId
        },

        resetSelection ({ commit, dispatch }) {
            commit('RESET_SELECTION')
            return dispatch('setFilterBySelection', { filterBySelection: false })
        },

        selectAll ({ commit }) {
            commit('SELECT_ALL')
        },

        setFilterBySelection ({ commit }, { filterBySelection = true }) {
            commit('SET_FILTER_BY_SELECTION', { filterBySelection })
        }
    },

    mutations: {
        ADD_SELECTED_ROW (state, { rowId }) {
            if (state.idList.indexOf(rowId) === -1) {
                state.idList = [
                    ...state.idList,
                    rowId
                ]
            }
        },

        REMOVE_SELECTED_ROW (state, { rowId }) {
            const index = state.idList.indexOf(rowId)
            if (index > -1) {
                state.idList = [
                    ...state.idList.slice(0, index),
                    ...state.idList.slice(index + 1)
                ]
            }
        },

        RESET_SELECTION (state) {
            state.mode = 'normal'
            state.idList = []
        },

        SELECT_ALL (state) {
            state.mode = 'invert'
            state.idList = []
        },

        SET_FILTER_BY_SELECTION (state, { filterBySelection = true }) {
            state.filterBySelection = filterBySelection
        }
    },

    getters: {
        idList: state => state.idList,
        mode: state => state.mode,
        count: state => state.count,
        filterBySelection: state => state.filterBySelection,

        isSelected: (state, getters) => row => {
            const idList = getters.idList
            const invert = getters.mode === 'invert'
            if (invert) {
                return idList.indexOf(row['@id']) === -1
            }
            return idList.indexOf(row['@id']) > -1
        }
    },

    state () {
        return {
            idList: [],
            mode: 'normal',
            filterBySelection: false
        }
    }
}

export default Vue.extend({
    name: 'WisolDataGroupModuleSelection',

    inheritAttrs: false,

    setup () {
        const {
            getter,
            dispatch
        } = useStoreWrapper(getCurrentInstance().proxy.$store, StoreModule, 'selection', {
            autoUnregister: false
        })

        return {
            getter,
            dispatch
        }
    },

    computed: {
        middleware () {
            return {
                filter: (ctx, next) => {
                    if (this.filterBySelection && this.filter) {
                        ctx.data.push(this.filter)
                    }
                    next()
                }
            }
        },

        idList () {
            return this.getter('idList')
        },

        filterBySelection () {
            return this.getter('filterBySelection')
        },

        invert () {
            return this.getter('mode') === 'invert'
        },

        count () {
            const count = this.idList.length
            if (this.invert) {
                const totalCount = this.$parent.totalRowCountWithoutMiddlewares
                return totalCount - count
            }
            return count
        },

        state () {
            if (this.count === 0) {
                return 'none'
            }
            const totalCount = this.$parent.totalRowCountWithoutMiddlewares
            if (totalCount === this.count) {
                return 'all'
            }
            return 'partial'
        },

        filter () {
            if (!this.idList.length) {
                return null
            }
            if (this.invert) {
                return {
                    $AND: this.idList.map(id => {
                        return {
                            '@id': ['!=', id]
                        }
                    })
                }
            }
            return {
                $OR: this.idList.map(id => {
                    return {
                        '@id': id
                    }
                })
            }
        }
    },

    methods: {
        async select (row) {
            return this.dispatch('selectRow', {
                rowId: row['@id']
            })
        },

        async deselect (row) {
            return this.dispatch('deselectRow', {
                rowId: row['@id']
            })
        },

        async reset () {
            return this.dispatch('resetSelection')
        },

        async selectAll () {
            return this.dispatch('selectAll')
        },

        async setFilterBySelection (filterBySelection) {
            return this.dispatch('setFilterBySelection', {
                filterBySelection
            })
        },

        isSelected (row) {
            return this.getter('isSelected')(row)
        }
    },

    render: h => h()
})
