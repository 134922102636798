<template>
    <div class="component-wisol-editor-apps">
        <div class="editor-container">
            <div class="toolbar tabs">
                <div
                    :class="isActiveTab('apps')"
                    class="tab"
                    @click="setActiveTab('apps')"
                >
                    Apps
                </div>
                <div
                    :class="isActiveTab('devices')"
                    class="tab"
                    @click="setActiveTab('devices')"
                >
                    Devices
                </div>
            </div>
            <div
                :is="activeView"
                class="view"
            />
        </div>
    </div>
</template>

<style lang="scss">
    .component-wisol-editor-apps {
        @import "@wisol/theme/variables";
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 100%;
        display: flex;
        flex-direction: column;
        background: #fff;
        overflow-y: auto;
        font-size: 1.1em;

        &> .toolbar {
            position: fixed;
            z-index: 2;
            width: 100%;
        }

        &> .editor-container {
            height: 100vh;
            box-sizing: border-box;
            width: 100%;

            &> .tabs {
                display: flex;
                width: 100%;
                justify-content: center;
                background-color: nth($color-palette-grey, 1);
                position: fixed;
                z-index: 2;
                width: 100%;

                & .tab {
                    display: flex;
                    flex-direction: column;
                    min-height: 50px;
                    width: 150px;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2em;
                    cursor: pointer;
                    user-select: none;

                    &.active {
                        border-bottom: 2px solid nth($color-palette-main, 11);
                    }

                    &:active {
                        background-color: nth($color-palette-grey, 2);
                    }
                }
            }

            &> .view {
                padding-top: 50px;
            }
        }
    }
</style>

<script>
import WisolEditorConfigToolbar from './Toolbar'
import WisolEditorAppsViewApps from './view/Apps'
import WisolEditorAppsViewDevices from './view/Devices'
export default {
    name: 'WisoEditorApps',

    components: {
        toolbar: WisolEditorConfigToolbar
    },

    inheritAttrs: false,

    data () {
        return {
            activeTab: 'apps',
            views: {
                apps: WisolEditorAppsViewApps,
                devices: WisolEditorAppsViewDevices
            }
        }
    },

    computed: {
        activeView () {
            return this.views[this.activeTab]
        }
    },

    methods: {
        isActiveTab (tab) {
            return {
                active: tab === this.activeTab
            }
        },

        setActiveTab (tab) {
            this.activeTab = tab
        }
    }
}
</script>
