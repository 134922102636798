<template>
    <div :class="$style.container">
        <div :class="$style.tabs">
            <div
                v-for="(tab, index) in tabs"
                :key="index"
                :class="tabClasses(tab)"
                @click="onClick(tab)"
            >
                {{ tab.label }}
            </div>
        </div>
        <div :class="$style.content">
            <slot
                :active-tab="activeTab"
            />
        </div>
    </div>
</template>

<style lang="scss" module>
    @import "@wisol/theme/variables";

    .container {
        @include wisol-box;
        padding: 0;
    }

    .tabs {
        display: flex;
        background: nth($color-palette-grey, 2);
        padding: 0.5em 0.5em 0 0.5em;
    }

    .tab {
        padding: 0.5em 1em;

        &.active {
            background: $box-background;
        }

        &:not(.active) {
            cursor: pointer;
        }

        &:not(:last-child) {
            margin: 0 1px 0 0;
        }
    }

    .content {
        padding: 0.5em;
    }
</style>

<script>
export default {
    name: 'GenericTabContainer',

    inheritAttrs: false,

    props: {
        tabs: {
            type: Array,
            required: true,
            validator (value) {
                return value.length > 0
            }
        }
    },

    data () {
        return {
            activeTab: null
        }
    },

    watch: {
        tabs: {
            handler () {
                if (this.tabs.indexOf(this.activeTab) === -1) {
                    this.activeTab = this.tabs[0]
                }
            },
            immediate: true
        }
    },

    methods: {
        tabClasses (tab) {
            return {
                [this.$style.tab]: true,
                [this.$style.active]: this.activeTab === tab
            }
        },

        onClick (tab) {
            this.activeTab = tab
        }
    }
}
</script>
