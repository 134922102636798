<template>
    <div class="component-generic-window">
        <div
            :style="headerStyle"
            class="header"
        >
            <span
                v-if="icon"
                :class="iconClasses"
            />

            <div class="title">
                {{ title }}
            </div>

            <generic-action-icons
                :actions="allActions"
                :groups="actionGroups"
            />
        </div>
        <div
            v-show="isMaximized"
            class="container"
        >
            <slot />
        </div>
    </div>
</template>

<style lang="scss">
    @import "@wisol/theme/variables";

    .component-generic-window {
        &> .header {
            font-size: 1.2rem;
            height: 2.2em;
            display: flex;
            align-items: center;
            padding: 0 1em;

            &> .icon {
                margin-right: 0.6em;
                font-size: 1.2em;
            }

            &> .title {
                flex-grow: 1;
                text-transform: uppercase;

            }
        }

        &> .container {
            width: 100%;
            max-width: 100%;
            padding: 0;
        }
    }
</style>

<script>
import ColorsMixin from '@/mixins/Colors'

export default {
    name: 'GenericWindow',

    mixins: [ColorsMixin],

    inheritAttrs: false,

    props: {
        title: {
            type: String,
            required: true
        },

        icon: {
            type: String,
            default: ''
        },

        color: {
            type: String,
            default: '#FFFFFF'
        },

        isMinimized: {
            type: Boolean,
            default: false
        },

        actions: {
            type: Array,
            default () {
                return []
            }
        }
    },

    computed: {
        isMaximized () {
            return !this.isMinimized
        },

        iconClasses () {
            return [
                'icon',
                this.icon
            ]
        },

        headerStyle () {
            return this.headerColorStyle(this.backgroundColor)
        },

        backgroundColor () {
            return this.color
        },

        allActions () {
            return [
                ...this.actions,
                {
                    icon: 'wisol/window',
                    title: this.$t('window.detach'),
                    group: 'window',
                    callback: evt => {}
                },
                {
                    icon: this.isMaximized
                        ? 'wisol/minimize'
                        : 'wisol/maximize',
                    title: this.isMaximized
                        ? this.$t('window.minimize')
                        : this.$t('window.maximize'),
                    group: 'window',
                    callback: evt => {
                        if (this.isMaximized) {
                            this.minimize()
                        } else {
                            this.maximize()
                        }
                    }
                },
                {
                    icon: 'wisol/close',
                    title: this.$t('window.close'),
                    group: 'window',
                    callback: evt => {
                        this.close()
                    }
                }
            ]
        },

        actionGroups () {
            return ['default', 'window']
        }
    },

    methods: {
        close () {
            this.$emit('close')
        },

        minimize () {
            this.$emit('minimize')
        },

        maximize () {
            this.$emit('maximize')
        }
    }
}
</script>
