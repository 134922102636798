<template>
    <div
        class="component-wisol-editor-menu-form-field"
    >
        <generic-form
            :key="'field:' + row['@id']"
            :fields="fields"
            :values="values"
            :layout="layout"
            :column-count="24"
            :cell-height="25"
            :min-cell-width="40"
            :max-cell-width="Infinity"
            :readonly="false"
            @update:value="onUpdateFormValue"
            @update:layout="onLayoutUpdate"
        />
        <generic-loading v-show="isLoading" />
    </div>
</template>

<style lang="scss">
    .component-wisol-editor-menu-form-field {
        padding: 20px 20px 0px 20px;
    }

</style>

<script>
import { Input as Inputs } from '@wisol/libs-inputs'
import layout from './layouts/field.json'

const LOCALSTORAGE_KEY = 'layout_field'

export default {
    name: 'WisolEditorMenuFormField',

    inheritAttrs: false,

    props: {
        row: {
            type: Object,
            default: () => {}
        },

        isLoading: {
            type: Boolean,
            default: false
        }
    },

    data () {
        return {
            layout,
            labels: {
                '@id': 'ID',
                '@dataGroup': 'ID Datagroup',
                disabled: 'Disabled',
                field: 'Field',
                filtervalue: 'Filter value',
                options: 'Options',
                label: 'Label',
                readonly: 'Readonly',
                required: 'Required',
                sort: 'Sort',
                sort_order: 'Sort Order',
                tabindex: 'Tabindex',
                type: 'Type',
                value: 'Value',
                virtual: 'Virtual'
            }
        }
    },

    computed: {
        fields () {
            return Object.keys(this.row).map((field) => {
                let component = Inputs.Text
                const props = {}
                switch (field) {
                    case '@id':
                    case '@dataGroup':
                        component = Inputs.Number
                        props.readonly = true
                        break
                    case 'field':
                    case 'label':
                        props.required = true
                        break
                    case 'disabled':
                    case 'readonly':
                    case 'required':
                    case 'virtual':
                        props.required = true
                        component = Inputs.Checkbox
                        break
                    case 'tabindex':
                        component = Inputs.Number
                        break
                    case 'options':
                        component = Inputs.Object
                        break
                    case 'sort':
                        component = Inputs.Selectbox
                        props.options = {
                            ASC: 'ASC',
                            DESC: 'DESC'
                        }
                        break
                    case 'type':
                        component = Inputs.Selectbox
                        props.required = true
                        props.options = {
                            text: 'Text',
                            dropdown: 'Dropdown',
                            'text-multiline': 'Multiline Text',
                            checkbox: 'Checkbox',
                            date: 'Date',
                            datetime: 'Date + Time',
                            time: 'Time',
                            number: 'Number',
                            ole: 'Ole',
                            html: 'Html',
                            url: 'URL / Link',
                            button: 'Button',
                            duration: 'Duration',
                            array: 'List Of Values (Array)',
                            color: 'Color',
                            code: 'Code',
                            select: 'Select',
                            scale: 'Scale'
                        }
                        break
                }

                return {
                    id: field,
                    field: field,
                    label: this.labels[field],
                    input: {
                        component,
                        props
                    }
                }
            })
        },

        values () {
            return this.row
        }
    },

    mounted () {
        let local = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)) || []
        local = local.reduce((arr, l) => {
            const foundLayout = arr.find((item) => item.id === l.id)

            if (foundLayout) {
                foundLayout.position = l.position
            } else {
                arr.push(l)
            }

            return arr
        }, layout)

        this.layout = local
    },

    methods: {
        onLayoutUpdate (layout) {
            localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(layout))
            this.layout = layout
        },

        onUpdateFormValue ({ field, value }) {
            switch (field) {
                case 'options':
                case 'sort':
                case 'sortorder':
                case 'value':
                case 'filtervalue':
                case 'tabindex':
                    value = value || null
                    break
            }
            const row = {
                ...this.row,
                [field]: value
            }
            this.$emit('updateRow', this.validateRow(row))
        },

        validateRow (row) {
            return {
                ...row,
                '@valid': !!row.field && !!row.label
            }
        }
    }
}

</script>
