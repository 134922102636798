export default {
    load ({ commit }, { rows, total = null }) {
        commit('LOAD_ROWS', { rows })
        commit('SET_TOTAL_COUNT', {
            count: total ?? rows.length
        })
    },

    update ({ commit, getters }, { rowId, values }) {
        if (!getters.has(rowId)) {
            return values
        }

        const row = getters.get(rowId)
        commit('UPDATE_ROW', {
            row,
            values
        })

        return row
    },

    setUpdateRequired ({ commit, getters }, { required }) {
        if (getters.updateRequired !== required) {
            commit('SET_UPDATE_REQUIRED', { required })
        }
    }
}
