import createInput from '../createInput.js'
import UiDuration from '../Ui/Duration.vue'
import TypeDurationValidator from '../../validator/type/Duration.js'
import Parser from '../../parser/Duration.js'

export default createInput(UiDuration, {
    Parser,
    defaultParserOptions: () => ({
        mode: 'time'
    }),
    validators: () => [TypeDurationValidator()]
})
