import Vue from 'vue'
import TemplateParserFactory from '@wisol/libs-inputs/src/parser/Template'
import * as filterUtils from '@/utils/filter'
import { WisolInputSymbol } from '../index.vue'

const queuesByRoot = new Map()
function addToQueue (context, WisolInput, filter) {
    const root = context.$root
    const queue = queuesByRoot.get(root) || {}
    queuesByRoot.set(root, queue)
    const field = WisolInput.id

    if (!queue[field]) {
        queue[field] = {
            requests: [],
            promise: new Promise(resolve => {
                Vue.nextTick(() => {
                    const { requests } = queue[field]
                    delete queue[field]

                    const filter = {
                        $or: requests.map(({ filter }) => filter)
                    }

                    resolve(
                        root.$api
                            .call('ui.field.dropdown', {
                                field,
                                filter
                            })
                            .then(({ result }) => {
                                updateCache(WisolInput, result)
                                return result
                            })
                    )
                })
            })
        }
    }

    queue[field].requests.push({
        field, filter
    })

    return queue[field].promise
}

const resultCache = new Map()

const getCache = function getCache (WisolInput) {
    let cache
    if (resultCache.has(WisolInput.id)) {
        cache = resultCache.get(WisolInput.id)
    } else {
        cache = {
            vms: new Set(),
            rows: []
        }
        resultCache.set(WisolInput.id, cache)
    }

    cache.vms.add(WisolInput)

    const clearCache = function clearCache () {
        WisolInput.$off('hook:destroyed', clearCache)
        cache.vms.delete(WisolInput)
        if (cache.vms.size === 0) {
            setTimeout(() => {
                if (cache.vms.size === 0) {
                    resultCache.delete(WisolInput.id)
                }
            }, 10000)
        }
    }
    WisolInput.$on('hook:destroyed', clearCache)

    return cache.rows
}

const updateCache = function updateCache (WisolInput, newRows) {
    const rows = getCache(WisolInput)
    rows.push(...newRows)
}

export default ({
    context,
    template
}) => {
    const getWisolInput = new Promise(resolve => {
        context.$on('hook:created', () => {
            const vm = new Vue({
                parent: context,

                inject: {
                    WisolInput: {
                        from: WisolInputSymbol
                    }
                }
            })
            resolve(vm.WisolInput)
            vm.$destroy()
        })
    })

    // try to find match from local data
    const localSearch = async (filter, matcher) => {
        const WisolInput = await getWisolInput
        const rows = getCache(WisolInput)

        if (rows.length) {
            const filteredRows = rows.filter(row => matcher(row))
            if (filteredRows.length) {
                return filteredRows[0]
            }
        }
    }

    const serverSearch = async (filter, matcher) => {
        const WisolInput = await getWisolInput
        const rows = await addToQueue(
            context,
            WisolInput,
            filter
        )

        const filteredRows = rows.filter(row => matcher(row))
        if (!filteredRows.length) {
            return Promise.reject(new Error('no match found'))
        }

        return filteredRows[0]
    }

    const search = async filter => {
        const matcher = filterUtils.createMatcher(filter)
        return (await localSearch(filter, matcher) || await serverSearch(filter, matcher))
    }

    return TemplateParserFactory({
        template,
        search
    })
}
