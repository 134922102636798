export default ({
    beautify = false
} = {}) => {
    return {
        async parse (raw) {
            return JSON.parse(raw)
        },

        serialize (value) {
            return JSON.stringify(value, null, beautify ? 4 : null)
        }
    }
}
