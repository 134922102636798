import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RowsModule from './modules/rows'

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    modules: {
        rows: RowsModule
    },
    state () {
        return {
            position: null,
            filter: {},
            sorting: []
        }
    }
}
