export default (context, dataGroup) => {
    return {
        find (where, order, fields, limit) {
            return context.$api
                .dataGroup(dataGroup)
                .search()
                .fields(fields)
                .limit(limit)
                .where(where)
                .order(order)
        },

        create (values) {
            return context.$api
                .dataGroup(dataGroup)
                .insert(values)
        },

        update (id, values) {
            return context.$api
                .dataGroup(dataGroup)
                .update(id, values)
        },

        delete (id) {
            return context.$api
                .dataGroup(dataGroup)
                .delete(id)
        }
    }
}
