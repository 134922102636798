import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'

class ApiError extends Error {
    // eslint-disable-next-line space-before-function-paren
    constructor([type, message]) {
        super(message)
        this.type = type
    }
}

const APP_KEY = '0ddb93f7-ba08-4675-ab33-331b306a8c86'
const DEVICE_KEY = (() => {
    let key = localStorage.getItem('WISOL_API_DEVICE_KEY')
    if (!key) {
        key = uuidv4()
        localStorage.setItem('WISOL_API_DEVICE_KEY', key)
    }
    return key
})()

const api = http => {
    let collect = []
    let batchPromise = null

    const httpConfig = {
        headers: {
            'Wisol-Api-App-Key': APP_KEY,
            'Wisol-Api-Device-Key': DEVICE_KEY
        }
    }

    const _createCRUD = (mode, params) => {
        return {
            async get (id) {
                if (!id) {
                    throw new Error('"id" is missing or empty')
                }

                const { result } = await endpoint(`${mode}.get`, { ...params, id })
                if (!result) {
                    throw new Error('Result is empty')
                }

                return {
                    row: result
                }
            },

            search () {
                let _fields = []
                let _where = {}
                let _order = {}
                let _limit = null

                let _request = null
                const doSearchRequest = () => {
                    if (!_request) {
                        _request = endpoint(`${mode}.search`, {
                            ...params,
                            fields: _fields,
                            where: _where,
                            order: _order,
                            limit: _limit
                        })
                            .then(({ result, meta }) => {
                                return {
                                    total: meta.totalCount ?? result.length,
                                    rows: result
                                }
                            })
                    }
                    return _request
                }

                let doSearchRequestPromise
                return {
                    fields (fields) {
                        _fields = fields
                        return this
                    },

                    where (where) {
                        _where = where
                        return this
                    },

                    order (order) {
                        _order = order
                        return this
                    },

                    limit (limit) {
                        _limit = limit
                        return this
                    },

                    then (...args) {
                        doSearchRequestPromise ??= doSearchRequest()
                        return doSearchRequestPromise.then(...args)
                    },

                    catch (...args) {
                        doSearchRequestPromise ??= doSearchRequest()
                        return doSearchRequestPromise.catch(...args)
                    }
                }
            },

            insert (values) {
                return endpoint(`${mode}.insert`, { ...params, values })
                    .then(({ result }) => {
                        if (result) {
                            return {
                                row: result
                            }
                        }
                        return Promise.reject(new Error('could not insert'))
                    })
            },

            update (id, values) {
                if (!id) {
                    return Promise.reject(new Error('"id" is missing or empty'))
                }
                return endpoint(`${mode}.update`, { ...params, id, values })
                    .then(({ result }) => {
                        if (result) {
                            return {
                                row: result
                            }
                        }
                        return Promise.reject(new Error('could not update ' + id))
                    })
            },

            delete (id) {
                if (!id) {
                    return Promise.reject(new Error('"id" is missing or empty'))
                }
                return endpoint(`${mode}.delete`, { ...params, id })
                    .then(({ result }) => {
                        if (result) {
                            return {
                                row: result
                            }
                        }
                        return Promise.reject(new Error(id + ' does not exist'))
                    })
            }
        }
    }

    const batch = async (endpoint, params = {}) => {
        const data = {
            '@endpoint': endpoint,
            ...params
        }

        const index = collect.push(data) - 1

        if (!batchPromise) {
            batchPromise = new Promise(resolve => {
                Vue.nextTick(() => {
                    batchPromise = null
                    const collectCopy = collect
                    collect = []
                    resolve(http.post('api/ui/batch', collectCopy, httpConfig))
                })
            })
        }

        let result
        try {
            result = await batchPromise
        } catch (err) {
            if (err?.response?.data?.error) {
                throw new ApiError(err.response.data.error)
            }
            throw err
        }

        if (!result.data || !result.data[index]) {
            throw new Error('Unknown error: Response is empty')
        }
        if (result.data[index].status !== 'SUCCESS') {
            if (result.data[index].error) {
                throw new ApiError(result.data[index].error)
            }
            throw new Error('Unknown error: Api error is empty')
        }
        return result.data[index]
    }

    const endpoint = async (endpoint, params = {}) => {
        const data = {
            '@endpoint': endpoint,
            ...params
        }

        let result
        try {
            result = await http.post('api/ui/endpoint', data, httpConfig)
        } catch (err) {
            if (err?.response?.data?.error) {
                throw new ApiError(err.response.data.error)
            }
            throw err
        }

        if (!result.data) {
            throw new Error('Unknown error: Response is empty')
        }
        return {
            result: result.data,
            meta: JSON.parse(result.headers['wisol-api-meta'] || '{}')
        }
    }

    const blob = async (endpoint, params = {}) => {
        const data = {
            '@endpoint': endpoint,
            ...params
        }

        let result
        try {
            result = await http.post('api/ui/endpoint', data, {
                ...httpConfig,
                responseType: 'blob'
            })
        } catch (err) {
            if (err?.response?.data?.error) {
                throw new ApiError(err.response.data.error)
            }
            throw err
        }

        if (!result.data) {
            throw new Error('Unknown error: Response is empty')
        }
        return result.data
    }

    const table = table => {
        if (!table) {
            throw new Error('"table" is missing or empty')
        }

        return {
            ..._createCRUD('table', { table }),

            async ole (id) {
                if (!id) {
                    throw new Error('"id" is missing or empty')
                }

                const result = await http.get(
                    'api/' + encodeURIComponent(table) + '/ole/' + encodeURIComponent(id),
                    {
                        ...httpConfig,
                        responseType: 'blob'
                    }
                )
                if (!result) {
                    throw new Error(id + ' does not exist')
                }

                return result
            }
        }
    }

    const dataGroup = dataGroup => {
        if (!dataGroup) {
            throw new Error('"dataGroup" is missing or empty')
        }

        return _createCRUD('ui.dataGroup', { dataGroup })
    }

    return { call: endpoint, endpoint, batch, blob, table, dataGroup }
}

export const factory = http => {
    return api(http)
}

export default {
    install (Vue, options) {
        let $api
        Object.defineProperty(Vue.prototype, '$api', {
            get () {
                if (!$api) {
                    $api = factory(this.$http)
                }
                return $api
            }
        })
    }
}
