<template>
    <div :style="wrapperStyle">
        <slot />
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    inheritAttrs: false,

    props: {
        dataGroup: {
            type: Vue,
            required: true
        },

        field: {
            type: Object,
            default: null
        },

        row: {
            type: Object,
            default: null
        }
    },

    computed: {
        wrapperStyle () {
            return this.dataGroup.getCombinedOptions(this.field, this.row)?.style || {}
        }
    }
}
</script>
