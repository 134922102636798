import isArray from 'lodash-es/isArray'
import sift from 'sift'

const getConditionKey = function (condition) {
    return Object.keys(condition)[0]
}

export const normalize = function (filter) {
    filter = !isArray(filter)
        ? [filter]
        : filter
    const normalizedFilter = []
    filter.forEach(cond => {
        if (isArray(cond)) {
            cond = {
                $and: cond
            }
        }
        for (const key in cond) {
            const value = key[0] === '$'
                ? normalize(cond[key])
                : cond[key]
            normalizedFilter.push({
                [key]: value
            })
        }
    })
    return normalizedFilter
}

export const isEmpty = function (normalizedFilter) {
    return !normalizedFilter.find(condition => {
        const key = getConditionKey(condition)
        if (key[0] === '$') {
            return !isEmpty(condition[key])
        }
        return key.length > 0
    })
}

export const removeEmpty = function (normalizedFilter) {
    return normalizedFilter
        .filter(condition => !isEmpty([condition]))
        .map(condition => {
            const key = getConditionKey(condition)
            if (key[0] === '$') {
                return {
                    [key]: removeEmpty(condition[key])
                }
            }
            return condition
        })
}

export const flatten = function (normalizedFilter, operator = '$and') {
    let flattedFilter = []
    normalizedFilter
        .forEach(condition => {
            const key = getConditionKey(condition)
            if (key[0] === '$') {
                const value = flatten(condition[key], key)
                if (value.length === 1 || operator === key) {
                    flattedFilter = [
                        ...flattedFilter,
                        ...value
                    ]
                } else {
                    flattedFilter.push(condition)
                }
            } else {
                flattedFilter.push(condition)
            }
        })
    return flattedFilter
}

export const optimize = function (filter) {
    return flatten(removeEmpty(normalize(filter)))
}

export const createMatcher = function (filter) {
    return sift(filter)
}
